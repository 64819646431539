import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav, ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {mega} from '../../types';
import {report4030Api} from '../../api/mega';
import * as messages from '../../messages';
import {Report4030CertificationForm, Report4030HistoryTable} from '../../components/mega';
import * as permissionUtils from '../../utils/permissionUtils';

const Report4030 = () => {
  const {currentUser} = useUserContext();
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    processing: false
  });
  const [report4030Dto, setReport4030Dto] = useState<mega.Report4030Dto | undefined>(undefined);
  const {showSuccessAlert, showErrorAlert} = useAlerts();

  const isFosAnalystForCounty = useMemo(() => {
    return permissionUtils.isFosAnalystForCounty(currentUser, Number(countyId));
  }, [
    currentUser,
    countyId
  ]);

  const isFosSupervisorForCounty = useMemo(() => {
    return permissionUtils.isFosSupervisorForCounty(currentUser, Number(countyId));
  }, [
    currentUser,
    countyId
  ]);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: report4030Dto?.county?.displayNameWithType ?? '', active: true}
  ]), [year, countyId, report4030Dto]);

  useEffect(() => {
    const loadReport4030Dto = async () => {
      try {
        const report4030Dto = await report4030Api.find(countyId, year);
        setReport4030Dto(report4030Dto);
        setLoadingState({loadError: false, loading: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadReport4030Dto();
  }, [countyId, year, showErrorAlert]);

  const handleCertifyClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});

    try {
      if (isFosAnalystForCounty) {
        await report4030Api.certifyAnalyst(countyId, year);
      } else if (isFosSupervisorForCounty) {
        await report4030Api.certifySupervisor(countyId, year);
      }
      const report4030Dto = await report4030Api.find(countyId, year);
      setReport4030Dto(report4030Dto);
      showSuccessAlert(messages.REPORT_CERTIFICATION_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.REPORT_CERTIFICATION_FAILURE);
    }

    setLoadingState({...loadingState, processing: false});
  }, [
    loadingState,
    isFosAnalystForCounty,
    isFosSupervisorForCounty,
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handlePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await report4030Api.openPdf(countyId, year, showErrorAlert);
    } catch {
      showErrorAlert(messages.VIEW_PDF_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyId, year, showErrorAlert, loadingState]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && report4030Dto && <>
      <Row className="mb-4">
        <Col md="4">
          <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
        </Col>
        <Col md="8" className="d-flex justify-content-end">
          <Button color="primary"
                  disabled={loadingState.processing}
                  onClick={() => handlePdfClick()}>
            View PDF
          </Button>
        </Col>
      </Row>
      {(isFosAnalystForCounty || isFosSupervisorForCounty) && <Report4030CertificationForm
        report4030Dto={report4030Dto}
        onCertify={handleCertifyClick}
        isFosAnalystForCounty={isFosAnalystForCounty}
        isFosSupervisorForCounty={isFosSupervisorForCounty}
        currentUser={currentUser}
        loadingState={loadingState}/>}
      <Report4030HistoryTable items={report4030Dto.history}/>
    </>}
  </Container>;
};

export default Report4030;