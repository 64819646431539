import {memo} from 'react';

import {ButtonIcon} from '@reasoncorp/kyber-js';

import {formatDate, formatInteger} from '../../../utils';
import {forms} from '../../../types';

type Props = {
  property: forms.Form4014aPropertyDto
  onEdit: (action: string, property: forms.Form4014aPropertyDto) => void
  onDelete: (property: forms.Form4014aPropertyDto) => void
  showReadOnlyView: boolean
}

const Form4014aPropertyRow = ({
                                property,
                                onEdit,
                                onDelete,
                                showReadOnlyView
                              }: Props) => {
  return (
    <tr>
      <td className="align-middle">{property.propertyNumber}</td>
      <td className="align-middle">{property.propertyAddress}</td>
      <td className="align-middle text-center">{property.appraiser}</td>
      <td className="align-middle text-center">{property.propertyClassification}</td>
      <td className="align-middle text-center">{formatInteger(property.currentYearAssessedValue)}</td>
      <td className="align-middle text-center">{property.vacantOrImproved}</td>
      <td className="align-middle text-center">{formatDate(property.fieldInspectionOn)}</td>
      <td className="align-middle text-center">{formatDate(property.dateOfEntry)}</td>
      <td className="align-middle text-nowrap">{property.comments}</td>
      {!showReadOnlyView && <td className="align-middle text-center">
        <ButtonIcon className="text-primary"
                    ariaLabel="Edit Parcel"
                    title="Edit Parcel"
                    icon="cog"
                    onClick={() => onEdit('Edit', property)}
        />
      </td>}
      {!showReadOnlyView && <td className="align-middle text-center">
        <ButtonIcon icon="trash"
                    title="Delete Parcel"
                    ariaLabel="Delete Parcel"
                    className="text-danger"
                    onClick={() => onDelete(property)}
        />
      </td>}
    </tr>
  );
};

export default memo(Form4014aPropertyRow);
