import {useMemo} from 'react';
import {Table} from 'reactstrap';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {forms} from '../../../types';
import {formatInteger} from '../../../utils';
import * as form4024Utils from '../../../utils/form4024Utils';

type Props = {
  localUnits: forms.Form4024LocalUnitDto[]
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: forms.Form4024Totals
}

const Form4024RealAssessedValuationsTab = ({
                                             localUnits,
                                             showReadOnlyView,
                                             setHasUnsavedChanges,
                                             totals

                                           }: Props) => {
  const renderRow = useMemo(() => (form4024LocalUnit: forms.Form4024LocalUnitDto, index: number) => {
    const localUnitTotals = form4024Utils.calculateLocalUnitTotals(form4024LocalUnit);

    return <tr key={index}>
      <td className="align-middle text-primary font-weight-bold">{form4024LocalUnit.displayName}</td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.agricultural)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.agricultural`}
                                                 ariaLabel="Real Property - Agricultural Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.commercial)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.commercial`}
                                                 ariaLabel="Real Property - Commercial Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.industrial)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.industrial`}
                                                 ariaLabel="Real Property - Industrial Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.residential)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.residential`}
                                                 ariaLabel="Real Property - Residential Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.timberCutover)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.timberCutover`}
                                                 ariaLabel="Real Property - Timber-Cutover Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.assessed.developmental)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].assessed.developmental`}
                                                 ariaLabel="Real Property - Developmental Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {formatInteger(localUnitTotals.assessed)}
      </td>
    </tr>;
  }, [
    showReadOnlyView,
    setHasUnsavedChanges
  ]);

  return <Table responsive bordered>
    <thead>
      <tr>
        <th className="align-middle text-primary">Township/City</th>
        <th className="align-middle text-primary text-center">Agricultural</th>
        <th className="align-middle text-primary text-center">Commercial</th>
        <th className="align-middle text-primary text-center">Industrial</th>
        <th className="align-middle text-primary text-center">Residential</th>
        <th className="align-middle text-primary text-center">Timber-Cutover</th>
        <th className="align-middle text-primary text-center">Developmental</th>
        <th className="align-middle text-primary text-center">Total Real Property</th>
      </tr>
    </thead>
    <tbody>
      {localUnits.map(renderRow)}
    </tbody>
    <tfoot className="bg-light">
      <tr>
        <td className="align-middle font-weight-bold text-primary">Totals</td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.agricultural)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.commercial)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.industrial)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.residential)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.timberCutover)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.assessed.developmental)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyAssessed)}
        </td>
      </tr>
    </tfoot>
  </Table>;
};

export default Form4024RealAssessedValuationsTab;