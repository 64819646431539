import {forms} from '../types';
import {sumObjects} from './math';

const mapRealProperty = (classification: forms.RealPropertyClassificationCode, form: forms.Form4018V1Dto) => {
  return {
    studyType: form.realProperty?.classifications?.[classification]?.studyType ?? '',
    stratifiedStudy: form?.realProperty?.classifications?.[classification]?.stratifiedStudy ?? '',
    combinedStudy: form?.realProperty?.classifications?.[classification]?.combinedStudy ?? '',
    assessmentRollClassificationAssessedValue: form?.realProperty?.classifications?.[classification]?.assessmentRollClassificationAssessedValue ?? '',
    sampleAssessedValue: form?.realProperty?.classifications?.[classification]?.sampleAssessedValue ?? '',
    numberOfParcels: form?.realProperty?.classifications?.[classification]?.numberOfParcels ?? '',
    trueCashValue: form?.realProperty?.classifications?.[classification]?.trueCashValue ?? '',
    ratioAssessmentToAppraisals: form?.realProperty?.classifications?.[classification]?.ratioAssessmentToAppraisals ?? '',
    projectedTrueCashValue: form?.realProperty?.classifications?.[classification]?.projectedTrueCashValue ?? '',
    remarks: form?.realProperty?.classifications?.[classification]?.remarks ?? ''
  };
};

const mapPersonalProperty = (classification: forms.PersonalPropertyClassificationCode, form: forms.Form4018V1Dto) => {
  return {
    studyType: form?.personalProperty?.classifications?.[classification]?.studyType ?? '',
    unitEndingAssessedValue: form?.personalProperty?.classifications?.[classification]?.unitEndingAssessedValue ?? '',
    numberOfParcels: form?.personalProperty?.classifications?.[classification]?.numberOfParcels ?? '',
    assessedValue: form?.personalProperty?.classifications?.[classification]?.assessedValue ?? '',
    trueCashValue: form?.personalProperty?.classifications?.[classification]?.trueCashValue ?? '',
    studyRatio: form?.personalProperty?.classifications?.[classification]?.studyRatio ?? '',
    unitStartingTrueCashValue: form?.personalProperty?.classifications?.[classification]?.unitStartingTrueCashValue ?? '',
    remarks: form?.personalProperty?.classifications?.[classification]?.remarks ?? ''
  };
};

const calculateTotals = (form4018: forms.Form4018V1Dto) => {
  const [realProperty, personalProperty] = [
    Object.values(form4018.realProperty.classifications),
    Object.values(form4018.personalProperty.classifications)
  ];

  return {
    realProperty: {
      assessmentRollClassificationAssessedValue: sumObjects(realProperty, 'assessmentRollClassificationAssessedValue'),
      numberOfParcels: sumObjects(realProperty, 'numberOfParcels'),
      projectedTrueCashValue: sumObjects(realProperty, 'projectedTrueCashValue')
    },
    personalProperty: {
      unitEndingAssessedValue: sumObjects(personalProperty, 'unitEndingAssessedValue'),
      numberOfParcels: sumObjects(personalProperty, 'numberOfParcels'),
      unitStartingTrueCashValue: sumObjects(personalProperty, 'unitStartingTrueCashValue')
    }
  };
};

const getInitialValues = (form4018V1Dto: forms.Form4018V1Dto) => ({
  realProperty: {
    classifications: {
      100: mapRealProperty(100, form4018V1Dto),
      200: mapRealProperty(200, form4018V1Dto),
      300: mapRealProperty(300, form4018V1Dto),
      400: mapRealProperty(400, form4018V1Dto),
      500: mapRealProperty(500, form4018V1Dto),
      600: mapRealProperty(600, form4018V1Dto)
    },
    estimatedValues: form4018V1Dto?.realProperty?.estimatedValues ?? ''
  },
  personalProperty: {
    classifications: {
      150: mapPersonalProperty(150, form4018V1Dto),
      250: mapPersonalProperty(250, form4018V1Dto),
      350: mapPersonalProperty(350, form4018V1Dto),
      450: mapPersonalProperty(450, form4018V1Dto),
      550: mapPersonalProperty(550, form4018V1Dto)
    },
    estimatedValues: form4018V1Dto?.personalProperty?.estimatedValues ?? '',
    additionalRemarks: form4018V1Dto?.personalProperty?.additionalRemarks ?? ''
  }
});

export {
  calculateTotals,
  getInitialValues
};