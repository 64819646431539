import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import {FormikInput, FormikRadioGroup} from '@reasoncorp/kyber-js';

import {formatBoolean} from '../../../utils';
import {forms} from '../../../types';
import {Form4027iAdditionalInformationDto} from '../../../types/forms/form4027i';

type Props = {
  additionalInformation: Form4027iAdditionalInformationDto
  showReadOnlyView: boolean
}

const Form4027iAdditionalInformationTab = ({
                                             additionalInformation,
                                             showReadOnlyView
                                           }: Props) => {
  return <>
    <Row>
      <Col>
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="text-nowrap text-primary w-10">Real Classification</th>
              <th className="text-nowrap text-primary text-center w-45">Land Value Study Comments</th>
              <th className="text-nowrap text-primary text-center w-45">ECF Study Comments</th>
            </tr>
          </thead>
          <tbody>
            {['100', '200', '300', '400', '500', '600'].map(classification => {
              const currentComments = additionalInformation
                .comments[classification as unknown as forms.RealPropertyClassificationCode];

              return <tr>
                <td className="font-weight-bold text-primary">{classification}</td>
                <td>
                  {showReadOnlyView && currentComments.landValueStudyComments}
                  {!showReadOnlyView &&
                    <FormikInput name={`additionalInformation.comments['${classification}'].landValueStudyComments`}
                                 disableFloatingLabel={true}
                                 formGroupClass="mb-0"
                                 type="textarea"
                                 rows={2}
                                 maxLength={200}
                                 ariaLabel="Land Value Study Comments"/>}
                </td>
                <td>
                  {showReadOnlyView && currentComments.ecfStudyComments}
                  {!showReadOnlyView &&
                    <FormikInput name={`additionalInformation.comments['${classification}'].ecfStudyComments`}
                                 disableFloatingLabel={true}
                                 formGroupClass="mb-0"
                                 type="textarea"
                                 rows={2}
                                 maxLength={200}
                                 ariaLabel="ECF Study Comments"/>}
                </td>
              </tr>;
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col sm="12" lg="12">
        <Card>
          <CardHeader>
            Additional Overall Study Comment for Current Year Land Value and ECF Study Status at Large
          </CardHeader>
          <CardBody>
            {showReadOnlyView && additionalInformation.overallStudyComments}
            {!showReadOnlyView && <FormikInput
              name="additionalInformation.overallStudyComments"
              maxLength="200"
              labelText="Overall Study Comments"/>}
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Card>
          <CardHeader>
            CED Adequate Procedures
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" lg="9" className="font-weight-bold">
                Does the County Equalization Department have adequate procedures in place to identify, inventory, conduct audits or record verifications of personal property; monitor local unit compliance with current directives in the handling of personal property; and result in a realistic estimated TCV projection for all non-exempt Personal Property where situs is within the county?
              </Col>
              <Col className="d-flex justify-content-lg-end justify-content-sm-start col-sm-12 col-lg-3">
                {showReadOnlyView && <span className="font-weight-bold text-primary">
                                  {formatBoolean(additionalInformation.adequateProcedures)}
                                </span>}
                {!showReadOnlyView && <FormikRadioGroup name="additionalInformation.adequateProcedures"
                                                        ariaLabel="Adequate Procedures"
                                                        aria-required={true}
                                                        radioButtons={[
                                                          {
                                                            labelText: 'Yes',
                                                            value: 'true',
                                                            ariaLabel: 'Yes'
                                                          },
                                                          {
                                                            labelText: 'No',
                                                            value: 'false',
                                                            ariaLabel: 'No'
                                                          }
                                                        ]}
                                                        inline={true}/>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {showReadOnlyView && additionalInformation.adequateProceduresComments}
                {!showReadOnlyView && <FormikInput name="additionalInformation.adequateProceduresComments"
                                                   maxLength="200"
                                                   aria-required={true}
                                                   labelText="County Equalization Department Comments"/>
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Card>
          <CardHeader>
            Boundary Changes
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" lg="9" className="font-weight-bold">
                Have there been or are you aware of any boundary changes, new cities, annexations new or in progress?
              </Col>
              <Col className="d-flex justify-content-lg-end justify-content-sm-start col-sm-12 col-lg-3">
                {showReadOnlyView && <span className="font-weight-bold text-primary">
                                  {formatBoolean(additionalInformation.boundaryChanges)}
                                </span>}
                {!showReadOnlyView && <FormikRadioGroup name="additionalInformation.boundaryChanges"
                                                        ariaLabel="Boundary Changes"
                                                        radioButtons={[
                                                          {
                                                            labelText: 'Yes',
                                                            value: 'true',
                                                            ariaLabel: 'Yes'
                                                          },
                                                          {
                                                            labelText: 'No',
                                                            value: 'false',
                                                            ariaLabel: 'No'
                                                          }
                                                        ]}
                                                        aria-required={true}
                                                        inline={true}/>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {showReadOnlyView && <span>
                                  {additionalInformation.boundaryChangesComments}
                                </span>}
                {!showReadOnlyView && <FormikInput name="additionalInformation.boundaryChangesComments"
                                                   maxLength="200"
                                                   aria-required={additionalInformation?.boundaryChanges?.toString() === 'true'}
                                                   labelText="Boundary Changes Comments"/>
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </>;
};

export default Form4027iAdditionalInformationTab;