import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

const realPropertySection = Yup.object({
  agricultural: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull),
  commercial: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull),
  industrial: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull),
  residential: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull),
  timberCutover: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull),
  developmental: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .max(999999999999999, messages.maxValueMessage(999999999999999))
    .transform(transformBlankToNull)
});

export default Yup.object().shape({
  localUnits: Yup.array().of(Yup.object({
    numberOfAcres: Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER)
      .max(999999.99, messages.maxValueMessage(999999.99))
      .transform(transformBlankToNull),
    personalPropertyEqualized: Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER)
      .max(999999999999999, messages.maxValueMessage(999999999999999))
      .transform(transformBlankToNull),
    personalPropertyAssessed: Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER)
      .max(999999999999999, messages.maxValueMessage(999999999999999))
      .transform(transformBlankToNull),
    equalized: realPropertySection,
    assessed: realPropertySection
  }))
});