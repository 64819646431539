import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: string
  isClickable?: boolean
  onClick?: () => void
  is4022Sa: boolean
}

// This represents the State view of the Form 4022 Local Unit form, filled out by the county user
const Form4022StateStatusBadge = ({
                                    status,
                                    isClickable,
                                    is4022Sa,
                                    onClick
                                  }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.ACCEPTED || (is4022Sa && status === FormStatus.RECEIVED)) {
      return 'success';
    } else if (status === FormStatus.SUBMITTED || status === FormStatus.RECEIVED) {
      return 'danger';
    } else {
      return 'light';
    }
  }, [status, is4022Sa]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.SUBMITTED || status === FormStatus.RECEIVED) {
      return FormStatus.RECEIVED;
    } else if (status === FormStatus.RETURNED_FROM_STATE) {
      return FormStatus.RETURNED;
    } else if (status === FormStatus.RETURNED_FROM_COUNTY) {
      return FormStatus.IN_PROGRESS;
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status]);

  return (
    <Badge color={color}
           tabIndex={isClickable ? 0 : -1}
           className="font-size-100 text-uppercase border-0"
           onClick={onClick}>
      {statusDisplayValue}
    </Badge>
  );
};

export default memo(Form4022StateStatusBadge);