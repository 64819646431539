import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  MiSuiteRole,
  ProgressIndicator,
  ssoUtils,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {filingCabinetApi, megApi} from '../api';
import * as messages from '../messages';
import {formatDate} from '../utils';
import {FileShareModal, FileUploadModal} from '../components/filingCabinet';
import {County, FileUpload, FilingCabinetUpload, LocalUnit} from '../types';
import {downloadFile} from '../api/apiUtils';

const CountyFilingCabinet = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {currentUser} = useUserContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [fileUploadModalIsOpen, setFileUploadModalIsOpen] = useState(false);
  const [fileShareModalIsOpen, setFileShareModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [county, setCounty] = useState<County | undefined>(undefined);
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const [selectedFileUpload, setSelectedFileUpload] = useState<FileUpload | undefined>(undefined);

  const hasCountyFilingCabinetDeleteAccess = useMemo(() => ssoUtils.hasJurisdictionCanonicalIdAndRole(
    currentUser,
    Number(countyId),
    MiSuiteRole.EQUALIZATION_DIRECTOR
  ) || ssoUtils.hasJurisdictionCanonicalIdAndRole(
    currentUser,
    Number(countyId),
    MiSuiteRole.EQUALIZATION_DEPUTY
  ), [currentUser, countyId]);

  useEffect(() => {
    const findFileUploads = async () => {
      try {
        const [fileUploads, counties, localUnits] = await Promise.all([
          filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year),
          megApi.findCounties(),
          megApi.findLocalUnitsByCountyId(countyId)
        ]);
        const currentCounty = counties.filter(county => county.id === Number(countyId))[0];
        setFileUploads(fileUploads);
        setCounty(currentCounty);
        setLocalUnits(localUnits);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void findFileUploads();
  }, [
    countyId,
    year,
    showErrorAlert
  ]);

  const handleDelete = useCallback(async (fileUploadId: number) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await filingCabinetApi.deleteFile(countyId, fileUploadId);
      const fileUploads = await filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year);
      setFileUploads(fileUploads as unknown as FileUpload[]);
      showSuccessAlert(messages.FILE_DELETE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FILE_DELETE_FAILED, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [countyId, showErrorAlert, year, loadingState, showSuccessAlert]);

  const toggleDeleteModal = useCallback(async (confirmDelete = false) => {
    if (confirmDelete && selectedFileUpload) {
      await handleDelete(selectedFileUpload.id);
    }

    setSelectedFileUpload(undefined);
    setDeleteModalIsOpen(!deleteModalIsOpen);
  }, [
    deleteModalIsOpen,
    selectedFileUpload,
    handleDelete
  ]);

  const handleOpenDeleteModal = useCallback((fileUpload: FileUpload) => {
    setSelectedFileUpload(fileUpload);
    setDeleteModalIsOpen(true);
  }, []);

  const breadcrumbs = useMemo(() => ([
    {text: 'County Dashboard', active: false, route: `/county-portal/${countyId}/${year}`, icon: 'home' as const},
    {text: county?.displayName ?? '', active: true}
  ]), [
    county,
    countyId,
    year
  ]);

  const handleDownload = useCallback((url: string) => downloadFile(showErrorAlert)(url), [showErrorAlert]);

  const handleUpload = useCallback(async (filingCabinetUpload: FilingCabinetUpload,
                                          actions: FormikHelpers<FilingCabinetUpload>) => {
    try {
      setLoadingState({...loadingState, processing: true});
      const formData = new FormData();
      filingCabinetUpload.files.forEach(file => formData.append(`files`, file as File));
      filingCabinetUpload.descriptions.forEach((description, index) => formData.append(`descriptions[${index}]`, description));

      await filingCabinetApi.upload(countyId, year, formData);
      actions.resetForm();
      setFileUploadModalIsOpen(false);
      showSuccessAlert(messages.FILE_UPLOAD_SUCCESSFUL);

      const fileUploads = await filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year);
      setFileUploads(fileUploads);
    } catch (e) {
      actions.setSubmitting(false);
      actions.resetForm();
      setFileUploadModalIsOpen(false);
      showErrorAlert(messages.FILE_UPLOAD_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    year,
    showErrorAlert,
    loadingState,
    showSuccessAlert
  ]);

  const handleShareCancel = useCallback(() => {
    setFileShareModalIsOpen(!fileShareModalIsOpen);
    setSelectedFileUpload(undefined);
  }, [
    fileShareModalIsOpen
  ]);

  const handleShare = useCallback(async (localUnitIds: number[]) => {
    setLoadingState({...loadingState, processing: true});
    if (selectedFileUpload) {
      try {
        await filingCabinetApi.share(countyId, selectedFileUpload.id, localUnitIds);
        const fileUploads = await filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year);
        setFileUploads(fileUploads as unknown as FileUpload[]);
        setFileShareModalIsOpen(false);
        showSuccessAlert(messages.FILE_SHARE_SUCCESSFUL);
        setSelectedFileUpload(undefined);
      } catch (e) {
        setFileShareModalIsOpen(false);
        showErrorAlert(messages.FILE_SHARE_FAILED, true);
      } finally {
        setLoadingState({...loadingState, processing: false});
      }
    }
  }, [
    countyId,
    selectedFileUpload,
    showErrorAlert,
    year,
    loadingState,
    showSuccessAlert
  ]);

  const handleFileShareClick = useCallback((fileUpload: FileUpload) => {
    setSelectedFileUpload(fileUpload);
    setFileShareModalIsOpen(true);
  }, []);

  const tableProps = useMemo(() => ({
    headers: [
      {
        title: 'Date',
        className: 'text-nowrap text-center',
        sortKey: 'createdAt'
      },
      {
        title: 'File Name',
        className: 'text-nowrap',
        sortKey: 'fileName'
      },
      {
        title: 'File Description',
        className: 'text-nowrap'
      }, {
        title: 'View File',
        className: 'text-nowrap text-center'
      },
      {
        title: 'Share File',
        className: 'text-nowrap text-center'
      },
      {
        title: 'Delete File',
        className: 'align-middle text-nowrap text-center',
        hide: !hasCountyFilingCabinetDeleteAccess
      }
    ],
    noResultsMessage: messages.NO_FILES_UPLOADED,
    items: fileUploads,
    renderRow: (fileUpload: FileUpload) => <tr key={fileUpload.id}>
      <td className="text-center align-middle">{formatDate(fileUpload.createdAt)}</td>
      <td className="align-middle">{fileUpload.fileName}</td>
      <td className="align-middle">{fileUpload.description}</td>
      <td className="text-center align-middle">
        <ButtonIcon ariaLabel="View File"
                    title="View File"
                    size="lg"
                    icon="paperclip"
                    onClick={() => handleDownload(fileUpload.url)}/>
      </td>
      <td className="text-center align-middle">
        <ButtonIcon ariaLabel="Share File"
                    title="Share File"
                    size="lg"
                    icon="share-square"
                    onClick={() => handleFileShareClick(fileUpload)}/>
      </td>
      {hasCountyFilingCabinetDeleteAccess && <td className="align-middle text-center">
        <ButtonIcon onClick={() => handleOpenDeleteModal(fileUpload)}
                    icon="trash"
                    className="text-danger"
                    title="Delete File"
                    ariaLabel="Delete File"
                    size="lg"/>
      </td>}
    </tr>,
    initialSort: {
      sortKey: 'createdAt',
      direction: 'desc' as const
    }
  }), [
    fileUploads,
    handleDownload,
    handleFileShareClick,
    handleOpenDeleteModal,
    hasCountyFilingCabinetDeleteAccess
  ]);

  return <Container fluid className="CountyFilingCabinet">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && <>
      <Row className="mb-3">
        <Col md="6">
          <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Button color="primary"
                  onClick={() => setFileUploadModalIsOpen(!fileUploadModalIsOpen)}
                  disabled={loadingState.loadError || loadingState.processing}>
            Upload File
          </Button>
        </Col>
      </Row>
      <Card>
        <CardHeader className="bg-secondary text-white">
          Filing Cabinet
        </CardHeader>
        <CustomTable {...tableProps}/>
      </Card>
    </>}

    <FileUploadModal isOpen={fileUploadModalIsOpen}
                     onSubmit={handleUpload}
                     onToggle={() => setFileUploadModalIsOpen(!fileUploadModalIsOpen)}/>

    {selectedFileUpload && <FileShareModal isOpen={fileShareModalIsOpen}
                                           fileUpload={selectedFileUpload}
                                           onCancel={handleShareCancel}
                                           onShare={handleShare}
                                           processing={loadingState.processing}
                                           localUnits={localUnits}/>}

    <ConfirmationModal isOpen={deleteModalIsOpen}
                       title="Delete Upload"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       confirmCallback={() => toggleDeleteModal(true)}
                       cancelCallback={() => toggleDeleteModal(false)}>
      <p>
        Are you sure you want to delete the <span className="text-danger">{selectedFileUpload && selectedFileUpload.fileName}</span> upload?
      </p>
    </ConfirmationModal>
  </Container>;
};

export default CountyFilingCabinet;