enum FormStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_TO_COUNTY = 'SUBMITTED_TO_COUNTY',
  ACCEPTED = 'ACCEPTED',
  RETURNED = 'RETURNED',
  RECEIVED = 'RECEIVED',
  RETURNED_FROM_STATE = 'RETURNED_FROM_STATE',
  RETURNED_FROM_COUNTY = 'RETURNED_FROM_COUNTY',
  SENT_TO_REVIEW_TO_LOCAL_UNIT = 'SENT_TO_REVIEW_TO_LOCAL_UNIT'
}

export default FormStatus;
