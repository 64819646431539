import {memo, useCallback, useMemo} from 'react';
import {Button, Col, Modal, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikCheckboxGroup, FormikInput} from '@reasoncorp/kyber-js';

import {form4022LuCertificationSchema} from '../../../schemas';

type Props = {
  isOpen: boolean
  onConfirm: (values: FormFields) => void
  onCancel: () => void
  formName: string
  formDescription: string
  localUnitDisplayName: string
  isAmending: boolean
}

type FormFields = {
  confirmed: boolean
  comment: string
}

const Form4022LuSubmitModal = ({
                                 isOpen,
                                 onCancel,
                                 onConfirm,
                                 formName,
                                 formDescription,
                                 localUnitDisplayName,
                                 isAmending
                               }: Props) => {
  const initialValues = useMemo(() => {
    return {
      confirmed: false,
      comment: ''
    };
  }, []);

  const modalTitle = useMemo(() => {
    if (isAmending) {
      return 'Certify / Amend Form';
    } else {
      return 'Certify / Submit Form';
    }
  }, [
    isAmending
  ]);

  const handleSubmit = useCallback(async (values: FormFields,
                                          helpers: FormikHelpers<FormFields>) => {
    onConfirm(values as FormFields);
    helpers.resetForm();
  }, [
    onConfirm
  ]);

  const ModalBody = useMemo(() => () => {
    if (isAmending) {
      return <Row>
        <Col>
          <>
            <div className="Form4022ModalHeader text-dark pr-4 pt-3 pb-3 pl-4">
              Provide a reason for amending the Form {formName} {formDescription} for <span className="text-danger">{localUnitDisplayName}</span>.
            </div>
            <div className="Form4022ModalSection p-4">
              <FormikInput name="comment"
                           labelText="Comment"
                           disableFloatingLabel
                           autoFocus
                           formGroupClass="mb-0"
                           required
                           type="text"
                           maxLength={200}/>
            </div>
          </>
        </Col>
      </Row>;
    } else {
      return <div className="pt-4 pb-2 pl-4 pr-4" style={{borderBottom: '1px solid #DDD'}}>
        <p>
          Are you sure you want to submit the Form {formName} {formDescription} for <span className="text-danger">{localUnitDisplayName}</span>?
        </p>
      </div>;
    }
  }, [
    isAmending,
    formName,
    formDescription,
    localUnitDisplayName
  ]);

  return <Formik validationSchema={form4022LuCertificationSchema(isAmending)}
                 initialValues={initialValues}
                 validateOnMount
                 onSubmit={handleSubmit}
                 onCancel={onCancel}>
    {(formikProps) => <Modal isOpen={isOpen}
                             toggle={onCancel}
                             size="xl"
                             autoFocus={!isAmending}
                             title={modalTitle}>
      <ModalHeader toggle={onCancel}>
        {modalTitle}
      </ModalHeader>
      <ModalBody/>
      <Row className="mt-4 pl-4">
        <Col>
          <FormikCheckboxGroup formGroupClass="mb-4"
                               checkboxes={[{
                                 name: 'confirmed',
                                 labelText: 'I hereby certify that all the information contained within this document is true and accurate to the best of my knowledge, information and belief.'
                               }]}/>
        </Col>
      </Row>
      <ModalFooter>
        <Button color="success"
                className="mr-1"
                disabled={!formikProps.isValid || formikProps.isSubmitting}
                onClick={formikProps.submitForm}>
          {isAmending ? 'Submit' : 'Yes'}
        </Button>
        <Button color="secondary"
                onClick={() => onCancel()}
                disabled={formikProps.isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>}
  </Formik>;
};

export default memo(Form4022LuSubmitModal);