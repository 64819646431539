import {useMemo} from 'react';
import {Table} from 'reactstrap';
import {isNaN} from 'lodash';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {formatAdjustmentModifier, formatDecimal, formatInteger, isNumber} from '../../../utils';
import * as form4017Utils from '../../../utils/form4017Utils';
import {Form4017ClassificationDto, Form4017TotalsDto} from '../../../types/forms/form4017';

type Props = {
  year: number
  showReadOnlyView: boolean
  selectedTab: string
  currentClassificationValues: Form4017ClassificationDto
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: Form4017TotalsDto
}

const Form4017Month24Table = ({
                                year,
                                showReadOnlyView,
                                selectedTab,
                                currentClassificationValues,
                                setHasUnsavedChanges,
                                totals
                              }: Props) => {
  const [
    month12,
    month24
  ] = useMemo(() => ([
    currentClassificationValues['12Month'],
    currentClassificationValues['24Month']
  ]), [
    currentClassificationValues
  ]);

  const month24Period4AdjustedAssessedValue = useMemo(() => {
    if (Number(month24.period4.totalAssessedValueForSales) === 0 ||
      month24.period4.totalAssessedValueForSales === null ||
      !isFinite(Number(totals.adjustmentModifiers[2])) ||
      !isFinite(Number(month24.period4.totalAssessedValueForSales))) {
      return '';
    } else {
      return form4017Utils.formatAdjustedValue(
        month24.period4.totalAssessedValueForSales,
        totals.adjustmentModifiers[2]
      );
    }
  }, [
    totals,
    month24
  ]);

  const monthPeriod4AdjustmentRatio = useMemo(() => {
    if (Number(month24.period4.totalAssessedValueForSales === 0) ||
      Number(month24.period4.totalAssessedValueForSales === null) ||
      Number(month24.period4.totalAdjustedPrices === 0) ||
      Number(month24.period4.totalAdjustedPrices === null) ||
      totals.adjustmentModifiers[2] === null ||
      totals.adjustmentModifiers[2] === 0 ||
      !isFinite(totals.adjustmentModifiers[2])) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        totals.adjustmentModifiers[2],
        (month24.period4?.totalAssessedValueForSales ?? 0),
        (month24.period4?.totalAdjustedPrices ?? 0)
      );
    }
  }, [
    totals,
    month24
  ]);

  const month12Period2AssessedValue = useMemo(() => {
    if (Number(month12.period2.totalAssessedValueForSales) === 0 ||
      month12.period2.totalAssessedValueForSales === null) {
      return '';
    } else {
      return Number(Number(month12.period2.totalAssessedValueForSales)
        .toFixed(0)).toLocaleString();
    }
  }, [
    month12
  ]);

  const month12Period2AdjustmentRatio = useMemo(() => {
    if (Number(month12.period2.totalAssessedValueForSales === 0) ||
      !isNumber(month12.period2.totalAdjustedPrices) ||
      month12.period2.totalAdjustedPrices === 0) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        1,
        (month12.period2?.totalAssessedValueForSales ?? 0),
        (month12.period2?.totalAdjustedPrices ?? 0)
      );
    }
  }, [
    month12
  ]);

  const month12TotalAdjustedAssessedValue = useMemo(() => {
    return !isNaN(totals['12Month'][2].adjustedAssessedValue) && isFinite(Number(totals.adjustmentModifiers[2])) ?
      form4017Utils.formatAdjustedValue(totals['12Month'][2].adjustedAssessedValue, 1) : 0;
  }, [
    totals
  ]);

  const month12TotalAdjustedPrices = useMemo(() => {
    return !isNaN(totals['12Month'][2].totalAdjustedPrices) ? formatInteger(totals['12Month'][2].totalAdjustedPrices) : 0;
  }, [
    totals
  ]);

  const month12TotalAdjustmentRatio = useMemo(() => {
    return isFinite(totals['12Month'][2].adjustmentRatio) ?
      formatDecimal(totals['12Month'][2].adjustmentRatio, 2, true) : '0.00%';
  }, [
    totals
  ]);

  return <Table bordered responsive>
    <thead>
      <tr>
        <th className="text-primary align-middle" colSpan={7}>12 Month / Single Year Sales Study</th>
        <th className="text-primary align-middle text-right">L-4047</th>
      </tr>
      <tr>
        <th className="text-primary text-center align-middle">(A) Year of Assessment</th>
        <th className="text-primary text-center align-middle">(B) Sales Period</th>
        <th className="text-primary text-center align-middle">(C) Number of Sales</th>
        <th className="text-primary text-center align-middle">(D) Total Assessed Value for Sales</th>
        <th className="text-primary text-center align-middle">(E) Applicable Adjustment Modifier</th>
        <th className="text-primary text-center align-middle">(F) Adjusted Assessed Value</th>
        <th className="text-primary text-center align-middle">(G) Total Adjusted Prices</th>
        <th className="text-primary text-center align-middle">(H) Adjust % Ratio</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="text-center align-middle">{year - 2}</td>
        <td className="text-center align-middle">10/{year - 2}-03/{year - 1}</td>
        <td className="text-center align-middle">
          {formatInteger(month24.period4.numberOfSales)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(month24.period4.totalAssessedValueForSales)}
        </td>
        <td className="text-center align-middle">
          {formatAdjustmentModifier(totals.adjustmentModifiers[2])}
        </td>
        <td className="text-center align-middle">
          {month24Period4AdjustedAssessedValue}
        </td>
        <td className="text-center align-middle">
          {formatInteger(month24.period4.totalAdjustedPrices)}
        </td>
        <td className="text-center align-middle">
          {monthPeriod4AdjustmentRatio}
        </td>
      </tr>
      <tr>
        <td className="text-center align-middle">{year - 1}</td>
        <td className="text-center align-middle">04/{year - 1}-09/{year - 1}</td>
        <td className="text-center align-middle">
          {showReadOnlyView && formatInteger(month12.period2.numberOfSales)}
          {!showReadOnlyView && <FormikNumberInput maxLength="7"
                                                   formGroupClass="mb-0"
                                                   disableFloatingLabel={true}
                                                   ariaLabel="Number of Sales"
                                                   onChange={() => setHasUnsavedChanges(true)}
                                                   name={`classifications['${selectedTab}']['12Month'].period2.numberOfSales`}
                                                   className="text-center"
          />}
        </td>
        <td className="text-center align-middle">
          {showReadOnlyView && formatInteger(month12.period2.totalAssessedValueForSales)}
          {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                   formGroupClass="mb-0"
                                                   disableFloatingLabel={true}
                                                   ariaLabel="Total Assessed Value for Sales"
                                                   onChange={() => setHasUnsavedChanges(true)}
                                                   name={`classifications['${selectedTab}']['12Month'].period2.totalAssessedValueForSales`}
                                                   className="text-center"
          />}
        </td>
        <td className="text-center align-middle">
          1.0000
        </td>
        <td className="text-center align-middle">
          {month12Period2AssessedValue}
        </td>
        <td className="text-center align-middle">
          {showReadOnlyView && formatInteger(month12.period2.totalAdjustedPrices)}
          {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                   formGroupClass="mb-0"
                                                   disableFloatingLabel={true}
                                                   ariaLabel="Total Adjusted Prices"
                                                   onChange={() => setHasUnsavedChanges(true)}
                                                   name={`classifications['${selectedTab}']['12Month'].period2.totalAdjustedPrices`}
                                                   className="text-center"/>}
        </td>
        <td className="text-center align-middle">
          {month12Period2AdjustmentRatio}
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-primary font-weight-bold text-nowrap text-center align-middle bg-light">
          12 Month Total Sales
        </td>
        <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
          {formatInteger(totals['12Month'][2]?.numberOfSales ?? 0)}
        </td>
        <td colSpan={2} className="align-middle bg-light"/>
        <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
          {month12TotalAdjustedAssessedValue}
        </td>
        <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
          {month12TotalAdjustedPrices}
        </td>
        <td className=" align-middle bg-light"/>
      </tr>
      <tr>
        <td colSpan={2} className="font-weight-bold text-primary text-nowrap text-center align-middle bg-light">
          12 Month Aggregate Adjusted Ratio
        </td>
        <td colSpan={5} className="text-nowrap text-center align-middle bg-light"/>
        <td className="text-nowrap text-center align-middle bg-light font-weight-bold">
          {month12TotalAdjustmentRatio}
        </td>
      </tr>
    </tbody>
  </Table>;
};

export default Form4017Month24Table;