import {memo} from 'react';

import {ButtonIcon} from '@reasoncorp/kyber-js';

import {formatDecimal, formatInteger} from '../../../utils';
import {forms} from '../../../types';

type Props = {
  property: forms.Form4015aPropertyDto
  showReadOnlyView: boolean
  onEdit: (property: forms.Form4015aPropertyDto) => void
  onDelete: () => void
  version: string
}

const Form4015aPropertyRow = ({
                                property,
                                showReadOnlyView,
                                version,
                                onEdit,
                                onDelete
                              }: Props) => {
  return (
    <tr key={property.id}>
      <td className="align-middle">
        {property.propertyNumber}
      </td>
      <td className="align-middle">
        {property.ownerName}
      </td>
      <td className="align-middle text-center">
        {property.propertyClassification}
      </td>
      <td className="align-middle text-center">
        {formatInteger(property.assessedValue)}
      </td>
      <td className="align-middle text-center">
        {formatInteger(property.appraisedValue)}
      </td>
      <td className="align-middle text-center">
        {formatDecimal(property.assessedValue / property.appraisedValue, 2, true)}
      </td>
      {version === 'V1' && <td className="align-middle">{property.comments}</td>}
      {!showReadOnlyView && <td className="align-middle text-center cursor-pointer">
        <ButtonIcon className="text-primary"
                    ariaLabel="Edit Parcel"
                    title="Edit Parcel"
                    icon="cog"
                    onClick={() => onEdit(property)}/>
      </td>}
      {!showReadOnlyView &&
        <td className="align-middle text-center cursor-pointer">
          <ButtonIcon icon="trash"
                      title="Delete Parcel"
                      ariaLabel="Delete Parcel"
                      className="text-danger"
                      onClick={onDelete}/>
        </td>}
    </tr>
  );
};

export default memo(Form4015aPropertyRow);