import {Button, Col, Modal, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {memo, useCallback, useMemo} from 'react';
import {Formik, FormikHelpers} from 'formik';

import {FormikCheckboxGroup, FormikInput, FormikRadioGroup} from '@reasoncorp/kyber-js';

import {form4022CountyCertificationSchema} from '../../../schemas';

type Props = {
  isOpen: boolean
  onConfirm: (values: FormFields) => void
  onCancel: () => void
  formName: string
  formDescription: string
  localUnitDisplayName: string
  isCertifying: boolean
  wasLastSubmittedByCounty: boolean
  isAmending: boolean
}

type FormFields = {
  confirmed: boolean
  reason: string
  comment: string
}

const Form4022CountySubmitModal = ({
                                     isOpen,
                                     onCancel,
                                     onConfirm,
                                     formName,
                                     formDescription,
                                     isCertifying,
                                     wasLastSubmittedByCounty,
                                     isAmending,
                                     localUnitDisplayName
                                   }: Props) => {
  const initialValues = useMemo(() => {
    return {
      confirmed: false,
      reason: '',
      comment: ''
    };
  }, []);

  const ModalBody = useMemo(() => () => {
    const submitText = wasLastSubmittedByCounty && !isAmending ? 'resubmit' : 'submit';
    if (isAmending) {
      return <Row>
        <Col>
          <>
            <div className="Form4022ModalHeader text-dark pr-4 pt-3 pb-3 pl-4">
              Provide a reason for amending the Form {formName} {formDescription} for <span className="text-danger">{localUnitDisplayName}</span>.
            </div>
            <div className="Form4022ModalSection p-4">
              <FormikInput name="comment"
                           labelText="Comment"
                           disableFloatingLabel
                           autoFocus
                           formGroupClass="mb-0"
                           required
                           type="text"
                           maxLength={200}/>
            </div>
          </>
        </Col>
      </Row>;
    } else {
      return <Row className="p-4">
        <Col>
          <p className="mb-0">
            Are you sure you want to {submitText} the Form {formName} {formDescription} for <span className="text-danger">{localUnitDisplayName}</span>?
          </p>
        </Col>
      </Row>;
    }
  }, [
    formDescription,
    formName,
    isAmending,
    localUnitDisplayName,
    wasLastSubmittedByCounty
  ]);

  const modalTitle = useMemo(() => {
    if (wasLastSubmittedByCounty && !isAmending) {
      return isCertifying ? 'Certify / Resubmit Form' : 'Resubmit Form';
    } else if (isAmending) {
      return isCertifying ? 'Certify / Amend Form' : 'Amend Form';
    } else {
      return isCertifying ? 'Certify / Submit Form' : 'Submit Form';
    }
  }, [
    wasLastSubmittedByCounty,
    isAmending,
    isCertifying
  ]);

  const handleSubmit = useCallback(async (values: FormFields,
                                          helpers: FormikHelpers<FormFields>) => {
    onConfirm(values as FormFields);
    helpers.resetForm();
  }, [
    onConfirm
  ]);

  return <Formik validationSchema={form4022CountyCertificationSchema({isCertifying, isAmending})}
                 initialValues={initialValues}
                 validateOnMount
                 onSubmit={handleSubmit}
                 onCancel={onCancel}>
    {(formikProps) => <Modal isOpen={isOpen}
                             toggle={onCancel}
                             size="xl"
                             autoFocus={!isAmending}
                             title={modalTitle}>
      <ModalHeader toggle={onCancel}>
        {modalTitle}
      </ModalHeader>
      <ModalBody/>
      {isCertifying && <>
        <Row>
          <Col>
            <>
              <div className="Form4022ModalHeader text-dark pr-4 pt-3 pb-3 pl-4">Select one of the following reasons for certifying</div>
              <div className="Form4022ModalSection p-4 pb-0">
                <FormikRadioGroup aria-label="Select one of the following reasons for certifying"
                                  name="reason"
                                  inline
                                  formGroupClass="mb-0"
                                  radioButtons={[
                                    {
                                      value: 'uncertified',
                                      labelText: 'Uncertified Unit'
                                    },
                                    {
                                      value: 'certified',
                                      labelText: 'Certified Unit, but Assessor unavailable to certify'
                                    }
                                  ]}/>
              </div>
            </>
          </Col>
        </Row>
        <Row className="mt-4 pl-4">
          <Col>
            <FormikCheckboxGroup checkboxes={[{
              name: 'confirmed',
              labelText: 'I hereby certify that all the information contained within this document is true and accurate to the best of my knowledge, information and belief.'
            }]}/>
          </Col>
        </Row>
      </>}
      <ModalFooter>
        <Button color="success"
                disabled={!formikProps.isValid || formikProps.isSubmitting}
                className="mr-1"
                onClick={formikProps.submitForm}>
          {isAmending ? 'Submit' : 'Yes'}
        </Button>
        <Button color="secondary"
                onClick={() => onCancel()}
                disabled={formikProps.isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>}
  </Formik>;
};

export default memo(Form4022CountySubmitModal);