import {useMemo} from 'react';
import {Table} from 'reactstrap';
import {FormikProps} from 'formik';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {forms, LocalUnitFormDto} from '../../../types';
import {format4022AdjustmentValueWithCommas, formatInteger} from '../../../utils';

type Totals = {
  propertyCount: number
  boardOfReview1: number
  boardOfReview2: number
  loss: number
  adjustment: number
  newField: number
}

type Props = {
  form: LocalUnitFormDto
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: Totals
  formikProps: FormikProps<any>
  classificationsType: 'real' | 'personal'
  calculateBoardOfReview2: (item: {
                                    boardOfReview1: number | null,
                                    loss: number | null,
                                    adjustment: number | null,
                                    newField: number | null
                                  } | null) => number,
}

const Form4022Table = ({
                         form,
                         showReadOnlyView,
                         setHasUnsavedChanges,
                         totals,
                         classificationsType,
                         formikProps,
                         calculateBoardOfReview2
                       }: Props) => {
  const formikValuesPrefix = useMemo(() => {
    return classificationsType === 'real' ? 'realProperty' : 'personalProperty';
  }, [
    classificationsType
  ]);

  const classifications = useMemo(() => {
    return classificationsType === 'real' ? Object.entries({
      '100': 'Agricultural',
      '200': 'Commercial',
      '300': 'Industrial',
      '400': 'Residential',
      '500': 'Timber-Cutover',
      '600': 'Developmental'
    }) : Object.entries({
      '150': 'Agricultural',
      '250': 'Commercial',
      '350': 'Industrial',
      '450': 'Residential',
      '550': 'Utility'
    });
  }, [
    classificationsType
  ]);

  return <div className="mb-4">
    <Table responsive bordered>
      <thead>
        <tr>
          <th className="text-center align-middle text-primary text-nowrap" style={{width: '20%'}}>
            {classificationsType === 'real' ? 'Real Property' : 'Personal Property'}
          </th>
          <th className="text-center align-middle text-primary" style={{width: '15%'}}>
            Parcel Count
          </th>
          <th className="text-center align-middle text-primary" style={{width: '15%'}}>
            {form.year - 1} Board of Review
          </th>
          <th className="text-center align-middle text-primary" style={{width: '10%'}}>
            Loss
          </th>
          <th className="text-center align-middle text-primary" style={{width: '15%'}}>
            + or ( - ) Adjustment
          </th>
          <th className="text-center align-middle text-primary" style={{width: '10%'}}>
            New
          </th>
          <th className="text-center align-middle text-primary" style={{width: '15%'}}>
            {form.year} Board of Review
          </th>
        </tr>
      </thead>
      <tbody>
        {classifications.map(([classification, classificationName]) => {
          const formikValues = classificationsType === 'real' ?
            formikProps.values.realProperty[classification as unknown as forms.RealPropertyClassificationCode] :
            formikProps.values.personalProperty[classification as unknown as forms.PersonalPropertyClassificationCode];

          return <tr key={classification}>
            <td className="align-middle text-center text-primary font-weight-bold">
              {classification} - {classificationName}
            </td>
            <td className="align-middle text-center">
              {showReadOnlyView ? formatInteger(formikValues.propertyCount) :
                <FormikNumberInput formGroupClass="mb-0"
                                   ariaLabel="Parcel Count"
                                   disableFloatingLabel={true}
                                   name={`${formikValuesPrefix}[${classification}].propertyCount`}
                                   className="text-center"
                                   onChange={() => setHasUnsavedChanges(true)}
                                   maxLength="11"
                />}
            </td>
            <td className="align-middle text-center">
              {showReadOnlyView ? formatInteger(formikValues.boardOfReview1) :
                <FormikNumberInput formGroupClass="mb-0"
                                   disableFloatingLabel={true}
                                   ariaLabel={`${form.year - 1} Board of Review`}
                                   name={`${formikValuesPrefix}[${classification}].boardOfReview1`}
                                   className="text-center"
                                   onChange={() => setHasUnsavedChanges(true)}
                                   maxLength="15"
                />}
            </td>
            <td className="align-middle text-center">
              {showReadOnlyView ? formatInteger(formikValues.loss) :
                <FormikNumberInput formGroupClass="mb-0"
                                   ariaLabel="Loss"
                                   disableFloatingLabel={true}
                                   name={`${formikValuesPrefix}[${classification}].loss`}
                                   className="text-center"
                                   onChange={() => setHasUnsavedChanges(true)}
                                   maxLength="15"
                />}
            </td>
            <td className="align-middle text-center">
              {showReadOnlyView ?
                format4022AdjustmentValueWithCommas(formikValues.adjustment) :
                <FormikNumberInput formGroupClass="mb-0"
                                   ariaLabel="Adjustment"
                                   disableFloatingLabel={true}
                                   name={`${formikValuesPrefix}[${classification}].adjustment`}
                                   className="text-center"
                                   includeSign={true}
                                   onChange={() => setHasUnsavedChanges(true)}
                                   maxLength="16"
                />
              }
            </td>
            <td className="align-middle text-center">
              {showReadOnlyView ?
                formatInteger(formikValues.newField) :
                <FormikNumberInput formGroupClass="mb-0"
                                   ariaLabel="New"
                                   disableFloatingLabel={true}
                                   name={`${formikValuesPrefix}[${classification}].newField`}
                                   className="text-center"
                                   onChange={() => setHasUnsavedChanges(true)}
                                   maxLength="15"
                />}
            </td>
            <td className="align-middle text-center">
              {formatInteger(calculateBoardOfReview2(formikValues))}
            </td>
          </tr>;
        })
        }
      </tbody>
      <tfoot>
        <tr className="bg-light">
          <td className="text-center align-middle text-primary font-weight-bold">
            {classificationsType === 'real' ? '800 - Totals' : '850 - Totals'}
          </td>
          <td className="align-middle font-weight-bold text-center">
            {formatInteger(totals.propertyCount)}
          </td>
          <td className="align-middle font-weight-bold text-center">
            {formatInteger(totals.boardOfReview1)}
          </td>
          <td className="align-middle font-weight-bold text-center">
            {formatInteger(totals.loss)}
          </td>
          <td className="align-middle font-weight-bold text-center">
            {formatInteger(format4022AdjustmentValueWithCommas(totals.adjustment))}
          </td>
          <td className="align-middle font-weight-bold text-center">
            {formatInteger(totals.newField)}
          </td>
          <td className="text-center align-middle font-weight-bold">
            {formatInteger(totals.boardOfReview2)}
          </td>
        </tr>
      </tfoot>
    </Table>
  </div>;
};

export default Form4022Table;