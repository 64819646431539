import {memo, useMemo} from 'react';
import {CustomInput} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon} from '@reasoncorp/kyber-js';

import {formatDate, formatDecimal, formatInteger} from '../../../utils';
import {forms} from '../../../types';

type Props = {
  property: forms.Form4015PropertyDto
  onEdit: (property: forms.Form4015PropertyDto) => void
  onDelete: () => void
  showReadOnlyView: boolean
  onContend: (property: forms.Form4015PropertyDto) => void,
  onContendAndCommentToggle: (property: forms.Form4015PropertyDto, comment?: string) => void
  isLocalUnitUser: boolean
  isCountyUser: boolean
}

const Form4015PropertyRow = ({
                               property,
                               onEdit,
                               onDelete,
                               onContend,
                               showReadOnlyView,
                               isLocalUnitUser,
                               isCountyUser,
                               onContendAndCommentToggle
                             }: Props) => {
  const {
    id,
    confidential,
    isDuplicate,
    contended,
    date,
    liberAndPage,
    propertyNumber,
    grantor,
    grantee,
    instrument,
    financing,
    assessedValue,
    salesPrice,
    adjustedSalesPrice,
    ratio,
    comments,
    wasAddedByLocalUnit
  } = useMemo(() => property, [property]);

  const isEditable = useMemo(() => {
    return wasAddedByLocalUnit ? (isLocalUnitUser || isCountyUser) : isCountyUser;
  }, [isLocalUnitUser, wasAddedByLocalUnit, isCountyUser]);

  return <tr key={id}>
    <td className="align-middle text-center">
      {confidential && <FontAwesomeIcon icon="user-secret"
                                        className="text-success"
                                        size="lg"
                                        title="Confidential"/>}
    </td>
    <td className="align-middle text-center">
      {isDuplicate && <FontAwesomeIcon icon="check-circle"
                                       className="text-secondary"
                                       size="lg"
                                       title="Twice Sold"/>}
    </td>
    <td className="align-middle text-center">
      {wasAddedByLocalUnit && <FontAwesomeIcon icon="check-circle"
                                               className="text-secondary"
                                               size="lg"
                                               title="LU"/>}
    </td>
    <td className="align-middle text-center">
      {formatDate(date)}
    </td>
    <td className="align-middle text-center">
      {liberAndPage}
    </td>
    <td className="align-middle text-center">
      {propertyNumber}
    </td>
    <td className="align-middle text-center">
      {grantor}
    </td>
    <td className="align-middle text-center">
      {grantee}
    </td>
    <td className="align-middle text-center">
      {instrument}
    </td>
    <td className="align-middle text-center">
      {financing}
    </td>
    <td className="align-middle text-center">
      {formatInteger(assessedValue)}
    </td>
    <td className="align-middle text-center">
      {formatInteger(salesPrice)}
    </td>
    <td className="align-middle text-center">
      {formatInteger(adjustedSalesPrice)}
    </td>
    <td className="align-middle text-center">
      {formatDecimal(ratio / 100, 2, true)}
    </td>
    <td className="align-middle">
      {comments}
    </td>
    <td className="align-middle text-center cursor-pointer">
      {!showReadOnlyView && isLocalUnitUser && !wasAddedByLocalUnit &&
        <CustomInput type="switch"
                     id={`contend-${property.id}`}
                     name={`contend-${property.id}`}
                     className="text-primary"
                     title="Mark Parcel as Contention"
                     aria-label="Mark Parcel as Contention"
                     onChange={() => contended ? onContend(property) : onContendAndCommentToggle(property, undefined)}
                     checked={contended}
                     disabled={showReadOnlyView}/>}
      {!showReadOnlyView && !isLocalUnitUser && !wasAddedByLocalUnit && contended &&
        <FontAwesomeIcon icon="check-circle"
                         className="text-secondary"
                         size="lg"
                         title="Contended"/>}
    </td>
    {!showReadOnlyView && <>
      <td className="align-middle text-center cursor-pointer">
        {isEditable && <ButtonIcon className="text-primary"
                                   ariaLabel="Edit Parcel"
                                   title="Edit Parcel"
                                   icon="cog"
                                   onClick={() => onEdit(property)}
        />}
      </td>
      <td className="align-middle text-center cursor-pointer">
        {isEditable &&
          <ButtonIcon icon="trash"
                      title="Delete Parcel"
                      ariaLabel="Delete Parcel"
                      className="text-danger"
                      onClick={onDelete}/>}
      </td>
    </>}
  </tr>;
};

export default memo(Form4015PropertyRow);