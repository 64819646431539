import {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Card, CardHeader, Container} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {CountyFormDto, FormPdf} from '../types';
import {countyFormApi, stateFormApi} from '../api';
import * as messages from '../messages';
import {formatDate} from '../utils';
import {openFileInNewWindow} from '../api/apiUtils';
import {usePortal} from '../hooks';

const LocalUnitFormPdfList = () => {
  const {
    countyId,
    countyFormId,
    localUnitFormId,
    localUnitId,
    year
  } = useParams() as {
    countyId: string,
    localUnitId: string,
    year: string,
    countyFormId: string,
    localUnitFormId: string
  };
  const {showErrorAlert} = useAlerts();
  const [countyForm, setCountyForm] = useState<CountyFormDto | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const {isStatePortal} = usePortal();

  const breadcrumbs = useMemo(() => {
    if (!countyForm) {
      return [];
    } else if (isStatePortal) {
      return [{
        text: 'State Dashboard',
        icon: 'home' as const,
        route: `/state-portal/forms/${year}`
      }, {
        text: countyForm!.countyDisplayName!,
        route: `/state-portal/${countyId}/${year}/forms/${countyFormId}`
      }, {
        text: 'PDF History',
        active: true
      }];
    } else {
      return [{
        text: 'County Dashboard',
        icon: 'home' as const,
        route: `/county-portal/${countyId}/${year}`
      }, {
        text: countyForm!.countyDisplayName!,
        route: `/county-portal/${countyId}/${year}/forms/${countyFormId}`
      }, {
        text: 'PDF History',
        active: true
      }];
    }
  }, [
    countyForm,
    countyFormId,
    countyId,
    isStatePortal,
    year
  ]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const countyForm = isStatePortal ?
          await stateFormApi.findCountyForm(countyId, countyFormId)
          : await countyFormApi.find(countyId, countyFormId);
        setCountyForm(countyForm);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void initialLoad();
  }, [
    countyId,
    localUnitId,
    localUnitFormId,
    showErrorAlert,
    countyFormId,
    isStatePortal
  ]);

  const getPdfBadgeText = useMemo(() => (submission: boolean, isLocalUnit: boolean) => {
    if (submission && !isLocalUnit) {
      return 'Submitted to State';
    } else {
      return 'County Draft (saved version)';
    }
  }, []);

  const handleOpenPdf = useCallback((url: string) =>
      openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE)(url),
    [showErrorAlert]
  );

  const formPdfs = useMemo(() => {
    const pdfs = countyForm?.formPdfs ?? [];

    if (isStatePortal) {
      return pdfs;
    } else {
      const filteredPdfs: FormPdf[] = [];
      pdfs.forEach(pdf => {
        if (pdf.submission && filteredPdfs.filter(pdf => pdf.submission).length === 0) {
          filteredPdfs.push(pdf);
        } else if (!pdf.submission) {
          filteredPdfs.push(pdf);
        }
      });

      return filteredPdfs;
    }
  }, [
    isStatePortal,
    countyForm?.formPdfs
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Date', className: 'align-middle'},
      {title: 'Type', className: 'align-middle'},
      {title: 'User', className: 'align-middle'},
      {title: 'PDF', className: 'text-center align-middle'}
    ],
    items: formPdfs,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    renderRow: ({
                  createdAt,
                  submission,
                  localUnit,
                  userFullName,
                  url
                }: FormPdf) => {
      const color = submission ? 'success' : 'secondary';

      return <tr key={`${createdAt}-${submission}`}>
        <td className="w-20 align-middle">
          {formatDate(createdAt, 'MM/dd/yyyy h:mm a')}
        </td>
        <td className="w-35 align-middle">
          <Badge color={color}>
            {getPdfBadgeText(submission, localUnit)}
          </Badge>
        </td>
        <td className="w-35 align-middle">{userFullName}</td>
        <td className="w-10 text-center align-middle">
          <ButtonIcon icon="file-pdf"
                      ariaLabel="Open PDF"
                      title="Open PDF"
                      className="text-primary"
                      onClick={() => handleOpenPdf(url)}/>
        </td>
      </tr>;
    }
  }), [
    formPdfs,
    getPdfBadgeText,
    handleOpenPdf
  ]);

  return <Container fluid>
    {loadingState.loading && !loadingState.loadError && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && countyForm && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>
          PDF History - Form {countyForm.name} {countyForm.description}
        </CardHeader>
        <CustomTable {...tableProps} />
      </Card>
    </>}
  </Container>;
};

export default LocalUnitFormPdfList;