import {createContext} from 'react';

export type PortalContextType = {
  portal: 'LOCAL_UNIT' | 'COUNTY' | 'STATE'
  isStatePortal: boolean
  isCountyPortal: boolean
  isLocalUnitPortal: boolean
}

export const PortalContext = createContext<PortalContextType>({
  portal: 'COUNTY',
  isCountyPortal: true,
  isStatePortal: false,
  isLocalUnitPortal: false
});