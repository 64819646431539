import {memo, useMemo} from 'react';
import {Badge, Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';

import {formatDate} from '../../utils';
import {FileDetails} from '../../types';

type Props = {
  onClick: () => void
  details: FileDetails
  isLocalUnitPortal: boolean
}

const FilingCabinetCard = ({
                             onClick,
                             details,
                             isLocalUnitPortal
                           }: Props) => {
  const badgeText = useMemo(() => {
    if (details.numberOfFiles === 1) {
      return '1 UPLOAD';
    } else if (details.numberOfFiles > 1) {
      return `${details.numberOfFiles} UPLOADS`;
    } else {
      return 'NO UPLOADS';
    }
  }, [
    details.numberOfFiles
  ]);

  return <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
    <Card className="h-100"
          title="Filing Cabinet"
          aria-label="Filing Cabinet"
          role="region"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' ? onClick() : null}
          onClick={onClick}
          style={{cursor: 'pointer'}}
          id="filing-cabinet">
      <CardHeader className="bg-secondary text-white">
        {!isLocalUnitPortal && 'Filing Cabinet'}
        {isLocalUnitPortal && 'Filing Cabinet - View Only'}
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col>
            {isLocalUnitPortal && 'View County Shared ECF, LVA and Misc Documents'}
            {!isLocalUnitPortal && 'Upload ECF, LVA and Misc Documents'}
          </Col>
        </Row>
        <Row>
          <Col>Due: {formatDate(details.dueOn)}</Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Row>
          <Col className="col-6"> </Col>
          <Col className="d-flex justify-content-end col-6">
            <Badge color={details.numberOfFiles !== 0 ? 'danger' : 'light'}
                   className="font-size-100 border-0">
              {badgeText}
            </Badge>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  </Col>;
};

export default memo(FilingCabinetCard);
