import * as Yup from 'yup';

import * as messages from '../../messages';

const commentSchema = Yup.string()
  .max(200, messages.maxCharactersMessage(200))
  .matches(/^$|^[a-z\d- ]+$/i, messages.MUST_BE_ALPHANUMERIC)
  .nullable();

const classificationsSchema = (schema: Yup.BaseSchema) => {
  return Yup.object({
    '100': schema,
    '200': schema,
    '300': schema,
    '400': schema,
    '500': schema,
    '600': schema
  });
};
const cedClassificationSchema = Yup.object().shape({
  localUnits: Yup.array().of(Yup.object().shape({
    studyType: Yup.string()
      .nullable(),
    numberOfParcels: Yup.number()
      .nullable(),
    assessedValue: Yup.number()
      .nullable(),
    trueCashValue: Yup.number()
      .nullable(),
    ratioL2793L4018: Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER),
    comments: commentSchema
  }))
});

const cedResidentialClassificationSchema = Yup.object().shape({
  localUnits: Yup.array().of(Yup.object().shape({
    studyType: Yup.string()
      .nullable(),
    firstPeriodNumberOfParcels: Yup.number()
      .nullable(),
    secondPeriodNumberOfParcels: Yup.number()
      .nullable(),
    thirdPeriodNumberOfParcels: Yup.number()
      .nullable(),
    fourthPeriodNumberOfParcels: Yup.number()
      .nullable(),
    appraisalNumberOfParcels: Yup.number()
      .nullable(),
    firstPeriodAdjustedAssessedValue: Yup.number()
      .nullable(),
    secondPeriodAdjustedAssessedValue: Yup.number()
      .nullable(),
    thirdPeriodAdjustedAssessedValue: Yup.number()
      .nullable(),
    fourthPeriodAdjustedAssessedValue: Yup.number()
      .nullable(),
    appraisalStudyAssessedValue: Yup.number()
      .nullable(),
    ratioL4018: Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER),
    comments: commentSchema
  }))
});

const trueCashValueProjectionSchema = Yup.number()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(999999999999, messages.maxValueMessage(999999999999))
  .nullable();

const studyTypeSummarySchema = Yup.string()
  .max(25, messages.maxCharactersMessage(25))
  .matches(/^$|^[a-z\d- =]+$/i, messages.MUST_BE_ALPHANUMERIC)
  .nullable();

const qaRatioSchema = Yup.number()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(99.99, messages.maxValueMessage(99.99))
  .nullable();

const preliminaryCedRecapSchema = Yup.object().shape({
  classifications: Yup.object().shape({
    '100': cedClassificationSchema,
    '200': cedClassificationSchema,
    '300': cedClassificationSchema,
    '400': cedResidentialClassificationSchema,
    '500': cedClassificationSchema,
    '600': cedClassificationSchema
  }),
  qaRatio: classificationsSchema(qaRatioSchema),
  trueCashValueProjection: classificationsSchema(trueCashValueProjectionSchema),
  studyTypeSummary: classificationsSchema(studyTypeSummarySchema)
});

export default preliminaryCedRecapSchema;