import {memo} from 'react';

import {ButtonIcon, FormikCheckboxGroup} from '@reasoncorp/kyber-js';

import {CED_4015_TYPE, formatDate, formatDecimal, formatInteger} from '../../utils';
import {mega} from '../../types';

type Props = {
  onEditClick: (item: mega.MasterSalesData) => void
  onDeleteClick: (item: mega.MasterSalesData) => void
  onToggleSalesIncluded: (item: mega.MasterSalesData) => void
  highlight: boolean
  isRawData: boolean
  item: mega.MasterSalesData
  disabled: boolean
}

const MasterSalesDataRow = ({
                              onEditClick,
                              onDeleteClick,
                              onToggleSalesIncluded,
                              highlight,
                              isRawData,
                              item,
                              disabled
                            }: Props) => {
  const className = highlight ? 'row-highlight text-white' : '';
  const editButtonClassName = highlight ? 'text-white' : 'text-primary';

  return <tr key={item?.id?.toString()} className={className}>
    <td className="align-middle text-center">
      <FormikCheckboxGroup
        formGroupClass="mb-0"
        disabled={disabled}
        checkboxes={[{
          name: isRawData ? `selected-${item.id}` : `selected-${item.id}-sales-included`,
          ariaLabel: isRawData ? `Item ${item.id} Selected` : `Item ${item.id} Sales Included Selected`,
          onChange: () => onToggleSalesIncluded(item)
        }]}/>
    </td>
    <td className="align-middle text-center">{item.psd4015Type}</td>
    <td className="align-middle text-center">{item.verified}</td>
    <td className="align-middle text-center text-nowrap">{item.propertyId}</td>
    <td className="align-middle text-center">{item.unitNumber}</td>
    <td className="align-middle text-center">{item.propertyClassification}</td>
    <td className="align-middle text-center">{formatDate(item.saleOn, 'MM/dd/yy')}</td>
    <td className="align-middle text-center">{item.liberPage}</td>
    <td className="align-middle text-left">{item.grantor}</td>
    <td className="align-middle text-left">{item.grantee}</td>
    <td className="align-middle text-center">{item.instrument}</td>
    <td className="align-middle text-center">{formatInteger(item.assessmentWhenSold)}</td>
    <td className="align-middle text-center">{formatInteger(item.unadjustedSalesPrice)}</td>
    <td className="align-middle text-center">{formatInteger(item.adjustedSalesPrice)}</td>
    <td className="align-middle text-center">{CED_4015_TYPE[item.ced4015Type]}</td>
    <td className="align-middle">{item.cedComments}</td>
    <td className="align-middle">{item.psdComments}</td>
    <td className="align-middle text-center">{item.rawRatio && formatDecimal(item.rawRatio, 2, true)}</td>
    <td className="align-middle text-center">{item.preRefinedRatio && formatDecimal(item.preRefinedRatio, 2, true)}</td>
    <td className="align-middle text-center">{item.refinedRatio && formatDecimal(item.refinedRatio, 2, true)}</td>
    {isRawData ?
      <>
        <td className="align-middle text-center">
          <ButtonIcon icon="cog"
                      disabled={disabled}
                      aria-disabled={disabled}
                      title="Edit parcel"
                      ariaLabel="Edit parcel"
                      className={editButtonClassName}
                      onClick={() => onEditClick(item)}
          />
        </td>
        <td className="align-middle text-center">
          <ButtonIcon icon="trash"
                      disabled={disabled}
                      aria-disabled={disabled}
                      title="Delete Parcel"
                      ariaLabel="Delete Parcel"
                      onClick={() => onDeleteClick(item)}
                      className="text-danger"/>
        </td>
      </>
      : null}
  </tr>;
};

export default memo(MasterSalesDataRow);