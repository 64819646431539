import {FormikProps} from 'formik';
import {Button} from 'reactstrap';

import {MiSuiteRole, ssoUtils, User} from '@reasoncorp/kyber-js';

import {LocalUnitFormDto} from '../../../types';

type Props = {
  formikProps: FormikProps<any>
  currentUser: User | undefined
  isCountyUser: boolean
  loading: boolean
  form: LocalUnitFormDto
  setSubmitModalIsOpen: (isOpen: boolean) => void
  shouldShowAmendButton: boolean
  wasLastSubmittedByCounty: boolean
}

const Form4022CountySubmitButton = ({
                                      formikProps,
                                      currentUser,
                                      isCountyUser,
                                      loading,
                                      form,
                                      setSubmitModalIsOpen,
                                      shouldShowAmendButton,
                                      wasLastSubmittedByCounty
                                    }: Props) => {
  const shouldShowSubmitButton = isCountyUser && ssoUtils.hasJurisdictionCanonicalIdAndRole(
    currentUser,
    form.countyId,
    MiSuiteRole.EQUALIZATION_DIRECTOR
  );
  let buttonText = 'Submit';

  if (!shouldShowSubmitButton) {
    return null;
  } else if (shouldShowAmendButton) {
    buttonText = 'Amend';
  } else if (wasLastSubmittedByCounty) {
    buttonText = 'Resubmit';
  }

  return <Button className="mr-1"
                 disabled={loading || form.locked || !formikProps.isValid || formikProps.isSubmitting}
                 onClick={() => setSubmitModalIsOpen(true)}
                 color={shouldShowAmendButton ? 'danger' : 'primary'}>

    {buttonText}
  </Button>;
};

export default Form4022CountySubmitButton;