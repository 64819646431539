import {useEffect, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Container} from 'reactstrap';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  CustomTable,
  ProgressIndicator,
  TabNav,
  useAlerts,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import {psdMasterSalesApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {County, mega} from '../../types';
import {PsdMasterSalesTable} from '../../components/mega';

const tabs = [
  {value: 'all', displayValue: 'All Real Classifications'}
].concat(PROPERTY_CLASSIFICATION_TABS);

const PsdMasterSales = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [county, setCounty] = useState<County | undefined>(undefined);
  const isYear2 = useMemo(() => location.pathname.indexOf('year2') !== -1, [location.pathname]);
  const studyYear = useMemo(() => isYear2 ? 'Year 2' : 'Year 1', [isYear2]);
  const {showErrorAlert} = useAlerts();
  const {selectedTab} = useTabNav();
  const [psdSalesReport, setPsdSalesReport] = useState<mega.PsdMasterSalesReport>(undefined);
  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    year,
    countyId,
    county
  ]);

  useEffect(() => {
    const loadPsdMasterSalesReport = async () => {
      try {
        const [counties, psdMasterSales] = await Promise.all([
          megApi.findCounties(true),
          psdMasterSalesApi.find(countyId, year, isYear2)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setPsdSalesReport(psdMasterSales);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadPsdMasterSalesReport();
  }, [countyId, showErrorAlert, year, navigate, isYear2]);

  const yearData = useMemo(() => {
    return psdSalesReport?.[selectedTab as string] ?? undefined;
  }, [
    psdSalesReport,
    selectedTab
  ]);

  const types4015TableProps = useMemo(() => ({
    items: psdSalesReport?.[selectedTab as string]?.types4015 ?? [],
    renderFooter: () => {
      const totals = psdSalesReport?.[selectedTab as string]?.totals ?? undefined;
      return totals && <tr key="totals" className="text-dark bg-light font-weight-bold">
        <td className="text-left align-middle">Totals</td>
        <td className="align-middle"/>
        <td className="text-center align-middle">{formatInteger(totals.count)}</td>
        <td className="text-center align-middle">{formatInteger(totals.assessed)}</td>
        <td className="text-center align-middle">{formatInteger(totals.salesPrice)}</td>
        <td className="text-center align-middle">{formatDecimal(totals.samplePercentParcels, 2, true)}</td>
        <td className="text-center align-middle">{formatDecimal(totals.samplePercentAssessedValue, 2, true)}</td>
        <td className="text-center align-middle">{formatDecimal(totals.ratio, 2, true)}</td>
      </tr>;
    },
    headers: [
      {title: 'L4015 Type', className: 'text-primary align-middle text-left bg-light'},
      {title: 'Code', className: 'text-primary align-middle text-center bg-light'},
      {title: '# Of', className: 'text-primary align-middle text-center bg-light'},
      {title: 'Assessed', className: 'text-primary align-middle text-center bg-light'},
      {title: 'Sale Price', className: 'text-primary align-middle text-center bg-light'},
      {title: 'Sample % Parcels', className: 'text-primary align-middle text-center bg-light'},
      {title: 'Sample % AV', className: 'text-primary align-middle text-center bg-light'},
      {title: 'Ratio', className: 'text-primary align-middle text-center bg-light'}
    ],
    renderRow: (item: mega.PsdMasterSalesTypes4015Row) => {
      return <tr key={item.type4015}>
        <td className="text-left align-middle font-weight-bold text-primary">{item.type4015}</td>
        <td className="text-center align-middle">{item.code}</td>
        <td className="text-center align-middle">{formatInteger(item.count)}</td>
        <td className="text-center align-middle">{formatInteger(item.assessed)}</td>
        <td className="text-center align-middle">{formatInteger(item.salesPrice)}</td>
        <td className="text-center align-middle">{formatDecimal(item.samplePercentParcels, 2, true)}</td>
        <td className="text-center align-middle">{formatDecimal(item.samplePercentAssessedValue, 2, true)}</td>
        <td className="text-center align-middle">{formatDecimal(item.ratio, 2, true)}</td>
      </tr>;
    }
  }), [
    psdSalesReport,
    selectedTab
  ]);

  return <Container fluid className="PsdMasterSales">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && psdSalesReport && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>PSD Master Sales {studyYear}</CardHeader>
        <CardHeader className="nav-tabs-header">
          <TabNav/>
        </CardHeader>
        <CardBody>
          {yearData && <PsdMasterSalesTable psdMasterSalesReport={yearData}
                                            header={studyYear}/>}
          <CustomTable {...types4015TableProps} />
        </CardBody>
      </Card>
    </>}
  </Container>;
};

export default withTabNav(PsdMasterSales, {tabs});