import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object().shape({
  values: Yup.object({
    localUnits: Yup.array(Yup.object({
      selected: Yup.bool(),
      comments: Yup.string()
        .max(200, messages.maxCharactersMessage(200))
        .matches(/^$|^[a-z\d- ]+$/i, messages.MUST_BE_ALPHANUMERIC)
        .nullable()
    }))
  })
});