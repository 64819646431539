import * as Yup from 'yup';

import * as messages from '../../messages';

type SchemaArgs = {
  isAmending: boolean
  isCertifying: boolean
}
const form4022LuCertificationSchema = ({
                                         isAmending,
                                         isCertifying
                                       }: SchemaArgs) => {
  const commentSchema = isAmending ? Yup.string()
    .max(200)
    .required(messages.REQUIRED) : Yup.string().nullable();

  if (isCertifying) {
    return Yup.object().shape({
      confirmed: Yup.boolean()
        .isTrue(messages.REQUIRED),
      reason: Yup.string()
        .required(messages.REQUIRED)
        .nullable()
        .oneOf([null, 'certified', 'uncertified']),
      comment: commentSchema
    });
  } else {
    return Yup.object().shape({
      comment: commentSchema
    });
  }
};

export default form4022LuCertificationSchema;