import {forms} from '../types';
import {sumObjects} from './math';

const mapRealProperty = (classification: forms.RealPropertyClassificationCode, form: forms.Form4018V2Dto) => {
  const classificationData = form.realProperty?.classifications?.[classification];
  return {
    studyType: classificationData?.studyType ?? '',
    projectedTrueCashValue: classificationData?.projectedTrueCashValue ?? '',
    assessmentRollClassificationAssessedValue: classificationData?.assessmentRollClassificationAssessedValue ?? '',
    ratioAssessmentToAppraisalsOverall: classificationData?.ratioAssessmentToAppraisalsOverall ?? '',
    sampleAssessedValue4015a: classificationData?.sampleAssessedValue4015a ?? '',
    numberOfParcels4015a: classificationData?.numberOfParcels4015a ?? '',
    trueCashValue4015a: classificationData?.trueCashValue4015a ?? '',
    ratioAssessmentToAppraisals4015a: classificationData?.ratioAssessmentToAppraisals4015a ?? '',
    ratioAssessmentToAppraisals2793: classificationData?.ratioAssessmentToAppraisals2793 ?? '',
    numberOfParcels2793: classificationData?.numberOfParcels2793 ?? ''
  };
};

const mapPersonalProperty = (classification: forms.PersonalPropertyClassificationCode,
                             form: forms.Form4018V2Dto) => {
  const classificationData = form?.personalProperty?.classifications?.[classification];
  return {
    studyType: classificationData?.studyType ?? '',
    assessmentRollClassificationAssessedValue: classificationData?.assessmentRollClassificationAssessedValue ?? '',
    numberOfParcels: classificationData?.numberOfParcels ?? '',
    sampleAssessedValue: classificationData?.sampleAssessedValue ?? '',
    sampleTrueCashValue: classificationData?.sampleTrueCashValue ?? '',
    studyRatio: classificationData?.studyRatio ?? '',
    projectedTrueCashValue: classificationData?.projectedTrueCashValue ?? ''
  };
};

const calculateTotals = (form4018: forms.Form4018V2Dto) => {
  const [realProperty, personalProperty] = [
    Object.values(form4018.realProperty.classifications),
    Object.values(form4018.personalProperty.classifications)
  ];

  return {
    realProperty: {
      assessmentRollClassificationAssessedValue: sumObjects(realProperty, 'assessmentRollClassificationAssessedValue'),
      numberOfParcels: sumObjects(realProperty, 'numberOfParcels4015a') + sumObjects(realProperty, 'numberOfParcels2793'),
      projectedTrueCashValue: sumObjects(realProperty, 'projectedTrueCashValue')
    },
    personalProperty: {
      assessmentRollClassificationAssessedValue: sumObjects(personalProperty, 'assessmentRollClassificationAssessedValue'),
      numberOfParcels: sumObjects(personalProperty, 'numberOfParcels'),
      projectedTrueCashValue: sumObjects(personalProperty, 'projectedTrueCashValue')
    }
  };
};

const getInitialValues = (form4018V2Dto: forms.Form4018V2Dto) => ({
  realProperty: {
    classifications: {
      100: mapRealProperty(100, form4018V2Dto),
      200: mapRealProperty(200, form4018V2Dto),
      300: mapRealProperty(300, form4018V2Dto),
      400: mapRealProperty(400, form4018V2Dto),
      500: mapRealProperty(500, form4018V2Dto),
      600: mapRealProperty(600, form4018V2Dto)
    },
    estimatedValues: form4018V2Dto?.realProperty?.estimatedValues ?? '',
    remarks: form4018V2Dto?.realProperty?.remarks ?? ''
  },
  personalProperty: {
    classifications: {
      150: mapPersonalProperty(150, form4018V2Dto),
      250: mapPersonalProperty(250, form4018V2Dto),
      350: mapPersonalProperty(350, form4018V2Dto),
      450: mapPersonalProperty(450, form4018V2Dto),
      550: mapPersonalProperty(550, form4018V2Dto)
    },
    remarks: form4018V2Dto?.personalProperty?.remarks ?? ''
  }
});

export {
  getInitialValues,
  calculateTotals
};