import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const find = (countyId: number | string,
                     year: number | string
): Promise<mega.CedRecapReport> => megApi.getWithJsonResponse(
  `mega/counties/${countyId}/ced-recap?year=${year}`
);

export const update = (countyId: number | string,
                       year: number | string,
                       cedRecapRequest: mega.CedRecapRequest
): Promise<mega.CedRecapReport> => megApi.patchWithJsonResponse(
  `/mega/counties/${countyId}/ced-recap?year=${year}`,
  {
    body: JSON.stringify(cedRecapRequest)
  }
);

export const findPreliminary = (countyId: number | string,
                                year: number | string
): Promise<mega.PreliminaryCedRecapReport> => megApi.getWithJsonResponse(
  `mega/counties/${countyId}/preliminary-ced-recap?year=${year}`
);

export const updatePreliminary = (countyId: number | string,
                                  year: number | string,
                                  preliminaryCedRecapReport: mega.PreliminaryCedRecapReport
): Promise<mega.PreliminaryCedRecapReport> => megApi.patchWithJsonResponse(
  `mega/counties/${countyId}/preliminary-ced-recap?year=${year}`,
  {
    body: JSON.stringify(preliminaryCedRecapReport)
  }
);