import {useEffect, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Container, Table} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, ProgressIndicator, TabNav, useAlerts, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {formatDecimal, formatInteger, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {megApi} from '../../api';
import {countyReport4017Api} from '../../api/mega';
import * as messages from '../../messages';
import {County, mega} from '../../types';

const CountyReport4017 = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [county, setCounty] = useState<County | undefined>(undefined);
  const [countyReport4017, setCountyReport4017] = useState<mega.CountyReport4017>(undefined);
  const {showErrorAlert} = useAlerts();
  const {selectedTab} = useTabNav();

  useEffect(() => {
    const loadCountyReport4017 = async () => {
      try {
        const [counties, report] = await Promise.all([
          megApi.findCounties(true),
          countyReport4017Api.find(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setCountyReport4017(report);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadCountyReport4017();
  }, [countyId, showErrorAlert, year, navigate]);

  const month24Data = useMemo(() => {
    return countyReport4017?.[selectedTab as string]?.month24Study ?? undefined;
  }, [
    selectedTab,
    countyReport4017
  ]);

  const month12Data = useMemo(() => {
    return countyReport4017?.[selectedTab as string]?.month12Study ?? undefined;
  }, [
    selectedTab,
    countyReport4017
  ]);

  const analysis4017 = useMemo(() => {
    return countyReport4017?.[selectedTab as string]?.analysis4017 ?? undefined;
  }, [
    selectedTab,
    countyReport4017
  ]);

  const analysis4047 = useMemo(() => {
    return countyReport4017?.[selectedTab as string]?.analysis4047 ?? undefined;
  }, [
    selectedTab,
    countyReport4017
  ]);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    year,
    countyId,
    county
  ]);

  const renderAnalysisTable = useMemo(() => (tableName: string, analysisData: mega.CountyReport4017Analysis) => {
    const adjustedValueText = tableName === '4017' ? 'Mean Adjusted Ratio' : 'Aggregate Adjusted Ratio';

    return <Table bordered responsive striped>
      <thead className="bg-light">
        <tr>
          <th colSpan={2} className="text-primary">
            {tableName} Analysis
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle" style={{width: '80%'}}>
            {year} Total Parcel Count
          </td>
          <td className="text-center align-middle" style={{width: '20%'}}>
            {formatInteger(analysisData.totalParcelCount)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">
            {year} Assessed Value of Class
          </td>
          <td className="text-center align-middle">
            {formatInteger(analysisData.assessedValueOfClass)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">
            {year} {adjustedValueText}
          </td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.adjustedRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">
            {year} Class TCV
          </td>
          <td className="text-center align-middle">
            {formatInteger(analysisData.classTrueCashValue)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">
            {Number(year) + 1} Projected TCV
          </td>
          <td className="text-center align-middle">
            {formatInteger(analysisData.projectedTrueCashValue)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">
            {year} to {Number(year) + 1} TCV % of Change
          </td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.trueCashValueChangePercent, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle"># of Parcels Sample %</td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.numberOfParcelsSamplePercent, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">Assessed Value Sample %</td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.assessedValueSamplePercent, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">Sample - Raw Weighted Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.sampleRawWeightedMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle">Sample - Refined Weighted Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(analysisData.sampleRefinedWeightedMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold text-primary text-left align-middle"># of Units with Sales</td>
          <td className="text-center align-middle">
            {formatInteger(analysisData.numberOfUnitsWithSales)}
          </td>
        </tr>
      </tbody>
    </Table>;
  }, [
    year
  ]);

  return <Container fluid className="CountyReport4017">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError &&
      countyReport4017 && month12Data && month24Data && analysis4047 && analysis4017 && <>
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
        <Card>
          <CardHeader>4017/4047 County Report</CardHeader>
          <CardHeader className="nav-tabs-header">
            <TabNav/>
          </CardHeader>
          <CardBody>
            <div className="mb-4">
              <Table bordered responsive>
                <thead className="bg-light">
                  <tr>
                    <th colSpan={9} className="text-primary">24 Month Sales Study</th>
                  </tr>
                  <tr className="bg-light">
                    <th className="text-primary text-center align-middle">Year of Assessment</th>
                    <th className="text-primary text-center align-middle">Sales Period</th>
                    <th className="text-primary text-center align-middle"># of Valid Sales</th>
                    <th className="text-primary text-center align-middle">Total Assessed Value for Sales</th>
                    <th className="text-primary text-center align-middle">Total Adjusted Assessed Value for Sales</th>
                    <th className="text-primary text-center align-middle">Total Prices for Sales</th>
                    <th className="text-primary text-center align-middle">Adjusted Ratio</th>
                    <th className="text-primary text-center align-middle" colSpan={2}>Statistical Observations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">
                      {Number(year) - 2}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`4/1/${Number(year) - 2} to 9/30/${Number(year) - 2}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period1.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period1.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period1.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period1.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period1.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">PRD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period1.statisticalObservations, 2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">
                      {Number(year) - 2}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`10/1/${Number(year) - 2} to 3/31/${Number(year) - 1}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period2.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period2.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period2.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period2.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period2.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">COD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period2.statisticalObservations, 2)}
                    </td>
                  </tr>
                  <tr className="bg-light font-weight-bold">
                    <td className="text-nowrap text-primary text-center align-middle" colSpan={2}>12 Month Total Sales</td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals1.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals1.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals1.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals1.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.totals1.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">COV</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.totals1.statisticalObservations, 2, true)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">
                      {Number(year) - 1}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`4/1/${Number(year) - 1} to 9/30/${Number(year) - 1}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period3.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period3.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period3.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period3.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period3.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">AAD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period3.statisticalObservations, 3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">
                      {Number(year) - 1}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`10/1/${Number(year) - 1} to 3/31/${year}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period4.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period4.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period4.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.period4.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period4.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">STD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.period4.statisticalObservations, 3)}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="font-weight-bold bg-light">
                    <td className="text-nowrap text-primary text-center align-middle" colSpan={2}>12 Month Total Sales</td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals2.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals2.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals2.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals2.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.totals2.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">AAR</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.totals2.statisticalObservations, 2, true)}
                    </td>
                  </tr>
                  <tr className="font-weight-bold bg-light">
                    <td className="text-nowrap text-primary text-center align-middle" colSpan={2}>24 Month Total Sales</td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals3.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals3.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals3.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month24Data.totals3.totalPricesForSales)}
                    </td>
                    <td className="bg-light"/>
                    <td className="text-left align-middle">MAR</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month24Data.totals3.statisticalObservations, 2, true)}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
            {renderAnalysisTable('4017', analysis4017)}
            <hr/>
            <div className="mb-4">
              <Table bordered responsive>
                <thead className="bg-light">
                  <tr>
                    <th colSpan={9} className="text-primary">12 Month Study</th>
                  </tr>
                  <tr className="bg-light">
                    <th className="text-primary text-center align-middle">Year of Assessment</th>
                    <th className="text-primary text-center align-middle">Sales Period</th>
                    <th className="text-primary text-center align-middle"># of Valid Sales</th>
                    <th className="text-primary text-center align-middle">Total Assessed Value for Sales</th>
                    <th className="text-primary text-center align-middle">Total Adjusted Assessed Value for Sales</th>
                    <th className="text-primary text-center align-middle">Total Prices for Sales</th>
                    <th className="text-primary text-center align-middle">Adjusted Ratio</th>
                    <th className="text-primary text-center align-middle" colSpan={2}>Statistical Observations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center align-middle">
                      {Number(year) - 1}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`10/1/${Number(year) - 1} to 3/31/${year}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period1.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period1.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period1.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period1.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month12Data.period1.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">PRD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month12Data.period1.statisticalObservations, 2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center align-middle">
                      {year}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {`4/1/${year} to 9/30/${year}`}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period2.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period2.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period2.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(month12Data.period2.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(month12Data.period2.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle">COD</td>
                    <td className="text-center align-middle">
                      {formatDecimal(month12Data.period2.statisticalObservations, 2)}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="font-weight-bold">
                    <td className="text-nowrap text-primary text-center align-middle bg-light" colSpan={2}>12 Month Total Sales</td>
                    <td className="text-center align-middle bg-light">
                      {formatInteger(month12Data.totals.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle bg-light">
                      {formatInteger(month12Data.totals.totalAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle bg-light">
                      {formatInteger(month12Data.totals.totalAdjustedAssessedValueForSales)}
                    </td>
                    <td className="text-center align-middle bg-light">
                      {formatInteger(month12Data.totals.totalPricesForSales)}
                    </td>
                    <td className="text-center align-middle bg-light">
                      {formatDecimal(month12Data.totals.adjustedRatio, 2, true)}
                    </td>
                    <td className="text-left align-middle bg-light">AAR</td>
                    <td className="text-center align-middle bg-light">
                      {formatDecimal(month12Data.totals.statisticalObservations, 2, true)}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
            {renderAnalysisTable('4047', analysis4047)}
          </CardBody>
        </Card>
      </>}
  </Container>;
};

export default withTabNav(CountyReport4017, {tabs: PROPERTY_CLASSIFICATION_TABS});