import CommentModal from './CommentModal';
import {Comment, ReturnRequest} from '../../types';

type Props = {
  formName: string
  formDescription: string
  localityDisplayName: string
  isOpen: boolean
  onReturn: (returnRequest: ReturnRequest) => void
  onCancel: () => void
}

const ReturnModal = ({
                       formName,
                       formDescription,
                       localityDisplayName,
                       isOpen,
                       onReturn,
                       onCancel
                     }: Props) => {
  return <>
    <CommentModal isOpen={isOpen}
                  modalTitle="Reason for Return"
                  onToggle={onCancel}
                  onSubmit={(returnRequest: Comment) => onReturn(returnRequest as ReturnRequest)}
                  confirmButtonText="Return"
                  cancelButtonText="Cancel">
      <p>
        Provide a reason for returning the Form {formName} {formDescription} for <span className="text-danger">{localityDisplayName}</span>.
      </p>
    </CommentModal>
  </>;
};

export default ReturnModal;