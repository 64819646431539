import {memo, useMemo} from 'react';
import {Table} from 'reactstrap';
import {divide, round, sum} from 'lodash';

import {FormikInput, FormikNumberInput, FormikPercentageInput, FormikSelect} from '@reasoncorp/kyber-js';

import {mega} from '../../types';
import {formatDecimal, formatInteger, STUDY_TYPES_REAL_4018} from '../../utils';

type Props = {
  selectedTab?: string
  classificationLocalUnits: mega.CedRecapReportLocalUnitResidential[]
  totals: mega.CedRecapReportResidentialTotals
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  editable?: boolean
  values?: mega.CedRecapReportResidential
}

type CedRecapTableRowProps = {
  selectedTab?: string
  item: mega.CedRecapReportLocalUnitResidential
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  values?: mega.CedRecapReportResidential
  editable?: boolean
  index: number
}

const calculateItemValues = ({
                               studyType,
                               firstPeriodNumberOfParcels,
                               secondPeriodNumberOfParcels,
                               thirdPeriodNumberOfParcels,
                               fourthPeriodNumberOfParcels,
                               appraisalNumberOfParcels,
                               unitTotalParcels,
                               firstPeriodAdjustedAssessedValue,
                               secondPeriodAdjustedAssessedValue,
                               thirdPeriodAdjustedAssessedValue,
                               fourthPeriodAdjustedAssessedValue,
                               appraisalStudyAssessedValue,
                               unitTotalAssessedValue,
                               ratioL4018
                             }: mega.CedRecapReportLocalUnitResidential,
                             editable: boolean = false) => {
  const calculatedValues: {
    tentativeMultiplier: string | number
    projectedTrueCashValue: string | number
    adjustedAssessedValueSampleSizePercent: string | number
    studyNumberOfParcels: number
    numberOfParcelsSampleSizePercent: string | number
    studyAssessedValue: string | number
  } = {
    tentativeMultiplier: '',
    projectedTrueCashValue: '',
    adjustedAssessedValueSampleSizePercent: '',
    studyNumberOfParcels: 0,
    numberOfParcelsSampleSizePercent: '',
    studyAssessedValue: ''
  };

  // calculate studyNumberOfParcels
  if (studyType === 'CS') {
    calculatedValues.studyNumberOfParcels = sum([
      firstPeriodNumberOfParcels,
      secondPeriodNumberOfParcels,
      thirdPeriodNumberOfParcels,
      fourthPeriodNumberOfParcels,
      appraisalNumberOfParcels
    ]);
  } else if (studyType === 'AS') {
    calculatedValues.studyNumberOfParcels = appraisalNumberOfParcels || 0;
  } else {
    calculatedValues.studyNumberOfParcels = sum([
      firstPeriodNumberOfParcels,
      secondPeriodNumberOfParcels,
      thirdPeriodNumberOfParcels,
      fourthPeriodNumberOfParcels
    ]);
  }

  // Calculate numberOfParcelsSampleSizePercent
  if (unitTotalParcels === 0) {
    calculatedValues.numberOfParcelsSampleSizePercent = 'NC';
  } else if (calculatedValues.studyNumberOfParcels === 0) {
    calculatedValues.numberOfParcelsSampleSizePercent = 'ES';
  } else if (calculatedValues.studyNumberOfParcels !== null && unitTotalParcels !== null) {
    calculatedValues.numberOfParcelsSampleSizePercent = divide(
      calculatedValues.studyNumberOfParcels,
      unitTotalParcels
    );
  }

  // calculate studyAssessedValue
  if (calculatedValues.studyNumberOfParcels === 0) {
    calculatedValues.studyAssessedValue = 'ES';
  } else if (studyType === 'CS') {
    calculatedValues.studyAssessedValue = sum([
      firstPeriodAdjustedAssessedValue,
      secondPeriodAdjustedAssessedValue,
      thirdPeriodAdjustedAssessedValue,
      fourthPeriodAdjustedAssessedValue
    ]);
  } else if (studyType === 'AS') {
    calculatedValues.studyAssessedValue = appraisalStudyAssessedValue || 0;
  } else {
    calculatedValues.studyAssessedValue = sum([
      firstPeriodAdjustedAssessedValue,
      secondPeriodAdjustedAssessedValue,
      thirdPeriodAdjustedAssessedValue,
      fourthPeriodAdjustedAssessedValue
    ]);
  }

  // calculate adjustedAssessedValueSampleSizePercent
  if (unitTotalParcels === 0) {
    calculatedValues.adjustedAssessedValueSampleSizePercent = 'NC';
  } else if (calculatedValues.studyNumberOfParcels === 0) {
    calculatedValues.adjustedAssessedValueSampleSizePercent = 'ES';
  } else if (unitTotalAssessedValue &&
    unitTotalAssessedValue > 0 &&
    calculatedValues.studyAssessedValue !== 'ES') {
    calculatedValues.adjustedAssessedValueSampleSizePercent = divide(
      calculatedValues.studyAssessedValue as number,
      unitTotalAssessedValue
    );
  }

  // calculate projectedTrueCashValue
  if (unitTotalAssessedValue === 0) {
    calculatedValues.projectedTrueCashValue = 'NC';
  } else if (ratioL4018 && ratioL4018 > 0 && unitTotalAssessedValue) {
    calculatedValues.projectedTrueCashValue = editable ? round(
      divide(
        unitTotalAssessedValue,
        divide(ratioL4018 as number, 100)
      )
    ) : round(divide(unitTotalAssessedValue, ratioL4018 as number));
  }

  // calculate tentativeMultiplier
  if (unitTotalAssessedValue === 0) {
    calculatedValues.tentativeMultiplier = 'NC';
  } else if (ratioL4018 && !isNaN(ratioL4018 as number)) {
    calculatedValues.tentativeMultiplier = editable ? divide(50, ratioL4018 as number)
      : divide(50, ratioL4018 as number * 100);
  }

  return calculatedValues;
};

const CedRecapTableRow = ({
                            item,
                            index,
                            selectedTab,
                            editable,
                            values,
                            setHasUnsavedChanges
                          }: CedRecapTableRowProps) => {
  const formValues = useMemo(() => {
    return selectedTab ? values?.localUnits[index] as mega.CedRecapReportLocalUnitResidential ?? item : item;
  }, [
    index,
    values,
    selectedTab,
    item
  ]);

  const calculations = useMemo(() =>
      calculateItemValues(formValues, editable),
    [
      formValues,
      editable
    ]);

  const fieldPrefix = useMemo(() =>
      `classifications[${selectedTab}].localUnits[${index}]`,
    [
      selectedTab,
      index
    ]);

  const commentFieldName = useMemo(() => {
    return editable ? `${fieldPrefix}.comments` : `comments['${selectedTab}'][${item.localUnitId}]`;
  }, [
    item,
    selectedTab,
    fieldPrefix,
    editable
  ]);

  return <tr key={item.localUnitId}>
    <td className="text-center align-middle text-primary font-weight-bold">
      {item.localUnitId}
    </td>
    <td className="text-left text-nowrap align-middle text-primary font-weight-bold">
      {item.localUnitName}
    </td>
    <td className="border-left-3 bg-light-teal text-center align-middle" style={{minWidth: '115px'}}>
      {editable && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                 formGroupClass="mb-0"
                                 ariaLabel="Study Type"
                                 name={`${fieldPrefix}.studyType`}>
        <option value="">Select</option>
        {STUDY_TYPES_REAL_4018.map(studyType =>
          <option key={studyType} value={studyType}>{studyType}</option>
        )}
      </FormikSelect>
      }
      {!editable && item.studyType}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.firstPeriodNumberOfParcels)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.firstPeriodNumberOfParcels`}
                           ariaLabel="First Period Number of Parcels"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.secondPeriodNumberOfParcels)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.secondPeriodNumberOfParcels`}
                           ariaLabel="Second Period Number of Parcels"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.thirdPeriodNumberOfParcels)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.thirdPeriodNumberOfParcels`}
                           ariaLabel="Third Period Number of Parcels"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.fourthPeriodNumberOfParcels)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.fourthPeriodNumberOfParcels`}
                           ariaLabel="Fourth Period Number of Parcels"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.appraisalNumberOfParcels)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.appraisalNumberOfParcels`}
                           ariaLabel="Appraisal Number of Parcels"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="border-left-3 text-center align-middle">
      {formatInteger(calculations.studyNumberOfParcels)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(item.unitTotalParcels)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(calculations.numberOfParcelsSampleSizePercent, 0, true)}
    </td>
    <td className="border-left-3 bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.firstPeriodAdjustedAssessedValue)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.firstPeriodAdjustedAssessedValue`}
                           ariaLabel="First Period Adjusted Assessed Value"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.secondPeriodAdjustedAssessedValue)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.secondPeriodAdjustedAssessedValue`}
                           ariaLabel="Second Period Adjusted Assessed Value"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.thirdPeriodAdjustedAssessedValue)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.thirdPeriodAdjustedAssessedValue`}
                           ariaLabel="Third Period Adjusted Assessed Value"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.fourthPeriodAdjustedAssessedValue)}
      {editable &&
        <FormikNumberInput name={`${fieldPrefix}.fourthPeriodAdjustedAssessedValue`}
                           ariaLabel="Fourth Period Adjusted Assessed Value"
                           formGroupClass="mb-0"
                           className="text-right"
                           onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {!editable && formatInteger(item.appraisalStudyAssessedValue)}
      {editable && <FormikNumberInput name={`${fieldPrefix}.appraisalStudyAssessedValue`}
                                      ariaLabel="Appraisal Adjusted Assessed Value"
                                      formGroupClass="mb-0"
                                      className="text-right"
                                      onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="border-left-3 text-center align-middle">
      {calculations.studyAssessedValue === 'ES' && 'ES'}
      {calculations.studyAssessedValue !== 'ES' && formatInteger(calculations.studyAssessedValue)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(item.unitTotalAssessedValue)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(calculations.adjustedAssessedValueSampleSizePercent, 0, true)}
    </td>
    <td className="text-center align-middle">
      {editable && <FormikPercentageInput name={`${fieldPrefix}.ratioL4018`}
                                          ariaLabel="L-4018 Ratio"
                                          className="text-right"
                                          formGroupClass="mb-0"
                                          onChange={() => setHasUnsavedChanges(true)}/>}
      {!editable && formatDecimal(item.ratioL4018, 2, true)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(calculations.projectedTrueCashValue)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(calculations.tentativeMultiplier, 4)}
    </td>
    <td className="text-center align-middle">
      <FormikInput name={commentFieldName}
                   maxLength="200"
                   disableFloatingLabel={true}
                   formGroupClass="mb-0"
                   ariaLabel="Comments"
                   onChange={() => {
                     setHasUnsavedChanges(true);
                   }}/>
    </td>
  </tr>;

};
const CedRecapResidentialTable = ({
                                    selectedTab,
                                    classificationLocalUnits,
                                    totals,
                                    values,
                                    setHasUnsavedChanges,
                                    editable
                                  }: Props) => {
  return <div className="table-fixed mb-4 SplitTable">
    <Table bordered responsive>
      <thead>
        <tr className="bg-light">
          <th colSpan={2} className="bg-light"/>
          <th colSpan={1} className="bg-light-teal border-left-3"/>
          <th colSpan={5} className="bg-light-teal text-primary text-center align-middle"># of Parcels</th>
          <th colSpan={3} className="border-left-3 bg-light"/>
          <th colSpan={4} className="bg-light-teal border-left-3 text-primary text-center align-middle">Adjusted Assessed Values</th>
          <th colSpan={1} className="bg-light-teal"/>
          <th colSpan={7} className="border-left-3 bg-light"/>
        </tr>
        <tr className="bg-light position-top-50">
          <th className="bg-light text-primary text-center align-middle">Unit #</th>
          <th className="bg-light text-primary text-left align-middle">Unit Name</th>
          <th className="border-left-3 bg-light-teal text-primary text-center align-middle">Study Type</th>
          <th className="bg-light-teal text-primary text-center align-middle">First Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Second Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Third Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Fourth Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Appraisal</th>
          <th className="border-left-3 bg-light text-primary text-center align-middle">Study # Parcels</th>
          <th className="bg-light text-primary text-center align-middle">Unit Total Parcels</th>
          <th className="bg-light text-primary text-center align-middle">Sample Size Percent</th>
          <th className="border-left-3 bg-light-teal text-primary text-center align-middle">First Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Second Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Third Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Fourth Period</th>
          <th className="bg-light-teal text-primary text-center align-middle">Appraisal Study Assessed Value</th>
          <th className="border-left-3 bg-light text-primary text-center align-middle">Study Assessed Value</th>
          <th className="bg-light text-primary text-center align-middle">Unit Total Assessed Value</th>
          <th className="bg-light text-primary text-center align-middle">Sample Size Percent</th>
          <th className="bg-light text-primary text-center align-middle">L4018 Ratio</th>
          <th className="bg-light text-primary text-center align-middle">Projected TCV</th>
          <th className="bg-light text-primary text-center align-middle">Tentative Mult.</th>
          <th className="bg-light text-primary text-center align-middle">Comments</th>
        </tr>
      </thead>
      <tbody>
        {classificationLocalUnits.map((item: mega.CedRecapReportLocalUnitResidential, index) =>
          <CedRecapTableRow item={item}
                            index={index}
                            values={values}
                            editable={editable}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                            selectedTab={selectedTab}/>)}
      </tbody>
      {totals && <tfoot>
        <tr key={`${selectedTab}-totals`} className="font-weight-bold text-dark">
          <td className="text-center align-middle bg-light">Totals</td>
          <td className="align-middle bg-light"/>
          <td className="border-left-3 bg-light-teal text-center align-middle">
            {totals.studyType}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.firstPeriodNumberOfParcels)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.secondPeriodNumberOfParcels)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.thirdPeriodNumberOfParcels)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.fourthPeriodNumberOfParcels)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.appraisalNumberOfParcels)}
          </td>
          <td className="border-left-3 bg-light text-center align-middle">
            {formatInteger(totals.studyNumberOfParcels)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatInteger(totals.unitTotalParcels)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatDecimal(totals.numberOfParcelsSampleSizePercent, 0, true)}
          </td>
          <td className="border-left-3 bg-light-teal text-center align-middle">
            {formatInteger(totals.firstPeriodAdjustedAssessedValue)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.secondPeriodAdjustedAssessedValue)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.thirdPeriodAdjustedAssessedValue)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.fourthPeriodAdjustedAssessedValue)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.appraisalStudyAssessedValue)}
          </td>
          <td className="border-left-3 bg-light text-center align-middle">
            {formatInteger(totals.studyAssessedValue)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatInteger(totals.unitTotalAssessedValue)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatDecimal(totals.adjustedAssessedValueSampleSizePercent, 0, true)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatDecimal(totals.ratioL4018, 2, true)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatInteger(totals.projectedTrueCashValue)}
          </td>
          <td className="bg-light text-center align-middle">
            {formatDecimal(totals.tentativeMultiplier, 4)}
          </td>
          <td className="bg-light align-middle"/>
        </tr>
      </tfoot>
      }
    </Table>
  </div>;
};

export default memo(CedRecapResidentialTable);