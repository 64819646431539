import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, ProgressIndicator, TabNav, useAlerts, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import * as messages from '../../messages';
import {isNumber, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {cedRecapSchema} from '../../schemas/mega';
import {County, mega} from '../../types';
import {cedRecapApi} from '../../api/mega';
import {CedRecapResidentialTable, CedRecapTable, CedRecapTotalsTable} from '../../components/mega';

const mapValues = (cedRecapReport: mega.CedRecapReport) => {
  let cedRecapRequest: mega.CedRecapRequest = {
    comments: {
      '100': {},
      '200': {},
      '300': {},
      '400': {},
      '500': {},
      '600': {}
    },
    trueCashValueProjection: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    },
    studyTypeSummary: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    },
    qaRatio: {
      '100': null,
      '200': null,
      '300': null,
      '400': null,
      '500': null,
      '600': null
    }
  };

  for (let i = 100; i <= 600; i += 100) {
    let classification = i.toString();

    if (!cedRecapReport?.[classification]) {
      continue;
    }

    let comments: {[localUnitId: string]: string} = {};
    if (cedRecapReport[classification] && classification === '400') {
      (cedRecapReport[classification] as mega.CedRecapReportResidential).localUnits.forEach((localUnit) => {
        comments[localUnit.localUnitId] = localUnit.comments || '';
      });
    } else if (cedRecapReport[classification] && classification !== '400') {
      (cedRecapReport[classification] as mega.CedRecapReportClassification).localUnits.forEach((localUnit) => {
        comments[localUnit.localUnitId] = localUnit.comments || '';
      });
    }

    cedRecapRequest.comments[classification] = comments;
    cedRecapRequest.trueCashValueProjection[classification] = cedRecapReport?.[classification]?.trueCashValueProjection;
    cedRecapRequest.qaRatio[classification] = cedRecapReport?.[classification]?.qaRatio;
    cedRecapRequest.studyTypeSummary[classification] = cedRecapReport?.[classification]?.studyTypeSummary;
  }

  return cedRecapRequest;
};

const CedRecap = () => {
  const navigate = useNavigate();
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [county, setCounty] = useState<County | undefined>(undefined);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {selectedTab} = useTabNav();
  const [cedRecapReport, setCedRecapReport] = useState<mega.CedRecapReport>(undefined);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    year,
    countyId,
    county
  ]);

  useEffect(() => {
    const loadCedRecapReport = async () => {
      try {
        const [counties, cedRecapData] = await Promise.all([
          megApi.findCounties(true),
          cedRecapApi.find(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setCedRecapReport(cedRecapData);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadCedRecapReport();
  }, [countyId, showErrorAlert, year, navigate]);

  const classificationLocalUnits = useMemo(() => {
    return cedRecapReport?.[selectedTab as string]?.localUnits ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const classificationTotals = useMemo(() => {
    return cedRecapReport?.[selectedTab as string]?.totals ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const additionalValues = useMemo(() => {
    return cedRecapReport?.[selectedTab as string] ?? undefined;
  }, [selectedTab, cedRecapReport]);

  const cedRecapReportClassificationTotals = useMemo(() => {
    return classificationTotals as mega.CedRecapReportClassificationTotals;
  }, [classificationTotals]);

  const cedRecapReportResidentialTotals = useMemo(() => {
    return (classificationTotals as mega.CedRecapReportResidentialTotals);
  }, [classificationTotals]);

  const calculateAdditionalValues = useCallback((formikProps: FormikProps<mega.CedRecapRequest>) => {
    const cedTrueCashValueProjection = formikProps.values.trueCashValueProjection[selectedTab as string];
    let psdTrueCashValueProjection: number | null;
    if (cedRecapReportClassificationTotals && cedRecapReportClassificationTotals.trueCashValue2 !== undefined) {
      psdTrueCashValueProjection = cedRecapReportClassificationTotals.trueCashValue2;
    } else {
      psdTrueCashValueProjection = cedRecapReportResidentialTotals.projectedTrueCashValue;
    }

    return {
      percentOfClassStudied: additionalValues?.percentOfClassStudied ?? null,
      trueCashValueDifference: (cedTrueCashValueProjection || 0) - psdTrueCashValueProjection,
      trueCashValueRatio: psdTrueCashValueProjection !== 0 &&
      cedTrueCashValueProjection !== null &&
      isNumber(cedTrueCashValueProjection) ?
        cedTrueCashValueProjection / psdTrueCashValueProjection : 0
    };
  }, [
    additionalValues,
    cedRecapReportClassificationTotals,
    cedRecapReportResidentialTotals?.projectedTrueCashValue,
    selectedTab
  ]);

  const handleSave = useCallback(async (cedRecapRequest: mega.CedRecapRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await cedRecapApi.update(countyId, year, cedRecapRequest);

      const cedRecapData = await cedRecapApi.find(countyId, year);
      setCedRecapReport(cedRecapData);
      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
      setHasUnsavedChanges(false);
    } catch (error) {
      showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    countyId,
    year
  ]);

  return <Container fluid className="CedRecap">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && cedRecapReport && classificationLocalUnits && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Formik validationSchema={cedRecapSchema}
              initialValues={{...mapValues(cedRecapReport)}}
              validateOnMount={true}
              onSubmit={async () => null}
              enableReinitialize={true}>
        {(formikProps) => {
          const calculatedValues = calculateAdditionalValues(formikProps);
          return <>
            <Card className="mb-4">
              <CardHeader>CED Recap</CardHeader>
              <CardHeader className="nav-tabs-header">
                <TabNav/>
              </CardHeader>
              <CardBody>
                {selectedTab !== '400' && <CedRecapTable selectedTab={selectedTab}
                                                         totals={cedRecapReportClassificationTotals}
                                                         classificationLocalUnits={classificationLocalUnits as mega.CedRecapReportLocalUnit[]}
                                                         setHasUnsavedChanges={setHasUnsavedChanges}/>}
                {selectedTab === '400' && <CedRecapResidentialTable selectedTab={selectedTab}
                                                                    setHasUnsavedChanges={setHasUnsavedChanges}
                                                                    classificationLocalUnits={classificationLocalUnits as mega.CedRecapReportLocalUnitResidential[]}
                                                                    totals={cedRecapReportResidentialTotals}/>}

                <CedRecapTotalsTable selectedTab={selectedTab}
                                     setHasUnsavedChanges={setHasUnsavedChanges}
                                     calculatedValues={calculatedValues}/>
              </CardBody>
            </Card>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button color="success"
                        disabled={loadingState.processing || !hasUnsavedChanges || !formikProps.isValid}
                        onClick={() => handleSave(formikProps.values)}>
                  Save
                </Button>
              </Col>
            </Row>
          </>;
        }}
      </Formik>
    </>}
  </Container>;
};

export default withTabNav(CedRecap, {tabs: PROPERTY_CLASSIFICATION_TABS});