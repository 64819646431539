import {Table} from 'reactstrap';

import {mega} from '../../types';
import {formatDecimal, formatInteger} from '../../utils';

type Props = {
  psdMasterSalesReport: mega.PsdMasterSalesReportClassification
  header: string
}

const PsdMasterSalesTable = ({
                               psdMasterSalesReport,
                               header
                             }: Props) => {
  return <div className="mb-4">
    <Table bordered responsive striped>
      <thead>
        <tr className="bg-light">
          <th className="text-primary text-center align-middle w-80">Property Classification Studied</th>
          <th className="text-primary text-center align-middle w-20">
            {header}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Total Assessed Value of County</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.totalAssessedValue)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Raw Assessed Value of Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.rawAssessedValueOfSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Refined Assessed Value of Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.refinedAssessedValueOfSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Raw Sale Price of Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.rawSalePriceOfSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Refined Sale Price of Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.refinedSalePriceOfSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Total # of Parcels In County</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.totalNumberOfParcelsInCounty)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Total # of Parcels In Raw Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.totalNumberOfParcelsInRawSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Total # of Parcels In Refined Sample</td>
          <td className="text-center align-middle">
            {formatInteger(psdMasterSalesReport.totalNumberOfParcelsInRefinedSample)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Raw Sample Size as % of Assessed Value</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.rawSampleSizeAsPercentAssessedValue, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Refined Sample Size as % of Assessed Value</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.refinedSampleSizeAsPercentAssessedValue, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Raw Sample Size as % of # of Parcels</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.rawSampleSizeAsPercentNumberOfParcels, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">Refined Sample Size as % of # of Parcels</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.refinedSampleSizeAsPercentNumberOfParcels, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Raw Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.rawMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Refined Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.refinedMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Raw Weighted Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.rawWeightedMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Refined Weighted Mean Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.refinedWeightedMeanRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Raw Median Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.rawMedianRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">The Refined Median Ratio</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.refinedMedianRatio, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">PRD (Price-related differential)</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.priceRelatedDifferential, 1)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">AAD (Average absolute deviation)</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.averageAbsoluteDeviation, 4)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">COD (Coefficient of dispersion)</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.coefficientOfDispersion, 2)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">STD (Standard Deviation)</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.standardDeviation, 4)}
          </td>
        </tr>
        <tr>
          <td className="text-left align-middle text-primary font-weight-bold">COV (Coefficient of variance)</td>
          <td className="text-center align-middle">
            {formatDecimal(psdMasterSalesReport.coefficientOfVariance, 2, true)}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>;
};

export default PsdMasterSalesTable;