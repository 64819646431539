import {memo, useMemo} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {formatDecimal, formatInteger} from '../../utils';

type Props = {
  beginYear: number
  endYear: number
  beforeAdjustment?: number
  afterAdjustment?: number
  adjustmentModifier: number
  reportLineNumbers: number[]
  divideLine1: number
  divideLine2: number
  isTwoYearStudy: boolean
}

const LocalUnitReport4017AdjustmentModifierCard = ({
                                                     beginYear,
                                                     endYear,
                                                     beforeAdjustment,
                                                     afterAdjustment,
                                                     adjustmentModifier,
                                                     reportLineNumbers,
                                                     divideLine1,
                                                     divideLine2,
                                                     isTwoYearStudy
                                                   }: Props) => {
  // These reference the line numbers from the form L-4023 residential classification data
  // By the default the line numbers are 405 and 403
  // if the study is a two-year study, then the line numbers should update to 408 and 401
  const formLine1 = useMemo(() => isTwoYearStudy ? 408 : 405, [isTwoYearStudy]);
  const formLine2 = useMemo(() => isTwoYearStudy ? 401 : 403, [isTwoYearStudy]);
  const shouldDisplayBeforeAndAfterAdjustment = useMemo(() =>
      beforeAdjustment !== undefined && afterAdjustment !== undefined,
    [beforeAdjustment, afterAdjustment]
  );
  const adjustmentModifierReportLineNumber = useMemo(() =>
      shouldDisplayBeforeAndAfterAdjustment ? reportLineNumbers[2] : reportLineNumbers[0],
    [shouldDisplayBeforeAndAfterAdjustment, reportLineNumbers]
  );

  return <Card className="mb-4">
    <CardHeader>
      {beginYear} to {endYear} Adjustment Modifier
    </CardHeader>
    <CardBody>
      {shouldDisplayBeforeAndAfterAdjustment && <>
        <Row className="d-flex justify-content-between">
          <Col>
            {reportLineNumbers[0]}. Enter the assessed valuation after adjustment from the {endYear} form L-4023 line {formLine1}
          </Col>
          <Col className="d-flex justify-content-end font-weight-bold">
            {formatInteger(afterAdjustment as number)}
          </Col>
        </Row>
        <hr/>
        <Row className="d-flex justify-content-between">
          <Col>
            {reportLineNumbers[1]}. Enter the assessed valuation before adjustment from the {endYear} form L-4023 line {formLine2}
          </Col>
          <Col className="d-flex justify-content-end font-weight-bold">
            {formatInteger(beforeAdjustment as number)}
          </Col>
        </Row>
        <hr/>
      </>}
      <Row className="d-flex justify-content-between">
        <Col>
          {adjustmentModifierReportLineNumber}. {beginYear} to {endYear} Adjustment Modifier.&nbsp;
          {shouldDisplayBeforeAndAfterAdjustment ? 'Divide' : 'Multiply'} line {divideLine1} by line {divideLine2}
        </Col>
        <Col className="d-flex justify-content-end font-weight-bold">
          {formatDecimal(adjustmentModifier, 4)}
        </Col>
      </Row>
    </CardBody>
  </Card>;
};

export default memo(LocalUnitReport4017AdjustmentModifierCard);