import {downloadUtils, RestApiHelper, SsoSpringConfig, SsoSpringRestApi, windowUtils} from '@reasoncorp/kyber-js';

import config from '../config';
import * as messages from '../messages';

export const ssoApi = new SsoSpringRestApi(new SsoSpringConfig({...config.sso}));

export const megApi = new RestApiHelper({
  path: config.apiHost,
  unauthorizedHandler: ssoApi.redirectToLogin,
  bumpLastActivity: ssoApi.bumpLastActivity,
  defaultFetchConfig: {
    credentials: 'include'
  }
});

// openFile util method
export const openFileInNewWindow = (showErrorAlert: (message: string, disableTimeout?: boolean) => void,
                                    message = messages.VIEW_FILE_FAILURE
) => async (url: string) => await windowUtils.openFileInNewWindow(megApi.getWithTextResponse(url), () => showErrorAlert(message, true));

export const downloadFile = (showErrorAlert: (message: string, disableTimeout?: boolean) => void,
                             message = messages.VIEW_FILE_FAILURE
) => async (url: string) => {
  await downloadUtils.downloadFile(megApi.getWithTextResponse(url), undefined, () => showErrorAlert(message, true));
};

