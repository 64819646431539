import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

type Values = {
  [classification: string]: {
    remarks: string
    trueCashValue: number | string | null
  }
}

const validateStudyType = (section: string) => function validateStudyType(this: any, values: any) {
  const classificationData = values as Values;
  const {trueCashValue, remarks} = classificationData[section];
  const isValid = remarks !== 'RA' || (remarks === 'RA' && trueCashValue !== '' && trueCashValue !== null);
  return isValid || this.createError({
    path: `${this.path}.${section}.trueCashValue`,
    message: messages.REQUIRED
  });
};

const propertySchema = Yup.object({
  numberOfParcels: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  assessedValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  trueCashValue: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  remarks: Yup.string()
    .nullable()
});

export default Yup.object().shape({
  realProperty: Yup.object().shape({
    agricultural: Yup.object().shape({
      101: propertySchema,
      102: propertySchema,
      103: propertySchema,
      104: propertySchema,
      105: propertySchema,
      106: propertySchema,
      107: propertySchema
    }).test('validateStudyType', '', validateStudyType('107')),
    commercial: Yup.object().shape({
      201: propertySchema,
      202: propertySchema,
      203: propertySchema,
      204: propertySchema,
      205: propertySchema,
      206: propertySchema,
      207: propertySchema
    }).test('validateStudyType', '', validateStudyType('207')),
    industrial: Yup.object().shape({
      301: propertySchema,
      302: propertySchema,
      303: propertySchema,
      304: propertySchema,
      305: propertySchema,
      306: propertySchema,
      307: propertySchema
    }).test('validateStudyType', '', validateStudyType('307')),
    residential: Yup.object().shape({
      401: propertySchema,
      402: propertySchema,
      403: propertySchema,
      404: propertySchema,
      405: propertySchema,
      406: propertySchema,
      407: propertySchema
    }).test('validateStudyType', '', validateStudyType('407')),
    timberCutover: Yup.object().shape({
      501: propertySchema,
      502: propertySchema,
      503: propertySchema,
      504: propertySchema,
      505: propertySchema,
      506: propertySchema,
      507: propertySchema
    }).test('validateStudyType', '', validateStudyType('507')),
    developmental: Yup.object().shape({
      601: propertySchema,
      602: propertySchema,
      603: propertySchema,
      604: propertySchema,
      605: propertySchema,
      606: propertySchema,
      607: propertySchema
    }).test('validateStudyType', '', validateStudyType('607'))
  }),
  personalProperty: Yup.object().shape({
    agricultural: Yup.object().shape({
      151: propertySchema,
      152: propertySchema,
      153: propertySchema,
      154: propertySchema,
      155: propertySchema,
      156: propertySchema,
      157: propertySchema
    }).test('validateStudyType', '', validateStudyType('157')),
    commercial: Yup.object().shape({
      251: propertySchema,
      252: propertySchema,
      253: propertySchema,
      254: propertySchema,
      255: propertySchema,
      256: propertySchema,
      257: propertySchema
    }).test('validateStudyType', '', validateStudyType('257')),
    industrial: Yup.object().shape({
      351: propertySchema,
      352: propertySchema,
      353: propertySchema,
      354: propertySchema,
      355: propertySchema,
      356: propertySchema,
      357: propertySchema
    }).test('validateStudyType', '', validateStudyType('357')),
    residential: Yup.object().shape({
      451: propertySchema,
      452: propertySchema,
      453: propertySchema,
      454: propertySchema,
      455: propertySchema,
      456: propertySchema,
      457: propertySchema
    }).test('validateStudyType', '', validateStudyType('457')),
    utility: Yup.object().shape({
      551: propertySchema,
      552: propertySchema,
      553: propertySchema,
      554: propertySchema,
      555: propertySchema,
      556: propertySchema,
      557: propertySchema
    }).test('validateStudyType', '', validateStudyType('557'))
  })
});
