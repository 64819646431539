import {ReactNode, useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {ObjectSchema} from 'yup';

import {FormikInput} from '@reasoncorp/kyber-js';

import {commentModalSchema} from '../../schemas';
import {Comment} from '../../types';

type Props = {
  children: ReactNode
  onSubmit: (comment: Comment) => void
  isOpen: boolean
  modalTitle: string
  onToggle: () => void
  confirmButtonText: string
  cancelButtonText: string
  defaultValue?: string
  renderAfterForm?: ReactNode
  schema?: ObjectSchema<any>
  maxLength?: number
  inputType?: 'text' | 'textarea'
}

const CommentModal = ({
                        children,
                        onSubmit,
                        isOpen,
                        modalTitle,
                        onToggle,
                        confirmButtonText,
                        cancelButtonText,
                        defaultValue = '',
                        renderAfterForm,
                        schema = commentModalSchema,
                        maxLength = 200,
                        inputType = 'text'
                      }: Props) => {
  const initialValues: Comment = useMemo(() => ({
    comment: defaultValue
  }), [defaultValue]);

  const handleToggle = useCallback((formikProps: FormikProps<Comment>) => {
    onToggle();
    formikProps.resetForm();
  }, [onToggle]);

  const handleSubmit = useCallback(async (comment: Comment, actions: FormikHelpers<Comment>) => {
    onSubmit(comment);
    onToggle();
    actions.setSubmitting(false);
    actions.resetForm();
  }, [onSubmit, onToggle]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal size="lg"
               isOpen={isOpen}
               toggle={() => handleToggle(formikProps)}
               autoFocus={false}
               aria-modal={true}
               returnFocusAfterClose={true}
               className="CommentModal">
          <ModalHeader toggle={() => handleToggle(formikProps)} className="h5" tag="h2">
            {modalTitle}
          </ModalHeader>
          <Form onSubmit={formikProps.handleSubmit}
                autoComplete="off">
            <ModalBody>
              <Row>
                <Col className="col-12">
                  {children}
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FormikInput name="comment"
                               labelText="Comment"
                               autoFocus
                               type={inputType}
                               maxLength={maxLength}/>
                </Col>
              </Row>
              {renderAfterForm && renderAfterForm}
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}>
                {confirmButtonText}
              </Button>
              <Button color="secondary"
                      onClick={() => handleToggle(formikProps)}
                      disabled={formikProps.isSubmitting}>
                {cancelButtonText}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CommentModal;