import {ChangeEvent, KeyboardEvent, memo, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Card, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {sortBy} from 'lodash';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {megApi, stateFormApi} from '../api';
import {Form4022LUStateStatusBadge, Form4022StateStatusBadge, StateStatusBadge} from '../components/badge';
import {ProgramYearSelect} from '../components/shared';
import {CountyFormDto, FileUploadCounts, ProgramYear, StateFormsResponse, StudyCertificationsStatuses} from '../types';
import {FormStatus, FormType} from '../enum';

type StateForms = [string, {[formType: string]: CountyFormDto}][]

const StatePortal = () => {
  const navigate = useNavigate();
  const year = Number(useParams<{year: string}>().year);
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [forms, setForms] = useState<StateForms>([]);
  const [fileUploadCounts, setFileUploadCounts] = useState<FileUploadCounts>({});
  const [studyCertificationStatuses, setStudyCertificationStatuses] = useState<StudyCertificationsStatuses>({});
  const [equalizationYears, setEqualizationYears] = useState<ProgramYear[]>([]);

  const selectedEqualizationYear = useMemo(() => {
    return isNaN(year) ? (equalizationYears?.filter(equalizationYear => equalizationYear.defaultYear)?.[0]?.year ?? undefined) : year;
  }, [
    year,
    equalizationYears
  ]);

  const setSelectedEqualizationYear = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    navigate(`/state-portal/forms/${e.target.value}`);
  }, [navigate]);

  useEffect(() => {
    const findForms = async () => {
      setLoadingState({loading: true, loadError: false});

      try {
        if (equalizationYears.length === 0) {
          const equalizationYears: ProgramYear[] = await megApi.findEqualizationYears();
          setEqualizationYears(equalizationYears);
        }

        if (selectedEqualizationYear !== undefined) {
          const forms: StateFormsResponse = await stateFormApi.findStateForms(selectedEqualizationYear);
          setForms(Object.entries(forms.counties));
          setFileUploadCounts(forms.fileUploadCounts);
          setStudyCertificationStatuses(forms.studyCertificationStatuses);
        }
        setLoadingState({loading: false, loadError: false});
      } catch {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void findForms();
  }, [
    navigate,
    selectedEqualizationYear,
    showErrorAlert,
    year,
    equalizationYears
  ]);

  const handleStatusClick = useCallback((form: CountyFormDto) => {
    const {formLevel, countyId, id, formType} = form;
    if (formType === FormType.FORM_4022_LU_AV || formType === FormType.FORM_4022_LU_SA) {
      navigate(`/state-portal/${countyId}/${selectedEqualizationYear}/forms/${id}/local-units/forms-local-unit`);
    } else if (formLevel === 'LOCAL_UNIT') {
      navigate(`/state-portal/${countyId}/${selectedEqualizationYear}/forms/${id}/local-units/forms`);
    } else {
      navigate(`/state-portal/${countyId}/${selectedEqualizationYear}/forms/${id}`);
    }
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleUploadClick = useCallback((countyId: number | null) => {
    navigate(`/state-portal/${countyId}/${selectedEqualizationYear}/filing-cabinet`);
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleStudyCertificationClick = useCallback((countyId: number | null) => {
    navigate(`/state-portal/study-certifications/${countyId}/${selectedEqualizationYear}`);
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleKeyPress = useCallback((form: CountyFormDto) => (event: KeyboardEvent) => {
    return event.key === 'Enter' ? handleStatusClick(form) : null;
  }, [
    handleStatusClick
  ]);

  const StatusBadge = useMemo(() => ({
                                       form,
                                       formType,
                                       status,
                                       isClickable
                                     }: {
    form: CountyFormDto,
    formType: string,
    status: string,
    isClickable: boolean
  }) => {
    if (formType === FormType.FORM_4022_LU_AV || formType === FormType.FORM_4022_LU_SA) {
      return <Form4022LUStateStatusBadge status={status}
                                         isClickable={isClickable}
                                         onClick={() => isClickable ? handleStatusClick(form) : null}/>;
    } else if (formType === FormType.FORM_4022_AV || formType === FormType.FORM_4022_SA) {
      return <Form4022StateStatusBadge status={status}
                                       is4022Sa={formType === FormType.FORM_4022_SA}
                                       isClickable={isClickable}
                                       onClick={() => isClickable ? handleStatusClick(form) : null}/>;
    } else {
      return <StateStatusBadge status={status}
                               isClickable={isClickable}
                               onClick={() => isClickable ? handleStatusClick(form) : null}/>;
    }
  }, [handleStatusClick]);

  const renderRow = useMemo(() => (stateForm: [string, CountyFormDto[]]): ReactNode => {
    const [countyName, formsList] = stateForm;
    const sortedByForms = sortBy(formsList, ['sortOrder']);
    const count = fileUploadCounts[countyName];
    const isStudyCertificationSubmitted = studyCertificationStatuses[countyName];
    let countyId: number | null = null;
    return (
      <tr key={countyName}>
        <td className="align-middle text-left text-nowrap left-fixed body-col-striped-fixed font-weight-bold text-primary"
            title={countyName}
            key={countyName}>
          {countyName}
        </td>
        {Object.values(sortedByForms).map((form) => {
          const {countyDisplayName, name, formType, stateStatus, status} = form;
          countyId = form.countyId;
          const isClickable = stateStatus !== FormStatus.NOT_STARTED ||
            formType === 'FORM_4022_AV' ||
            formType === 'FORM_4022_SA';
          const title = `${countyDisplayName} Form ${name} ${status}`;
          const statusBadgeProps = {form, formType, status, isClickable};

          return <td key={formType}
                     className="align-middle text-center text-nowrap"
                     id={formType}
                     onKeyDown={isClickable ? handleKeyPress(form) : () => null}
                     title={title}
                     aria-label={title}
                     style={isClickable ? {cursor: 'pointer'} : {}}>
            <StatusBadge {...statusBadgeProps} />
          </td>;
        })}
        <td className="align-middle text-center text-nowrap"
            title="Uploads"
            key={`uploads-${countyName}`}>
          <Badge className="font-size-100 border-0"
                 color={count === 0 ? 'light' : 'danger'}
                 onClick={() => count !== 0 ? handleUploadClick(countyId) : null}
                 tag={count !== 0 ? 'button' : 'span'}>
            {count}
          </Badge>
        </td>
        <td className="align-middle text-center text-nowrap"
            title="Study Certification"
            key={`study-certification-${countyName}`}>
          <ButtonIcon icon={isStudyCertificationSubmitted ? 'check-circle' : 'times'}
                      className={isStudyCertificationSubmitted ? 'text-success' : 'text-danger'}
                      onClick={() => isStudyCertificationSubmitted ? handleStudyCertificationClick(countyId) : null}
                      ariaLabel={isStudyCertificationSubmitted ? 'Study Certification Submitted' : 'Study Certification Not Started'}
                      title={isStudyCertificationSubmitted ? 'Study Certification Submitted' : 'Study Certification Not Started'}/>
        </td>
      </tr>
    );
  }, [
    fileUploadCounts,
    handleKeyPress,
    handleStudyCertificationClick,
    handleUploadClick,
    StatusBadge,
    studyCertificationStatuses
  ]);

  const tableProps = useMemo(() => {
    const thClassNameCenter = 'text-center text-primary bg-light text-nowrap';

    return {
      className: 'mb-0',
      headers: [{
        title: 'County',
        className: 'text-left text-primary bg-light left-fixed top-left-fixed-header'
      }, {
        title: 'L-4014a',
        className: thClassNameCenter
      }, {
        title: 'L-4027i-J',
        className: thClassNameCenter
      }, {
        title: 'L-4027i-A',
        className: thClassNameCenter
      }, {
        title: 'L-4027i-O',
        className: thClassNameCenter
      }, {
        title: 'L-4015',
        className: thClassNameCenter
      }, {
        title: 'L-4015a',
        className: thClassNameCenter
      }, {
        title: 'L-4017/4047',
        className: thClassNameCenter
      }, {
        title: 'L-4018R/P',
        className: thClassNameCenter
      }, {
        title: 'L-4022AV LU',
        className: thClassNameCenter
      }, {
        title: 'L-4022AV',
        className: thClassNameCenter
      }, {
        title: 'L-4022SA LU',
        className: thClassNameCenter,
        hide: Number(selectedEqualizationYear) < 2023
      }, {
        title: 'L-4022SA',
        className: thClassNameCenter,
        hide: Number(selectedEqualizationYear) < 2023
      }, {
        title: 'L-4023',
        className: thClassNameCenter
      }, {
        title: 'L-4024',
        className: thClassNameCenter
      }, {
        title: 'L-4046',
        className: thClassNameCenter
      }, {
        title: 'Uploads',
        className: thClassNameCenter
      }, {
        title: '3215',
        className: thClassNameCenter
      }],
      items: forms,
      renderRow
    };
  }, [
    forms,
    renderRow,
    selectedEqualizationYear
  ]);

  const breadcrumbs = useMemo(() => ([
    {text: 'State Dashboard', active: true}
  ]), []);

  const initialValues = useMemo(() => ({
    equalizationYear: selectedEqualizationYear
  }), [
    selectedEqualizationYear
  ]);

  return <Container fluid className="StateFormsList">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading &&
      <>
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
        <Formik initialValues={initialValues}
                onSubmit={async () => null}>
          {(_) => (
            <Form autoComplete="off" className="mb-0">
              <Row className="d-flex justify-content-between">
                <Col xs="12" sm="6" md="3" lg="2">
                  <ProgramYearSelect onChange={setSelectedEqualizationYear}
                                     years={equalizationYears}/>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader className="bg-secondary text-white">Form Review</CardHeader>
              <div className="table-fixed">
                <CustomTable {...tableProps} />
              </div>
            </Card>
          </Col>
        </Row>
      </>}
  </Container>;
};

export default memo(StatePortal);
