import * as Yup from 'yup';

import * as messages from '../../messages';

const form4022LuCertificationSchema = (isAmending: boolean) => Yup.object().shape({
  confirmed: Yup.boolean()
    .isTrue(messages.REQUIRED),
  comment: isAmending ? Yup.string()
    .max(200)
    .required(messages.REQUIRED) : Yup.string().nullable()
});

export default form4022LuCertificationSchema;