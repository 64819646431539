import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const find = (countyId: number | string,
                     year: number | string,
                     isYear2: boolean
): Promise<mega.PsdMasterSalesReport> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/psd-master-sales?year=${year}&year2=${isYear2}`
);

