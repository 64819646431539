import {YupFile} from '@reasoncorp/kyber-js';

import {CountyFormDto, JsonImportFileContent, LocalUnitFormDto} from '../../types';
import * as messages from '../../messages';

const formImportJsonFileSchema = (form: CountyFormDto | LocalUnitFormDto, fileContent: JsonImportFileContent) => new YupFile()
  .maxFileSize(50 * 1000 * 1000, messages.MAX_FILE_SIZE)
  .acceptedFileTypes(['text/json', 'application/json'], messages.BAD_FILE_FORMAT_JSON)
  .schema()
  .required(messages.REQUIRED)
  .test('validateJson', messages.JSON_IMPORT_INVALID, (file) => file !== null && fileContent !== null)
  .test('validateFormType', messages.INCORRECT_FORM_JSON, (file) => {
    if (file !== null &&
      fileContent !== null &&
      fileContent.type !== null &&
      fileContent.type === 'FORM_4022') {
      return form.formType === 'FORM_4022_AV' || form.formType === 'FORM_4022_SA';
    } else if (file !== null && fileContent !== null) {
      return fileContent.type === form.formImportKey;
    }
    return true;
  })
  .test('validateCountyId', messages.INCORRECT_COUNTY_JSON, (file) => {
    if (file !== null && fileContent !== null) {
      return fileContent.countyId === form.countyId;
    }
    return true;
  });

export default formImportJsonFileSchema;
