// TODO consider replacing this with round from lodash
export const roundDecimal = (number: number, decimals = 2): number => Number(number.toFixed(decimals));

export const sumObjects = (values: any[], key: string): number => {
  return values ? sum(values.map(value => value[key] as number)) : 0;
};

export const numberOrZero = (value: number | null): number => {
  const valueAsNumber = Number(value);
  return isNumber(valueAsNumber) ? valueAsNumber : 0;
};

export const sum = (values: number[]): number => {
  return values.reduce((valA, valB) => numberOrZero(valA) + numberOrZero(valB), 0);
};

export const isNumber = (value?: number | string | null): boolean => {
  return value !== null &&
    value !== undefined &&
    value !== '' &&
    !isNaN((value as number));
};