import {useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, CustomTable} from '@reasoncorp/kyber-js';

import {Report4030Dto} from '../../types/mega';
import {Report4030Badge} from '../badge';

type Props = {
  handleViewClick: (countyId: number) => void
  report4030Dtos: Report4030Dto[]
  year: number
  isDashboard: boolean
  isViewButtonDisabled?: boolean
}

const Report4030Table = ({
                           report4030Dtos,
                           handleViewClick,
                           year,
                           isDashboard,
                           isViewButtonDisabled = false
                         }: Props) => {
  const reviewHeaderText = useMemo(() => {
    return isDashboard ? 'Review Report' : 'Review PDF';
  }, [
    isDashboard
  ]);

  const CertificationStatusIcon = useMemo(() => ({
                                                   isCertified,
                                                   isBypassed
                                                 }: {
    isCertified: boolean,
    isBypassed: boolean
  }) => {
    const icon = isBypassed ? 'minus-circle' : (isCertified ? 'check-circle' : 'times');
    const className = isBypassed ? 'text-info' : (isCertified ? 'text-success' : 'text-danger');
    return <FontAwesomeIcon icon={icon}
                            className={className}/>;
  }, []);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'County', className: 'text-left text-primary bg-light left-fixed top-left-fixed-header'},
      {title: 'Analyst', className: 'text-center bg-light'},
      {title: 'Supervisor', className: 'text-center bg-light'},
      {title: 'Status', className: 'text-center bg-light'},
      {title: reviewHeaderText, className: 'text-center bg-light'}
    ],
    items: report4030Dtos,
    renderRow: (report4030Dto: Report4030Dto) => {
      const ariaLabelText = isDashboard ? `View ${report4030Dto.county.displayNameWithType} ${year} 4030 report` :
        `View ${report4030Dto.county.displayNameWithType} ${year} 4030 report PDF`;

      return <tr key={report4030Dto.county.id}>
        <td className="align-middle text-left text-nowrap text-primary font-weight-bold w-20">
          {report4030Dto.county.displayNameWithType}
        </td>
        <td className="text-center align-middle w-20">
          <CertificationStatusIcon isCertified={report4030Dto.certifiedByAnalystId !== null}
                                   isBypassed={report4030Dto.bypassedAnalyst}/>
        </td>
        <td className="text-center align-middle w-20">
          <CertificationStatusIcon isCertified={report4030Dto.certifiedBySupervisorId !== null}
                                   isBypassed={report4030Dto.bypassedSupervisor}/>
        </td>
        <td className="text-center align-middle w-20">
          <Report4030Badge report4030Dto={report4030Dto}
                           isAdmin={true}
                           isAnalyst={false}
                           isSupervisor={false}/>
        </td>
        <td className="text-center align-middle w-20">
          <ButtonIcon icon={isDashboard ? 'edit' : 'file-pdf'}
                      onClick={() => handleViewClick(report4030Dto.county.id)}
                      title={ariaLabelText}
                      ariaLabel={ariaLabelText}
                      disabled={isViewButtonDisabled}
                      className={isDashboard ? 'text-warning' : 'text-primary'}/>
        </td>
      </tr>;
    }
  }), [
    handleViewClick,
    isDashboard,
    reviewHeaderText,
    year,
    report4030Dtos,
    isViewButtonDisabled,
    CertificationStatusIcon
  ]);

  return <div className="Report4030Table">
    <CustomTable {...tableProps} />
  </div>;
};

export default Report4030Table;