enum FormType {
  FORM_4014A = 'FORM_4014A',
  FORM_4015 = 'FORM_4015',
  FORM_4015A = 'FORM_4015A',
  FORM_4017_4047 = 'FORM_4017_4047',
  FORM_4018_P_R = 'FORM_4018_P_R',
  FORM_4022_AV = 'FORM_4022_AV',
  FORM_4022_SA = 'FORM_4022_SA',
  FORM_4022_LU_AV = 'FORM_4022_LU_AV',
  FORM_4022_LU_SA = 'FORM_4022_LU_SA',
  FORM_4023 = 'FORM_4023',
  FORM_4024 = 'FORM_4024',
  FORM_4027I_JUNE = 'FORM_4027I_JUNE',
  FORM_4027I_AUG = 'FORM_4027I_AUG',
  FORM_4027I_OCT = 'FORM_4027I_OCT',
  FORM_4046 = 'FORM_4046'
}

export default FormType;