import {memo, useMemo} from 'react';
import {Table} from 'reactstrap';
import {divide, round} from 'lodash';

import {FormikInput, FormikNumberInput, FormikPercentageInput, FormikSelect} from '@reasoncorp/kyber-js';

import {mega} from '../../types';
import {formatDecimal, formatInteger, STUDY_TYPES_REAL_4018} from '../../utils';
import {CedRecapReportLocalUnit} from '../../types/mega';

type Props = {
  selectedTab?: string
  classificationLocalUnits: mega.CedRecapReportLocalUnit[]
  totals: mega.CedRecapReportClassificationTotals
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  editable?: boolean
  values?: mega.CedRecapReportClassification
}

type CedRecapTableRowProps = {
  selectedTab?: string
  item: mega.CedRecapReportLocalUnit
  values?: mega.CedRecapReportClassification
  index: number
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  editable?: boolean
}

const calculateItemValues = ({
                               studyType,
                               percentSampled1,
                               percentSampled2,
                               ratioL2793L4018,
                               ratioL4018,
                               totalParcels = 0,
                               numberOfParcels = 0,
                               assessedValue = 0,
                               totalAssessedValue = 0,
                               trueCashValue = 0
                             }: CedRecapReportLocalUnit, editable: boolean = false) => {
  const calculatedValues: {
    percentSampled1: string | number | null
    percentSampled2: string | number | null
    ratioL4018: string | number | null
    multiplier: string | number
    trueCashValue2: number
  } = {
    percentSampled1,
    percentSampled2,
    ratioL4018,
    multiplier: '',
    trueCashValue2: 0
  };

  // calculate percentSampled1
  if (totalParcels === 0) {
    calculatedValues.percentSampled1 = 'NC';
  } else if (numberOfParcels === 0) {
    calculatedValues.percentSampled1 = 'NS';
  } else if (totalParcels !== null) {
    calculatedValues.percentSampled1 = divide(numberOfParcels, totalParcels);
  }

  // calculate percentSampled2
  if (totalParcels === 0) {
    calculatedValues.percentSampled2 = 'NC';
  } else if (assessedValue === 0) {
    calculatedValues.percentSampled2 = 'ES';
  } else if (totalAssessedValue !== null) {
    calculatedValues.percentSampled2 = divide(assessedValue, totalAssessedValue);
  }

  // calculate ratioL4018
  if (totalParcels === 0) {
    calculatedValues.ratioL4018 = 'NC';
  } else if (ratioL2793L4018 > 0) {
    calculatedValues.ratioL4018 = editable ? divide(ratioL2793L4018 as number, 100) : ratioL2793L4018;
  } else if (trueCashValue > 0) {
    calculatedValues.ratioL4018 = divide(assessedValue, trueCashValue);
  } else {
    calculatedValues.ratioL4018 = null;
  }

  // calculate trueCashValue2
  if (studyType === 'ST') {
    calculatedValues.trueCashValue2 = trueCashValue;
  } else if (calculatedValues.ratioL4018 && calculatedValues.ratioL4018 !== 'NC') {
    calculatedValues.trueCashValue2 = round(divide(totalAssessedValue, calculatedValues.ratioL4018 as number));
  }

  // calculate multiplier
  if (totalAssessedValue === 0) {
    calculatedValues.multiplier = 'NC';
  } else if (calculatedValues.ratioL4018 && calculatedValues.ratioL4018 !== 'NC') {
    calculatedValues.multiplier = divide(50, (calculatedValues.ratioL4018 as number) * 100);
  }

  return calculatedValues;
};

const CedRecapTableRow = ({
                            selectedTab,
                            item,
                            setHasUnsavedChanges,
                            index,
                            values,
                            editable
                          }: CedRecapTableRowProps) => {
  const formValues = useMemo(() => {
    return selectedTab ? values?.localUnits[index] as mega.CedRecapReportLocalUnit ?? item : item;
  }, [
    index,
    selectedTab,
    values,
    item
  ]);

  const calculations = useMemo(() =>
      calculateItemValues(formValues, editable),
    [
      formValues,
      editable
    ]);

  const fieldPrefix = useMemo(() =>
      `classifications[${selectedTab}].localUnits[${index}]`,
    [
      selectedTab,
      index
    ]);

  const commentFieldName = useMemo(() =>
      editable ? `${fieldPrefix}.comments` : `comments['${selectedTab}'][${item.localUnitId}]`,
    [
      selectedTab,
      fieldPrefix,
      editable,
      item
    ]);

  return <tr key={item.localUnitId}>
    <td className="text-center text-primary font-weight-bold align-middle">
      {item.localUnitId}
    </td>
    <td className="text-left text-primary font-weight-bold text-nowrap align-middle">
      {item.localUnitName}
    </td>
    <td className="text-center align-middle" style={{minWidth: '115px'}}>
      {editable && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                 formGroupClass="mb-0"
                                 ariaLabel="Study Type"
                                 name={`${fieldPrefix}.studyType`}>
        <option value="">Select</option>
        {STUDY_TYPES_REAL_4018.map(studyType =>
          <option key={studyType} value={studyType}>{studyType}</option>
        )}
      </FormikSelect>
      }
      {!editable && item.studyType}
    </td>
    <td className="text-center align-middle">
      {!editable && formatInteger(item.numberOfParcels)}
      {editable && <FormikNumberInput name={`${fieldPrefix}.numberOfParcels`}
                                      ariaLabel="Number of Parcels"
                                      formGroupClass="mb-0"
                                      className="text-right"
                                      onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="text-center align-middle">
      {formatInteger(item.totalParcels)}
    </td>
    <td className="text-center align-middle">
      {(calculations.percentSampled1 === 'NS' || calculations.percentSampled1 === 'NC') && calculations.percentSampled1}
      {
        calculations.percentSampled1 !== 'NS' &&
        calculations.percentSampled1 !== 'NC' &&
        formatDecimal(calculations.percentSampled1, 0, true)
      }
    </td>
    <td className="text-center align-middle">
      {!editable && formatInteger(item.assessedValue)}
      {editable && <FormikNumberInput name={`${fieldPrefix}.assessedValue`}
                                      ariaLabel="Assessed Value"
                                      className="text-right"
                                      formGroupClass="mb-0"
                                      onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="text-center align-middle">
      {!editable && formatInteger(item.trueCashValue)}
      {editable && <FormikNumberInput name={`${fieldPrefix}.trueCashValue`}
                                      ariaLabel="True Cash Value CED"
                                      className="text-right"
                                      formGroupClass="mb-0"
                                      onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="text-center align-middle">
      {formatInteger(item.totalAssessedValue)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(calculations.percentSampled2, 0, true)}
    </td>
    <td className="text-center align-middle">
      {!editable && formatDecimal(item.ratioL2793L4018, 2, true)}
      {editable && <FormikPercentageInput name={`${fieldPrefix}.ratioL2793L4018`}
                                          ariaLabel="2793 L-4018 Ratio"
                                          className="text-right"
                                          formGroupClass="mb-0"
                                          onChange={() => setHasUnsavedChanges(true)}/>}
    </td>
    <td className="border-left-3 bg-light-teal text-center align-middle">
      {calculations.ratioL4018 === 'NC' && 'NC'}
      {calculations.ratioL4018 !== 'NC' && formatDecimal(calculations.ratioL4018, 2, true)}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {formatInteger(calculations.trueCashValue2)}
    </td>
    <td className="bg-light-teal text-center align-middle">
      {calculations.multiplier === 'NC' && 'NC'}
      {calculations.multiplier !== 'NC' && formatDecimal(calculations.multiplier, 4)}
    </td>
    <td className="bg-light-teal text-center align-middle">
      <label style={{display: 'none'}}
             htmlFor={commentFieldName}>
        Comments
      </label>
      <FormikInput name={commentFieldName}
                   maxLength="200"
                   disableFloatingLabel={true}
                   formGroupClass="mb-0"
                   ariaLabel="Comments"
                   className="shaded-comments"
                   onChange={() => {
                     setHasUnsavedChanges(true);
                   }}/>
    </td>
  </tr>;
};

const CedRecapTable = ({
                         selectedTab,
                         classificationLocalUnits,
                         totals,
                         setHasUnsavedChanges,
                         values,
                         editable
                       }: Props) => {
  return <div className="table-fixed mb-4 SplitTable">
    <Table bordered responsive>
      <thead>
        <tr className="text-primary bg-light">
          <th className="bg-light"/>
          <th colSpan={10} className="text-primary bg-light text-center align-middle">The CED Unit Studies</th>
          <th colSpan={3} className="text-primary border-left-3 bg-light-teal text-center align-middle">PSD Indicated Tentative</th>
          <th className="bg-light-teal"/>
        </tr>
        <tr className="bg-light position-top-50">
          <th className="bg-light text-primary text-center align-middle">Unit #</th>
          <th className="bg-light text-primary text-left align-middle">Unit Name</th>
          <th className="bg-light text-primary text-center align-middle">Study Type</th>
          <th className="bg-light text-primary text-center align-middle"># of Parcels</th>
          <th className="bg-light text-primary text-center align-middle">Total Parcels</th>
          <th className="bg-light text-primary text-center align-middle">% Sampled</th>
          <th className="bg-light text-primary text-center align-middle">Assessed Value</th>
          <th className="bg-light text-primary text-center align-middle">True Cash Value</th>
          <th className="bg-light text-primary text-center align-middle">Total Assessed Value</th>
          <th className="bg-light text-primary text-center align-middle">% Sampled</th>
          <th className="bg-light text-primary text-center align-middle">2793 L-4018 Ratio</th>
          <th className="border-left-3 bg-light-teal text-primary text-center align-middle">L-4018 Ratio</th>
          <th className="bg-light-teal text-primary text-center align-middle">True Cash Value</th>
          <th className="bg-light-teal text-primary text-center align-middle">Multiplier</th>
          <th className="bg-light-teal text-primary text-center align-middle">PSD Comments</th>
        </tr>
      </thead>
      <tbody>
        {classificationLocalUnits.map((item, index) =>
          <CedRecapTableRow item={item}
                            index={index}
                            values={values}
                            editable={editable}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                            selectedTab={selectedTab}/>)}
      </tbody>
      {totals && <tfoot>
        <tr key={`${selectedTab}-totals`} className="bg-light font-weight-bold">
          <td className="text-center align-middle text-dark bg-light">Totals</td>
          <td className="align-middle bg-light"/>
          <td className="text-center align-middle bg-light">
            {totals.studyType}
          </td>
          <td className="text-center align-middle bg-light">
            {formatInteger(totals.numberOfParcels)}
          </td>
          <td className="text-center align-middle bg-light">
            {formatInteger(totals.totalParcels)}
          </td>
          <td className="text-center align-middle bg-light">
            {(totals.percentSampled1 === 'NC' || totals.percentSampled1 === 'NS') && totals.percentSampled1}
            {
              totals.percentSampled1 !== 'NC' && totals.percentSampled1 !== 'NS' &&
              formatDecimal(totals.percentSampled1, 0, true)
            }
          </td>
          <td className="text-center align-middle bg-light">
            {formatInteger(totals.assessedValue)}
          </td>
          <td className="text-center align-middle bg-light">
            {formatInteger(totals.trueCashValue)}
          </td>
          <td className="text-center align-middle bg-light">
            {formatInteger(totals.totalAssessedValue)}
          </td>
          <td className="text-center align-middle bg-light">
            {formatDecimal(totals.percentSampled2, 0, true)}
          </td>
          <td className="align-middle bg-light"/>
          <td className="border-left-3 bg-light-teal text-center align-middle">
            {formatDecimal(totals.ratioL4018, 2, true)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatInteger(totals.trueCashValue2)}
          </td>
          <td className="bg-light-teal text-center align-middle">
            {formatDecimal(totals.multiplier, 4)}
          </td>
          <td className="bg-light-teal align-middle"/>
        </tr>
      </tfoot>}
    </Table>
  </div>;
};

export default memo(CedRecapTable);