import {memo} from 'react';

import {mega} from '../../types';
import {formatDecimal, formatInteger} from '../../utils';

type Props = {
  beginDate: string
  endDate: string
  period: mega.LocalUnitReport4017Period
  salesPeriod: number
  year: number
}

const LocalUnitReport4017TableRow = ({
                                       beginDate,
                                       endDate,
                                       period,
                                       salesPeriod,
                                       year
                                     }: Props) => {
  return <tr>
    <td className="text-center align-middle">
      {year}
    </td>
    <td className="text-nowrap text-center align-middle">
      {beginDate} to {endDate}
    </td>
    <td className="text-center align-middle">
      {salesPeriod}
    </td>
    <td className="text-center align-middle">
      {formatInteger(period.numberOfSales)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(period.totalAssessedValueForSales)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(period.applicableAdjustmentModifier, 4)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(period.adjustedAssessedValue)}
    </td>
    <td className="text-center align-middle">
      {formatInteger(period.totalAdjustedPrices)}
    </td>
    <td className="text-center align-middle">
      {formatDecimal(period.adjustmentRatio, 2, true)}
    </td>
  </tr>;
};

export default memo(LocalUnitReport4017TableRow);