import {FormikProps} from 'formik';
import {isNaN, round} from 'lodash';

import {isNumber, roundDecimal} from './math';
import {formatDecimal, formatInteger} from './formatters';
import {forms} from '../types';

const calculateAdjustedValue = (value: number, modifier: number) => roundDecimal(modifier * (Math.round(100 * value) / 100), 0);
const formatAdjustedValue = (value: number, modifier: number) => formatInteger(calculateAdjustedValue(value, modifier));
const roundAdjustmentRatio = (val: number, dec = 1000) => Math.round((val + Number.EPSILON) * dec) / dec;
const formatAdjustmentRatio = (modifier: number, value1: number, value2: number) => formatDecimal(
  Number((Math.round(100 * modifier * value1) / 100).toFixed(0)) / Number((Math.round(100 * value2) / 100).toFixed(0))
  , 2, true);

const mapStudyYears = (studyYears: forms.ClassificationStudyYears) => {
  const studyYearsMap: forms.ClassificationStudyYears = {};
  Object.entries(studyYears)
    .forEach(([key, value]) => studyYearsMap[key] = value ? value.toString() : null);
  return studyYearsMap;
};

const mapPeriodData = (form4017Dto: forms.Form4017Dto,
                       classification: string,
                       section: '24Month' | '12Month',
                       period: string) => {
  const periodKey = section === '24Month' ? (period as 'period1' | 'period2' | 'period3' | 'period4') :
    period as 'period1' | 'period2';
  const data = section === '24Month' ? form4017Dto?.classifications?.[classification]?.['24Month']?.[periodKey] :
    form4017Dto?.classifications?.[classification]?.['12Month']?.[periodKey as 'period1' | 'period2'];

  return {
    numberOfSales: data?.numberOfSales ?? '',
    totalAssessedValueForSales: data?.totalAssessedValueForSales ?? '',
    adjustedAssessedValue: data?.adjustedAssessedValue ?? '',
    totalAdjustedPrices: data?.totalAdjustedPrices ?? '',
    applicableAdjustmentModifier: data?.applicableAdjustmentModifier ?? ''
  };
};

const mapClassificationData = (form4017Dto: forms.Form4017Dto, classification: string) => {
  return {
    adjustmentModifier1: {
      beforeAdjustment: form4017Dto?.classifications?.[classification]?.adjustmentModifier1?.beforeAdjustment ?? '',
      afterAdjustment: form4017Dto?.classifications?.[classification]?.adjustmentModifier1?.afterAdjustment ?? ''
    },
    adjustmentModifier2: {
      beforeAdjustment: form4017Dto?.classifications?.[classification]?.adjustmentModifier2?.beforeAdjustment ?? '',
      afterAdjustment: form4017Dto?.classifications?.[classification]?.adjustmentModifier2?.afterAdjustment ?? ''
    },
    '24Month': {
      period1: mapPeriodData(form4017Dto, classification, '24Month', 'period1'),
      period2: mapPeriodData(form4017Dto, classification, '24Month', 'period2'),
      period3: mapPeriodData(form4017Dto, classification, '24Month', 'period3'),
      period4: mapPeriodData(form4017Dto, classification, '24Month', 'period4')
    },
    '12Month': {
      period1: mapPeriodData(form4017Dto, classification, '12Month', 'period1'),
      period2: mapPeriodData(form4017Dto, classification, '12Month', 'period2')
    }
  };
};

const getInitialValues = (form4017Dto: forms.Form4017Dto,
                          classificationStudyYears: forms.ClassificationStudyYears) => {
  return {
    classificationStudyYears: classificationStudyYears ? mapStudyYears(classificationStudyYears) : {},
    classifications: {
      100: mapClassificationData(form4017Dto, '100'),
      200: mapClassificationData(form4017Dto, '200'),
      300: mapClassificationData(form4017Dto, '300'),
      400: mapClassificationData(form4017Dto, '400'),
      500: mapClassificationData(form4017Dto, '500'),
      600: mapClassificationData(form4017Dto, '600')
    }
  };
};

const calculateTotals = (formikProps: FormikProps<any>, selectedTab: string) => {
  const currentClassification = formikProps.values.classifications[selectedTab as string];
  const studies12Month = currentClassification['12Month'];
  const studies24Month = currentClassification['24Month'];

  const adjustmentModifiers = [
    roundAdjustmentRatio(
      currentClassification.adjustmentModifier1.afterAdjustment / currentClassification.adjustmentModifier1.beforeAdjustment,
      10000),
    roundAdjustmentRatio(
      roundAdjustmentRatio(
        (currentClassification.adjustmentModifier2.afterAdjustment / currentClassification.adjustmentModifier2.beforeAdjustment),
        10000
      ) * roundAdjustmentRatio(
        (currentClassification.adjustmentModifier1.afterAdjustment / currentClassification.adjustmentModifier1.beforeAdjustment),
        10000
      ),
      10000),
    roundAdjustmentRatio(
      currentClassification.adjustmentModifier2.afterAdjustment / currentClassification.adjustmentModifier2.beforeAdjustment,
      10000
    )
  ].map(adjustmentModifier => !isNaN(adjustmentModifier) && isFinite(adjustmentModifier) ? adjustmentModifier : 0);

  const totals = {
    '12Month': [
      {
        numberOfSales: (isFinite(Number(studies24Month.period1.numberOfSales)) ? Number(studies24Month.period1.numberOfSales) : 0) + (isFinite(Number(studies24Month.period2.numberOfSales)) ? Number(studies24Month.period2.numberOfSales) : 0),
        adjustedAssessedValue: isNumber(adjustmentModifiers[1]) ? Number(
            calculateAdjustedValue(
              Number(studies24Month.period1.totalAssessedValueForSales), adjustmentModifiers[1])
          ) +
          Number(
            calculateAdjustedValue(Number(studies24Month.period2.totalAssessedValueForSales), adjustmentModifiers[1])
          ) : 0,
        totalAdjustedPrices: Number(studies24Month.period1.totalAdjustedPrices) + Number(studies24Month.period2.totalAdjustedPrices),
        adjustmentRatio: 0
      },
      {
        numberOfSales: (isFinite(Number(studies24Month.period3.numberOfSales)) ? Number(studies24Month.period3.numberOfSales) : 0) + (isFinite(Number(studies24Month.period4.numberOfSales)) ? Number(studies24Month.period4.numberOfSales) : 0),
        adjustedAssessedValue: isNumber(adjustmentModifiers[2]) ? Number(
            calculateAdjustedValue(
              Number(studies24Month.period3.totalAssessedValueForSales),
              adjustmentModifiers[2]
            )) +
          Number(
            calculateAdjustedValue(
              Number(studies24Month.period4.totalAssessedValueForSales), adjustmentModifiers[2]
            )
          ) : 0,
        totalAdjustedPrices: Number(studies24Month.period3.totalAdjustedPrices) + Number(studies24Month.period4.totalAdjustedPrices),
        adjustmentRatio: 0
      },
      {
        numberOfSales: Number(studies24Month.period4.numberOfSales) + Number(studies12Month.period2.numberOfSales),
        adjustedAssessedValue: isNumber(adjustmentModifiers[2]) ? Number(
            calculateAdjustedValue(
              Number(studies24Month.period4.totalAssessedValueForSales),
              adjustmentModifiers[2]
            )
          ) +
          Number(
            calculateAdjustedValue(
              Number(studies12Month.period2.totalAssessedValueForSales),
              1
            )
          ) : 0,
        totalAdjustedPrices: Number(studies24Month.period4.totalAdjustedPrices) + Number(studies12Month.period2.totalAdjustedPrices),
        adjustmentRatio: 0
      }
    ]
  };

  totals['12Month'].forEach((_, index) => {
    totals['12Month'][index].adjustmentRatio = totals['12Month'][index].adjustedAssessedValue / totals['12Month'][index].totalAdjustedPrices;
  });

  // Handle JS Floating point issue
  const line3 = Math.round(totals['12Month'][0].adjustmentRatio * 10000) / 10000;
  const line6 = Math.round(totals['12Month'][1].adjustmentRatio * 10000) / 10000;
  const line3And6Average = (((100000 * line3) + (100000 * line6)) / 2) / 100000;

  return {
    ...totals,
    adjustmentModifiers,
    '24Month': {
      numberOfSales: totals['12Month'][0].numberOfSales + totals['12Month'][1].numberOfSales,
      adjustedAssessedValue: totals['12Month'][0].adjustedAssessedValue + totals['12Month'][1].adjustedAssessedValue,
      totalAdjustedPrices: totals['12Month'][0].totalAdjustedPrices + totals['12Month'][1].totalAdjustedPrices,
      adjustmentRatio: round(line3And6Average, 4)
    }
  };
};

export {
  calculateTotals,
  calculateAdjustedValue,
  formatAdjustmentRatio,
  formatAdjustedValue,
  getInitialValues,
  mapClassificationData,
  mapStudyYears,
  roundAdjustmentRatio
};