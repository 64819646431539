import {FormikProps} from 'formik';

import {forms, LocalUnit} from '../types';
import {sum, sumObjects} from './math';

const mapLocalUnit = (lu: forms.Form4024LocalUnitDto) => {
  return {
    id: lu.id,
    displayName: lu.displayName,
    numberOfAcres: lu.numberOfAcres || null,
    personalPropertyAssessed: lu.personalPropertyAssessed || null,
    personalPropertyEqualized: lu.personalPropertyEqualized || null,
    assessed: {
      agricultural: lu.assessed.agricultural || null,
      commercial: lu.assessed.commercial || null,
      residential: lu.assessed.residential || null,
      industrial: lu.assessed.industrial || null,
      timberCutover: lu.assessed.timberCutover || null,
      developmental: lu.assessed.developmental || null
    },
    equalized: {
      agricultural: lu.equalized.agricultural || null,
      commercial: lu.equalized.commercial || null,
      residential: lu.equalized.residential || null,
      industrial: lu.equalized.industrial || null,
      timberCutover: lu.equalized.timberCutover || null,
      developmental: lu.equalized.developmental || null
    }
  };
};

const calculateTotals = (formikProps: FormikProps<any>) => {
  const {values} = formikProps;
  const localUnits = values.localUnits;
  const localUnitsEqualized = localUnits.map((lu: {equalized: any;}) => lu.equalized);
  const localUnitsAssessed = localUnits.map((lu: {assessed: any;}) => lu.assessed);

  const totals = {
    numberOfAcres: Number(sum(localUnits.map((lu: {
      numberOfAcres: {toString: () => string;};
    }) => lu.numberOfAcres?.toString?.().replace?.(/[^\d|.]/g, '')))),
    realPropertyAssessed: 0,
    personalPropertyAssessed: sumObjects(localUnits, 'personalPropertyAssessed'),
    realPropertyEqualized: 0,
    personalPropertyEqualized: sumObjects(localUnits, 'personalPropertyEqualized'),
    assessed: {
      agricultural: sumObjects(localUnitsAssessed, 'agricultural'),
      commercial: sumObjects(localUnitsAssessed, 'commercial'),
      residential: sumObjects(localUnitsAssessed, 'residential'),
      industrial: sumObjects(localUnitsAssessed, 'industrial'),
      timberCutover: sumObjects(localUnitsAssessed, 'timberCutover'),
      developmental: sumObjects(localUnitsAssessed, 'developmental')
    },
    equalized: {
      agricultural: sumObjects(localUnitsEqualized, 'agricultural'),
      commercial: sumObjects(localUnitsEqualized, 'commercial'),
      residential: sumObjects(localUnitsEqualized, 'residential'),
      industrial: sumObjects(localUnitsEqualized, 'industrial'),
      timberCutover: sumObjects(localUnitsEqualized, 'timberCutover'),
      developmental: sumObjects(localUnitsEqualized, 'developmental')
    }
  };

  totals.realPropertyAssessed = sum(Object.values(totals.assessed));
  totals.realPropertyEqualized = sum(Object.values(totals.equalized));

  return totals;
};

const calculateLocalUnitTotals = (form4024LocalUnit: forms.Form4024LocalUnitDto) => {
  return {
    equalized: sum(Object.values(form4024LocalUnit.equalized)),
    assessed: sum(Object.values(form4024LocalUnit.assessed))
  };
};

const getInitialValues = (form4024Dto: forms.Form4024Dto, localUnits: LocalUnit[]) => ({
  localUnits: form4024Dto.localUnits.length > 0 ?
    form4024Dto.localUnits.map(mapLocalUnit) :
    (localUnits.map((lu) => {
      return {
        ...lu,
        numberOfAcres: null,
        assessed: {
          agricultural: null,
          commercial: null,
          residential: null,
          industrial: null,
          timberCutover: null,
          developmental: null
        },
        equalized: {
          agricultural: null,
          commercial: null,
          residential: null,
          industrial: null,
          timberCutover: null,
          developmental: null
        },
        personalPropertyAssessed: null,
        personalPropertyEqualized: null,
        displayName: lu.displayNameWithType
      };
    }))
});

export {
  calculateTotals,
  calculateLocalUnitTotals,
  getInitialValues
};