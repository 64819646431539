import * as Yup from 'yup';

import * as messages from '../../messages';

const report4030CertificationSchema = (isBypassed = false) => Yup.object().shape({
  name: Yup.string()
    .required(messages.REQUIRED),
  certified: isBypassed ? Yup.boolean() : Yup.boolean()
    .isTrue(messages.REQUIRED)
});

export default report4030CertificationSchema;