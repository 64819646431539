import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../../types';
import Form4018V1 from './Form4018V1';
import Form4018V2 from './Form4018V2';
import {FormVersion} from '../../../enum';

type Props = {
  loading: boolean
  form: LocalUnitFormDto
  isStateUser: boolean
  submitButtonText: string
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  isLocalUnitUser: boolean
  onSave: (localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
}

const Form4018 = (props: Props) => {
  if (props.form.version === FormVersion.V1) {
    return <Form4018V1 {...props} />;
  } else if (props.form.version === FormVersion.V2) {
    return <Form4018V2 {...props} />;
  } else {
    return <div>Form Version {props.form.version} unsupported.</div>;
  }
};

export default Form4018;