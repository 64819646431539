import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import {divide, isNumber, round, sum} from 'lodash';

import {BreadcrumbsNav, ProgressIndicator, TabNav, useAlerts, useTabNav, withTabNav} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import * as messages from '../../messages';
import {PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {County, mega} from '../../types';
import {cedRecapApi} from '../../api/mega';
import {CedRecapResidentialTable, CedRecapTable, CedRecapTotalsTable} from '../../components/mega';
import {preliminaryCedRecapSchema} from '../../schemas/mega';

type ClassificationKeys = '100' | '200' | '300' | '400' | '500' | '600'

const calculateResidentialTotals = (localUnits: mega.CedRecapReportLocalUnitResidential[]) => {
  const totals: mega.CedRecapReportResidentialTotals = {
    studyAssessedValue: 0,
    studyNumberOfParcels: 0,
    studyType: 0,
    ratioL4018: 0,
    tentativeMultiplier: 0,
    firstPeriodNumberOfParcels: 0,
    secondPeriodNumberOfParcels: 0,
    thirdPeriodNumberOfParcels: 0,
    fourthPeriodNumberOfParcels: 0,
    appraisalNumberOfParcels: 0,
    firstPeriodAdjustedAssessedValue: 0,
    secondPeriodAdjustedAssessedValue: 0,
    thirdPeriodAdjustedAssessedValue: 0,
    fourthPeriodAdjustedAssessedValue: 0,
    unitTotalParcels: 0,
    appraisalStudyAssessedValue: 0,
    unitTotalAssessedValue: 0,
    unitTotalAssessedValueWithParcels: 0,
    adjustedAssessedValueSampleSizePercent: '',
    numberOfParcelsSampleSizePercent: '',
    projectedTrueCashValue: 0
  };

  localUnits.forEach(cedRecapReportLocalUnit => {
    if (cedRecapReportLocalUnit.studyType !== 'NC') {
      totals.studyType += 1;
    }

    totals.firstPeriodNumberOfParcels += cedRecapReportLocalUnit?.firstPeriodNumberOfParcels ?? 0;
    totals.secondPeriodNumberOfParcels += cedRecapReportLocalUnit?.secondPeriodNumberOfParcels ?? 0;
    totals.thirdPeriodNumberOfParcels += cedRecapReportLocalUnit?.thirdPeriodNumberOfParcels ?? 0;
    totals.fourthPeriodNumberOfParcels += cedRecapReportLocalUnit?.fourthPeriodNumberOfParcels ?? 0;
    totals.appraisalNumberOfParcels += cedRecapReportLocalUnit?.appraisalNumberOfParcels ?? 0;
    totals.firstPeriodAdjustedAssessedValue += cedRecapReportLocalUnit?.firstPeriodAdjustedAssessedValue ?? 0;
    totals.secondPeriodAdjustedAssessedValue += cedRecapReportLocalUnit?.secondPeriodAdjustedAssessedValue ?? 0;
    totals.thirdPeriodAdjustedAssessedValue += cedRecapReportLocalUnit?.thirdPeriodAdjustedAssessedValue ?? 0;
    totals.fourthPeriodAdjustedAssessedValue += cedRecapReportLocalUnit?.fourthPeriodAdjustedAssessedValue ?? 0;
    totals.appraisalStudyAssessedValue += cedRecapReportLocalUnit?.appraisalStudyAssessedValue ?? 0;
    totals.unitTotalAssessedValue += cedRecapReportLocalUnit?.unitTotalAssessedValue ?? 0;
    totals.unitTotalParcels += cedRecapReportLocalUnit?.unitTotalParcels ?? 0;

    // calculate projectedTrueCashValue
    if (cedRecapReportLocalUnit.ratioL4018 &&
      cedRecapReportLocalUnit.ratioL4018 > 0 &&
      cedRecapReportLocalUnit.unitTotalAssessedValue) {
      totals.projectedTrueCashValue += round(
        divide(
          cedRecapReportLocalUnit.unitTotalAssessedValue,
          divide(cedRecapReportLocalUnit.ratioL4018 as number, 100)
        )
      );
    }

    // Calculate studyNumberOfParcels
    let studyNumberOfParcels = 0;
    if (cedRecapReportLocalUnit.studyType === 'CS') {
      studyNumberOfParcels += sum([
        cedRecapReportLocalUnit.firstPeriodNumberOfParcels,
        cedRecapReportLocalUnit.secondPeriodNumberOfParcels,
        cedRecapReportLocalUnit.thirdPeriodNumberOfParcels,
        cedRecapReportLocalUnit.fourthPeriodNumberOfParcels,
        cedRecapReportLocalUnit.appraisalNumberOfParcels
      ]);
    } else if (cedRecapReportLocalUnit.studyType === 'AS') {
      studyNumberOfParcels += cedRecapReportLocalUnit.appraisalNumberOfParcels || 0;
    } else {
      studyNumberOfParcels += sum([
        cedRecapReportLocalUnit.firstPeriodNumberOfParcels,
        cedRecapReportLocalUnit.secondPeriodNumberOfParcels,
        cedRecapReportLocalUnit.thirdPeriodNumberOfParcels,
        cedRecapReportLocalUnit.fourthPeriodNumberOfParcels
      ]);
    }
    totals.studyNumberOfParcels += studyNumberOfParcels;

    // Add to unitTotalAssessedValueWithParcels for percentStudied
    if (studyNumberOfParcels > 0) {
      totals.unitTotalAssessedValueWithParcels += cedRecapReportLocalUnit?.unitTotalAssessedValue ?? 0;
    }

    // calculate studyAssessedValue
    if (cedRecapReportLocalUnit.studyType === 'CS') {
      totals.studyAssessedValue += sum([
        cedRecapReportLocalUnit.firstPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.secondPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.thirdPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.fourthPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.appraisalStudyAssessedValue
      ]);
    } else if (cedRecapReportLocalUnit.studyType === 'AS') {
      totals.studyAssessedValue += cedRecapReportLocalUnit.appraisalStudyAssessedValue || 0;
    } else {
      totals.studyAssessedValue += sum([
        cedRecapReportLocalUnit.firstPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.secondPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.thirdPeriodAdjustedAssessedValue,
        cedRecapReportLocalUnit.fourthPeriodAdjustedAssessedValue
      ]);
    }
  });

  // calculate numberOfParcelsSampleSizePercent
  if (totals.unitTotalParcels === 0) {
    totals.numberOfParcelsSampleSizePercent = 'NC';
  } else if (totals.studyNumberOfParcels === 0) {
    totals.numberOfParcelsSampleSizePercent = 'ES';
  } else {
    totals.numberOfParcelsSampleSizePercent = divide(totals.studyNumberOfParcels, totals.unitTotalParcels);
  }

  // calculate adjustedAssessedValueSampleSizePercent
  if (totals.unitTotalParcels === 0) {
    totals.adjustedAssessedValueSampleSizePercent = 'NC';
  } else if (totals.studyNumberOfParcels === 0) {
    totals.adjustedAssessedValueSampleSizePercent = 'ES';
  } else if (totals.unitTotalAssessedValue > 0) {
    totals.adjustedAssessedValueSampleSizePercent = divide(totals.studyAssessedValue, totals.unitTotalAssessedValue);
  }

  // calculate ratioL4018
  if (totals.projectedTrueCashValue > 0) {
    totals.ratioL4018 = divide(totals.unitTotalAssessedValue, totals.projectedTrueCashValue);
  }

  // calculate tentativeMultiplier
  if (totals.unitTotalAssessedValue === 0) {
    totals.tentativeMultiplier = 'NC';
  } else if (totals.ratioL4018 && !isNaN(totals.ratioL4018)) {
    totals.tentativeMultiplier = divide(50, totals.ratioL4018) / 100;
  }

  return totals;
};

const calculateClassificationTotals = (localUnits: mega.CedRecapReportLocalUnit[]) => {
  const totals: {
    numberOfParcels: number
    totalParcels: number
    assessedValue: number
    totalAssessedValue: number
    trueCashValue: number
    trueCashValue2: number
    studyType: number
    percentSampled1: string | number
    percentSampled2: string | number
    ratioL4018: number
    multiplier: string | number
    totalAssessedValueWithParcels: number
  } = {
    numberOfParcels: 0,
    totalParcels: 0,
    assessedValue: 0,
    totalAssessedValue: 0,
    trueCashValue: 0,
    trueCashValue2: 0,
    studyType: 0,
    percentSampled1: 0,
    percentSampled2: 0,
    ratioL4018: 0,
    multiplier: '',
    totalAssessedValueWithParcels: 0
  };

  localUnits.forEach(cedRecapReportLocalUnit => {
    if (cedRecapReportLocalUnit.studyType !== 'NC') {
      totals.studyType += 1;
    }

    // calculate ratioL4018
    if (cedRecapReportLocalUnit.totalParcels === 0) {
      cedRecapReportLocalUnit.ratioL4018 = 'NC';
    } else if (cedRecapReportLocalUnit.ratioL2793L4018 > 0) {
      cedRecapReportLocalUnit.ratioL4018 = divide(cedRecapReportLocalUnit.ratioL2793L4018 as number, 100);
    } else if (cedRecapReportLocalUnit.trueCashValue > 0) {
      cedRecapReportLocalUnit.ratioL4018 = divide(
        cedRecapReportLocalUnit.assessedValue,
        cedRecapReportLocalUnit.trueCashValue
      );
    }

    // calculate trueCashValue2
    if (cedRecapReportLocalUnit.studyType === 'ST') {
      cedRecapReportLocalUnit.trueCashValue2 = cedRecapReportLocalUnit.trueCashValue;
    } else if (cedRecapReportLocalUnit.ratioL4018 && cedRecapReportLocalUnit.ratioL4018 !== 'NC') {
      cedRecapReportLocalUnit.trueCashValue2 = round(
        divide(
          cedRecapReportLocalUnit.totalAssessedValue,
          cedRecapReportLocalUnit.ratioL4018 as number
        )
      );
    }
    totals.trueCashValue2 += cedRecapReportLocalUnit.trueCashValue2 as number;

    totals.numberOfParcels += cedRecapReportLocalUnit.numberOfParcels;
    totals.totalParcels += cedRecapReportLocalUnit.totalParcels;
    totals.assessedValue += cedRecapReportLocalUnit.assessedValue;
    totals.totalAssessedValue += cedRecapReportLocalUnit.totalAssessedValue;
    totals.trueCashValue += cedRecapReportLocalUnit.trueCashValue;

    if (cedRecapReportLocalUnit.numberOfParcels > 0) {
      totals.totalAssessedValueWithParcels += cedRecapReportLocalUnit.totalAssessedValue;
    }
  });

  // calculate percentSampled1
  if (totals.totalParcels === 0) {
    totals.percentSampled1 = 'NC';
  } else if (totals.numberOfParcels === 0) {
    totals.percentSampled1 = 'NS';
  } else if (totals.totalParcels !== null) {
    totals.percentSampled1 = divide(totals.numberOfParcels, totals.totalParcels);
  }

  // calculate percentSampled2
  if (totals.totalParcels === 0) {
    totals.percentSampled2 = 'NC';
  } else if (totals.assessedValue === 0) {
    totals.percentSampled2 = 'ES';
  } else if (totals.totalAssessedValue !== null) {
    totals.percentSampled2 = divide(totals.assessedValue, totals.totalAssessedValue);
  }

  // calculate ratioL4018
  if (totals.trueCashValue2 > 0) {
    totals.ratioL4018 = divide(totals.totalAssessedValue, totals.trueCashValue2);
  }

  // calculate multiplier
  if (totals.totalAssessedValue === 0) {
    totals.multiplier = 'NC';
  } else if (totals.ratioL4018 && totals.ratioL4018 > 0) {
    totals.multiplier = divide(50, totals.ratioL4018 as number) / 100;
  }

  return totals;
};

const PreliminaryCedRecap = () => {
  const navigate = useNavigate();
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [county, setCounty] = useState<County | undefined>(undefined);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {selectedTab} = useTabNav();
  const [
    preliminaryCedRecapReport,
    setPreliminaryCedRecapReport
  ] = useState<mega.PreliminaryCedRecapReport | undefined>(undefined);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county?.displayName ?? '', active: true}
  ]), [
    year,
    countyId,
    county
  ]);

  useEffect(() => {
    const loadPreliminaryCedRecapReport = async () => {
      try {
        const [counties, preliminaryCedRecapReport] = await Promise.all([
          megApi.findCounties(true),
          cedRecapApi.findPreliminary(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }

        setCounty(currentCounty);
        setPreliminaryCedRecapReport(preliminaryCedRecapReport);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadPreliminaryCedRecapReport();
  }, [countyId, showErrorAlert, year, navigate]);

  const classificationLocalUnits = useMemo(() => {
    return preliminaryCedRecapReport?.classifications?.[selectedTab as ClassificationKeys]?.localUnits ?? undefined;
  }, [selectedTab, preliminaryCedRecapReport]);

  const calculateAdditionalValues = useCallback((
    preliminaryCedRecapReportClassificationTotals: mega.CedRecapReportClassificationTotals,
    preliminaryCedRecapReportResidentialTotals: mega.CedRecapReportResidentialTotals,
    cedTrueCashValueProjection: number = 0
  ) => {
    const psdTrueCashValueProjection = selectedTab === '400' ?
      preliminaryCedRecapReportResidentialTotals.projectedTrueCashValue :
      preliminaryCedRecapReportClassificationTotals.trueCashValue2;
    const totalAssessedValueWithParcels = selectedTab === '400' ?
      preliminaryCedRecapReportResidentialTotals.unitTotalAssessedValueWithParcels :
      preliminaryCedRecapReportClassificationTotals.totalAssessedValueWithParcels;
    const totalAssessedValue = selectedTab === '400' ?
      preliminaryCedRecapReportResidentialTotals.unitTotalAssessedValue :
      preliminaryCedRecapReportClassificationTotals.totalAssessedValue;

    return {
      percentOfClassStudied: totalAssessedValue > 0 ? divide(totalAssessedValueWithParcels, totalAssessedValue) : 0,
      trueCashValueDifference: (cedTrueCashValueProjection || 0) - psdTrueCashValueProjection,
      trueCashValueRatio: psdTrueCashValueProjection !== 0 &&
      cedTrueCashValueProjection !== null &&
      isNumber(cedTrueCashValueProjection) ?
        cedTrueCashValueProjection / psdTrueCashValueProjection : 0
    };
  }, [
    selectedTab
  ]);

  const handleSave = useCallback(async (preliminaryCedRecapReport?: mega.PreliminaryCedRecapReport) => {
    if (preliminaryCedRecapReport) {
      setLoadingState({...loadingState, processing: true});
      try {
        await cedRecapApi.updatePreliminary(countyId, year, preliminaryCedRecapReport);

        const updatedPreliminaryCedRecapReport = await cedRecapApi.findPreliminary(countyId, year);
        setPreliminaryCedRecapReport(updatedPreliminaryCedRecapReport);
        showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
        setHasUnsavedChanges(false);
      } catch (error) {
        showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
      } finally {
        setLoadingState({...loadingState, processing: false});
      }
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    loadingState,
    countyId,
    year
  ]);

  const initialValues = useMemo(() => ({
    classifications: preliminaryCedRecapReport?.classifications ?? {},
    qaRatio: {},
    studyTypeSummary: {},
    trueCashValueProjection: {
      '100': preliminaryCedRecapReport?.trueCashValueProjection?.['100'] ?? null,
      '200': preliminaryCedRecapReport?.trueCashValueProjection?.['200'] ?? null,
      '300': preliminaryCedRecapReport?.trueCashValueProjection?.['300'] ?? null,
      '400': preliminaryCedRecapReport?.trueCashValueProjection?.['400'] ?? null,
      '500': preliminaryCedRecapReport?.trueCashValueProjection?.['500'] ?? null,
      '600': preliminaryCedRecapReport?.trueCashValueProjection?.['600'] ?? null
    }
  }), [
    preliminaryCedRecapReport
  ]);

  return <Container fluid className="CedRecap">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && preliminaryCedRecapReport && classificationLocalUnits && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Formik validationSchema={preliminaryCedRecapSchema}
              initialValues={initialValues}
              validateOnMount={true}
              onSubmit={async () => null}
              enableReinitialize={true}>
        {(formikProps) => {
          const currentClassificationValues = formikProps.values.classifications[selectedTab as ClassificationKeys] ?? undefined;
          const preliminaryCedRecapReportClassificationTotals = calculateClassificationTotals(
            formikProps.values.classifications[selectedTab as ClassificationKeys].localUnits as mega.CedRecapReportLocalUnit[]
          );

          const preliminaryCedRecapReportResidentialTotals = calculateResidentialTotals(
            formikProps.values.classifications[selectedTab as ClassificationKeys].localUnits as mega.CedRecapReportLocalUnitResidential[]
          );

          const calculatedValues = calculateAdditionalValues(
            preliminaryCedRecapReportClassificationTotals,
            preliminaryCedRecapReportResidentialTotals,
            formikProps.values.trueCashValueProjection[selectedTab as ClassificationKeys] || 0
          );

          return <>
            <Card className="mb-4">
              <CardHeader>Preliminary CED Recap</CardHeader>
              <CardHeader className="nav-tabs-header">
                <TabNav/>
              </CardHeader>
              <CardBody>
                {selectedTab !== '400' && currentClassificationValues && <CedRecapTable selectedTab={selectedTab}
                                                                                        editable
                                                                                        values={currentClassificationValues as mega.CedRecapReportClassification}
                                                                                        totals={preliminaryCedRecapReportClassificationTotals}
                                                                                        classificationLocalUnits={classificationLocalUnits as mega.CedRecapReportLocalUnit[]}
                                                                                        setHasUnsavedChanges={setHasUnsavedChanges}/>}
                {selectedTab === '400' && <CedRecapResidentialTable selectedTab={selectedTab}
                                                                    setHasUnsavedChanges={setHasUnsavedChanges}
                                                                    editable
                                                                    values={currentClassificationValues as mega.CedRecapReportResidential}
                                                                    classificationLocalUnits={classificationLocalUnits as mega.CedRecapReportLocalUnitResidential[]}
                                                                    totals={preliminaryCedRecapReportResidentialTotals}/>}

                <CedRecapTotalsTable selectedTab={selectedTab}
                                     setHasUnsavedChanges={setHasUnsavedChanges}
                                     calculatedValues={calculatedValues}/>
              </CardBody>
            </Card>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button color="success"
                        disabled={loadingState.processing || !hasUnsavedChanges || !formikProps.isValid}
                        onClick={() => handleSave(formikProps.values)}>
                  Save
                </Button>
              </Col>
            </Row>
          </>;
        }}
      </Formik>
    </>}
  </Container>;
};

export default withTabNav(PreliminaryCedRecap, {tabs: PROPERTY_CLASSIFICATION_TABS});