import {memo, useMemo} from 'react';
import {Card, CardHeader} from 'reactstrap';

import {CustomTable} from '@reasoncorp/kyber-js';

import {formatDate} from '../../utils';
import {FormHistory} from '../../types';

type Props = {
  items: FormHistory[]
}

const FormHistoryTable = ({
                            items
                          }: Props) => {
  const renderRow = useMemo(() => ({
                                     createdAt,
                                     type,
                                     userFullName,
                                     comment
                                   }: FormHistory) => {
    return (
      <tr key={`formHistory-${createdAt}`}>
        <td className="align-middle text-center">{formatDate(createdAt)}</td>
        <td className="align-middle text-nowrap">{type}</td>
        <td className="align-middle text-nowrap">{userFullName}</td>
        <td className="align-middle">{comment}</td>
      </tr>
    );
  }, []);

  const tableProps = useMemo(() => ({
    headers: [{
      title: 'Date',
      className: 'text-center align-middle'
    }, {
      title: 'Type',
      className: 'align-middle'
    }, {
      title: 'User',
      className: 'align-middle'
    }, {
      title: 'Comment',
      className: 'align-middle'
    }],
    items,
    renderRow,
    className: 'mb-0',
    headerRowClassName: 'text-primary'
  }), [
    items,
    renderRow
  ]);

  if (items.length === 0) {
    return null;
  } else {
    return <Card className="mb-3">
      <CardHeader className="bg-secondary text-white">
        Form History
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>;
  }
};

export default memo(FormHistoryTable);