import * as Yup from 'yup';

import {YupFile} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';

export default Yup.object().shape({
  csvFile: new YupFile()
    .maxFileSize(50 * 1000 * 1000, messages.MAX_FILE_SIZE)
    .acceptedFileTypes(['CSV'], messages.BAD_FILE_FORMAT_CSV)
    .schema()
    .required(messages.REQUIRED)
});