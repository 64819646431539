import {memo} from 'react';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  formName: string
  formDescription: string
  localityDisplayName: string
}

const AcceptModal = ({
                       isOpen,
                       formName,
                       formDescription,
                       localityDisplayName,
                       onCancel,
                       onConfirm
                     }: Props) => {
  return <ConfirmationModal isOpen={isOpen}
                            title="Accept Form"
                            confirmButtonText="Yes"
                            cancelButtonText="No"
                            confirmCallback={() => onConfirm()}
                            cancelCallback={() => onCancel()}
                            size="lg">
    <p>
      Are you sure you want to accept the Form {formName} {formDescription} for <span className="text-danger">{localityDisplayName}</span>?
    </p>
  </ConfirmationModal>;
};

export default memo(AcceptModal);