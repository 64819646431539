import {memo} from 'react';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  formName: string
  formDescription: string
  localityDisplayName: string
}

const ResetModal = ({
                      isOpen,
                      onConfirm,
                      onCancel,
                      formName,
                      formDescription,
                      localityDisplayName
                    }: Props) => {
  return <ConfirmationModal isOpen={isOpen}
                            size="lg"
                            title="Reset Form"
                            confirmButtonText="Reset"
                            cancelButtonText="Cancel"
                            confirmCallback={() => onConfirm()}
                            cancelCallback={() => onCancel()}>
    <p>
      Are you sure you want to clear all the data from Form {formName} {formDescription} for <span className="text-danger">{localityDisplayName}</span>?
    </p>
  </ConfirmationModal>;
};

export default memo(ResetModal);