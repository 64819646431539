import {useMemo} from 'react';
import {Table} from 'reactstrap';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {forms} from '../../../types';
import {formatInteger} from '../../../utils';
import * as form4024Utils from '../../../utils/form4024Utils';

type Props = {
  localUnits: forms.Form4024LocalUnitDto[]
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: forms.Form4024Totals
}

const Form4024RealEqualizedValuationsTab = ({
                                              localUnits,
                                              showReadOnlyView,
                                              setHasUnsavedChanges,
                                              totals
                                            }: Props) => {
  const renderRow = useMemo(() => (form4024LocalUnit: forms.Form4024LocalUnitDto, index: number) => {
    const localUnitTotals = form4024Utils.calculateLocalUnitTotals(form4024LocalUnit);

    return <tr key={index}>
      <td className="align-middle text-primary font-weight-bold">{form4024LocalUnit.displayName}</td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.agricultural)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.agricultural`}
                                                 ariaLabel="Real Property - Agricultural Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.commercial)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.commercial`}
                                                 ariaLabel="Real Property - Commercial Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.industrial)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.industrial`}
                                                 ariaLabel="Real Property - Industrial Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.residential)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.residential`}
                                                 ariaLabel="Real Property - Residential Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.timberCutover)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.timberCutover`}
                                                 ariaLabel="Real Property - Timber-Cutover Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.equalized.developmental)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].equalized.developmental`}
                                                 ariaLabel="Real Property - Developmental Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {formatInteger(localUnitTotals.equalized)}
      </td>
    </tr>;
  }, [
    showReadOnlyView,
    setHasUnsavedChanges
  ]);

  return <Table responsive bordered>
    <thead>
      <tr>
        <th className="align-middle text-primary font-weight-bold">Township/City</th>
        <th className="align-middle text-primary text-center">Agricultural</th>
        <th className="align-middle text-primary text-center">Commercial</th>
        <th className="align-middle text-primary text-center">Industrial</th>
        <th className="align-middle text-primary text-center">Residential</th>
        <th className="align-middle text-primary text-center">Timber-Cutover</th>
        <th className="align-middle text-primary text-center">Developmental</th>
        <th className="align-middle text-primary text-center">Total Real Property</th>
      </tr>
    </thead>
    <tbody>
      {localUnits.map(renderRow)}
    </tbody>
    <tfoot className="bg-light">
      <tr>
        <td className="align-middle font-weight-bold text-primary">Totals</td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.agricultural)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.commercial)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.industrial)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.residential)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.timberCutover)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.equalized.developmental)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyEqualized)}
        </td>
      </tr>
    </tfoot>
  </Table>;
};

export default Form4024RealEqualizedValuationsTab;