import * as Yup from 'yup';
import {get} from 'lodash';

import {YupPhone} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {CERTIFICATION_LEVELS} from '../../utils';
import {transformBlankToNull} from '../helpers';
import {ClassificationsBooleanMap} from '../../types/forms';

const percentSchema = Yup.number()
  .nullable()
  .max(100, messages.MAX_VALUE_PERCENT)
  .transform(transformBlankToNull)
  .typeError(messages.MUST_BE_NUMBER);

const numberSchema = Yup.number()
  .max(9999999, messages.MAX_VALUE_NUMBER)
  .transform(transformBlankToNull)
  .typeError(messages.MUST_BE_NUMBER);

const validateLessThanNumberOfParcelsInStudyInLocalUnit = function (this: any, values: any) {
  const localUnitValues = values as {numberOfParcelsInClass: number, numberOfParcelsInStudy: number};
  const isValid = localUnitValues.numberOfParcelsInClass === null ||
    localUnitValues.numberOfParcelsInClass === 0 ||
    localUnitValues.numberOfParcelsInStudy === null ||
    localUnitValues.numberOfParcelsInStudy === 0 ||
    Number(localUnitValues.numberOfParcelsInStudy) <= Number(localUnitValues.numberOfParcelsInClass);

  return isValid || this.createError({
    path: `${this.path}.numberOfParcelsInStudy`,
    message: messages.MAX_NUMBER_OF_PARCELS_IN_CLASS
  });
};

const validateStudyType = function (this: any, values: any) {
  const localUnitValues = values as {numberOfParcelsInClass: number | null, studyType: string | null};
  const isValid = (localUnitValues.numberOfParcelsInClass === null || localUnitValues.numberOfParcelsInClass === 0) ? true : localUnitValues.studyType !== '';

  return isValid || this.createError({
    path: `${this.path}.studyType`,
    message: messages.REQUIRED
  });
};

const validateLessThanNumberOfParcelsInStudy = (key: string,
                                                numberOfParcelsInStudyKey?: string,
                                                copyIfBlank = false) => {
  return function (this: any, values: any) {
    const localUnitsValues = values as {
      localUnits: {
        numberOfParcelsInStudy: number
      }[]
    };

    // Get the value from the top section totalling the values from the local units
    let numberOfParcelsInStudy = localUnitsValues.localUnits.map(lu => {
      return isFinite(Number(lu.numberOfParcelsInStudy)) ? Number(lu.numberOfParcelsInStudy) : 0;
    }).reduce((a, b) => a + b);
    const value = Number(get(localUnitsValues, key));

    // Get the value from data pre-populated from the prior 4027i month and compare to see how this should be validated
    // if it is june 4027i, it will always use the top numberOfParcelsInStudyValue for all three statuses
    // august 4027i will use the pre-populated value for june status, and numberOfParcelsInStudy for the august and october status
    // october 4027i will use the pre-populated value for june and august status, and numberOfParcelsInStudy for the october status
    if (numberOfParcelsInStudyKey !== undefined) {
      const numberOfParcelsInStudyForStatus = get(localUnitsValues, numberOfParcelsInStudyKey);

      // Use the sum of the local units for the june status
      if (numberOfParcelsInStudyForStatus !== null &&
        numberOfParcelsInStudyForStatus !== undefined &&
        copyIfBlank) {
        numberOfParcelsInStudy = Number(numberOfParcelsInStudyForStatus);
      }
    }

    if (value > numberOfParcelsInStudy && isFinite(value)) {
      return this.createError({
        path: `${this.path}.${key}`,
        message: messages.MAX_NUMBER_OF_PARCELS_IN_STUDY
      });
    }

    return true;
  };
};

const validateBoundaryChanges = function (this: any, values: any) {
  const additionalInformationValues = values as {
    boundaryChanges: boolean | string | null,
    boundaryChangesComments?: string | null
  };
  const isValid = additionalInformationValues.boundaryChanges === '' ||
    additionalInformationValues.boundaryChanges === 'false' ||
    (additionalInformationValues.boundaryChanges === 'true' &&
      additionalInformationValues.boundaryChangesComments !== '' &&
      additionalInformationValues.boundaryChangesComments !== undefined &&
      additionalInformationValues.boundaryChangesComments !== null);

  return isValid || this.createError({
    path: `${this.path}.boundaryChangesComments`,
    message: messages.REQUIRED
  });
};

const residentialStatusSchema = Yup.object({
  parcelSelection: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  fieldWorkNumberOfParcelsInStudy: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  fieldWork: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  appraisalDataEntryNumberOfParcelsInStudy: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  appraisalDataEntry: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  otherCategories: Yup.object({
    salesDataEntry: percentSchema,
    landValueStudy: percentSchema,
    ecfStudy: percentSchema
  })
});

const statusSchema = Yup.object({
  parcelSelection: numberSchema
    .nullable()
    .required(messages.REQUIRED)
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  fieldWorkNumberOfParcelsInStudy: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  fieldWork: numberSchema
    .nullable()
    .required(messages.REQUIRED)
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  appraisalDataEntryNumberOfParcelsInStudy: numberSchema
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  appraisalDataEntry: numberSchema
    .nullable()
    .required(messages.REQUIRED)
    .min(0, messages.MUST_NOT_BE_NEGATIVE),
  otherCategories: Yup.object({
    salesDataEntry: percentSchema,
    landValueStudy: percentSchema,
    ecfStudy: percentSchema
  })
});

const classificationSchema = (isResidential: boolean = false) => Yup.object({
  localUnits: Yup.array().of(Yup.object({
    studyType: Yup.string()
      .required(messages.REQUIRED)
      .nullable(),
    numberOfParcelsInStudy: numberSchema
      .when('studyType', {
        is: (studyType: string) => ['NC', 'NS'].includes(studyType),
        then: numberSchema.nullable(),
        otherwise: numberSchema.nullable().required(messages.REQUIRED)
      }),
    numberOfParcelsInClass: Yup.number()
      .transform(transformBlankToNull)
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
  })
    .test('validateStudyType', '', validateStudyType)
    .test('validateLessThanNumberOfParcelsInStudyInLocalUnit', '',
      validateLessThanNumberOfParcelsInStudyInLocalUnit)),
  june30Status: isResidential ? residentialStatusSchema : statusSchema,
  august31Status: isResidential ? residentialStatusSchema : statusSchema,
  october31Status: isResidential ? residentialStatusSchema : statusSchema
})
  .test(
    'validateLessThanNumberOfParcelsInStudy',
    '',
    validateLessThanNumberOfParcelsInStudy('june30Status.parcelSelection', 'june30Status.numberOfParcelsInStudy', true)
  )
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('june30Status.fieldWork', 'june30Status.numberOfParcelsInStudy', true))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('june30Status.appraisalDataEntry', 'june30Status.numberOfParcelsInStudy', true))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('august31Status.parcelSelection', 'august31Status.numberOfParcelsInStudy'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('august31Status.fieldWork', 'august31Status.numberOfParcelsInStudy'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('august31Status.appraisalDataEntry', 'august31Status.numberOfParcelsInStudy'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('october31Status.parcelSelection'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('october31Status.fieldWork'))
  .test('validateLessThanNumberOfParcelsInStudy', ''
    , validateLessThanNumberOfParcelsInStudy('october31Status.appraisalDataEntry'));

const personalPropertySchema = Yup.object({
  localUnits: Yup.array().of(Yup.object({
    studyType: Yup.string()
      .required(messages.REQUIRED),
    numberOfParcelsInStudy: numberSchema
      .when('studyType', {
        is: (studyType: string) => 'NC' === studyType || 'NS' === studyType,
        then: numberSchema.nullable(),
        otherwise: numberSchema.nullable().required(messages.REQUIRED)
      }),
    numberOfParcelsInClass: numberSchema
      .nullable()
  })
    .test('validateStudyType', '', validateStudyType)
    .test('validateLessThanNumberOfParcelsInStudyInLocalUnit', '', validateLessThanNumberOfParcelsInStudyInLocalUnit)),
  june30Status: Yup.object({
    parcelSelection: numberSchema
      .required(messages.REQUIRED)
      .nullable()
      .min(0, messages.MUST_NOT_BE_NEGATIVE),
    auditReview: percentSchema
  }),
  august31Status: Yup.object({
    parcelSelection: numberSchema
      .required(messages.REQUIRED)
      .nullable()
      .min(0, messages.MUST_NOT_BE_NEGATIVE),
    auditReview: percentSchema
  }),
  october31Status: Yup.object({
    parcelSelection: numberSchema
      .required(messages.REQUIRED)
      .nullable()
      .min(0, messages.MUST_NOT_BE_NEGATIVE),
    auditReview: percentSchema
  })
}).test('validateLessThanNumberOfParcelsInStudy', '',
  validateLessThanNumberOfParcelsInStudy('june30Status.parcelSelection', 'june30Status.numberOfParcelsInStudy'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('august31Status.parcelSelection', 'august31Status.numberOfParcelsInStudy'))
  .test('validateLessThanNumberOfParcelsInStudy', '',
    validateLessThanNumberOfParcelsInStudy('october31Status.parcelSelection'));


const form4027iSchema = (noPropertiesSelections: ClassificationsBooleanMap) => {
  return Yup.object().shape({
    classifications: Yup.object({
      agricultural: noPropertiesSelections.agricultural ? Yup.object({}) : classificationSchema(),
      commercial: noPropertiesSelections.commercial ? Yup.object({}) : classificationSchema(),
      industrial: noPropertiesSelections.industrial ? Yup.object({}) : classificationSchema(),
      residential: noPropertiesSelections.residential ? Yup.object({}) : classificationSchema(true),
      timberCutover: noPropertiesSelections.timberCutover ? Yup.object({}) : classificationSchema(),
      developmental: noPropertiesSelections.developmental ? Yup.object({}) : classificationSchema(),
      personalProperty: noPropertiesSelections.personalProperty ? Yup.object({}) : personalPropertySchema
    }),
    additionalInformation: Yup.object({
      overallStudyComments: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH),
      adequateProcedures: Yup.string()
        .oneOf(['true', 'false', ''])
        .required(messages.REQUIRED),
      adequateProceduresComments: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH)
        .required(messages.REQUIRED),
      boundaryChanges: Yup.string()
        .oneOf(['true', 'false', ''])
        .required(messages.REQUIRED),
      boundaryChangesComments: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH),
      comments: Yup.object({
        landValueStudyComments: Yup.string()
          .max(200, messages.MAX_COMMENT_LENGTH),
        ecfStudyComments: Yup.string()
          .max(200, messages.MAX_COMMENT_LENGTH)
      })
    }).test('validateBoundaryChanges', '', validateBoundaryChanges),
    cedCertification: Yup.object({
      yearsInPosition: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      yearsInField: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      certificationLevelRequired: Yup.string()
        .oneOf([...CERTIFICATION_LEVELS, ''], messages.REQUIRED_CERTIFICATION_LEVEL),
      certificationLevelHeld: Yup.string()
        .oneOf([...CERTIFICATION_LEVELS, ''], messages.REQUIRED_CERTIFICATION_LEVEL),
      numberOfStaffMcao2: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      numberOfStaffMaao3: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      numberOfStaffMmao4: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      numberOfStaffClerical: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      numberOfStaffOther: numberSchema
        .nullable()
        .max(99, messages.maxValueMessage(99)),
      camaGisSoftware: Yup.string()
        .max(20, messages.maxCharactersMessage(20))
        .required(messages.REQUIRED),
      camaGisSqlVersion: numberSchema
        .max(9999, messages.maxValueMessage(9999))
        .required(messages.REQUIRED),
      camaGis: Yup.string()
        .oneOf(['Yes', 'No', ''])
        .required(messages.REQUIRED),
      certificationRequirementsComments: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH),
      contactOfficeHours: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH),
      contactAddress: Yup.string()
        .max(350, messages.maxCharactersMessage(350)),
      contactPhoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT).schema(),
      contactEmail: Yup.string()
        .email(messages.MUST_BE_VALID_EMAIL)
        .max(50, messages.maxCharactersMessage(50)),
      contactComments: Yup.string()
        .max(200, messages.MAX_COMMENT_LENGTH)
    })
  });
};

export default form4027iSchema;