import {StudyCertificationQuestions, StudyCertificationType} from '../types';
import {megApi} from './apiUtils';

export const findByCountyIdAndYear = (countyId: number | string,
                                      year: number | string): Promise<StudyCertificationType> => megApi.getWithJsonResponse(
  `/study-certifications/${countyId}/${year}`
);

export const submit = (countyId: number | string,
                       year: number | string,
                       studyCertificationQuestions: StudyCertificationQuestions
): Promise<StudyCertificationType> => megApi.postWithJsonResponse(
  `/study-certifications/${countyId}/${year}`,
  {body: JSON.stringify(studyCertificationQuestions)}
);
