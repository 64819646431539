import {ChangeEvent, memo} from 'react';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {LocalUnit} from '../../types';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  localUnits: LocalUnit[]
}

const LocalUnitSelect = ({
                           onChange,
                           localUnits
                         }: Props) => {
  return <FormikSelect name="localUnitId"
                       labelText="Local Unit"
                       onChange={onChange}>
    {localUnits.map(localUnit =>
      <option key={localUnit.id} value={localUnit.id}>{localUnit.county.displayNameWithType} - {localUnit.displayNameWithType}</option>)
    }
  </FormikSelect>;
};

export default memo(LocalUnitSelect);
