import {mega} from '../../types';
import {megApi, openFileInNewWindow} from '../apiUtils';
import * as messages from '../../messages';

export const find = (countyId: number | string,
                     year: number | string
): Promise<mega.PersonalPropertyReport> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/personal-property?year=${year}`
);

export const update = (countyId: number | string,
                       year: number | string,
                       personalPropertyReportRequest: mega.PersonalPropertyReportRequest
): Promise<mega.PersonalPropertyReport> =>
  megApi.putWithJsonResponse(
    `/mega/counties/${countyId}/personal-property?year=${year}`,
    {body: JSON.stringify(personalPropertyReportRequest)}
  );

export const openPdf = (countyId: number | string,
                        year: number | string,
                        showErrorAlert: (message: string, disableTimeout?: boolean) => void
): Promise<void> => openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE)(
  `/pdfs/personal-property/${countyId}?year=${year}`
);
