import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const find = (countyId: number | string,
                     year: number | string
): Promise<mega.MonitorStudyReport> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/monitor-studies?year=${year}`
);

export const update = (countyId: number | string,
                       year: number | string,
                       monitorStudiesRequest: mega.MonitorStudiesRequest
): Promise<mega.MonitorStudyReport> => megApi.patchWithJsonResponse(
  `/mega/counties/${countyId}/monitor-studies?year=${year}`,
  {body: JSON.stringify(monitorStudiesRequest)}
);