import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

const propertySchema = Yup.object({
  propertyCount: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999, messages.maxValueMessage(999999999)),
  boardOfReview1: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  loss: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  adjustment: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  newField: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .transform(transformBlankToNull)
    .max(999999999999, messages.maxValueMessage(999999999999))
});

export default Yup.object().shape({
  realProperty: Yup.object().shape({
    100: propertySchema,
    200: propertySchema,
    300: propertySchema,
    400: propertySchema,
    500: propertySchema,
    600: propertySchema
  }),
  personalProperty: Yup.object().shape({
    150: propertySchema,
    250: propertySchema,
    350: propertySchema,
    450: propertySchema,
    550: propertySchema
  })
});