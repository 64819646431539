import {useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Container, Table} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {BreadcrumbsNav, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {statisticsApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger} from '../../utils';
import {mega} from '../../types';

const Statistics = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [statistics, setStatistics] = useState<mega.Statistics>(undefined);
  const {showErrorAlert} = useAlerts();

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: statistics?.county?.displayNameWithType ?? '', active: true}
  ]), [year, countyId, statistics]);

  useEffect(() => {
    const loadStatistics = async () => {
      try {
        const statistics = await statisticsApi.find(countyId, year);
        setStatistics(statistics);
        setLoadingState({loadError: false, loading: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadStatistics();
  }, [countyId, year, showErrorAlert]);

  const totals = useMemo(() => statistics?.totals ?? undefined, [statistics]);

  return <Container fluid className="Statistics">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && statistics && totals && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>
          Statistics
        </CardHeader>
        <div className="table-fixed">
          <Table bordered responsive>
            <thead>
              <tr className="text-center bg-light-teal fixed-height-75">
                <th className="bg-light-teal align-middle text-primary" colSpan={4}/>
                <th className="bg-light-teal align-middle border-left-3 text-primary" colSpan={2}># of Valid Sales</th>
                <th className="bg-light-teal align-middle text-primary" colSpan={2}>Sample Size % of Parcels</th>
                <th className="bg-light-teal align-middle border-left-3 text-primary" colSpan={2}>Assessed Value of Sales</th>
                <th className="bg-light-teal text-nowrap align-middle text-primary" colSpan={2}>Sample Size % of Assessed Value</th>
                <th className="bg-light-teal align-middle border-left-3 text-primary" colSpan={2}>Sales Amount</th>
                <th className="bg-light-teal align-middle text-primary" colSpan={2}>Aggregate Mean Ratio</th>
                <th className="bg-light-teal align-middle text-primary" colSpan={2}>Refined Mean Ratio</th>
                <th className="bg-light-teal align-middle text-primary" colSpan={2}>AAD for COD</th>
                <th className="bg-light-teal align-middle text-primary" colSpan={2}>STD for COV</th>
                <th className="bg-light-teal align-middle border-left-3 text-primary" colSpan={3}>2 Year Study</th>
                <th className="bg-light-teal align-middle border-left-3 text-primary" colSpan={3}>1 Year Study</th>
              </tr>
              <tr className="text-center bg-light position-top-75">
                <th className="bg-light align-middle text-primary">Unit #</th>
                <th className="bg-light align-middle text-left text-primary">Unit Name</th>
                <th className="bg-light align-middle text-primary">Parcels</th>
                <th className="bg-light align-middle text-primary">Total Assessed Value</th>
                <th className="bg-light align-middle border-left-3 text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle border-left-3 text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle border-left-3 text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle text-primary">2 Year Study</th>
                <th className="bg-light align-middle text-primary">1 Year Study</th>
                <th className="bg-light align-middle border-left-3 text-primary">PRD</th>
                <th className="bg-light align-middle text-primary">COD</th>
                <th className="bg-light align-middle text-primary">COV</th>
                <th className="bg-light align-middle border-left-3 text-primary">PRD</th>
                <th className="bg-light align-middle text-primary">COD</th>
                <th className="bg-light align-middle text-primary">COV</th>
              </tr>
            </thead>
            <tbody>
              {
                statistics.localUnits.map((item) => {
                  const study1YearStats = item.study1Year || {};
                  const study2YearStats = item.study2Year || {};
                  return <tr key={item.localUnitId}>
                    <td className="text-center align-middle text-primary font-weight-bold">
                      {item.localUnitId}
                    </td>
                    <td className="text-left text-nowrap align-middle font-weight-bold text-primary">
                      {item.localUnitName}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(item.numberOfParcels)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(item.totalAssessedValue)}
                    </td>
                    <td className="text-center align-middle border-left-3">
                      {formatInteger(study2YearStats.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(study1YearStats.numberOfValidSales)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.sampleSizePercentParcels, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.sampleSizePercentParcels, 2, true)}
                    </td>
                    <td className="text-center align-middle border-left-3">
                      {formatInteger(study2YearStats.assessedValue)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(study1YearStats.assessedValue)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.sampleSizePercentAssessedValue, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.sampleSizePercentAssessedValue, 2, true)}
                    </td>
                    <td className="text-center align-middle border-left-3">
                      {formatInteger(study2YearStats.salesAmount)}
                    </td>
                    <td className="text-center align-middle">
                      {formatInteger(study1YearStats.salesAmount)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.aggregateMeanRatio, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.aggregateMeanRatio, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.refinedMeanRatio, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.refinedMeanRatio, 2, true)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.absoluteAverageDeviation, 5, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.absoluteAverageDeviation, 5, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.standardDeviation, 6, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.standardDeviation, 6, false)}
                    </td>
                    <td className="text-center align-middle border-left-3">
                      {formatDecimal(study2YearStats.priceRelatedDifferential, 2, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.coefficientOfDispersion, 2, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study2YearStats.coefficientOfVariance, 2, true)}
                    </td>
                    <td className="text-center align-middle border-left-3">
                      {formatDecimal(study1YearStats.priceRelatedDifferential, 2, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.coefficientOfDispersion, 2, false)}
                    </td>
                    <td className="text-center align-middle">
                      {formatDecimal(study1YearStats.coefficientOfVariance, 2, true)}
                    </td>
                  </tr>;
                })
              }
            </tbody>
            <tfoot>
              <tr key="totals" className="font-weight-bold text-center align-middle text-dark">
                <td className="bg-light">Totals</td>
                <td className="bg-light">
                  {formatInteger(statistics.numberOfUnitsWithSales)}
                </td>
                <td className="bg-light">
                  {formatInteger(totals.numberOfParcels)}
                </td>
                <td className="bg-light">
                  {formatInteger(totals.totalAssessedValue)}
                </td>
                <td className="border-left-3 bg-light">
                  {formatInteger(totals.study2Year.numberOfValidSales)}
                </td>
                <td className="bg-light">
                  {formatInteger(totals.study1Year.numberOfValidSales)}
                </td>
                <td className="bg-light">
                  {formatDecimal(totals.study2Year.sampleSizePercentParcels, 2, true)}
                </td>
                <td className="bg-light">
                  {formatDecimal(totals.study1Year.sampleSizePercentParcels, 2, true)}
                </td>
                <td className="border-left-3 bg-light">
                  {formatInteger(totals.study2Year.assessedValue)}
                </td>
                <td className="bg-light">
                  {formatInteger(totals.study1Year.assessedValue)}
                </td>
                <td className="bg-light">
                  {formatDecimal(totals.study2Year.sampleSizePercentAssessedValue, 2, true)}
                </td>
                <td className="bg-light">
                  {formatDecimal(totals.study1Year.sampleSizePercentAssessedValue, 2, true)}
                </td>
                <td className="border-left-3 bg-light">
                  {formatInteger(totals.study2Year.salesAmount)}
                </td>
                <td className="bg-light">
                  {formatInteger(totals.study1Year.salesAmount)}
                </td>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="border-left-3 bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
                <td className="border-left-3 bg-light"/>
                <td className="bg-light"/>
                <td className="bg-light"/>
              </tr>
            </tfoot>
          </Table>
        </div>
      </Card>
    </>}
  </Container>;
};

export default Statistics;