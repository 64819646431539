import {AmendmentRequest, CountyFormDto, forms, LocalUnitFormDto, ReturnRequest} from '../types';
import {megApi} from './apiUtils';

export const find = (localUnitId: number | string,
                     localUnitFormId: number | string
): Promise<LocalUnitFormDto> => megApi.getWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}`
);

export const save = (localUnitId: number | string,
                     localUnitFormId: number | string,
                     localUnitFormData: forms.LocalUnitFormData,
                     isLocalUnit: boolean = false
): Promise<LocalUnitFormDto> => megApi.putWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}?isLocalUnit=${isLocalUnit}`,
  {body: JSON.stringify(localUnitFormData)}
);

export const submit = (localUnitId: number | string,
                       localUnitFormId: number | string
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/submit`
);

export const submitToCounty = (localUnitId: number | string,
                               localUnitFormId: number | string
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/submit/county`
);

export const amend = (localUnitId: number | string,
                      localUnitFormId: number | string,
                      amendmentRequest: AmendmentRequest
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/amend`,
  {body: JSON.stringify(amendmentRequest)}
);

export const reset = (localUnitId: number | string,
                      localUnitFormId: number | string,
                      isLocalUnitUser: boolean
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/reset?isLocalUnitUser=${isLocalUnitUser}`
);

export const findLocalUnitPortalForms = (localUnitId: number | string,
                                         year: number | string
): Promise<CountyFormDto[]> => megApi.getWithJsonResponse(
  `/local-units/${localUnitId}/forms?year=${year}`
);

export const returnToLocalUnit = (localUnitId: number | string,
                                  localUnitFormId: number | string,
                                  returnRequest: ReturnRequest
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/return`,
  {body: JSON.stringify(returnRequest)}
);

export const send4015ToReview = (localUnitId: number | string,
                                 localUnitFormId: number | string): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/local-units/${localUnitId}/forms/${localUnitFormId}/send-to-review`
);