import {mega} from '../../types';
import {megApi, openFileInNewWindow} from '../apiUtils';
import * as messages from '../../messages';

export const find = (countyId: number | string,
                     year: number | string
): Promise<mega.Report4030Dto> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/4030-report?year=${year}`
);

export const findAllByYear = (year: number | string
): Promise<mega.Report4030Dto[]> => megApi.getWithJsonResponse(
  `/mega/4030-report?year=${year}`
);

export const openStatewidePdf = (year: number | string,
                                 showErrorAlert: (message: string, disableTimeout?: boolean) => void
): Promise<void> => openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE)(
  `/mega/4030-report/pdfs?year=${year}`
);

export const openPdf = (countyId: number | string,
                        year: number | string,
                        showErrorAlert: (message: string, disableTimeout?: boolean) => void
): Promise<void> => openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE)(
  `/pdfs/4030-report/${countyId}?year=${year}`
);

export const certifySupervisor = (countyId: number | string,
                                  year: number | string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report/certify-supervisor?year=${year}`
);

export const certifyAnalyst = (countyId: number | string,
                               year: number | string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report/certify-analyst?year=${year}`
);

export const accept = (countyId: number | string,
                       year: number | string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report/accept?year=${year}`
);

export const returnReport = (countyId: number | string,
                             year: number | string,
                             comments: string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report/return?year=${year}`,
  {
    body: JSON.stringify({comments})
  }
);

export const save = (countyId: number | string,
                     year: number | string,
                     comments: string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report?year=${year}`,
  {
    body: JSON.stringify({comments})
  }
);

export const bypass = (countyId: number | string,
                       year: number | string,
                       userType: string
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/4030-report/bypass/${userType}?year=${year}`,
  {}
);
