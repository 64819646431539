import * as Yup from 'yup';
import {TestContext} from 'yup';

import * as messages from '../../messages';
import {STUDY_TYPES_PERSONAL_4018, STUDY_TYPES_REAL_4018} from '../../utils';
import {transformBlankBooleanToNull, transformBlankToNull} from '../helpers';

type Values = {
  classifications: {
    [classification: string]: {
      studyType: string
    }
  }
  estimatedValues: string | null
}

const validateEstimatedValues = function (this: TestContext, values: any) {
  if (!values) {
    return true;
  }
  const classificationData = values as Values;
  const valuesWithEs = Object.values(classificationData.classifications).filter((c) => c.studyType === 'ES');
  const isValid = valuesWithEs.length === 0 ||
    (valuesWithEs.length > 0 && (classificationData.estimatedValues !== '' && classificationData.estimatedValues !== null));

  return isValid || this.createError({
    path: `${this.path}.estimatedValues`,
    message: messages.REQUIRED
  });
};

const realPropertySchema = Yup.object().shape({
  studyType: Yup.string()
    .nullable()
    .oneOf(
      STUDY_TYPES_REAL_4018.concat(''),
      `Study type must be one of the following values: ${STUDY_TYPES_REAL_4018.join(', ')}`
    ),
  stratifiedStudy: Yup
    .bool()
    .nullable()
    .transform(transformBlankBooleanToNull),
  combinedStudy: Yup
    .bool()
    .nullable()
    .transform(transformBlankBooleanToNull),
  assessmentRollClassificationAssessedValue:
    Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER)
      .min(0, messages.MUST_NOT_BE_NEGATIVE)
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull),
  sampleAssessedValue:
    Yup.number()
      .nullable()
      .typeError(messages.MUST_BE_NUMBER)
      .min(0, messages.MUST_NOT_BE_NEGATIVE)
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull),
  numberOfParcels: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(9999999, messages.maxValueMessage(9999999))
    .transform(transformBlankToNull),
  trueCashValue: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  ratioAssessmentToAppraisals: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999.99, messages.maxValueMessage('999.99%'))
    .transform(transformBlankToNull),
  projectedTrueCashValue: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  remarks: Yup.string()
    .max(240, messages.maxCharactersMessage(240))
});

const personalPropertySchema = Yup.object().shape({
  studyType: Yup.string()
    .nullable()
    .oneOf(
      STUDY_TYPES_PERSONAL_4018.concat(''),
      `Study type must be one of the following values: ${STUDY_TYPES_PERSONAL_4018.join(', ')}`
    ),
  unitEndingAssessedValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  numberOfParcels: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(9999999, messages.maxValueMessage(9999999))
    .transform(transformBlankToNull),
  assessedValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  trueCashValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  studyRatio: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999.99, messages.maxValueMessage('999.99%'))
    .transform(transformBlankToNull),
  unitStartingTrueCashValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  remarks: Yup.string()
    .max(240, messages.maxCharactersMessage(240))
});

export default Yup.object().shape({
  realProperty: Yup.object().shape({
    classifications: Yup.object().shape({
      100: realPropertySchema,
      200: realPropertySchema,
      300: realPropertySchema,
      400: realPropertySchema,
      500: realPropertySchema,
      600: realPropertySchema
    }),
    estimatedValues: Yup.string()
      .max(240, messages.maxCharactersMessage(240))
  }).test('validate estimated values', '', validateEstimatedValues),
  personalProperty: Yup.object().shape({
    classifications: Yup.object().shape({
      150: personalPropertySchema,
      250: personalPropertySchema,
      350: personalPropertySchema,
      450: personalPropertySchema,
      550: personalPropertySchema
    }),
    additionalRemarks: Yup.string()
      .max(240, messages.maxCharactersMessage(240)),
    estimatedValues: Yup.string()
      .max(240, messages.maxCharactersMessage(240))
  }).test('validate estimated values', '', validateEstimatedValues)
});
