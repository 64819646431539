import * as Yup from 'yup';

import {YupDate} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {FINANCING_TYPES, INSTRUMENT_TYPES} from '../../utils';
import {transformBlankToNull} from '../helpers';

const form4015Schema = (minDate: Date, maxDate: Date) => {
  return Yup.object().shape({
    id: Yup.number(),
    confidential: Yup.bool()
      .nullable(),
    propertyNumber: Yup.string()
      .required(messages.REQUIRED)
      .max(30, messages.maxCharactersMessage(30)),
    liberAndPage: Yup.string()
      .max(50, messages.maxCharactersMessage(50))
      .nullable(),
    grantor: Yup.string()
      .max(50, messages.maxCharactersMessage(50))
      .required(messages.REQUIRED),
    grantee: Yup.string()
      .max(50, messages.maxCharactersMessage(50))
      .required(messages.REQUIRED),
    instrument: Yup.string()
      .required(messages.REQUIRED)
      .oneOf(INSTRUMENT_TYPES),
    financing: Yup.string()
      .required(messages.REQUIRED)
      .oneOf(FINANCING_TYPES),
    assessedValue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .transform(transformBlankToNull)
      .min(0, messages.MUST_NOT_BE_NEGATIVE)
      .max(999999999999, messages.MAX_ASSESSED_VALUE)
      .required(messages.REQUIRED),
    salesPrice: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .transform(transformBlankToNull)
      .min(0, messages.MUST_NOT_BE_NEGATIVE)
      .max(999999999999, messages.maxValueMessage(999999999999))
      .required(messages.REQUIRED),
    adjustedSalesPrice: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .transform(transformBlankToNull)
      .min(0, messages.MUST_NOT_BE_NEGATIVE)
      .max(999999999999, messages.maxValueMessage(999999999999))
      .required(messages.REQUIRED),
    date: YupDate(true)
      .typeError(messages.MUST_BE_VALID_DATE)
      .nullable()
      .min(minDate, messages.mustBeAfterMessage(minDate))
      .max(maxDate, messages.mustBeBeforeMessage(maxDate))
      .required(messages.REQUIRED),
    ratio: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .transform(transformBlankToNull)
      .required(messages.REQUIRED),
    comments: Yup.string()
      .max(200, messages.MAX_COMMENT_LENGTH)
      .nullable()
  });
};

export default form4015Schema;