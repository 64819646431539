import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: String
  isClickable?: boolean
  onClick?: () => void
}

// This represents the State view of the Form 4022 Local Unit form, filled out by the local unit user
const Form4022LUStateStatusBadge = ({
                                      status,
                                      isClickable,
                                      onClick
                                    }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.RECEIVED ||
      status === FormStatus.SUBMITTED ||
      status === FormStatus.ACCEPTED) {
      return 'success';
    } else {
      return 'light';
    }
  }, [status]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.RECEIVED) {
      return FormStatus.SUBMITTED;
    } else if (status === FormStatus.RETURNED_FROM_STATE) {
      return FormStatus.RETURNED;
    } else if (status === FormStatus.RETURNED_FROM_COUNTY) {
      return 'IN PROGRESS';
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status]);

  return <Badge color={color}
                tabIndex={isClickable ? 0 : -1}
                className="font-size-100 text-uppercase border-0"
                onClick={onClick}>
    {statusDisplayValue}
  </Badge>;
};

export default memo(Form4022LUStateStatusBadge);