import {memo, useCallback, useMemo} from 'react';
import {Button, Col, CustomInput, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps, FormikValues} from 'formik';

import {
  FormikDatePicker,
  FormikInput,
  FormikNumberInput,
  FormikPercentageInput,
  FormikSelect
} from '@reasoncorp/kyber-js';

import {FINANCING_TYPES, INSTRUMENT_TYPES} from '../../../utils';
import {form4015Schema} from '../../../schemas';
import {forms} from '../../../types';

type Props = {
  property?: forms.Form4015PropertyDto
  isOpen: boolean
  operation: 'Add' | 'Edit'
  classificationFilter: string | number
  onToggle: (operation: 'Add' | 'Edit', property?: forms.Form4015PropertyDto) => void
  equalizationYear: number
}

const Form4015PropertyModal = ({
                                 isOpen,
                                 operation,
                                 property,
                                 onToggle,
                                 equalizationYear,
                                 classificationFilter
                               }: Props) => {
  const minDate = useMemo(() => new Date(`04/01/${equalizationYear - 3}`), [equalizationYear]);
  const maxDate = useMemo(() => new Date(`09/30/${equalizationYear - 1}`), [equalizationYear]);

  const validationSchema = useMemo(() => form4015Schema(minDate, maxDate), [minDate, maxDate]);

  const initialValues = useMemo(() => ({
    id: property?.id || 0,
    confidential: property?.confidential || false,
    propertyNumber: property?.propertyNumber || '',
    propertyClassification: property?.propertyClassification || classificationFilter,
    assessedValue: property?.assessedValue || '',
    liberAndPage: property?.liberAndPage || '',
    instrument: property?.instrument || '',
    financing: property?.financing || '',
    grantor: property?.grantor || '',
    grantee: property?.grantee || '',
    salesPrice: property?.salesPrice || '',
    adjustedSalesPrice: property?.adjustedSalesPrice || '',
    ratio: property?.ratio || '',
    date: property?.date || '',
    comments: property?.comments || ''
  }), [property, classificationFilter]);

  const handleToggle = useCallback((formikProps: FormikProps<any>) => {
    onToggle(operation);
    formikProps.resetForm();
  }, [onToggle, operation]);

  const handleSubmit = useCallback(async (values: FormikValues, actions: FormikHelpers<any>) => {
    const valuesToSubmit = {
      ...values,
      ratio: Number(values.ratio),
      assessedValue: Number(values.assessedValue),
      salesPrice: Number(values.salesPrice),
      adjustedSalesPrice: Number(values.adjustedSalesPrice)
    } as forms.Form4015PropertyDto;
    onToggle(operation, valuesToSubmit);
    actions.setSubmitting(false);
    actions.resetForm();
  }, [onToggle, operation]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal size="lg"
               isOpen={isOpen}
               toggle={() => handleToggle(formikProps)}
               autoFocus={false}
               aria-modal={true}
               returnFocusAfterClose={true}
               className="Form4015ParcelModal">
          <ModalHeader toggle={() => handleToggle(formikProps)}
                       className="h5"
                       tag="h2">
            {operation === 'Add' ? 'Add Parcel' : 'Edit Parcel'}
          </ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col className="col-4">
                  <CustomInput type="switch"
                               id="confidentialSwitch"
                               name="confidentialSwitch"
                               className="pt-1 mt-2"
                               label="Confidential Parcel"
                               aria-label="Confidential Parcel"
                               onChange={() => formikProps.setFieldValue('confidential', !formikProps.values.confidential)}
                               checked={formikProps.values.confidential}
                               autoFocus
                               value={formikProps.values.confidential.toString()}
                  />
                </Col>
                <Col className="col-4">
                  <FormikDatePicker labelText="Date"
                                    name="date"
                                    ariaRequired={true}
                                    minDate={minDate}
                                    maxDate={maxDate}/>
                </Col>
                <Col className="col-4">
                  <FormikInput name="propertyNumber"
                               aria-required={true}
                               labelText="Parcel Number"
                               maxLength="30"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <FormikInput name="liberAndPage"
                               labelText="Liber & Page"
                               maxLength="50"/>
                </Col>
                <Col className="col-4">
                  <FormikInput name="grantor"
                               aria-required={true}
                               labelText="Grantor"
                               maxLength="50"/>
                </Col>
                <Col className="col-4">
                  <FormikInput name="grantee"
                               aria-required={true}
                               labelText="Grantee"
                               maxLength="50"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <FormikSelect name="instrument"
                                aria-required={true}
                                labelText="Instrument">
                    <option value="">Select</option>
                    {INSTRUMENT_TYPES.map((instrument) => {
                      return <option key={instrument} value={instrument}>{instrument}</option>;
                    })}
                  </FormikSelect>
                </Col>
                <Col className="col-4">
                  <FormikSelect name="financing"
                                aria-required={true}
                                labelText="Financing">
                    <option value={''}>Select</option>
                    {FINANCING_TYPES.map((financing) => {
                      return <option key={financing} value={financing}>{financing}</option>;
                    })}
                  </FormikSelect>
                </Col>
                <Col className="col-4">
                  <FormikNumberInput name="assessedValue"
                                     aria-required={true}
                                     labelText="Assessed Value"
                                     maxLength="15"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <FormikNumberInput name="salesPrice"
                                     aria-required={true}
                                     labelText="Sales Price"
                                     maxLength="15"/>
                </Col>
                <Col className="col-4">
                  <FormikNumberInput name="adjustedSalesPrice"
                                     aria-required={true}
                                     labelText="Adjusted Sales Price"
                                     maxLength="15"/>
                </Col>
                <Col className="col-4">
                  <FormikPercentageInput name="ratio"
                                         aria-required={true}
                                         labelText="Ratio"
                                         maxLength="7"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FormikInput name="comments"
                               labelText="Comments"
                               maxLength="200"/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}>
                {operation === 'Add' ? 'Add' : 'Edit'}
              </Button>
              <Button color="secondary"
                      onClick={() => handleToggle(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(Form4015PropertyModal);