import {memo, useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikDatePicker, FormikInput, FormikNumberInput, FormikSelect} from '@reasoncorp/kyber-js';

import {CED_4015_TYPE, INSTRUMENT_TYPES} from '../../utils';
import {masterSalesSchema} from '../../schemas/mega';
import {mega} from '../../types';

const psd4015Types = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '11',
  '12',
  '13'
];

type Props = {
  isOpen: boolean
  onToggle: (formikProps: FormikProps<mega.MasterSalesData>) => void
  operation: 'Add' | 'Edit'
  parcel?: mega.MasterSalesData
  onUpdate: (property: mega.MasterSalesData) => void
  onCreate: (property: mega.MasterSalesData) => void
  countyId: number
}

const MasterSalesModal = ({
                            isOpen,
                            onToggle,
                            operation,
                            parcel,
                            onUpdate,
                            onCreate,
                            countyId
                          }: Props) => {
  const initialValues: mega.MasterSalesData = useMemo(() => ({
    id: parcel?.id || null,
    psd4015Type: parcel?.psd4015Type || null,
    propertyId: parcel?.propertyId || '',
    verified: parcel?.verified || '',
    unitNumber: parcel?.unitNumber || null,
    propertyClassification: parcel?.propertyClassification || null,
    saleOn: parcel?.saleOn || '',
    liberPage: parcel?.liberPage || '',
    grantor: parcel?.grantor || '',
    grantee: parcel?.grantee || '',
    instrument: parcel?.instrument || '',
    assessmentWhenSold: parcel?.assessmentWhenSold || null,
    unadjustedSalesPrice: parcel?.unadjustedSalesPrice || null,
    adjustedSalesPrice: parcel?.adjustedSalesPrice || null,
    ced4015Type: parcel?.ced4015Type || '',
    cedComments: parcel?.cedComments || '',
    psdComments: parcel?.psdComments || '',
    selected: parcel?.selected || false,
    unsaved: parcel?.unsaved || false
  }), [
    parcel
  ]);

  const handleSubmit = useCallback(async (masterSalesData: mega.MasterSalesData,
                                          actions: FormikHelpers<mega.MasterSalesData>) => {
    const property = {
      ...masterSalesData,
      unitNumber: Number(masterSalesData.unitNumber),
      assessmentWhenSold: Number(masterSalesData.assessmentWhenSold),
      adjustedSalesPrice: Number(masterSalesData.adjustedSalesPrice),
      unadjustedSalesPrice: Number(masterSalesData.unadjustedSalesPrice),
      psd4015Type: Number(masterSalesData.psd4015Type)
    };
    operation === 'Add' ? onCreate(property) : onUpdate(property);
    actions.setSubmitting(false);
    actions.resetForm();
  }, [
    onCreate,
    onUpdate,
    operation
  ]);

  return <Formik initialValues={initialValues}
                 validationSchema={masterSalesSchema(countyId)}
                 enableReinitialize={true}
                 validateOnMount={true}
                 onSubmit={handleSubmit}>
    {formikProps => <Form autoComplete="off" onSubmit={formikProps.handleSubmit}>
      <Modal toggle={() => onToggle(formikProps)}
             size="lg"
             isOpen={isOpen}
             aria-modal={true}
             autoFocus={false}
             returnFocusAfterClose={true}>
        <ModalHeader toggle={() => onToggle(formikProps)} className="h5" tag="h2">
          {operation} Parcel
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-4">
              <FormikSelect name="psd4015Type"
                            autoFocus
                            labelText="PSD L-4015 Type">
                <option value="">Select</option>
                {psd4015Types.map((type) => {
                  return <option key={type} value={type}>{type}</option>;
                })}
              </FormikSelect>
            </Col>
            <Col className="col-8">
              <FormikInput name="propertyId"
                           labelText="Parcel Code"
                           maxLength="30"/>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <FormikInput name="verified"
                           labelText="Verified"
                           maxLength="30"/>
            </Col>
            <Col className="col-4">
              <FormikInput name="unitNumber"
                           labelText="Unit Number"
                           maxLength="6"/>
            </Col>
            <Col className="col-4">
              <FormikInput name="propertyClassification"
                           labelText="Property Class"
                           maxLength="3"/>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <FormikDatePicker name="saleOn"
                                dateFormat="MM/dd/yy"
                                labelText="Sale Date"/>
            </Col>
            <Col className="col-4">
              <FormikInput name="liberPage"
                           labelText="Liber/Page"
                           maxLength="30"/>
            </Col>
            <Col className="col-4">
              <FormikInput name="grantor"
                           labelText="Grantor"
                           maxLength="50"/>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <FormikInput name="grantee"
                           labelText="Grantee"
                           maxLength="50"/>
            </Col>
            <Col className="col-4">
              <FormikSelect name="instrument"
                            labelText="Instrument Type">
                <option value="">Select</option>
                {INSTRUMENT_TYPES.map((type) => {
                  return <option key={type} value={type}>{type}</option>;
                })}
              </FormikSelect>
            </Col>
            <Col className="col-4">
              <FormikSelect name="ced4015Type"
                            labelText="CED L-4015 Type">
                <option value="">Select</option>
                {Object.entries(CED_4015_TYPE).map(([value, displayValue]) => {
                  return <option key={value} value={value}>{displayValue}</option>;
                })}
              </FormikSelect>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <FormikNumberInput name="assessmentWhenSold"
                                 labelText="Assessment When Sold"
                                 maxLength="15"/>
            </Col>
            <Col className="col-4">
              <FormikNumberInput name="unadjustedSalesPrice"
                                 labelText="Unadjusted Sales Price"
                                 maxLength="15"/>
            </Col>
            <Col className="col-4">
              <FormikNumberInput name="adjustedSalesPrice"
                                 labelText="Adjusted Sales Price"
                                 maxLength="15"/>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <FormikInput name="cedComments"
                           labelText="CED Comments"
                           maxLength="30"/>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <FormikInput name="psdComments"
                           labelText="PSD Comments"
                           maxLength="30"/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100 p-0">
            <Col className="d-flex justify-content-end p-0">
              <Button color="primary"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || !formikProps.dirty}
                      className=" mr-1">
                {operation}
              </Button>
              <Button color="secondary"
                      onClick={() => onToggle(formikProps)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Form>}
  </Formik>;
};

export default memo(MasterSalesModal);