import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {megApi} from '../../api';
import {appraisalParcelReportFormSchema} from '../../schemas/mega';
import {LocalUnit} from '../../types';

type AppraisalParcelReportFormFields = {
  localUnitId?: number
}

type Props = {
  isOpen: boolean
  onSubmit: (localUnitId: number) => void
  onCancel: () => void
  countyName: string
  countyId: number
  year: number
}

const AppraisalParcelReportModal = ({
                                      isOpen,
                                      onSubmit,
                                      onCancel,
                                      countyName,
                                      countyId
                                    }: Props) => {
  const {showErrorAlert} = useAlerts();
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadLocalUnits = async () => {
      try {
        const localUnits = await megApi.findLocalUnitsByCountyId(countyId);
        setLocalUnits(localUnits);
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
      }

      setIsLoading(false);
    };

    void loadLocalUnits();
  }, [countyId, showErrorAlert]);

  const initialValues: AppraisalParcelReportFormFields = useMemo(() => ({
    localUnitId: undefined
  }), []);

  const handleClose = useCallback((formikProps: FormikProps<AppraisalParcelReportFormFields>) => {
    formikProps.resetForm();
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback((values: AppraisalParcelReportFormFields,
                                    helpers: FormikHelpers<AppraisalParcelReportFormFields>) => {
    onSubmit(values.localUnitId!);
    helpers.resetForm();
  }, [onSubmit]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={appraisalParcelReportFormSchema}
            validateOnMount={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               size="md"
               backdrop="static"
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>Appraisal Parcel Audit / Recycle Report</ModalHeader>
          <ModalBody>
            <Row>
              <Col className="mb-2">
                Select a Local Unit within <span className="text-danger">{countyName}</span>.
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormikSelect name="localUnitId"
                              autoFocus
                              disabled={isLoading}
                              labelText="Local Unit">
                  <option value="">Select</option>
                  {localUnits.map(localUnit =>
                    <option key={localUnit.id} value={localUnit.id}>{localUnit.displayNameWithType}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.isValid || formikProps.isSubmitting}>
              Generate
            </Button>
            <Button color="secondary"
                    onClick={() => handleClose(formikProps)}
                    disabled={formikProps.isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

export default AppraisalParcelReportModal;