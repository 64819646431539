import {FileDetails, FileUpload} from '../types';
import {megApi} from './apiUtils';

export const findDetailsByCountyIdAndYear = (countyId: number | string,
                                             year: number | string): Promise<FileDetails> => megApi.getWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files/details?year=${year}`
);

export const findUploadsByCountyIdAndYear = (countyId: number | string,
                                             year: number | string): Promise<FileUpload[]> => megApi.getWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files?year=${year}`
);

export const findDeletedUploadsByCountyIdAndYear = (countyId: number | string,
                                                    year: number | string): Promise<FileUpload[]> => megApi.getWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files/history?year=${year}`
);

export const upload = (countyId: number | string, year: number | string, fileData: FormData) => megApi.postWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files?year=${year}`,
  {body: fileData}
);

export const deleteFile = (countyId: number | string, fileUploadId: number) => megApi.deleteWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files/${fileUploadId}`
);

export const share = (countyId: number | string,
                      fileUploadId: number,
                      localUnitIds: number[]): Promise<FileUpload> => megApi.patchWithJsonResponse(
  `/filing-cabinet/counties/${countyId}/files/${fileUploadId}/share`,
  {body: JSON.stringify(localUnitIds)}
);


export const findDetailsByLocalUnitIdAndYear = (localUnitId: number | string,
                                                year: number | string): Promise<FileDetails> => megApi.getWithJsonResponse(
  `/filing-cabinet/local-units/${localUnitId}/files/details?year=${year}`
);

export const findFilesByLocalUnitIdAndYear = (localUnitId: number | string,
                                              year: number | string): Promise<FileUpload[]> => megApi.getWithJsonResponse(
  `/filing-cabinet/local-units/${localUnitId}/files?year=${year}`
);