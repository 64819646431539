import {CountyFormDto, LocalUnitFormDto, ReturnRequest, StateFormsResponse} from '../types';
import {megApi} from './apiUtils';

export const findStateForms = (year: number | string): Promise<StateFormsResponse> => megApi.getWithJsonResponse(
  `/state/forms/${year}`
);

export const findCountyForm = (countyId: number | string,
                               countyFormId: number | string
): Promise<CountyFormDto> => megApi.getWithJsonResponse(
  `/state/counties/${countyId}/forms/${countyFormId}`
);

export const returnCountyForm = (countyId: number | string,
                                 countyFormId: number | string,
                                 returnRequest: ReturnRequest
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/forms/${countyFormId}/return`,
  {body: JSON.stringify(returnRequest)}
);

export const acceptCountyForm = (countyId: number | string,
                                 countyFormId: number | string
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/forms/${countyFormId}/accept`
);

export const findLocalUnitForm = (countyId: number | string,
                                  localUnitId: number | string,
                                  localUnitFormId: number | string
): Promise<LocalUnitFormDto> => megApi.getWithJsonResponse(
  `/state/counties/${countyId}/local-units/${localUnitId}/forms/${localUnitFormId}`
);

export const returnLocalUnitForm = (countyId: number | string,
                                    localUnitId: number | string,
                                    localUnitFormId: number | string,
                                    returnRequest: ReturnRequest
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/local-units/${localUnitId}/forms/${localUnitFormId}/return`,
  {body: JSON.stringify(returnRequest)}
);

export const acceptLocalUnitForm = (countyId: number | string,
                                    localUnitId: number | string,
                                    localUnitFormId: number | string
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/local-units/${localUnitId}/forms/${localUnitFormId}/accept`
);

export const updateLocalUnitFormLock = (countyId: number | string,
                                        localUnitId: number | string,
                                        localUnitFormId: number | string,
                                        locked: boolean
): Promise<LocalUnitFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/local-units/${localUnitId}/forms/${localUnitFormId}/lock?locked=${locked}`
);

export const updateCountyFormLock = (countyId: number | string,
                                     countyFormId: number | string,
                                     locked: boolean
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/state/counties/${countyId}/forms/${countyFormId}/lock?locked=${locked}`
);
