import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const find = (countyId: number | string,
                     year: number | string,
                     includeOutliers = false
): Promise<mega.MasterSalesData[]> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/master-sales-data?year=${year}&includeOutliers=${includeOutliers}`
);

export const save = (countyId: number | string,
                     year: number | string,
                     masterSalesDataSaveRequest: mega.MasterSalesDataSaveRequest
): Promise<Response> => megApi.postWithEmptyResponse(
  `/mega/counties/${countyId}/master-sales-data?year=${year}`,
  {body: JSON.stringify(masterSalesDataSaveRequest)}
);

export const update = (countyId: number | string,
                       masterSalesDataSaveRequest: mega.MasterSalesDataSaveRequest
): Promise<Response> => megApi.patchWithEmptyResponse(
  `/mega/counties/${countyId}/master-sales-data`,
  {body: JSON.stringify(masterSalesDataSaveRequest)}
);

export const deleteMasterSales = (ids: string,
                                  countyId: number | string
): Promise<Response> => megApi.deleteWithEmptyResponse(
  `/mega/counties/${countyId}/master-sales-data?salesDataIds=${ids}`
);

export const importMasterSales = (countyId: number | string,
                                  year: number | string,
                                  csvRequest: FormData)
  : Promise<mega.MasterSalesDataImportResult> => megApi.postWithJsonResponse(
  `/mega/counties/${countyId}/master-sales-data/import?year=${year}`,
  {body: csvRequest}
);