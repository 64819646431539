import {mega} from '../../types';
import {megApi} from '../apiUtils';

export const findOutliers = (countyId: number | string,
                             year: number | string): Promise<mega.Outliers> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/outliers?year=${year}`
);

export const updateOutliers = (countyId: number | string,
                               year: number | string,
                               enabled: boolean,
                               studyLength: number): Promise<mega.Outliers> => megApi.patchWithJsonResponse(
  `/mega/counties/${countyId}/outliers?year=${year}&enabled=${enabled}&studyLength=${studyLength}`
);

export const findOutliersCalculations = (countyId: number | string,
                                         year: number | string
): Promise<mega.OutliersCalculations> => megApi.getWithJsonResponse(
  `/mega/counties/${countyId}/outliers/calculations?year=${year}`
);