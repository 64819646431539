import {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {filingCabinetApi, megApi} from '../api';
import * as messages from '../messages';
import {formatDate} from '../utils';
import {FileUpload} from '../types';
import {downloadFile} from '../api/apiUtils';

const StateFilingCabinet = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const navigate = useNavigate();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [county, setCounty] = useState({displayName: ''});
  const [fileUploads, setFileUploads] = useState<FileUpload[]>([]);
  const [deletedFileUploads, setDeletedFileUploads] = useState<FileUpload[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedFileUpload, setSelectedFileUpload] = useState<FileUpload | undefined>(undefined);


  const handleDelete = useCallback(async (fileUploadId: number) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await filingCabinetApi.deleteFile(countyId, fileUploadId);
      const [fileUploads, deletedFileUploads] = await Promise.all([
        filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year),
        filingCabinetApi.findDeletedUploadsByCountyIdAndYear(countyId, year)
      ]);
      setFileUploads(fileUploads as unknown as FileUpload[]);
      setDeletedFileUploads(deletedFileUploads as unknown as FileUpload[]);
      showSuccessAlert(messages.FILE_DELETE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FILE_DELETE_FAILED, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    year,
    loadingState,
    showSuccessAlert,
    showErrorAlert
  ]);

  const toggleDeleteModal = useCallback(async (confirmDelete = false) => {
    if (confirmDelete && selectedFileUpload) {
      await handleDelete(selectedFileUpload.id);
    }

    setSelectedFileUpload(undefined);
    setDeleteModalIsOpen(!deleteModalIsOpen);
  }, [
    deleteModalIsOpen,
    handleDelete,
    selectedFileUpload
  ]);

  const handleOpenDeleteModal = useCallback((fileUpload: FileUpload) => {
    setSelectedFileUpload(fileUpload);
    setDeleteModalIsOpen(true);
  }, []);

  const breadcrumbs = useMemo(() => ([
    {text: 'State Dashboard', active: false, route: '/state-portal', icon: 'home' as const},
    {text: county.displayName, active: true}
  ]), [county]);

  const handleDownload = downloadFile(showErrorAlert);

  const fileUploadsTableProps = useMemo(() => ({
    items: fileUploads,
    headers: [
      {title: 'Date', className: 'align-middle text-nowrap text-center', sortKey: 'createdAt'},
      {title: 'User', className: 'align-middle text-nowrap', sortKey: 'createdByUserFullName'},
      {title: 'File Name', className: 'align-middle text-nowrap', sortKey: 'fileName'},
      {title: 'File Description', className: 'align-middle text-nowrap'},
      {title: 'View File', className: 'align-middle text-nowrap text-center'},
      {title: 'Delete File', className: 'align-middle text-nowrap text-center'}
    ],
    noResultsMessage: messages.NO_FILES_UPLOADED,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    renderRow: (fileUpload: FileUpload) => {
      return <tr key={fileUpload.id}>
        <td className="align-middle text-center">{formatDate(fileUpload.createdAt)}</td>
        <td className="align-middle">{fileUpload.createdByUserFullName}</td>
        <td className="align-middle">{fileUpload.fileName}</td>
        <td className="align-middle">{fileUpload.description}</td>
        <td className="align-middle text-center">
          <ButtonIcon onClick={() => handleDownload(fileUpload.url)}
                      icon="paperclip"
                      className="text-success"
                      title="View File"
                      ariaLabel="View File"
                      size="lg"/>
        </td>
        <td className="align-middle text-center">
          <ButtonIcon onClick={() => handleOpenDeleteModal(fileUpload)}
                      icon="trash"
                      className="text-danger"
                      title="Delete File"
                      ariaLabel="Delete File"
                      size="lg"/>
        </td>
      </tr>;
    }
  }), [
    fileUploads,
    handleDownload,
    handleOpenDeleteModal
  ]);

  const deletedFileUploadsTableProps = useMemo(() => ({
    items: deletedFileUploads,
    headers: [
      {title: 'Date', className: 'align-middle text-nowrap text-center', sortKey: 'createdAt'},
      {title: 'User', className: 'align-middle text-nowrap', sortKey: 'createdByUserFullName'},
      {title: 'File Name', className: 'align-middle text-nowrap', sortKey: 'fileName'},
      {title: 'File Description', className: 'align-middle text-nowrap'},
      {title: 'Deleted Date', className: 'align-middle text-nowrap text-center', sortKey: 'deletedAt'},
      {title: 'Deleted User', className: 'align-middle text-nowrap', sortKey: 'deletedByUserFullName'},
      {title: 'View File', className: 'align-middle text-nowrap text-center'}
    ],
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    renderRow: (fileUpload: FileUpload) => {
      return <tr key={fileUpload.id}>
        <td className="align-middle text-center">{formatDate(fileUpload.createdAt)}</td>
        <td className="align-middle">{fileUpload.createdByUserFullName}</td>
        <td className="align-middle">{fileUpload.fileName}</td>
        <td className="align-middle">{fileUpload.description}</td>
        <td className="align-middle text-center">{formatDate(fileUpload.deletedAt)}</td>
        <td className="align-middle">{fileUpload.deletedByUserFullName}</td>
        <td className="align-middle text-center">
          <ButtonIcon onClick={() => handleDownload(fileUpload.url)}
                      icon="paperclip"
                      className="text-success"
                      title="View File"
                      ariaLabel="View File"
                      size="lg"/>
        </td>
      </tr>;
    }
  }), [
    handleDownload,
    deletedFileUploads
  ]);

  useEffect(() => {
    const findFileUploads = async () => {
      try {
        const [counties, fileUploads, deletedFileUploads] = await Promise.all([
          megApi.findCounties(true),
          filingCabinetApi.findUploadsByCountyIdAndYear(countyId, year),
          filingCabinetApi.findDeletedUploadsByCountyIdAndYear(countyId, year)
        ]);

        const currentCounty = counties.filter(c => c.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal');
        }
        setCounty(currentCounty);

        setFileUploads(fileUploads as unknown as FileUpload[]);
        setDeletedFileUploads(deletedFileUploads as unknown as FileUpload[]);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true, processing: false});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void findFileUploads();
  }, [countyId, year, showErrorAlert, navigate]);

  return <Container className="StateFilingCabinet" fluid>
    {(loadingState.loading || loadingState.processing) && <>
      <ProgressIndicator className="mb-3"/>
    </>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Row>
        <Col>
          <Card>
            <CardHeader>Filing Cabinet</CardHeader>
            <CustomTable {...fileUploadsTableProps} />
          </Card>
        </Col>
      </Row>
      {deletedFileUploads.length > 0 && <Row className="mt-4">
        <Col>
          <Card>
            <CardHeader>Deleted File History</CardHeader>
            <CustomTable {...deletedFileUploadsTableProps} />
          </Card>
        </Col>
      </Row>}
    </>}
    <ConfirmationModal isOpen={deleteModalIsOpen}
                       title="Delete Upload"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       confirmCallback={() => toggleDeleteModal(true)}
                       cancelCallback={() => toggleDeleteModal(false)}>
      <p>
        Are you sure you want to delete the <span className="text-danger">{selectedFileUpload && selectedFileUpload.fileName}</span> upload?
      </p>
    </ConfirmationModal>
  </Container>;
};

export default StateFilingCabinet;