import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: string
}

const CountyStatusBadge = ({
                             status
                           }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.ACCEPTED ||
      status === FormStatus.SUBMITTED ||
      status === FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT) {
      return 'success';
    } else if (status === FormStatus.RECEIVED || status === FormStatus.RETURNED) {
      return 'danger';
    } else {
      return 'light';
    }
  }, [status]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.SENT_TO_REVIEW_TO_LOCAL_UNIT) {
      return 'SENT TO LOCAL UNIT';
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status]);

  return <Badge color={color}
                className="font-size-100 text-uppercase border-0">
    {statusDisplayValue}
  </Badge>;
};

export default memo(CountyStatusBadge);