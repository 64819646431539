import {Card, CardHeader, Col, Row, Table} from 'reactstrap';
import {FormikProps} from 'formik';

import {FormikInput, FormikNumberInput, FormikPhoneInput, FormikSelect} from '@reasoncorp/kyber-js';

import {CERTIFICATION_LEVELS, formatInteger, sum, sumObjects} from '../../../utils';
import {Form4027iCedCertificationDto} from '../../../types/forms/form4027i';
import {forms} from '../../../types';
import {useMemo} from 'react';

type Calculations = {
  numberOfParcelsInClass: number
  numberOfLocalUnits: number
  numberOfStudiesRequired: number
  numberOfStaffTotal: number
}

type Props = {
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  cedCertification: Form4027iCedCertificationDto
  isStateUser: boolean
  formikProps: FormikProps<any>
  numberOfLocalUnits: number
}

const Form4027iCedCertificationTab = ({
                                        showReadOnlyView,
                                        setHasUnsavedChanges,
                                        cedCertification,
                                        isStateUser,
                                        formikProps,
                                        numberOfLocalUnits
                                      }: Props) => {
  const calculations: Calculations = useMemo(() => {
    const {classifications} = formikProps.values as forms.Form4027iDto;

    return {
      numberOfParcelsInClass:
        Object.values(classifications)
          .map(classification => sumObjects(classification.localUnits, 'numberOfParcelsInClass'))
          .reduce((a, b) => a + b),
      numberOfLocalUnits,
      numberOfStudiesRequired: Object.values(classifications)
        .map(classification =>
          classification
            .localUnits
            .map((lu: forms.Form4027iLocalUnitDto) => Number(lu.numberOfParcelsInClass && lu.numberOfParcelsInClass > 0 ? 1 : 0))
            .reduce((a: number, b: number) => a + b))
        .reduce((a, b) => a + b),
      numberOfStaffTotal: sum([
        Number(cedCertification.numberOfStaffMcao2),
        Number(cedCertification.numberOfStaffMaao3),
        Number(cedCertification.numberOfStaffMmao4),
        Number(cedCertification.numberOfStaffClerical),
        Number(cedCertification.numberOfStaffOther)
      ])
    };
  }, [
    numberOfLocalUnits,
    formikProps.values,
    cedCertification
  ]);

  return <>
    <Row>
      <Col className="responsive-table-eq-height col-md-12 mb-md-4 col-lg-6 mb-lg-0">
        <Card>
          <CardHeader>
            Totals
          </CardHeader>
          <Table bordered responsive>
            <thead>
              <tr>
                {/* match height to other table header in this row */}
                <th className="text-primary text-center align-middle" style={{
                  width: '33%',
                  height: '71px'
                }}>Local Units
                </th>
                <th className="text-primary text-center align-middle" style={{
                  width: '33%',
                  height: '71px'
                }}>Parcels
                </th>
                <th className="text-primary text-center align-middle" style={{
                  width: '33%',
                  height: '71px'
                }}>Studies Required
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center font-weight-bold" style={{
                  paddingTop: '34px',
                  paddingBottom: '27px'
                }}>
                  {calculations?.numberOfLocalUnits}
                </td>
                <td className="text-center font-weight-bold" style={{
                  paddingTop: '34px',
                  paddingBottom: '27px'
                }}>
                  {formatInteger(calculations?.numberOfParcelsInClass ?? 0)}
                </td>
                <td className="text-center font-weight-bold" style={{
                  paddingTop: '34px',
                  paddingBottom: '27px'
                }}>
                  {calculations?.numberOfStudiesRequired}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col className="col-md-12 col-lg-6">
        <Card>
          <CardHeader>
            Equalization Director Status
          </CardHeader>
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="text-primary text-center align-middle" style={{height: '71px'}}>Years in Position</th>
                <th className="text-primary text-center align-middle" style={{height: '71px'}}>Years in Assessment Field</th>
                <th className="text-primary text-center align-middle" style={{height: '71px'}}>Certification Level Required</th>
                <th className="text-primary text-center align-middle" style={{height: '71px'}}>Certification Level Held</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.yearsInPosition}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Years in Position"
                                                           name="cedCertification.yearsInPosition"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.yearsInField}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Years in Field"
                                                           name="cedCertification.yearsInField"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.certificationLevelRequired}
                  {!showReadOnlyView && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                                      formGroupClass="mb-0"
                                                      ariaLabel="Certification Level Required"
                                                      name="cedCertification.certificationLevelRequired">
                    <option value="">Select</option>
                    {CERTIFICATION_LEVELS.map((certificationLevel) => {
                      return <option key={certificationLevel} value={certificationLevel}>{certificationLevel}</option>;
                    })}
                  </FormikSelect>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.certificationLevelHeld}
                  {!showReadOnlyView && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                                      formGroupClass="mb-0"
                                                      ariaLabel="Certification Level Held"
                                                      name="cedCertification.certificationLevelHeld">
                    <option value="">Select</option>
                    {CERTIFICATION_LEVELS.map((certificationLevel) => {
                      return <option key={certificationLevel} value={certificationLevel}>{certificationLevel}</option>;
                    })}
                  </FormikSelect>}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>

    <Row className="mt-4">
      <Col className="responsive-table-eq-height col-md-12 mb-md-4 col-lg-6 mb-lg-0">
        <Card>
          <CardHeader>
            Current Staffing Level Status (including Director)
          </CardHeader>
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="text-primary text-center">MCAO 2</th>
                <th className="text-primary text-center">MAAO 3</th>
                <th className="text-primary text-center">MMAO 4</th>
                <th className="text-primary text-center">Clerical</th>
                <th className="text-primary text-center">Other</th>
                <th className="text-primary text-center text-nowrap">Staff Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.numberOfStaffMcao2}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Number of Staff Mcao2"
                                                           name="cedCertification.numberOfStaffMcao2"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.numberOfStaffMaao3}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Number of Staff Maao3"
                                                           name="cedCertification.numberOfStaffMaao3"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.numberOfStaffMmao4}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Number of Staff Mmao4"
                                                           name="cedCertification.numberOfStaffMmao4"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {isStateUser && cedCertification.numberOfStaffClerical}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Number of Staff Clerical"
                                                           name="cedCertification.numberOfStaffClerical"/>}
                </td>
                <td className="align-middle text-nowrap text-center" style={{height: '86px'}}>
                  {showReadOnlyView && cedCertification.numberOfStaffOther}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="2"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           className="text-center"
                                                           ariaLabel="Number of Staff Other"
                                                           name="cedCertification.numberOfStaffOther"/>}
                </td>
                <td className="text-center align-middle font-weight-bold">
                  {showReadOnlyView && calculations?.numberOfStaffTotal}
                  {!showReadOnlyView && <p className="mt-3">
                    {calculations?.numberOfStaffTotal}
                  </p>}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
      <Col className="responsive-table-eq-height col-md-12 col-lg-6">
        <Card>
          <CardHeader>
            CAMA - GIS Status
          </CardHeader>
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="text-primary text-center" style={{
                  width: '33%'
                }}>Software
                </th>
                <th className="text-primary text-center" style={{
                  width: '33%'
                }}>SQL Version
                </th>
                <th className="text-primary text-center" style={{width: '33%'}}>GIS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{height: '86px'}} className="align-middle text-nowrap text-center">
                  {showReadOnlyView && cedCertification.camaGisSoftware}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="20"
                                                     disableFloatingLabel={true}
                                                     formGroupClass="mb-0"
                                                     aria-required={true}
                                                     className="text-center"
                                                     ariaLabel="Cama Gis Software"
                                                     name="cedCertification.camaGisSoftware"/>}
                </td>
                <td style={{height: '86px'}} className="align-middle text-nowrap text-center">
                  {showReadOnlyView && cedCertification.camaGisSqlVersion}
                  {!showReadOnlyView && <FormikNumberInput onChange={() => setHasUnsavedChanges(true)}
                                                           maxLength="4"
                                                           disableFloatingLabel={true}
                                                           formGroupClass="mb-0"
                                                           aria-required={true}
                                                           includeCommas={false}
                                                           className="text-center"
                                                           ariaLabel="Cama Gis SQL Version"
                                                           name="cedCertification.camaGisSqlVersion"/>}
                </td>
                <td style={{height: '86px'}} className="align-middle text-nowrap text-center">
                  {showReadOnlyView && cedCertification.camaGis}
                  {!showReadOnlyView && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                                      formGroupClass="mb-0"
                                                      ariaLabel="Cama Gis"
                                                      aria-required={true}
                                                      disableFloatingLabel={true}
                                                      name="cedCertification.camaGis">
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </FormikSelect>}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Card>
          <CardHeader>
            CED Current Certification Requirements and Staffing Level Comments or Concerns
          </CardHeader>
          <Table bordered responsive>
            <tbody>
              <tr>
                <td style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.certificationRequirementsComments}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="200"
                                                     labelText="Comments"
                                                     ariaLabel="Certification Requirements Comments"
                                                     name="cedCertification.certificationRequirementsComments"/>}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <Card>
          <CardHeader>
            Contact Information
          </CardHeader>
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="text-primary text-center">Office Hours</th>
                <th className="text-primary text-center">Address</th>
                <th className="text-primary text-center">Phone Number</th>
                <th className="text-primary text-center">Email Address</th>
                <th className="text-primary text-center">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center" style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.contactOfficeHours}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="200"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Contact Office Hours"
                                                     className="text-center"
                                                     name="cedCertification.contactOfficeHours"/>}
                </td>
                <td className="text-center" style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.contactAddress}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="350"
                                                     disableFloatingLabel={true}
                                                     formGroupClass="mb-0"
                                                     ariaLabel="Contact Address"
                                                     className="text-center"
                                                     name="cedCertification.contactAddress"/>}
                </td>
                <td className="text-center" style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.contactPhoneNumber}
                  {!showReadOnlyView && <FormikPhoneInput onChange={() => setHasUnsavedChanges(true)}
                                                          maxLength="14"
                                                          disableFloatingLabel={true}
                                                          formGroupClass="mb-0"
                                                          ariaLabel="Contact Phone Number"
                                                          className="text-center"
                                                          name="cedCertification.contactPhoneNumber"/>}
                </td>
                <td className="text-center" style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.contactEmail}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="50"
                                                     disableFloatingLabel={true}
                                                     formGroupClass="mb-0"
                                                     ariaLabel="Contact Email"
                                                     className="text-center"
                                                     name="cedCertification.contactEmail"/>}
                </td>
                <td className="text-center" style={{minHeight: '96px'}}>
                  {showReadOnlyView && cedCertification.contactComments}
                  {!showReadOnlyView && <FormikInput onChange={() => setHasUnsavedChanges(true)}
                                                     maxLength="200"
                                                     disableFloatingLabel={true}
                                                     formGroupClass="mb-0"
                                                     className="text-center"
                                                     aria-required={true}
                                                     ariaLabel="Contact Comments"
                                                     name="cedCertification.contactComments"/>}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  </>;
};

export default Form4027iCedCertificationTab;