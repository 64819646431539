import {useMemo} from 'react';
import {Table} from 'reactstrap';
import {isNaN} from 'lodash';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {formatAdjustmentModifier, formatDecimal, formatInteger} from '../../../utils';
import * as form4017Utils from '../../../utils/form4017Utils';
import {Form4017ClassificationDto, Form4017TotalsDto} from '../../../types/forms/form4017';

type Props = {
  year: number
  showReadOnlyView: boolean
  selectedTab: string
  currentClassificationValues: Form4017ClassificationDto
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: Form4017TotalsDto
}

const Form4017Month24Table = ({
                                year,
                                showReadOnlyView,
                                selectedTab,
                                currentClassificationValues,
                                setHasUnsavedChanges,
                                totals
                              }: Props) => {
  const month24 = useMemo(() => currentClassificationValues['24Month'], [
    currentClassificationValues
  ]);

  const month12AdjustmentRatio1 = useMemo(() => {
    return isFinite(totals['12Month'][0].adjustmentRatio) ? totals['12Month'][0].adjustmentRatio : 0;
  }, [
    totals
  ]);

  const month12AdjustmentRatio2 = useMemo(() => {
    return isFinite(totals['12Month'][1].adjustmentRatio) ? totals['12Month'][1].adjustmentRatio : 0;
  }, [
    totals
  ]);

  const month24AdjustmentRatio = useMemo(() => {
    return isFinite(totals['24Month'].adjustmentRatio) ? totals['24Month'].adjustmentRatio : 0;
  }, [
    totals
  ]);


  const period1AdjustedValue = useMemo(() => {
    if (month24.period1.totalAssessedValueForSales === null ||
      Number(month24.period1.totalAssessedValueForSales) === 0 ||
      !isFinite(month24.period1.totalAssessedValueForSales) ||
      totals.adjustmentModifiers[1] === null ||
      totals.adjustmentModifiers[1] === 0 ||
      !isFinite(totals.adjustmentModifiers[1])) {
      return '';
    } else {
      return form4017Utils.formatAdjustedValue(
        month24.period1.totalAssessedValueForSales,
        totals.adjustmentModifiers[1]
      );
    }
  }, [
    month24,
    totals
  ]);

  const period1AdjustmentRatio = useMemo(() => {
    if (Number(month24.period1.totalAssessedValueForSales) === 0 ||
      month24.period1.totalAssessedValueForSales === null ||
      totals.adjustmentModifiers[1] === 0 ||
      totals.adjustmentModifiers[1] === null ||
      Number(month24.period1.totalAdjustedPrices === 0) ||
      month24.period1.totalAdjustedPrices === null ||
      !isFinite(totals.adjustmentModifiers[1])) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        totals.adjustmentModifiers[1],
        month24.period1.totalAssessedValueForSales,
        month24.period1.totalAdjustedPrices
      );
    }
  }, [
    totals,
    month24
  ]);

  const period2AdjustedValue = useMemo(() => {
    if (month24.period2.totalAssessedValueForSales === null ||
      Number(month24.period2.totalAssessedValueForSales) === 0 ||
      !isFinite(month24.period2.totalAssessedValueForSales) ||
      totals.adjustmentModifiers[1] === null ||
      totals.adjustmentModifiers[1] === 0 ||
      !isFinite(totals.adjustmentModifiers[1])) {
      return '';
    } else {
      return form4017Utils.formatAdjustedValue(
        month24.period2.totalAssessedValueForSales,
        totals.adjustmentModifiers[1]
      );
    }
  }, [
    totals,
    month24
  ]);

  const period2AdjustmentRatio = useMemo(() => {
    if (Number(month24.period2.totalAssessedValueForSales) === 0 ||
      month24.period2.totalAssessedValueForSales === null ||
      totals.adjustmentModifiers[1] === null ||
      totals.adjustmentModifiers[1] === 0 ||
      Number(month24.period2.totalAdjustedPrices === 0) ||
      month24.period2.totalAdjustedPrices === null ||
      !isFinite(totals.adjustmentModifiers[1])) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        totals.adjustmentModifiers[1],
        month24.period2.totalAssessedValueForSales,
        month24.period2.totalAdjustedPrices
      );
    }
  }, [
    totals,
    month24
  ]);

  const period3AdjustedValue = useMemo(() => {
    if (month24.period3.totalAssessedValueForSales === null ||
      Number(month24.period3.totalAssessedValueForSales) === 0 ||
      totals.adjustmentModifiers[1] === null ||
      totals.adjustmentModifiers[1] === 0 ||
      !isFinite(totals.adjustmentModifiers[1]) ||
      !isFinite(month24.period3.totalAssessedValueForSales)) {
      return '';
    } else {
      return form4017Utils.formatAdjustedValue(month24.period3.totalAssessedValueForSales, totals.adjustmentModifiers[2]);
    }
  }, [
    totals,
    month24
  ]);

  const period3AdjustmentRatio = useMemo(() => {
    if (Number(month24.period3.totalAssessedValueForSales) === 0 ||
      month24.period3.totalAssessedValueForSales === null ||
      totals.adjustmentModifiers[2] === null ||
      totals.adjustmentModifiers[2] === 0 ||
      Number(month24.period3.totalAdjustedPrices === 0) ||
      month24.period3.totalAdjustedPrices === null ||
      !isFinite(totals.adjustmentModifiers[2])) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        totals.adjustmentModifiers[2],
        month24.period3.totalAssessedValueForSales,
        month24.period3.totalAdjustedPrices
      );
    }
  }, [
    totals,
    month24
  ]);

  const period4AdjustmentRatio = useMemo(() => {
    if (
      Number(month24.period4.totalAssessedValueForSales) === 0 ||
      month24.period4.totalAssessedValueForSales === null ||
      Number(month24.period4.totalAdjustedPrices === 0) ||
      month24.period4.totalAdjustedPrices === null ||
      totals.adjustmentModifiers[2] === null ||
      totals.adjustmentModifiers[2] === 0 ||
      !isFinite(totals.adjustmentModifiers[2])) {
      return '0.00%';
    } else {
      return form4017Utils.formatAdjustmentRatio(
        totals.adjustmentModifiers[2],
        month24.period4.totalAssessedValueForSales,
        month24.period4.totalAdjustedPrices
      );
    }
  }, [
    totals,
    month24
  ]);

  const period4AdjustedValue = useMemo(() => {
    if (month24.period4.totalAssessedValueForSales === null ||
      Number(month24.period4.totalAssessedValueForSales) === 0 ||
      !isFinite(month24.period4.totalAssessedValueForSales) ||
      totals.adjustmentModifiers[2] === null ||
      totals.adjustmentModifiers[2] === 0 ||
      !isFinite(totals.adjustmentModifiers[2])) {
      return '';
    } else {
      return form4017Utils.formatAdjustedValue(
        month24.period4.totalAssessedValueForSales,
        totals.adjustmentModifiers[2]
      );
    }
  }, [
    totals,
    month24
  ]);

  const month12Totals1AdjustedAssessedValue = useMemo(() => {
    return !isNaN(totals['12Month'][0].adjustedAssessedValue) &&
      form4017Utils.formatAdjustedValue(totals['12Month'][0].adjustedAssessedValue, 1);
  }, [
    totals
  ]);

  const month12Totals1AdjustedPrices = useMemo(() => {
    return totals['12Month'][0].totalAdjustedPrices ?
      formatInteger(Number(totals['12Month'][0].totalAdjustedPrices.toFixed(0))) : null;
  }, [
    totals
  ]);

  const month12Totals2AdjustedAssessedValue = useMemo(() => {
    return totals['12Month'][1].adjustedAssessedValue && isFinite(Number(totals.adjustmentModifiers[1])) ?
      form4017Utils.formatAdjustedValue(totals['12Month'][1].adjustedAssessedValue, 1) : null;
  }, [
    totals
  ]);

  const month12Totals2AdjustedPrices = useMemo(() => {
    return totals['12Month'][1].totalAdjustedPrices ?
      formatInteger(Number(totals['12Month'][1].totalAdjustedPrices.toFixed(0))) : null;
  }, [
    totals
  ]);

  const month24TotalsAdjustedAssessedValue = useMemo(() => {
    return totals['24Month'].adjustedAssessedValue && isFinite(Number(totals.adjustmentModifiers[2])) ?
      form4017Utils.formatAdjustedValue(totals['24Month'].adjustedAssessedValue, 1) : null;
  }, [
    totals
  ]);

  const month24TotalsAdjustedPrices = useMemo(() => {
    return !isNaN(totals['24Month'].totalAdjustedPrices) &&
      totals['24Month'].totalAdjustedPrices !== null &&
      formatInteger(Number(totals['24Month'].totalAdjustedPrices.toFixed(0)));
  }, [
    totals
  ]);

  return <div className="mb-4">
    <Table responsive bordered>
      <thead>
        <tr>
          <th className="text-primary font-weight-bold" colSpan={8}>24 Month Sales Study</th>
        </tr>
        <tr>
          <th className="text-primary text-center align-middle">(A) Year of Assessment</th>
          <th className="text-primary text-center align-middle">(B) Sales Period</th>
          <th className="text-primary text-center align-middle">(C) Number of Sales</th>
          <th className="text-primary text-center align-middle">(D) Total Assessed Value for Sales</th>
          <th className="text-primary text-center align-middle">(E) Applicable Adjustment Modifier</th>
          <th className="text-primary text-center align-middle">(F) Adjusted Assessed Value</th>
          <th className="text-primary text-center align-middle">(G) Total Adjusted Prices</th>
          <th className="text-primary text-center align-middle">(H) Adjust % Ratio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center align-middle">{year - 3}</td>
          <td className="text-center align-middle">04/{year - 3} - 09/{year - 3}</td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period1.numberOfSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="7"
                                                     ariaLabel="Number of Sales"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period1.numberOfSales`}
                                                     className="text-center"
            />}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period1.totalAssessedValueForSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Assessed Value for Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period1.totalAssessedValueForSales`}
                                                     className="text-center"
            />}
          </td>
          <td className="text-center align-middle">
            {formatAdjustmentModifier(totals.adjustmentModifiers[1])}
          </td>
          <td className="text-center align-middle">
            <div>
              {period1AdjustedValue}
            </div>
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period1.totalAdjustedPrices)}
            {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Adjusted Prices"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period1.totalAdjustedPrices`}
                                                     maxLength="15"
                                                     className="text-center"
            />}
          </td>
          <td className="text-center align-middle">
            {period1AdjustmentRatio}
          </td>
        </tr>
        <tr>
          <td className="text-center align-middle">{year - 3}</td>
          <td className="text-center align-middle">10/{year - 3} - 03/{year - 2}</td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period2.numberOfSales)}
            {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                     maxLength="7"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Number of Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period2.numberOfSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period2.totalAssessedValueForSales)}
            {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                     maxLength="15"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Assessed Value for Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period2.totalAssessedValueForSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {formatAdjustmentModifier(totals.adjustmentModifiers[1])}
          </td>
          <td className="text-center align-middle">
            {period2AdjustedValue}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period2.totalAdjustedPrices)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Adjusted Prices"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period2.totalAdjustedPrices`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {period2AdjustmentRatio}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="font-weight-bold text-primary text-nowrap text-center align-middle bg-light">
            12 Month Total Sales
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatInteger(totals['12Month'][0].numberOfSales)}
          </td>
          <td colSpan={2} className="align-middle bg-light"/>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month12Totals1AdjustedAssessedValue}
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month12Totals1AdjustedPrices}
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatDecimal(month12AdjustmentRatio1, 2, true)}
          </td>
        </tr>
        <tr>
          <td className="text-center align-middle">{year - 2}</td>
          <td className="text-center align-middle">04/{year - 2} - 09/{year - 2}</td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period3.numberOfSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="7"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Number of Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period3.numberOfSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period3.totalAssessedValueForSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     ariaLabel="Total Assessed Value for Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period3.totalAssessedValueForSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {formatAdjustmentModifier(totals.adjustmentModifiers[2])}
          </td>
          <td className="text-center align-middle">
            {period3AdjustedValue}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period3.totalAdjustedPrices)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Adjusted Prices"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period3.totalAdjustedPrices`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {period3AdjustmentRatio}
          </td>
        </tr>
        <tr>
          <td className="text-center align-middle">{year - 2}</td>
          <td className="text-center align-middle">10/{year - 2} - 03/{year - 1}</td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period4.numberOfSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="7"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Number of Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period4.numberOfSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period4.totalAssessedValueForSales)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Assessed Value for Sales"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period4.totalAssessedValueForSales`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {formatAdjustmentModifier(totals.adjustmentModifiers[2])}
          </td>
          <td className="text-center align-middle">
            {period4AdjustedValue}
          </td>
          <td className="text-center align-middle">
            {showReadOnlyView && formatInteger(month24.period4.totalAdjustedPrices)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Total Adjusted Prices"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}']['24Month'].period4.totalAdjustedPrices`}
                                                     className="text-center"/>}
          </td>
          <td className="text-center align-middle">
            {period4AdjustmentRatio}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="text-primary font-weight-bold text-nowrap text-center align-middle bg-light">
            12 Month Total Sales
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatInteger(totals['12Month'][1].numberOfSales)}
          </td>
          <td colSpan={2} className="align-middle bg-light"/>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month12Totals2AdjustedAssessedValue}
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month12Totals2AdjustedPrices}
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatDecimal(month12AdjustmentRatio2, 2, true)}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="font-weight-bold text-primary text-nowrap text-center align-middle bg-light">
            24 Month Total Sales
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatInteger(totals['24Month'].numberOfSales)}
          </td>
          <td colSpan={2} className="align-middle bg-light"/>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month24TotalsAdjustedAssessedValue}
          </td>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {month24TotalsAdjustedPrices}
          </td>
          <td className="align-middle bg-light"/>
        </tr>
        <tr>
          <td colSpan={2} className="text-primary font-weight-bold text-nowrap text-center align-middle bg-light">
            24 Month Mean Adjusted Ratio
          </td>
          <td colSpan={5} className="align-middle bg-light"/>
          <td className="font-weight-bold text-nowrap text-center align-middle bg-light">
            {formatDecimal(month24AdjustmentRatio, 2, true)}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>;
};

export default Form4017Month24Table;