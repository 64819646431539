import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Formik} from 'formik';

import {FormikRadioGroup} from '@reasoncorp/kyber-js';

import {report4030BypassSchema} from '../../schemas/mega';

type Props = {
  isOpen: boolean
  onBypass: (userType: string) => void
  onCancel: () => void
  disableAnalyst: boolean
  disableSupervisor: boolean
}

const Report4030BypassModal = ({
                                 isOpen,
                                 onBypass,
                                 onCancel,
                                 disableAnalyst,
                                 disableSupervisor
                               }: Props) => {
  return <Modal isOpen={isOpen}
                toggle={() => onCancel()}
                autoFocus={false}
                aria-modal={true}
                returnFocusAfterClose={true}>
    <ModalHeader toggle={() => onCancel()} className="h5" tag="h2">
      Bypass Signature
    </ModalHeader>
    <Formik initialValues={{
      userType: disableSupervisor ? 'analyst' : 'supervisor'
    }}
            validateOnMount={true}
            validationSchema={report4030BypassSchema}
            onSubmit={(values) => onBypass(values.userType)}>
      {formikProps => {
        return <Form onSubmit={formikProps.handleSubmit}
                     autoComplete="off">
          <ModalBody>
            <div className="mb-2">
              Select which signature you would like to bypass:
            </div>
            <FormikRadioGroup ariaLabel="Select which signature you would like to bypass:"
                              name="userType"
                              autoFocus
                              radioButtons={[
                                {value: 'analyst', labelText: 'Analyst', disabled: disableAnalyst},
                                {value: 'supervisor', labelText: 'Supervisor', disabled: disableSupervisor}
                              ]}/>
          </ModalBody>
          <ModalFooter>
            <Button onClick={formikProps.submitForm}
                    color="success">
              Bypass
            </Button>
            <Button onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>;
      }}
    </Formik>
  </Modal>;
};

export default Report4030BypassModal;