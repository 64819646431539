import {memo, useCallback, useMemo} from 'react';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {CountyStatusBadge, Form4022CountyStatusBadge, Form4022LUStatusBadge, LocalUnitStatusBadge} from '../badge';
import {formatDate} from '../../utils';
import {CountyFormDto, LocalUnitFormDto} from '../../types';
import {FormStatus, FormType} from '../../enum';

type Props = {
  form: CountyFormDto | LocalUnitFormDto
  onClick: ((jurisdictionId: number, formId: number, formLevel?: string) => void)
  disabled?: boolean
  isCountyUser?: boolean,
}

const FormCard = ({
                    form,
                    onClick,
                    disabled,
                    isCountyUser
                  }: Props) => {
  const {
    name,
    description,
    dueOn,
    formType,
    status,
    formLevel
  } = useMemo(() => form, [form]);

  const style = useMemo(() => {
    return disabled ? {opacity: 0.7, cursor: 'default'} : {cursor: 'pointer'};
  }, [disabled]);

  const handleClick = useCallback(() => {
    const jurisdictionId = (form as LocalUnitFormDto).localUnitId ? (form as LocalUnitFormDto).localUnitId : form.countyId;

    if (disabled) {
      return null;
    } else {
      onClick(jurisdictionId, form.id, formLevel);
    }
  }, [
    disabled,
    form,
    formLevel,
    onClick
  ]);

  const Badge = useMemo(() => () => {
    const is4022Form = formType === FormType.FORM_4022_AV || formType === FormType.FORM_4022_SA;

    if (isCountyUser && !is4022Form) {
      return <CountyStatusBadge status={status}/>;
    } else if (isCountyUser && is4022Form) {
      return <Form4022CountyStatusBadge status={status}
                                        returnedFromStateDisplayText={FormStatus.RETURNED}/>;
    } else if (!isCountyUser && is4022Form) {
      return <Form4022LUStatusBadge status={status}/>;
    } else if (!isCountyUser) {
      return <LocalUnitStatusBadge status={status}/>;
    } else {
      return null;
    }
  }, [
    formType,
    isCountyUser,
    status
  ]);

  return (
    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
      <Card className="h-100"
            title={`Form ${name}`}
            aria-label={formType}
            tabIndex={disabled ? -1 : 0}
            role="region"
            onClick={() => handleClick()}
            onKeyDown={(e) => e.key === 'Enter' ? handleClick() : null}
            style={style}
            id={`form-${formType}`}>
        <CardHeader className="bg-secondary text-white">
          Form {name}
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col>
              {description}
            </Col>
          </Row>
          <Row>
            <Col>
              Due: {formatDate(dueOn)}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row>
            <Col className="col-6">
              <FontAwesomeIcon icon="clock"
                               className="text-danger"
                               size="lg"
                               title="overdue"
                               style={{display: form.isOverdue ? 'block' : 'none'}}/>
            </Col>
            <Col className="d-flex justify-content-end col-6">
              <Badge/>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>);
};

export default memo(FormCard);
