import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

const OWNER_NAME_REGEX = /^[0-9a-z-;:,/&' .\\#@%^*()"+=]+$/i;

const form4015aSchema = (classificationFilter: number) => {
  const propertyClassificationRangeError = `Must be between ${classificationFilter} and ${classificationFilter + 99}`;
  return Yup.object().shape({
    id: Yup.number(),
    propertyNumber: Yup.string()
      .required(messages.REQUIRED)
      .matches(/^[a-z\d- ]+$/i, messages.MUST_BE_ALPHANUMERIC)
      .max(30, messages.maxCharactersMessage(30)),
    ownerName: Yup.string()
      .matches(OWNER_NAME_REGEX, messages.MUST_BE_ALPHANUMERIC)
      .max(50, messages.maxCharactersMessage(50)),
    propertyClassification: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .required(messages.REQUIRED)
      .transform(transformBlankToNull)
      .min(classificationFilter, propertyClassificationRangeError)
      .max(classificationFilter + 99, propertyClassificationRangeError),
    assessedValue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .required(messages.REQUIRED)
      .transform(transformBlankToNull)
      .max(999999999999, messages.maxValueMessage(999999999999)),
    appraisedValue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .required(messages.REQUIRED)
      .transform(transformBlankToNull)
      .max(999999999999, messages.maxValueMessage(999999999999)),
    comments: Yup.string()
      .max(200, messages.MAX_COMMENT_LENGTH)
      .nullable(),
    stratified: Yup.boolean()
      .nullable()
  });
};

export default form4015aSchema;