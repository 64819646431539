import {useMemo} from 'react';
import {Table} from 'reactstrap';

import {FormikDecimalInput, FormikNumberInput} from '@reasoncorp/kyber-js';

import {forms} from '../../../types';
import {formatDecimal, formatInteger} from '../../../utils';
import * as form4024Utils from '../../../utils/form4024Utils';

type Props = {
  localUnits: forms.Form4024LocalUnitDto[]
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: forms.Form4024Totals
}

const Form4024TotalsTab = ({
                             localUnits,
                             showReadOnlyView,
                             setHasUnsavedChanges,
                             totals
                           }: Props) => {
  const renderRow = useMemo(() => (form4024LocalUnit: forms.Form4024LocalUnitDto, index: number) => {
    const localUnitTotals = form4024Utils.calculateLocalUnitTotals(form4024LocalUnit);

    return <tr key={index}>
      <td className="align-middle text-nowrap text-primary font-weight-bold">{form4024LocalUnit.displayName}</td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatDecimal(localUnits[index].numberOfAcres ?? 0, 2)}
        {!showReadOnlyView && <FormikDecimalInput name={`localUnits[${index}].numberOfAcres`}
                                                  integerPlaces="6"
                                                  decimalPlaces="2"
                                                  disableFloatingLabel={true}
                                                  ariaLabel="Number of Acres"
                                                  formGroupClass="mb-0"
                                                  onChange={() => setHasUnsavedChanges(true)}
                                                  className="text-center"
        />}
      </td>
      <td className="align-middle text-center">
        {formatInteger(localUnitTotals.assessed)}
      </td>
      <td className="align-middle text-center">
        {formatInteger(localUnitTotals.equalized)}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.personalPropertyAssessed ?? 0)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].personalPropertyAssessed`}
                                                 ariaLabel="Personal Property Assessed"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {showReadOnlyView && formatInteger(form4024LocalUnit.personalPropertyEqualized ?? 0)}
        {!showReadOnlyView && <FormikNumberInput name={`localUnits[${index}].personalPropertyEqualized`}
                                                 ariaLabel="Personal Property Equalized"
                                                 formGroupClass="mb-0"
                                                 disableFloatingLabel={true}
                                                 maxLength={19}
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 className="text-center"/>}
      </td>
      <td className="align-middle text-center">
        {
          formatInteger(
            localUnitTotals.assessed +
            (form4024LocalUnit.personalPropertyAssessed ?? 0)
          )
        }
      </td>
      <td className="align-middle text-center">
        {
          formatInteger(
            localUnitTotals.equalized +
            (form4024LocalUnit.personalPropertyEqualized ?? 0)
          )
        }
      </td>
    </tr>;
  }, [
    localUnits,
    showReadOnlyView,
    setHasUnsavedChanges
  ]);

  return <Table responsive bordered>
    <thead>
      <tr>
        <th className="align-middle text-primary">Township/City</th>
        <th className="align-middle text-primary text-center">Number of Acres Assessed</th>
        <th className="align-middle text-primary text-center">Real Property Assessed</th>
        <th className="align-middle text-primary text-center">Real Property Equalized</th>
        <th className="align-middle text-primary text-center">Personal Property Assessed</th>
        <th className="align-middle text-primary text-center">Personal Property Equalized</th>
        <th className="align-middle text-primary text-center">Real + Personal Property Assessed</th>
        <th className="align-middle text-primary text-center">Real + Personal Property Equalized</th>
      </tr>
    </thead>
    <tbody>
      {localUnits.map(renderRow)}
    </tbody>
    <tfoot className="bg-light">
      <tr>
        <td className="align-middle font-weight-bold text-primary">Totals</td>
        <td className="align-middle text-center font-weight-bold">
          {totals.numberOfAcres.toLocaleString(undefined, {minimumFractionDigits: 2})}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyAssessed)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyEqualized)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.personalPropertyAssessed)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.personalPropertyEqualized)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyAssessed + totals.personalPropertyAssessed)}
        </td>
        <td className="align-middle text-center font-weight-bold">
          {formatInteger(totals.realPropertyEqualized + totals.personalPropertyEqualized)}
        </td>
      </tr>
    </tfoot>
  </Table>;
};

export default Form4024TotalsTab;