import {kyberFontAwesomeLibrary} from '@reasoncorp/kyber-js';

import {
  faCheckCircle,
  faClock,
  faCog,
  faEdit,
  faExclamationCircle,
  faFilePdf,
  faHome,
  faLock,
  faMinusCircle,
  faPaperclip,
  faShareSquare,
  faSpinner,
  faTrash,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons';

kyberFontAwesomeLibrary.add(
  faCheckCircle,
  faClock,
  faCog,
  faEdit,
  faExclamationCircle,
  faFilePdf,
  faHome,
  faLock,
  faMinusCircle,
  faPaperclip,
  faShareSquare,
  faSpinner,
  faTrash,
  faUserSecret
);
