import {useMemo} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {formatAdjustmentModifier, formatInteger} from '../../../utils';
import {Form4017ClassificationDto, Form4017TotalsDto} from '../../../types/forms/form4017';

type Props = {
  year: number
  showReadOnlyView: boolean
  selectedTab: string
  currentClassificationValues: Form4017ClassificationDto
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  totals: Form4017TotalsDto
}

const Form4017AdjustmentModifierCards = ({
                                           year,
                                           showReadOnlyView,
                                           selectedTab,
                                           currentClassificationValues,
                                           setHasUnsavedChanges,
                                           totals
                                         }: Props) => {
  const totalsAdjustmentModifier0 = useMemo(() => {
    if (currentClassificationValues.adjustmentModifier1.beforeAdjustment === null ||
      Number(currentClassificationValues.adjustmentModifier1.beforeAdjustment) === 0) {
      return 0;
    } else {
      return totals.adjustmentModifiers[0];
    }
  }, [
    currentClassificationValues,
    totals
  ]);

  const totalsAdjustmentModifier2 = useMemo(() => {
    if (currentClassificationValues.adjustmentModifier2.beforeAdjustment === null ||
      Number(currentClassificationValues.adjustmentModifier2.beforeAdjustment) === 0) {
      return 0;
    } else {
      return totals.adjustmentModifiers[2];
    }
  }, [
    currentClassificationValues,
    totals
  ]);

  return <>
    <Card className="mb-4">
      <CardHeader className="bg-secondary text-white">
        {year - 3} to {year - 2} Adjustment Modifier
      </CardHeader>
      <CardBody>
        <Row className={!showReadOnlyView ? 'mb-4' : ''}>
          <Col className="col-6">
            1. Enter the assessed valuation after adjustment from the {year - 2} form L-4023 line {Number(selectedTab) + 5}
          </Col>
          <Col className="d-flex justify-content-end col-6">
            {showReadOnlyView && formatInteger(currentClassificationValues.adjustmentModifier1.afterAdjustment)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="After Adjustment"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}'].adjustmentModifier1.afterAdjustment`}
                                                     className="text-right"/>
            }
          </Col>
        </Row>
        {showReadOnlyView && <hr/>}
        <Row className={!showReadOnlyView ? 'mb-4' : ''}>
          <Col className="col-6">
            2. Enter the assessed valuation before adjustment from the {year - 2} form L-4023 line {Number(selectedTab) + 3}
          </Col>
          <Col className="d-flex justify-content-end col-6">
            {showReadOnlyView && formatInteger(currentClassificationValues.adjustmentModifier1.beforeAdjustment)}
            {!showReadOnlyView &&
              <FormikNumberInput maxLength="15"
                                 formGroupClass="mb-0"
                                 disableFloatingLabel={true}
                                 ariaLabel="Before Adjustment"
                                 onChange={() => setHasUnsavedChanges(true)}
                                 name={`classifications['${selectedTab}'].adjustmentModifier1.beforeAdjustment`}
                                 className="text-right"/>
            }
          </Col>
        </Row>
        {showReadOnlyView && <hr/>}
        <Row>
          <Col className="col-6">
            3. {year - 3} to {year - 2} Adjustment Modifier. Divide line 1 by line 2.
          </Col>
          <Col className="d-flex justify-content-end col-6">
            <div>
              {formatAdjustmentModifier(totalsAdjustmentModifier0)}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card className="mb-4">
      <CardHeader className="bg-secondary text-white">
        {year - 2} to {year - 1} Adjustment Modifier
      </CardHeader>
      <CardBody>
        <Row className={!showReadOnlyView ? 'mb-4' : ''}>
          <Col className="col-6">
            4. Enter the assessed valuation after adjustment from the {year - 1} form L-4023 line {Number(selectedTab) + 5}
          </Col>
          <Col className="d-flex justify-content-end col-6">
            {showReadOnlyView && formatInteger(currentClassificationValues.adjustmentModifier2.afterAdjustment)}
            {!showReadOnlyView &&
              <FormikNumberInput maxLength="15"
                                 formGroupClass="mb-0"
                                 disableFloatingLabel={true}
                                 ariaLabel="After Adjustment"
                                 onChange={() => setHasUnsavedChanges(true)}
                                 name={`classifications['${selectedTab}'].adjustmentModifier2.afterAdjustment`}
                                 className="text-right"/>
            }
          </Col>
        </Row>
        {showReadOnlyView && <hr/>}
        <Row className={!showReadOnlyView ? 'mb-4' : ''}>
          <Col className="col-6">
            5. Enter the assessed valuation before adjustment from the {year - 1} form L-4023 line {Number(selectedTab) + 3}
          </Col>
          <Col className="d-flex justify-content-end col-6">
            {showReadOnlyView && formatInteger(currentClassificationValues.adjustmentModifier2.beforeAdjustment)}
            {!showReadOnlyView && <FormikNumberInput maxLength="15"
                                                     formGroupClass="mb-0"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Before Adjustment"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`classifications['${selectedTab}'].adjustmentModifier2.beforeAdjustment`}
                                                     className="text-right"
            />}
          </Col>
        </Row>
        {showReadOnlyView && <hr/>}
        <Row>
          <Col className="col-6">
            6. {year - 2} to {year - 1} Adjustment Modifier. Divide line 4 by line 5.
          </Col>
          <Col className="d-flex justify-content-end col-6">
            <div>
              {formatAdjustmentModifier(totalsAdjustmentModifier2)}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card className="mb-4">
      <CardHeader className="bg-secondary text-white">{year - 3} to {year - 1} Adjustment Modifier</CardHeader>
      <CardBody>
        <Row>
          <Col className="col-6">
            7. {year - 3} to {year - 1} Adjustment Modifier. Multiply line 3 by line 6.
          </Col>
          <Col className="d-flex justify-content-end col-6">
            {formatAdjustmentModifier(totals.adjustmentModifiers[1])}
          </Col>
        </Row>
      </CardBody>
    </Card>
  </>;
};

export default Form4017AdjustmentModifierCards;