import {AmendmentRequest, CountyFormDto, forms} from '../types';
import {megApi} from './apiUtils';

export const findForms = (countyId: number | string,
                          year: number | string
): Promise<CountyFormDto[]> => megApi.getWithJsonResponse(
  `/counties/${countyId}/forms?year=${year}`
);

export const find = (countyId: number | string,
                     countyFormId: number | string
): Promise<CountyFormDto> => megApi.getWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}`
);

export const submit = (countyId: number | string,
                       countyFormId: number | string
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/submit`
);

export const submitAll = (countyId: number | string,
                          countyFormId: number | string
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/submit-all`
);

export const sendAllToReview = (countyId: number | string,
                                countyFormId: number | string
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/send-to-review`
);

export const amend = (countyId: number | string,
                      countyFormId: number | string,
                      amendmentRequest: AmendmentRequest
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/amend`,
  {body: JSON.stringify(amendmentRequest)}
);

export const reset = (countyId: number | string,
                      countyFormId: number | string
): Promise<CountyFormDto> => megApi.patchWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}/reset`
);

export const save = (countyId: number | string,
                     countyFormId: number | string,
                     countyFormData: forms.CountyFormData): Promise<CountyFormDto> => megApi.putWithJsonResponse(
  `/counties/${countyId}/forms/${countyFormId}`,
  {body: JSON.stringify(countyFormData)}
);