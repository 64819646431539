import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';

import {BreadcrumbsNav, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {FormCard, LocalUnitSelect, ProgramYearSelect} from '../components/shared';
import {filingCabinetApi, localUnitFormApi, megApi} from '../api';
import * as messages from '../messages';
import {CountyFormDto, FileDetails, LocalUnit, ProgramYear} from '../types';
import {FormStatus, FormType} from '../enum';
import {FilingCabinetCard} from '../components/filingCabinet';

const LocalUnitPortal = () => {
  const {localUnitId, year} = useParams<{localUnitId: string, year: string}>();
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnits, setLocalUnits] = useState<LocalUnit[]>([]);
  const [selectedLocalUnitId, setSelectedLocalUnitId] = useState<number | string>(0);
  const [selectedEqualizationYear, setSelectedEqualizationYear] = useState<number | string>(0);
  const [uploadDetails, setUploadDetails] = useState<FileDetails>({numberOfFiles: 0, dueOn: ''});
  const [forms, setForms] = useState<CountyFormDto[]>([]);
  const [equalizationYears, setEqualizationYears] = useState<ProgramYear[]>([]);

  useEffect(() => {
    const initialLoad = async () => {
      setLoadingState({loading: true, loadError: false});
      try {
        const [equalizationYears, localUnits] = await Promise.all([
          megApi.findEqualizationYears(),
          megApi.findLocalUnits()
        ]);
        setEqualizationYears(equalizationYears);
        setLocalUnits(localUnits);

        const selectedEqualizationYear = year ?
          year
          : equalizationYears.filter((equalizationYear: ProgramYear) => equalizationYear.defaultYear)[0].year;
        const selectedLocalUnitId = localUnitId ? localUnitId : localUnits[0].id;

        setSelectedEqualizationYear(selectedEqualizationYear);
        setSelectedLocalUnitId(selectedLocalUnitId);

        if (selectedEqualizationYear !== 0 && selectedLocalUnitId !== 0) {
          const [forms, fileUploadDetails] = await Promise.all([
            localUnitFormApi.findLocalUnitPortalForms(selectedLocalUnitId, selectedEqualizationYear),
            filingCabinetApi.findDetailsByLocalUnitIdAndYear(selectedLocalUnitId, selectedEqualizationYear)
          ]);
          setForms(forms);
          setUploadDetails(fileUploadDetails);
        }

        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void initialLoad();
  }, [
    localUnitId,
    year,
    showErrorAlert
  ]);

  const setSelectedYear = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    navigate(`/local-unit-portal/${selectedLocalUnitId}/${e.target.value}`);
  }, [
    navigate,
    selectedLocalUnitId
  ]);

  const setSelectedLocalUnit = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    navigate(`/local-unit-portal/${e.target.value}/${selectedEqualizationYear}`);
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const handleFormClick = useCallback((localUnitId: number, formId: number) => {
    navigate(`/local-unit-portal/${localUnitId}/${selectedEqualizationYear}/forms/${formId}`);
  }, [
    navigate,
    selectedEqualizationYear
  ]);

  const renderFormCards = useMemo(() => forms.map((form: CountyFormDto) => {
    const disabled = (form.formType !== FormType.FORM_4022_AV && form.formType !== FormType.FORM_4022_SA) ?
      form.status === FormStatus.NOT_STARTED : false;
    return <FormCard key={form.id}
                     onClick={handleFormClick}
                     disabled={disabled}
                     form={form}/>;
  }), [
    forms,
    handleFormClick
  ]);

  const handleCountyFilingCabinetClick = useCallback(() => {
    navigate(`/local-unit-portal/${selectedLocalUnitId}/${selectedEqualizationYear}/filing-cabinet`);
  }, [
    navigate,
    selectedEqualizationYear,
    selectedLocalUnitId
  ]);

  const showFilingCabinetCard = useMemo(() => {
    return uploadDetails.dueOn !== '';
  }, [
    uploadDetails
  ]);

  const initialValues = useMemo(() => ({
    localUnitId: selectedLocalUnitId,
    equalizationYear: selectedEqualizationYear
  }), [
    selectedLocalUnitId,
    selectedEqualizationYear
  ]);

  return <Container fluid className="LocalUnitPortal">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={[{text: 'Local Unit Dashboard', active: true}]}/>
      <Formik initialValues={initialValues}
              onSubmit={async () => null}
              enableReinitialize={true}>
        {(_) => <>
          <Row className="d-flex justify-content-between">
            <Col xs="12" sm="6" md="3" lg="2">
              <ProgramYearSelect years={equalizationYears}
                                 onChange={setSelectedYear}/>
            </Col>
            <Col xs="12" sm="6" md="3" lg="3">
              <LocalUnitSelect localUnits={localUnits}
                               onChange={setSelectedLocalUnit}/>
            </Col>
          </Row>
          <Row>
            {renderFormCards}
            {showFilingCabinetCard && <FilingCabinetCard onClick={handleCountyFilingCabinetClick}
                                                         isLocalUnitPortal={true}
                                                         details={uploadDetails}/>}
          </Row>
        </>}
      </Formik>
    </>}
  </Container>;
};

export default LocalUnitPortal;