import * as Yup from 'yup';

import * as messages from '../../messages';

const classificationSchema = Yup.object().shape({
  comments: Yup.string()
    .max(2000, messages.maxCharactersMessage(2000))
    .nullable(),
  studyType: Yup.string().nullable(),
  psdStudyType: Yup.string().nullable()
});

export default Yup.object().shape({
  commentsSectionFields: Yup.object().shape({
    100: classificationSchema,
    200: classificationSchema,
    300: classificationSchema,
    400: classificationSchema,
    500: classificationSchema,
    600: classificationSchema,
    personal: classificationSchema
  })
});