import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  FormikInput,
  FormikNumberInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {personalPropertyApi} from '../../api/mega';
import * as messages from '../../messages';
import {personalPropertyReportSchema} from '../../schemas/mega';
import {mega} from '../../types';

type PersonalPropertyQuestionProps = {
  questionText: string
  children: ReactNode
  className?: string
  questionResponseClassName?: string
}

const PersonalPropertyReport = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [personalPropertyReport, setPersonalPropertyReport] = useState<mega.PersonalPropertyReport>(undefined);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const selectOptions = useMemo(() => (['Yes', 'No', 'Unknown']), []);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: personalPropertyReport?.county?.displayNameWithType ?? '', active: true}
  ]), [
    year,
    countyId,
    personalPropertyReport
  ]);

  const handleSave = useCallback(async (values: mega.PersonalPropertyReportRequest) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await personalPropertyApi.update(countyId, year, values);
      const personalPropertyReport = await personalPropertyApi.find(countyId, year);
      setPersonalPropertyReport(personalPropertyReport);

      showSuccessAlert(messages.REPORT_SAVE_SUCCESSFUL);
      setHasUnsavedChanges(false);
    } catch (error) {
      showErrorAlert(messages.REPORT_SAVE_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    year,
    showErrorAlert,
    showSuccessAlert,
    loadingState
  ]);

  useEffect(() => {
    const loadPersonalPropertyReport = async () => {
      try {
        const personalPropertyReport = await personalPropertyApi.find(countyId, year);
        setPersonalPropertyReport(personalPropertyReport);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadPersonalPropertyReport();
  }, [countyId, year, showErrorAlert]);

  const calculateRows = useCallback((remarks: string | null) => {
    if (remarks && remarks.split('\n').length > 1) {
      return remarks.split('\n').length;
    } else {
      return 3;
    }
  }, []);

  const handlePdfClick = useCallback(async () => {
    setLoadingState({...loadingState, processing: true});
    try {
      await personalPropertyApi.openPdf(countyId, year, showErrorAlert);
    } catch {
      showErrorAlert(messages.VIEW_PDF_FAILURE, true);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    countyId,
    year,
    showErrorAlert,
    loadingState
  ]);

  const PersonalPropertyQuestion = useMemo(() => ({
                                                    questionText,
                                                    questionResponseClassName = 'col-2 mt-4 mb-4',
                                                    children,
                                                    className
                                                  }: PersonalPropertyQuestionProps) => {
    const rowClassName = `PersonalPropertyReportQuestion ${className}`;

    return <Row className={rowClassName}>
      <Col className="font-weight-bold text-primary col-12">
        {questionText}
      </Col>
      <Col className={questionResponseClassName}>
        {children}
      </Col>
    </Row>;
  }, []);

  return <Container fluid className="PersonalPropertyReport">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && personalPropertyReport && <>
      <Row className="mb-3">
        <Col md="6">
          <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Button color="primary"
                  disabled={!personalPropertyReport.pdf}
                  onClick={handlePdfClick}>
            View PDF
          </Button>
        </Col>
      </Row>
      <Formik initialValues={personalPropertyReport}
              enableReinitialize={true}
              onSubmit={async (values, _) => handleSave(values)}
              validateOnMount={true}
              validationSchema={personalPropertyReportSchema}>
        {(formikProps) => <>
          <Card className="mb-4">
            <CardHeader>
              Personal Property Report
            </CardHeader>
            <div style={{
              marginLeft: '15px',
              marginRight: '15px'
            }}>
              <PersonalPropertyQuestion questionText="1. The total number of assessment units having personal property."
                                        questionResponseClassName="d-flex col-2 mt-4">
                <FormikNumberInput formGroupClass="max-w-125 mr-2"
                                   name="numberOfCities"
                                   aria-required={true}
                                   disableFloatingLabel
                                   labelText="Cities"
                                   maxLength="2"
                                   onChange={() => setHasUnsavedChanges(true)}/>
                <FormikNumberInput formGroupClass="max-w-125"
                                   name="numberOfTownships"
                                   aria-required={true}
                                   disableFloatingLabel
                                   labelText="Townships"
                                   maxLength="2"
                                   onChange={() => setHasUnsavedChanges(true)}/>
              </PersonalPropertyQuestion>

              <PersonalPropertyQuestion questionText="2. The number of units the county equalization department made equalization studies or conducted audits or records verification.">
                <FormikNumberInput formGroupClass="max-w-125 mb-0"
                                   name="numberOfAuditedOrVerifiedUnits"
                                   aria-required={true}
                                   labelText="Units"
                                   disableFloatingLabel
                                   maxLength="2"
                                   onChange={() => setHasUnsavedChanges(true)}/>
              </PersonalPropertyQuestion>

              <PersonalPropertyQuestion questionText="3. Are the requirements of the Michigan Assessors Manual (such as discovery, statements, employment of appropriate depreciation schedules) recognized by the C.E.D. in the performance of audits, records verification, or studies?">
                <FormikSelect formGroupClass="mb-0"
                              onChange={() => setHasUnsavedChanges(true)}
                              ariaLabel="Requirements Recognized"
                              name="requirementsRecognized">
                  <option value={''}>Select</option>
                  {selectOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </FormikSelect>
              </PersonalPropertyQuestion>
              <PersonalPropertyQuestion questionText="4. Does the C.E.D. receive copies of all personal property statements from the assessing officers in the county?">
                <FormikSelect formGroupClass="mb-0"
                              onChange={() => setHasUnsavedChanges(true)}
                              ariaLabel="CED Received Statements"
                              name="cedReceivedStatements">
                  <option value="">Select</option>
                  {selectOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </FormikSelect>
              </PersonalPropertyQuestion>
              <PersonalPropertyQuestion questionText="5. Does the C.E.D. request personal property statements independently of the assessing officers within the county?">
                <FormikSelect formGroupClass="mb-0"
                              onChange={() => setHasUnsavedChanges(true)}
                              ariaLabel="CED Requested Statements"
                              name="cedRequestedStatements">
                  <option value={''}>Select</option>
                  {selectOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </FormikSelect>
              </PersonalPropertyQuestion>
              <PersonalPropertyQuestion questionText="6. Does the equalization study made by the C.E.D. result in a realistic estimate of the total county wide TCV of personal property?">
                <FormikSelect formGroupClass="mb-0"
                              onChange={() => setHasUnsavedChanges(true)}
                              ariaLabel="Equalization Study Realistic"
                              name="equalizationStudyRealistic">
                  <option value="">Select</option>
                  {selectOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </FormikSelect>
              </PersonalPropertyQuestion>

              <PersonalPropertyQuestion questionText="7. Does it appear that the assessing officers and the equalization department have established an adequate personal property program to discover and assess all tangible personal property
                  in the county?">
                <FormikSelect formGroupClass="mb-0"
                              onChange={() => setHasUnsavedChanges(true)}
                              ariaLabel="Established Adequate Program"
                              name="establishedAdequateProgram">
                  <option value="">Select</option>
                  {selectOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </FormikSelect>
              </PersonalPropertyQuestion>
              <PersonalPropertyQuestion questionText="Remarks"
                                        questionResponseClassName="mt-4">
                <FormikInput name="remarks"
                             placeholder="Remarks"
                             ariaLabel="Remarks"
                             type="textarea"
                             rows={calculateRows(formikProps.values?.remarks)}
                             maxLength="500"
                             onChange={() => setHasUnsavedChanges(true)}/>
              </PersonalPropertyQuestion>
            </div>
          </Card>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button color="success"
                      disabled={loadingState.processing || !hasUnsavedChanges || !formikProps.isValid}
                      onClick={formikProps.submitForm}>
                Save
              </Button>
            </Col>
          </Row>
        </>}
      </Formik>
    </>}
  </Container>;
};

export default PersonalPropertyReport;