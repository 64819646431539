import {memo} from 'react';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

type Props = {
  isOpen: boolean
  locked: boolean
  formName: string
  formDescription: string
  onConfirm: () => void
  onCancel: () => void
  localityDisplayName: string
}

const LockModal = ({
                     isOpen,
                     locked,
                     onCancel,
                     onConfirm,
                     formName,
                     formDescription,
                     localityDisplayName
                   }: Props) => {
  return <ConfirmationModal isOpen={isOpen}
                            size="lg"
                            title={locked ? 'Confirm Unlocking Form' : 'Confirm Locking Form'}
                            confirmButtonText="Yes"
                            cancelButtonText="No"
                            confirmCallback={() => onConfirm()}
                            cancelCallback={() => onCancel()}>
    <p>
      Are you sure you want to {locked ? 'unlock' : 'lock'} Form {formName} {formDescription} for <span className="text-danger">{localityDisplayName}</span>?
    </p>
  </ConfirmationModal>;
};

export default memo(LockModal);
