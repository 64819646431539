import {memo, ReactNode, useMemo} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';

type Props = {
  description: string
  title: string
  onClick: () => void
  disabled?: boolean
  buttonText?: string
  renderBadge?: ReactNode
}

const MegaCard = ({
                    description,
                    onClick,
                    title,
                    disabled,
                    renderBadge,
                    buttonText = 'Open'
                  }: Props) => {
  const style = useMemo(() => {
    return disabled ? {opacity: 0.7} : {cursor: 'pointer'};
  }, [disabled]);

  return <Card title={title}
               aria-label={title}
               role="region"
               className="h-100"
               style={style}>
    <CardHeader>{title}</CardHeader>
    <CardBody>
      <Row>
        <Col>
          {description}
        </Col>
      </Row>
    </CardBody>
    <CardFooter>
      <Row>
        {renderBadge && <Col md="6" className="mt-1">
          {renderBadge}
        </Col>}
        <Col md={renderBadge ? 6 : 12} className="d-flex justify-content-end">
          <Button color="primary"
                  disabled={disabled}
                  aria-label={`${title} ${buttonText} Button`}
                  onClick={onClick}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    </CardFooter>
  </Card>;
};

export default memo(MegaCard);