import {memo} from 'react';
import {Button, Col, Row} from 'reactstrap';

type Props = {
  loading: boolean
  onReturnClick: () => void
  onAcceptClick: () => void
}

const StateFormButtons = ({
                            loading,
                            onReturnClick,
                            onAcceptClick
                          }: Props) => {
  return <Row>
    <Col className="d-flex justify-content-end">
      <Button className="mr-2"
              color="danger"
              onClick={onReturnClick}
              disabled={loading}>
        Return
      </Button>
      <Button color="primary"
              onClick={onAcceptClick}
              disabled={loading}>
        Accept
      </Button>
    </Col>
  </Row>;
};

export default memo(StateFormButtons);