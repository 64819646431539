import ReturnModal from './ReturnModal';
import AcceptModal from './AcceptModal';

import {CountyFormDto, LocalUnitFormDto, ReturnRequest} from '../../types';

type Props = {
  form: LocalUnitFormDto | CountyFormDto
  acceptModalIsOpen: boolean
  returnModalIsOpen: boolean
  onReturn: (returnRequest: ReturnRequest) => void
  onAccept: () => void
  onReturnCancel: () => void
  onAcceptCancel: () => void
}

const StateFormModals = ({
                           form,
                           acceptModalIsOpen,
                           returnModalIsOpen,
                           onReturn,
                           onAccept,
                           onReturnCancel,
                           onAcceptCancel
                         }: Props) => {
  return <>
    <ReturnModal formName={form.name}
                 formDescription={form.description}
                 localityDisplayName={form.localUnitDisplayName || form.countyDisplayName}
                 isOpen={returnModalIsOpen}
                 onReturn={(returnRequest: ReturnRequest) => onReturn(returnRequest)}
                 onCancel={onReturnCancel}/>
    <AcceptModal formName={form.name}
                 formDescription={form.description}
                 localityDisplayName={form.localUnitDisplayName || form.countyDisplayName}
                 isOpen={acceptModalIsOpen}
                 onConfirm={onAccept}
                 onCancel={onAcceptCancel}/>
  </>;
};

export default StateFormModals;