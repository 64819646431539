import {memo, useMemo} from 'react';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

import {getPropertyClassificationDisplayFromFilter} from '../../utils';

type Props = {
  isOpen: boolean
  onToggle: (confirmNoProperties?: boolean) => void
  selectedClassificationFilter: string
  title?: string
  bodyTextEnding?: string
}

const NoPropertiesModal = ({
                             isOpen,
                             onToggle,
                             selectedClassificationFilter,
                             title = 'Confirm No Parcels',
                             bodyTextEnding = 'class'
                           }: Props) => {
  const propertyClassName = useMemo(() => {
    return selectedClassificationFilter === 'personalProperty' ? 'Personal Property' :
      getPropertyClassificationDisplayFromFilter(Number(selectedClassificationFilter));
  }, [
    selectedClassificationFilter
  ]);

  return <ConfirmationModal isOpen={isOpen}
                            size="lg"
                            title={title}
                            aria-modal={true}
                            confirmButtonColor="primary"
                            confirmButtonText="Yes"
                            cancelButtonText="No"
                            confirmCallback={() => onToggle(true)}
                            cancelCallback={() => onToggle()}>
    <p>
      Are you sure there are no parcels for the <span className="text-danger">{propertyClassName}</span> {bodyTextEnding}?
    </p>
  </ConfirmationModal>;
};

export default memo(NoPropertiesModal);