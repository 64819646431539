import {ChangeEvent, useCallback, useMemo} from 'react';
import {Table} from 'reactstrap';
import {FormikProps} from 'formik';

import {FormikNumberInput, FormikSelect} from '@reasoncorp/kyber-js';

import {formatDecimal, formatInteger, isNumber, STUDY_TYPES, STUDY_TYPES_PERSONAL_PROPERTY} from '../../../utils';
import {Form4027iLocalUnitDto} from '../../../types/forms';
import {LocalUnit} from '../../../types';
import * as form4027iUtils from '../../../utils/form4027iUtils';
import {Form4027iClassificationKey} from '../../../types/forms/form4027i';

const PERSONAL_PROPERTY = 'personalProperty';

const formatStudyTypeValue = (studyTypes: {[studyType: string]: string}, value: string) => {
  return value ? `${value} - ${studyTypes[value]}` : null;
};

type Props = {
  filteredLocalUnits: LocalUnit[]
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  showReadOnlyView: boolean
  selectedTab: string
  handleStudyTypeChange: (formikProps: FormikProps<any>, index: number) => (e: ChangeEvent<HTMLInputElement>) => void
  formikProps: FormikProps<any>
  formType: string
}

const Form4027iLocalUnitsForm = ({
                                   filteredLocalUnits,
                                   setHasUnsavedChanges,
                                   showReadOnlyView,
                                   selectedTab,
                                   handleStudyTypeChange,
                                   formikProps,
                                   formType
                                 }: Props) => {
  const form4027iLocalUnits = useMemo(() => {
    return selectedTab ? formikProps.values.classifications[selectedTab as Form4027iClassificationKey]?.localUnits : [];
  }, [
    formikProps,
    selectedTab
  ]);

  const studyTypes = useMemo(() => {
    return selectedTab === PERSONAL_PROPERTY ? STUDY_TYPES_PERSONAL_PROPERTY : STUDY_TYPES;
  }, [
    selectedTab
  ]);

  const classificationCalculations = useMemo(() => {
    return form4027iUtils.getClassificationCalculations(formType, formikProps, selectedTab);
  }, [
    formType,
    formikProps,
    selectedTab
  ]);

  const renderStudyTypeOption = useMemo(() => (studyType: string) => {
    return <option key={studyType}
                   value={studyType}>
      {studyType} - {studyTypes[studyType]}
    </option>;
  }, [
    studyTypes
  ]);

  const getPercentStudied = useCallback((localUnitValues: Form4027iLocalUnitDto) => {
    if (isNumber(localUnitValues.numberOfParcelsInClass) &&
      isNumber(localUnitValues.numberOfParcelsInStudy) &&
      localUnitValues.numberOfParcelsInClass !== null &&
      localUnitValues.numberOfParcelsInStudy !== null &&
      localUnitValues.numberOfParcelsInClass !== 0) {
      return Number(localUnitValues.numberOfParcelsInStudy) / Number(localUnitValues.numberOfParcelsInClass);
    } else {
      return 0;
    }
  }, []);

  const numberOfUnitsOfClass = useMemo(() => {
    return form4027iLocalUnits?.filter((localUnit: Form4027iLocalUnitDto) => {
      return !['', undefined, null, 'NC'].includes(localUnit.studyType);
    })?.length;
  }, [
    form4027iLocalUnits
  ]);

  const totalPercentStudied = useMemo(() => {
    return isNumber(classificationCalculations.totals.percentStudied) ?
      classificationCalculations.totals.percentStudied : 0;
  }, [
    classificationCalculations
  ]);

  return <>
    <Table bordered responsive key="local-units-table">
      <thead>
        <tr>
          <th className="text-nowrap text-primary">Local Unit</th>
          <th className="text-nowrap text-primary text-center">Study Type</th>
          <th className="text-nowrap text-primary text-center">Number of Parcels in Study</th>
          <th className="text-nowrap text-primary text-center">Number of Parcels in Class</th>
          <th className="text-nowrap text-primary text-center">Percent Studied</th>
        </tr>
      </thead>
      <tbody>
        {filteredLocalUnits.map((localUnit, index) => {
          let localUnitValues = form4027iLocalUnits?.[index] ?? undefined;
          if (!localUnitValues) {
            return null;
          } else {
            localUnitValues.studyType = localUnitValues.studyType || '';
            localUnitValues.numberOfParcelsInClass = localUnitValues.numberOfParcelsInClass as number || null;
            localUnitValues.numberOfParcelsInStudy = localUnitValues.numberOfParcelsInStudy as number || null;

            return (
              <tr key={`local-unit-${index}`}>
                <td className="align-middle text-nowrap text-primary font-weight-bold">
                  {localUnit.displayNameWithType}
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatStudyTypeValue(studyTypes, localUnitValues.studyType)}
                  {!showReadOnlyView && <FormikSelect formGroupClass="mb-0"
                                                      ariaLabel="Study Type"
                                                      onChange={handleStudyTypeChange(formikProps, index)}
                                                      name={`classifications['${selectedTab}'].localUnits[${index}].studyType`}>
                    <option value="">Select</option>
                    {Object.keys(studyTypes).map(renderStudyTypeOption)}
                  </FormikSelect>}
                  &nbsp;
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatInteger(localUnitValues?.numberOfParcelsInStudy ?? 0)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           bsSize="sm"
                                                           maxLength="9"
                                                           disableFloatingLabel={true}
                                                           ariaLabel="Number of Parcels in Study"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           className="text-center"
                                                           aria-required={!['NC', 'NS'].includes(localUnitValues.studyType)}
                                                           disabled={'NC' === localUnitValues.studyType}
                                                           name={`classifications['${selectedTab}'].localUnits[${index}].numberOfParcelsInStudy`}/>
                  }
                </td>
                <td className="align-middle text-center">
                  {formatInteger(localUnitValues?.numberOfParcelsInClass ?? 0)}
                </td>
                <td className="align-middle text-center">
                  {formatDecimal(getPercentStudied(localUnitValues), 2, true)}
                </td>
              </tr>);
          }
        })}
      </tbody>
      <tfoot>
        <tr className="bg-light font-weight-bold">
          <td className="text-nowrap text-primary">Totals</td>
          <td className="text-nowrap text-center">
            {numberOfUnitsOfClass} Units of Class
          </td>
          <td className="text-nowrap text-center">
            {formatInteger(classificationCalculations.totals.numberOfParcelsInStudy)}
            &nbsp;Parcels in Study
          </td>
          <td className="text-nowrap text-center">
            {formatInteger(classificationCalculations.totals.numberOfParcelsInClass)}
            &nbsp;Parcels in Class
          </td>
          <td className="text-nowrap text-center">
            {formatDecimal(totalPercentStudied, 2, true)} Studied
          </td>
        </tr>
      </tfoot>
    </Table>
  </>;
};

export default Form4027iLocalUnitsForm;