import {memo, useMemo} from 'react';
import {Card, CardHeader} from 'reactstrap';

import {CustomTable} from '@reasoncorp/kyber-js';

import {formatDate} from '../../utils';
import {Report4030HistoryDto} from '../../types/mega';

type Props = {
  items: Report4030HistoryDto[]
}

const Report4030HistoryTable = ({
                                  items
                                }: Props) => {
  const renderRow = useMemo(() => ({
                                     createdAt,
                                     actionDisplayValue,
                                     userName,
                                     comment
                                   }: Report4030HistoryDto) => {
    return (
      <tr key={`report-4030-${createdAt}`}>
        <td className="align-middle text-center">{formatDate(createdAt)}</td>
        <td className="align-middle text-nowrap">{actionDisplayValue}</td>
        <td className="align-middle text-nowrap">{userName}</td>
        <td className="align-middle">{comment}</td>
      </tr>
    );
  }, []);

  const tableProps = useMemo(() => ({
    headers: [{
      title: 'Date',
      className: 'text-center align-middle',
      sortKey: 'createdAt'
    }, {
      title: 'Type',
      className: 'align-middle'
    }, {
      title: 'User',
      className: 'align-middle'
    }, {
      title: 'Comment',
      className: 'align-middle'
    }],
    items,
    renderRow,
    className: 'mb-0',
    headerRowClassName: 'text-primary',
    initialSort: {
      sortKey: 'createdAt',
      direction: 'desc' as const
    }
  }), [
    items,
    renderRow
  ]);

  if (items.length === 0) {
    return null;
  } else {
    return <Card className="mt-4">
      <CardHeader className="bg-secondary text-white">
        4030 History
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>;
  }
};

export default memo(Report4030HistoryTable);