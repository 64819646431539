import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {
  Button,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {FormikCheckboxGroup, FormikFileInput, StatefulButton} from '@reasoncorp/kyber-js';

import {countyImportSchema, localUnitImportSchema} from '../../schemas';
import {readFileContent} from '../../utils';
import {
  CountyFormDto,
  JsonImportFileContent,
  JsonImportFormFields,
  JsonImportMappingKey,
  JsonImportUploadRequest,
  LocalUnitFormDto
} from '../../types';

type Props = {
  isOpen: boolean
  onSubmit: (jsonUploadRequest: JsonImportUploadRequest, actions: FormikHelpers<any>) => void
  onToggle: () => void
  importWillOverwriteData: boolean
  form: LocalUnitFormDto | CountyFormDto
  errorMessages: string[]
  isCountyImport?: boolean
  onMappingKeyToggle?: (mappingKey: string) => void
  hasErrors?: boolean // Used to display error messages for the 4018; otherwise a generic message is shown
}

const JsonImportModal = ({
                           isOpen,
                           onSubmit,
                           onToggle,
                           importWillOverwriteData,
                           form,
                           errorMessages = [],
                           onMappingKeyToggle = () => {
                           },
                           isCountyImport = false,
                           hasErrors
                         }: Props) => {
  const [fileContent, setFileContent] = useState<JsonImportFileContent>(null);
  const shouldDisplayMappingKeys = useMemo(() => [
    'FORM_4014A',
    'FORM_4015',
    'FORM_4015A',
    'FORM_4017_4047',
    'FORM_4018_P_R'
  ].includes(form?.formType ?? ''), [
    form?.formType
  ]);

  const availableMappingKeys = useMemo(() => form?.formType === 'FORM_4018_P_R' ? ({
    'ALL': 'All',
    'REAL': 'Real',
    'PERSONAL': 'Personal'
  }) : ({
    'ALL': 'All',
    'AGRICULTURAL': 'Agricultural',
    'COMMERCIAL': 'Commercial',
    'INDUSTRIAL': 'Industrial',
    'RESIDENTIAL': 'Residential',
    'TIMBER_CUTOVER': 'Timber-Cutover',
    'DEVELOPMENTAL': 'Developmental'
  }), [form]);

  const handleSubmit = useCallback(({
                                      jsonFile,
                                      mappingKeys
                                    }: JsonImportFormFields,
                                    actions: FormikHelpers<any>) => {
    const jsonUploadRequest: JsonImportUploadRequest = {
      jsonFile,
      mappingKeys: mappingKeys
        .filter(mappingKey => mappingKey.checked && mappingKey.name !== 'ALL')
        .map(mappingKey => mappingKey.name)
    };

    onSubmit(jsonUploadRequest, actions);
    actions.resetForm();
  }, [
    onSubmit
  ]);

  const handleToggle = useCallback((formikProps: FormikProps<JsonImportFormFields>) => {
    onToggle();
    formikProps.resetForm();
  }, [onToggle]);

  const initialMappingKeys = useMemo(() => Object.keys(availableMappingKeys)
    .map((mappingKey, index) => ({
      name: mappingKey,
      checked: mappingKey === 'ALL',
      sortOrder: index
    })), [availableMappingKeys]);

  const initialValues: JsonImportFormFields = useMemo(() => ({
    jsonFile: null,
    mappingKeys: initialMappingKeys
  }), [initialMappingKeys]);

  const handleFileChange = useCallback((formikProps: FormikProps<JsonImportFormFields>) => async (e: ChangeEvent) => {
    const file = (e.target as HTMLInputElement)?.files?.[0];
    if (file) {
      readFileContent(file, setFileContent, formikProps);
    }
    await formikProps.setFieldValue('jsonFile', file);
  }, []);

  const renderErrorMessage = useCallback((msg: string, i: number) => <ListGroupItem key={i}>
    <Row className="align-items-center w-100">
      <Col xs="1">
        <FontAwesomeIcon icon="exclamation-circle"
                         className="text-danger"
                         size="lg"/>
      </Col>
      <Col className="pl-lg-0" xs="11">
        {msg}
      </Col>
    </Row>
  </ListGroupItem>, []);

  const handleMappingKeyToggle = useCallback((formikProps: FormikProps<JsonImportFormFields>,
                                              mappingKey: string) => {
    onMappingKeyToggle(mappingKey);
    const mappingKeys = formikProps.values.mappingKeys;
    const updatedKeyValue = !mappingKeys.filter((matchedKey: JsonImportMappingKey) => matchedKey.name === mappingKey)[0].checked;
    const keySortOrder = initialMappingKeys.filter((matchedKey: JsonImportMappingKey) => matchedKey.name === mappingKey)[0].sortOrder;
    const numberOfCheckedKeys = mappingKeys.filter((mappingKey: JsonImportMappingKey) => mappingKey.checked && mappingKey.name !== 'ALL').length +
      (updatedKeyValue ? 1 : -1);
    const allSelected = numberOfCheckedKeys === 0 ||
      numberOfCheckedKeys === Object.keys(availableMappingKeys).length - 1;

    // If the user selects ALL or the user checks all the mapping keys,
    // then we need to reset to the initial state with ALL selected
    if (mappingKey === 'ALL' || (mappingKey !== 'ALL' && allSelected)) {
      void formikProps.setFieldValue('mappingKeys', initialMappingKeys);
    } else {
      const updatedMappingKeysWithAll = [
        {name: 'ALL', checked: allSelected, sortOrder: 0},
        ...mappingKeys.filter((mappingKey2: JsonImportMappingKey) => mappingKey2.name !== mappingKey && mappingKey2.name !== 'ALL'),
        {name: mappingKey, checked: updatedKeyValue, sortOrder: keySortOrder}
      ].sort((a, b) => a.sortOrder - b.sortOrder);

      void formikProps.setFieldValue('mappingKeys', updatedMappingKeysWithAll);
    }
  }, [
    availableMappingKeys,
    initialMappingKeys,
    onMappingKeyToggle
  ]);

  const renderMappingKeyRow = useMemo(() => (mappingKey: string,
                                             index: number,
                                             formikProps: FormikProps<JsonImportFormFields>) => {
    const keyDisplayValue = availableMappingKeys[mappingKey as keyof typeof availableMappingKeys];

    return <tr>
      <td className="text-center align-middle w-5">
        <FormikCheckboxGroup formGroupClass="mb-0"
                             checkboxes={[{
                               autoFocus: shouldDisplayMappingKeys && mappingKey === 'ALL',
                               name: `mappingKeys['${index}'].checked`,
                               labelText: '',
                               ariaLabel: `Toggle select ${mappingKey}: ${keyDisplayValue}`,
                               onChange: () => handleMappingKeyToggle(formikProps, mappingKey)
                             }]}/>
      </td>
      <td className="w-95 font-weight-bold">
        {availableMappingKeys[mappingKey as keyof typeof availableMappingKeys]}
      </td>
    </tr>;
  }, [
    handleMappingKeyToggle,
    availableMappingKeys,
    shouldDisplayMappingKeys
  ]);

  const localityDisplayName = useMemo(() => {
    return form?.localUnitDisplayName || form?.countyDisplayName || '';
  }, [
    form
  ]);

  const validationSchema = useMemo(() => isCountyImport ?
      countyImportSchema(form as CountyFormDto, fileContent) :
      localUnitImportSchema(form as LocalUnitFormDto, fileContent),
    [form, fileContent, isCountyImport]
  );

  return <Formik initialValues={initialValues}
                 validateOnMount={true}
                 enableReinitialize={true}
                 validationSchema={validationSchema}
                 onSubmit={handleSubmit}>
    {(formikProps) => (
      <Modal scrollable
             isOpen={isOpen}
             aria-modal={true}
             toggle={() => handleToggle(formikProps)}
             size="lg"
             returnFocusAfterClose={true}
             autoFocus={false}>
        <ModalHeader toggle={() => handleToggle(formikProps)} className="h5" tag="h2">
          Import Data
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={formikProps.handleSubmit}>
            {importWillOverwriteData && <Row className="mb-3">
              <Col>
                  <span className="text-danger">
                    Uploading file will clear all previous entered data within {localityDisplayName}.
                  </span>
              </Col>
            </Row>}
            {shouldDisplayMappingKeys && <Row className="mb-3">
              <Col>
                <Table striped responsive bordered>
                  <thead className="font-weight-bold text-primary">
                    <tr>
                      <th colSpan={3}>
                        Select Classifications to Import
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(availableMappingKeys).map((mappingKey, index) => renderMappingKeyRow(mappingKey, index, formikProps))}
                  </tbody>
                </Table>
              </Col>
            </Row>}
            <Row>
              <Col>
                <FormikFileInput name="jsonFile"
                                 labelText="Upload a file"
                                 autoFocus={!shouldDisplayMappingKeys}
                                 onChange={handleFileChange(formikProps)}/>
              </Col>
            </Row>
            {errorMessages.length > 0 && <ListGroup>
              {errorMessages.map(renderErrorMessage)}
            </ListGroup>}
            {hasErrors && <ListGroup flush>
              <ListGroupItem key={0}>
                <Row className="align-items-center w-100">
                  <Col xs="1">
                    <FontAwesomeIcon icon="exclamation-circle"
                                     className="text-danger"
                                     size="lg"/>
                  </Col>
                  <Col className="pl-0" xs="11">
                    L-4018 1 or more starting assessed values do not match prior year’s ending value.
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>}
          </Form>
        </ModalBody>
        <ModalFooter>
          {isCountyImport && <StatefulButton color="success"
                                             className="mr-1"
                                             disabled={!formikProps.isValid || !formikProps.dirty || formikProps.isSubmitting}
                                             onClick={formikProps.submitForm}
                                             aria-label="Import button"
                                             activeState={formikProps.isSubmitting ? 'importing' : 'default'}
                                             states={[
                                               {name: 'importing', text: 'Importing', icon: 'spinner', spinIcon: true},
                                               {name: 'default', text: 'Import'}
                                             ]}/>}
          {!isCountyImport && <Button color="primary"
                                      className="mr-1"
                                      disabled={!formikProps.isValid || !formikProps.dirty}
                                      onClick={formikProps.submitForm}>
            Import
          </Button>}
          <Button color="secondary"
                  onClick={() => handleToggle(formikProps)}
                  disabled={formikProps.isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )}
  </Formik>;
};

export default JsonImportModal;