import {useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Container} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  CustomTable,
  ProgressIndicator,
  TabNav,
  useAlerts,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {megApi} from '../../api';
import {modifiersApi} from '../../api/mega';
import * as messages from '../../messages';
import {formatDecimal, formatInteger, PROPERTY_CLASSIFICATION_TABS} from '../../utils';
import {County, mega} from '../../types';

const Modifiers = () => {
  const {countyId, year} = useParams() as {countyId: string, year: string};
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [county, setCounty] = useState({displayName: ''});
  const {selectedTab} = useTabNav();
  const {showErrorAlert} = useAlerts();
  const [modifiersReport, setModifiersReport] = useState<mega.ModifiersReport>(undefined);

  const breadcrumbs = useMemo(() => ([
    {
      text: 'Analytics Dashboard',
      active: false,
      icon: 'home' as const,
      route: `/state-portal/analytics/${year}/${countyId}`
    },
    {text: county.displayName, active: true}
  ]), [
    year,
    county,
    countyId
  ]);

  useEffect(() => {
    const loadModifiersReport = async () => {
      try {
        const [counties, modifiers] = await Promise.all([
          megApi.findCounties(true),
          modifiersApi.find(countyId, year)
        ]);
        const currentCounty = counties.filter((county: County) => county.id === Number(countyId))[0];
        if (currentCounty === undefined) {
          navigate('/state-portal/analytics');
        }
        setCounty(currentCounty);
        setModifiersReport(modifiers);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE, true);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadModifiersReport();
  }, [countyId, showErrorAlert, year, navigate]);

  const tableProps = useMemo(() => ({
    items: (modifiersReport?.[selectedTab as string]?.localUnits ?? []),
    renderFooter: () => {
      const totals = modifiersReport?.[selectedTab as string]?.totals ?? null;
      return totals && <tr key="totals" className="bg-light text-primary font-weight-bold">
        <td className="text-center align-middle bg-light">Totals</td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.unitsWithParcelInClass)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.numberOfParcels)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearMinusOneLine03)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearMinusOneLine05)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatDecimal(totals.currentYearMinusTwoToCurrentYearMinusOneAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearLine03)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearLine05)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatDecimal(totals.currentYearMinusOneToCurrentYearAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle  bg-light">
          {formatDecimal(totals.currentYearMinusTwoToCurrentYearAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearAssessedValue)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatInteger(totals.currentYearTrueCashValue)}
        </td>
        <td className="text-center align-middle bg-light">
          {formatDecimal(totals.currentYearEqualizationFactor, 5)}
        </td>
      </tr>;
    },
    headers: [
      {title: 'STC #', className: 'align-middle text-primary text-center bg-light'},
      {title: 'Unit Name', className: 'align-middle text-primary text-left bg-light'},
      {title: '# of Parcels', className: 'align-middle text-primary text-center bg-light'},
      {title: `${Number(year) - 1} Line 03`, className: 'align-middle text-primary text-center bg-light'},
      {title: `${Number(year) - 1} Line 05`, className: 'align-middle text-primary text-center bg-light'},
      {
        title: `${Number(year) - 2} to ${Number(year) - 1} Adjustment Modifier`,
        className: 'align-middle text-primary text-center bg-light'
      },
      {title: `${year} Line 03`, className: 'align-middle text-primary text-center bg-light'},
      {title: `${year} Line 05`, className: 'align-middle text-primary text-center bg-light'},
      {
        title: `${Number(year) - 1} to ${year} Adjustment Modifier`,
        className: 'align-middle text-primary text-center bg-light'
      },
      {
        title: `${Number(year) - 2} to ${year} Adjustment Modifier`,
        className: 'align-middle text-primary text-center bg-light'
      },
      {title: `${year} Assessed Value`, className: 'align-middle text-primary text-center bg-light'},
      {title: `${year} True Cash Value`, className: 'align-middle text-primary text-center bg-light'},
      {title: `${year} Equalization Factor`, className: 'align-middle text-primary text-center bg-light'}
    ],
    renderRow: (item: mega.ModifiersRow) => {
      return <tr key={item.localUnitId}>
        <td className="text-center align-middle">
          {item.localUnitId}
        </td>
        <td className="text-left align-middle text-nowrap">
          {item.localUnitName}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.numberOfParcels)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearMinusOneLine03)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearMinusOneLine05)}
        </td>
        <td className="text-center align-middle">
          {formatDecimal(item.currentYearMinusTwoToCurrentYearMinusOneAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearLine03)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearLine05)}
        </td>
        <td className="text-center align-middle">
          {formatDecimal(item.currentYearMinusOneToCurrentYearAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle">
          {formatDecimal(item.currentYearMinusTwoToCurrentYearAdjustmentModifier, 4)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearAssessedValue)}
        </td>
        <td className="text-center align-middle">
          {formatInteger(item.currentYearTrueCashValue)}
        </td>
        <td className="text-center align-middle">
          {formatDecimal(item.currentYearEqualizationFactor, 5)}
        </td>
      </tr>;
    }
  }), [
    year,
    modifiersReport,
    selectedTab
  ]);

  return <Container fluid className="Modifiers">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>County Adjustment Modifiers and Equalization Factors for PDF Sales Studies for Projected TCV</CardHeader>
        <CardHeader className="nav-tabs-header">
          <TabNav/>
        </CardHeader>
        <div className="table-fixed">
          <CustomTable {...tableProps}/>
        </div>
      </Card>
    </>}
  </Container>;
};

export default withTabNav(Modifiers, {tabs: PROPERTY_CLASSIFICATION_TABS});