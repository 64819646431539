import {memo, ReactNode} from 'react';
import {Table} from 'reactstrap';

type Props = {
  className?: string
  headerText: string,
  children: ReactNode
}

const LocalUnitReport4017Table = ({
                                    className = '',
                                    headerText,
                                    children
                                  }: Props) => {
  return <div className={className}>
    <Table bordered responsive>
      <thead className="bg-light">
        <tr>
          <th colSpan={9} className="text-primary">
            {headerText}
          </th>
        </tr>
        <tr className="bg-light">
          <th className="text-center align-middle text-primary">
            A. Year of Assessment
          </th>
          <th className="text-center align-middle text-primary">
            B. Sales Period Date
          </th>
          <th className="text-center align-middle text-primary">
            B1. Sales Period #
          </th>
          <th className="text-center align-middle text-primary">
            C. Total # of Sales
          </th>
          <th className="text-center align-middle text-primary">
            D. Total Assessed Value for Sales
          </th>
          <th className="text-center align-middle text-primary">
            E. Applicable Adjustment Modifier
          </th>
          <th className="text-center align-middle text-primary">
            F. Adjusted Assessed Value
          </th>
          <th className="text-center align-middle text-primary">
            G. Total Adjusted Sales Prices
          </th>
          <th className="text-center align-middle text-primary">
            H. Adjusted % Ratio
          </th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </Table>
  </div>;
};

export default memo(LocalUnitReport4017Table);