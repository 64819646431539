import {Col, Row, Table} from 'reactstrap';

import {FormikInput, FormikNumberInput, FormikPercentageInput, FormikSelect} from '@reasoncorp/kyber-js';

import {forms} from '../../../types';
import {formatDecimal, formatInteger, STUDY_TYPES_REAL_4018} from '../../../utils';
import * as form4018V2Utils from '../../../utils/form4018V2Utils';

type Props = {
  form4018V2Dto: forms.Form4018V2Dto
  showReadOnlyView: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
}

const Form4018V2RealPropertyTab = ({
                                     form4018V2Dto,
                                     showReadOnlyView,
                                     setHasUnsavedChanges
                                   }: Props) => {
  const totals = form4018V2Utils.calculateTotals(form4018V2Dto);

  return <>
    <div className="mb-4">
      <Table responsive bordered>
        <thead>
          <tr>
            <th colSpan={3} className="text-primary text-center align-middle">Assessment Roll Classification</th>
            <th colSpan={4} className="text-primary text-center align-middle border-left-3">Sample</th>
            <th colSpan={1} className="border-left-3"/>
          </tr>
          <tr>
            <th className="text-primary text-center align-middle">Class of Real Property</th>
            <th className="text-primary text-center align-middle">Study Type</th>
            <th className="text-primary text-center align-middle">Sampling Support Form</th>
            <th className="text-primary text-center align-middle border-left-3">Number of Parcels</th>
            <th className="text-primary text-center align-middle">Assessed Value</th>
            <th className="text-primary text-center align-middle">True Cash Value</th>
            <th className="text-primary text-center align-middle">Study Ratio</th>
            <th className="text-primary text-center align-middle border-left-3">Projected True Cash Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries({
            '100': 'Agricultural',
            '200': 'Commercial',
            '300': 'Industrial',
            '400': 'Residential',
            '500': 'Timber-Cutover',
            '600': 'Developmental'
          }).map(([classification, classificationName]) => {
            const classificationData = form4018V2Dto.realProperty.classifications[classification as unknown as forms.RealPropertyClassificationCode];

            return <>
              <tr key={classification}>
                <td className="align-middle text-center text-nowrap text-primary font-weight-bold"
                    rowSpan={3}>
                  {classification} - {classificationName}
                </td>
                <td className="align-middle text-center" rowSpan={2}>
                  {showReadOnlyView && classificationData.studyType}
                  {!showReadOnlyView && <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                                      formGroupClass="mb-0"
                                                      ariaLabel="Study Type"
                                                      name={`realProperty.classifications[${classification}].studyType`}>
                    <option value="">Select</option>
                    {STUDY_TYPES_REAL_4018.map(studyType =>
                      <option key={studyType} value={studyType}>{studyType}</option>
                    )}
                  </FormikSelect>
                  }
                </td>
                <td className="align-middle text-center font-weight-bold text-primary">
                  L-4015 A
                </td>
                <td className="align-middle text-center border-left-3">
                  {showReadOnlyView && formatInteger(classificationData.numberOfParcels4015a)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="8"
                                                           className="text-center"
                                                           disableFloatingLabel={true}
                                                           ariaLabel="Number of Parcels"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].numberOfParcels4015a`}/>}
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatInteger(classificationData.sampleAssessedValue4015a)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="15"
                                                           className="text-center"
                                                           aria-required={classificationData.studyType === 'AS'}
                                                           disableFloatingLabel={true}
                                                           ariaLabel="Sample Assessed Value"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].sampleAssessedValue4015a`}/>}
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatInteger(classificationData.trueCashValue4015a)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="15"
                                                           disableFloatingLabel={true}
                                                           aria-required={classificationData.studyType === 'AS'}
                                                           className="text-center"
                                                           ariaLabel="True Cash Value"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].trueCashValue4015a`}/>}
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatDecimal(
                    classificationData.ratioAssessmentToAppraisals4015a as number / 100, 2, true
                  )}
                  {!showReadOnlyView && <FormikPercentageInput formGroupClass="mb-0"
                                                               maxLength="7"
                                                               disableFloatingLabel={true}
                                                               className="text-center"
                                                               ariaLabel="Study Ratio"
                                                               onChange={() => setHasUnsavedChanges(true)}
                                                               name={`realProperty.classifications[${classification}].ratioAssessmentToAppraisals4015a`}/>}
                </td>
                <td className="empty-cell border-left-3"/>
              </tr>
              <tr>
                <td className="align-middle text-center font-weight-bold text-primary">
                  2793
                </td>
                <td className="align-middle text-center border-left-3">
                  {showReadOnlyView && formatInteger(classificationData.numberOfParcels2793)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="8"
                                                           className="text-center"
                                                           disableFloatingLabel={true}
                                                           ariaLabel="Number of Parcels"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].numberOfParcels2793`}/>}
                </td>
                <td className="empty-cell"/>
                <td className="empty-cell"/>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatDecimal(classificationData.ratioAssessmentToAppraisals2793 as number / 100, 2, true)}
                  {!showReadOnlyView && <FormikPercentageInput formGroupClass="mb-0"
                                                               maxLength="7"
                                                               disableFloatingLabel={true}
                                                               className="text-center"
                                                               ariaLabel="2793 Ratio Assessment to Appraisals"
                                                               onChange={() => setHasUnsavedChanges(true)}
                                                               name={`realProperty.classifications[${classification}].ratioAssessmentToAppraisals2793`}/>}
                </td>
                <td className="empty-cell border-left-3"/>
              </tr>
              <tr key={classification + '-totals'}
                  style={{borderBottom: '3px solid rgb(196, 196, 196)'}}>
                <td className="align-middle text-center text-nowrap text-primary font-weight-bold">
                  Totals
                </td>
                <td className="align-middle text-center">
                  {showReadOnlyView && formatInteger(classificationData.assessmentRollClassificationAssessedValue)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="15"
                                                           className="text-center"
                                                           disableFloatingLabel={true}
                                                           placeholder="Assessed Value"
                                                           ariaLabel="Assessment Roll Classification Assessed Value"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].assessmentRollClassificationAssessedValue`}/>}
                </td>
                <td className="align-middle text-right text-nowrap text-primary font-weight-bold border-left-3"
                    colSpan={3}>
                  Overall Study Ratio
                </td>
                <td className="align-middle text-center font-weight-bold">
                  {showReadOnlyView && formatDecimal(classificationData.ratioAssessmentToAppraisalsOverall as number / 100, 2, true)}
                  {!showReadOnlyView && <FormikPercentageInput formGroupClass="mb-0"
                                                               maxLength="7"
                                                               disableFloatingLabel={true}
                                                               className="text-center"
                                                               ariaLabel="Ratio Assessment to Appraisals"
                                                               onChange={() => setHasUnsavedChanges(true)}
                                                               name={`realProperty.classifications[${classification}].ratioAssessmentToAppraisalsOverall`}/>}
                </td>
                <td className="align-middle text-center border-left-3">
                  {showReadOnlyView && formatInteger(classificationData.projectedTrueCashValue)}
                  {!showReadOnlyView && <FormikNumberInput formGroupClass="mb-0"
                                                           maxLength="15"
                                                           disableFloatingLabel={true}
                                                           className="text-center"
                                                           ariaLabel="Projected True Cash Value"
                                                           onChange={() => setHasUnsavedChanges(true)}
                                                           name={`realProperty.classifications[${classification}].projectedTrueCashValue`}/>}
                </td>
              </tr>
            </>;
          })}
        </tbody>
        <tfoot>
          <tr className="bg-light">
            <td className="text-center align-middle font-weight-bold text-primary">Totals</td>
            <td/>
            <td className="text-center align-middle font-weight-bold">
              {formatInteger(totals.realProperty.assessmentRollClassificationAssessedValue)}
            </td>
            <td className="border-left-3 text-center align-middle font-weight-bold">
              {formatInteger(totals.realProperty.numberOfParcels)}
            </td>
            <td/>
            <td/>
            <td/>
            <td className="text-center align-middle font-weight-bold border-left-3">
              {formatInteger(totals.realProperty.projectedTrueCashValue)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
    <Row>
      <Col>
        {showReadOnlyView && <>
          Estimated Values (Explain)<br/>
          {form4018V2Dto.realProperty.estimatedValues}
        </>}
        {!showReadOnlyView && <FormikInput maxLength="240"
                                           labelText="Estimated Values (Explain)"
                                           onChange={() => setHasUnsavedChanges(true)}
                                           name="realProperty.estimatedValues"/>}
      </Col>
    </Row>
    <Row>
      <Col>
        {showReadOnlyView && <>
          Remarks<br/>
          {form4018V2Dto.realProperty.remarks}
        </>}
        {!showReadOnlyView && <FormikInput maxLength="240"
                                           onChange={() => setHasUnsavedChanges(true)}
                                           labelText="Remarks"
                                           name="realProperty.remarks"/>}
      </Col>
    </Row>
  </>;
};

export default Form4018V2RealPropertyTab;