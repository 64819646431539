import * as Yup from 'yup';

import * as messages from '../messages';

const studyCertificationSchema = (year: number) => {
  const isPinRequired = year >= 2025;

  return Yup.object().shape({
    hasLandValueAnalysisBeenUploaded: Yup.boolean()
      .isTrue(messages.REQUIRED),
    hasEcfAnalysisBeenUploaded: Yup.boolean()
      .isTrue(messages.REQUIRED),
    hasForm3215BeenUploaded: !isPinRequired ? Yup.boolean()
      .isTrue(messages.REQUIRED) : Yup.boolean(),
    pin: Yup.string()
      .max(6)
      .required(messages.REQUIRED)
  });
};

export default studyCertificationSchema;