import {memo, useCallback, useMemo} from 'react';
import {Badge, Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';

import {StudyCertificationType} from '../../types';
import {formatDate} from '../../utils';

type Props = {
  onClick: () => void
  studyCertification: StudyCertificationType
}

const StudyCertificationCard = ({
                                  onClick,
                                  studyCertification: {
                                    submitAvailable,
                                    submitted,
                                    dueOn
                                  }
                                }: Props) => {
  const handleClick = useCallback(() => {
    if (submitAvailable) {
      onClick();
    }
  }, [
    submitAvailable,
    onClick
  ]);

  const style = useMemo(() => {
    return submitAvailable ? {cursor: 'pointer'} : {opacity: 0.7, cursor: 'default'};
  }, [
    submitAvailable
  ]);

  return (
    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
      <Card className="h-100"
            title="Study Certification"
            style={style}
            aria-label="Study Certification"
            role="region"
            tabIndex={submitAvailable ? 0 : -1}
            onKeyDown={(e) => e.key === 'Enter' && submitAvailable && handleClick ? handleClick() : null}
            onClick={handleClick}
            id="study-certification">
        <CardHeader className="bg-secondary text-white">
          Study Certification
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col>
              3215 Study Certification Verification
              <br/>
              Available once forms 4618, 4504, 2793 and 602/603 are submitted
            </Col>
          </Row>
          <Row>
            <Col>Due: {formatDate(dueOn)}</Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row>
            <Col className="col-6"/>
            <Col className="d-flex justify-content-end col-6">
              <Badge color={submitted ? 'success' : 'light'}
                     className="font-size-100 border-0">
                {submitted ? 'SUBMITTED' : 'NOT STARTED'}
              </Badge>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>);
};

export default memo(StudyCertificationCard);
