import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {get} from 'lodash';

import {useUserContext} from '@reasoncorp/kyber-js';

import {
  AcceptModal,
  FormHeader,
  FormHistoryTable,
  LocalUnitFormNavigator,
  ReturnModal,
  StateFormButtons
} from '../../components/shared';
import {form4022Schema} from '../../schemas';
import {AmendmentRequest, Comment, forms, LocalUnitFormDto, ReturnRequest} from '../../types';
import {
  Form4022CountySubmitButton,
  Form4022CountySubmitModal,
  Form4022LuSubmitButton,
  Form4022LuSubmitModal,
  Form4022Table,
  Form4022TotalsTable
} from '../../components/forms/form4022';
import {sum} from '../../utils';
import {usePortal} from '../../hooks';

const mapProperty = (form4022: forms.Form4022Dto,
                     key: string,
                     classification: string) => {
  return {
    propertyCount: get(form4022, `${key}.${classification}.propertyCount`, null),
    boardOfReview1: get(form4022, `${key}.${classification}.boardOfReview1`, null),
    loss: get(form4022, `${key}.${classification}.loss`, null),
    adjustment: get(form4022, `${key}.${classification}.adjustment`, ''),
    newField: get(form4022, `${key}.${classification}.newField`, null)
  };
};

type Props = {
  form: LocalUnitFormDto
  isStateUser: boolean
  isCountyUser: boolean
  isLocalUnitUser: boolean
  loading: boolean
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  onSave: (localUnitFormData: forms.LocalUnitFormData) => void
  onReturn: (returnRequest: ReturnRequest) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.Form4022Dto) => void
  onSubmitToCounty: (localUnitFormData: forms.Form4022Dto) => void
  onSubmitToState: (localUnitFormData: forms.Form4022Dto) => void
  onReturnToLocalUnit: (returnRequest: ReturnRequest) => void
  onResubmit: (localUnitFormData: forms.Form4022Dto) => void
  onAccept: () => void
  isStateViewLocalUnit4022?: boolean
}

const Form4022 = ({
                    form,
                    isStateUser,
                    isCountyUser,
                    isLocalUnitUser,
                    loading,
                    setHasUnsavedChanges,
                    onSave,
                    onReturn,
                    onAmend,
                    onSubmitToCounty,
                    onSubmitToState,
                    onReturnToLocalUnit,
                    onResubmit,
                    onAccept,
                    isStateViewLocalUnit4022 = false
                  }: Props) => {
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [commentModalIsOpen, toggleCommentModal] = useState(false);
  const [returnLocalUnitModalIsOpen, toggleReturnLocalUnitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const {currentUser} = useUserContext();
  const {isStatePortal, isLocalUnitPortal} = usePortal();

  const form4022Dto = useMemo(() => {
    // Use latest form submission data if available for state users
    if (isStateViewLocalUnit4022 && isStateUser && form.latestLocalUnitSubmissionData !== null) {
      return form.latestLocalUnitSubmissionData as forms.Form4022Dto;
    } else if (!isStateViewLocalUnit4022 && isStateUser && form.latestSubmissionData !== null) {
      return form.latestSubmissionData as forms.Form4022Dto;
    } else if (isLocalUnitPortal && form.localUnitData !== null) {
      return form.localUnitData as forms.Form4022Dto;
    } else {
      return form.data as forms.Form4022Dto;
    }
  }, [
    form,
    isStateViewLocalUnit4022,
    isStateUser,
    isLocalUnitPortal
  ]);

  const initialValues = useMemo(() => ({
    realProperty: {
      100: mapProperty(form4022Dto, 'realProperty', '100'),
      200: mapProperty(form4022Dto, 'realProperty', '200'),
      300: mapProperty(form4022Dto, 'realProperty', '300'),
      400: mapProperty(form4022Dto, 'realProperty', '400'),
      500: mapProperty(form4022Dto, 'realProperty', '500'),
      600: mapProperty(form4022Dto, 'realProperty', '600')
    },
    personalProperty: {
      150: mapProperty(form4022Dto, 'personalProperty', '150'),
      250: mapProperty(form4022Dto, 'personalProperty', '250'),
      350: mapProperty(form4022Dto, 'personalProperty', '350'),
      450: mapProperty(form4022Dto, 'personalProperty', '450'),
      550: mapProperty(form4022Dto, 'personalProperty', '550')
    }
  }), [
    form4022Dto
  ]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4022',
      ...formikProps.values
    };
  }, []);

  const calculateBoardOfReview2 = useCallback((item: {
                                                       boardOfReview1: number | null,
                                                       loss: number | null,
                                                       adjustment: number | null,
                                                       newField: number | null
                                                     } | null) => {
    if (item === null) {
      return 0;
    } else {
      const {boardOfReview1 = 0, loss = 0, adjustment = 0, newField = 0} = item;
      const adjustmentNumberValue = adjustment && isFinite(Number(adjustment)) ? Number(adjustment) : 0;
      return (isFinite(Number(boardOfReview1)) ? Number(boardOfReview1) : 0) - (isFinite(Number(loss)) ? Number(loss) : 0) + adjustmentNumberValue + (isFinite(Number(newField)) ? Number(newField) : 0);
    }
  }, []);

  const calculateTotals = useCallback((formikProps: FormikProps<any>) => {
    const values = formikProps.values as forms.Form4022Dto;
    const totals = {
      realProperty: {
        propertyCount: sum(Object.values(values.realProperty).map(c => c.propertyCount || 0)),
        boardOfReview1: sum(Object.values(values.realProperty).map(c => c.boardOfReview1 || 0)),
        boardOfReview2: sum(Object.values(values.realProperty).map(calculateBoardOfReview2)),
        loss: sum(Object.values(values.realProperty).map(c => c.loss || 0)),
        adjustment: sum(Object.values(values.realProperty).map(c => c.adjustment || 0)),
        newField: sum(Object.values(values.realProperty).map(c => c.newField || 0))
      },
      personalProperty: {
        propertyCount: sum(Object.values(values.personalProperty).map(c => c.propertyCount || 0)),
        boardOfReview1: sum(Object.values(values.personalProperty).map(c => c.boardOfReview1 || 0)),
        boardOfReview2: sum(Object.values(values.personalProperty).map(calculateBoardOfReview2)),
        loss: sum(Object.values(values.personalProperty).map(c => c.loss || 0)),
        adjustment: sum(Object.values(values.personalProperty).map(c => c.adjustment || 0)),
        newField: sum(Object.values(values.personalProperty).map(c => c.newField || 0))
      },
      all: {
        propertyCount: 0,
        boardOfReview1: 0,
        boardOfReview2: 0,
        loss: 0,
        adjustment: 0,
        newField: 0
      }
    };

    totals.all = {
      propertyCount: totals.realProperty.propertyCount + totals.personalProperty.propertyCount,
      boardOfReview1: totals.realProperty.boardOfReview1 + totals.personalProperty.boardOfReview1,
      boardOfReview2: totals.realProperty.boardOfReview2 + totals.personalProperty.boardOfReview2,
      loss: totals.realProperty.loss + totals.personalProperty.loss,
      adjustment: totals.realProperty.adjustment + totals.personalProperty.adjustment,
      newField: totals.realProperty.newField + totals.personalProperty.newField
    };

    return totals;
  }, [
    calculateBoardOfReview2
  ]);

  const handleSave = useCallback((formikProps: FormikProps<any>) => {
    return onSave(getSaveableData(formikProps));
  }, [
    getSaveableData,
    onSave
  ]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [
    onAccept,
    acceptModalIsOpen
  ]);

  const wasLastSubmittedByCounty = useMemo(() => {
    return form.hasSubmissions &&
      form.formSubmissions[form.formSubmissions.length - 1].wasSubmittedByCounty;
  }, [
    form.hasSubmissions,
    form.formSubmissions
  ]);

  const shouldShowAmendButton = (
    form.isOverdue ||
    form.formReturns.filter((formReturn) => formReturn.wasReturnedByState).length > 0
  ) && wasLastSubmittedByCounty;

  const toggleCountySubmitModal = useCallback((confirmSubmit = false,
                                               formikProps?: FormikProps<any>,
                                               comment?: Comment) => {
    if (confirmSubmit && formikProps) {
      setHasUnsavedChanges(true);

      if (wasLastSubmittedByCounty && !shouldShowAmendButton) {
        onResubmit(getSaveableData(formikProps));
      } else if (wasLastSubmittedByCounty && shouldShowAmendButton && comment) {
        onAmend({comment: comment.comment, localUnitUser: false}, getSaveableData(formikProps));
      } else {
        onSubmitToState(getSaveableData(formikProps));
      }
    }

    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    onAmend,
    onResubmit,
    shouldShowAmendButton,
    wasLastSubmittedByCounty,
    onSubmitToState,
    getSaveableData,
    setHasUnsavedChanges,
    submitModalIsOpen
  ]);

  const toggleLocalUnitSubmitModal = useCallback((confirmSubmit = false,
                                                  formikProps?: FormikProps<any>,
                                                  comment?: Comment) => {
    if (confirmSubmit && formikProps) {
      setHasUnsavedChanges(true);

      if (!wasLastSubmittedByCounty && !shouldShowAmendButton) {
        onSubmitToCounty(getSaveableData(formikProps));
      } else if (wasLastSubmittedByCounty && shouldShowAmendButton && comment) {
        onAmend({comment: comment.comment, localUnitUser: true}, getSaveableData(formikProps));
      }
    }

    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    onSubmitToCounty,
    onAmend,
    shouldShowAmendButton,
    wasLastSubmittedByCounty,
    getSaveableData,
    setHasUnsavedChanges,
    submitModalIsOpen
  ]);

  const shouldShowStateFormButtons = useMemo(() => {
    return !isStateViewLocalUnit4022 &&
      isStatePortal &&
      form.stateStatus !== 'IN_PROGRESS' &&
      form.formType === 'FORM_4022_AV';
  }, [
    isStatePortal,
    isStateViewLocalUnit4022,
    form.stateStatus,
    form.formType
  ]);

  const isLocked = useMemo(() => {
    return isLocalUnitPortal ? form?.lockedForLocalUnit : form?.locked;
  }, [
    isLocalUnitPortal,
    form
  ]);

  const isReadOnly = useMemo(() => {
    return isStatePortal || isLocked;
  }, [
    isStatePortal,
    isLocked
  ]);

  const shouldShowReturnToLocalUnitButton = useMemo(() => {
    return isCountyUser && form.formType === 'FORM_4022_AV';
  }, [
    isCountyUser,
    form.formType
  ]);

  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => {
    return form.name?.replace('Ad Valorem', '')?.replace('Special Acts', '');
  }, [
    form.name
  ]);

  const shouldShowSaveButton = useMemo(() => {
    return (isLocalUnitUser || isCountyUser) && !isStatePortal;
  }, [
    isLocalUnitUser,
    isCountyUser,
    isStatePortal
  ]);

  const hasBeenSubmittedByLocalUnit = useMemo(() => {
    return form.formSubmissions.filter(fs => fs.wasSubmittedByLocalUnit).length > 0;
  }, [
    form.formSubmissions
  ]);

  const isReturnToLocalUnitDisabled = useMemo(() => {
    return loading || form.locked || !hasBeenSubmittedByLocalUnit;
  }, [
    loading,
    form.locked,
    hasBeenSubmittedByLocalUnit
  ]);

  const hasFormReturns = useMemo(() => {
    return form.formReturns.length > 0;
  }, [
    form.formReturns
  ]);

  return <div className="Form4022">
    <Formik initialValues={initialValues}
            validationSchema={form4022Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const totals = calculateTotals(formikProps);

        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardBody>
              <Form4022Table form={form}
                             showReadOnlyView={isReadOnly}
                             setHasUnsavedChanges={setHasUnsavedChanges}
                             totals={totals.realProperty}
                             classificationsType="real"
                             calculateBoardOfReview2={calculateBoardOfReview2}
                             formikProps={formikProps}/>
              <Form4022Table form={form}
                             showReadOnlyView={isReadOnly}
                             setHasUnsavedChanges={setHasUnsavedChanges}
                             totals={totals.personalProperty}
                             calculateBoardOfReview2={calculateBoardOfReview2}
                             classificationsType="personal"
                             formikProps={formikProps}/>
              <Form4022TotalsTable formYear={form.year}
                                   totals={totals.all}/>
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {shouldShowSaveButton && <Row>
            <Col className="d-flex justify-content-end">
              <Button className="mr-1"
                      disabled={loading || isLocked}
                      onClick={() => handleSave(formikProps)}
                      color="success">
                Save
              </Button>
              {shouldShowReturnToLocalUnitButton && <Button className="mr-1"
                                                            disabled={isReturnToLocalUnitDisabled}
                                                            onClick={() => toggleReturnLocalUnitModalIsOpen(true)}
                                                            color="primary">
                Return to Local Unit
              </Button>}
              <Form4022CountySubmitButton formikProps={formikProps}
                                          isCountyUser={isCountyUser}
                                          loading={loading}
                                          form={form}
                                          wasLastSubmittedByCounty={wasLastSubmittedByCounty}
                                          shouldShowAmendButton={shouldShowAmendButton}
                                          setSubmitModalIsOpen={setSubmitModalIsOpen}
                                          currentUser={currentUser}/>
              <Form4022LuSubmitButton formikProps={formikProps}
                                      isLocalUnitPortal={isLocalUnitPortal}
                                      loading={loading}
                                      isAmending={hasFormReturns}
                                      form={form}
                                      setSubmitModalIsOpen={setSubmitModalIsOpen}
                                      currentUser={currentUser}/>
            </Col>
          </Row>}

          {shouldShowStateFormButtons && <StateFormButtons loading={loading}
                                                           onReturnClick={() => toggleCommentModal(true)}
                                                           onAcceptClick={() => toggleAcceptModal()}/>}

          {!isLocalUnitPortal && <LocalUnitFormNavigator localUnitForm={form}
                                                         isStateUser={isStatePortal}/>}

          {isLocalUnitPortal && <Form4022LuSubmitModal isOpen={submitModalIsOpen}
                                                       onConfirm={(comment: Comment) => toggleLocalUnitSubmitModal(true, formikProps, comment)}
                                                       onCancel={() => toggleLocalUnitSubmitModal(false)}
                                                       formDescription={form.description}
                                                       formName={scrubbedFormName}
                                                       isAmending={hasFormReturns}
                                                       localUnitDisplayName={form.localUnitDisplayName ?? ''}/>}
          {!isStatePortal && isCountyUser &&
            <Form4022CountySubmitModal isOpen={submitModalIsOpen}
                                       onConfirm={(comment: Comment) => toggleCountySubmitModal(true, formikProps, comment)}
                                       onCancel={() => toggleCountySubmitModal(false)}
                                       formDescription={form.description}
                                       isCertifying={!hasBeenSubmittedByLocalUnit}
                                       formName={scrubbedFormName}
                                       isAmending={shouldShowAmendButton}
                                       wasLastSubmittedByCounty={wasLastSubmittedByCounty}
                                       localUnitDisplayName={form.localUnitDisplayName ?? ''}/>}

          {isCountyUser && <ReturnModal formName={scrubbedFormName}
                                        formDescription={form.description}
                                        localityDisplayName={form.localUnitDisplayName ?? ''}
                                        isOpen={returnLocalUnitModalIsOpen}
                                        onReturn={onReturnToLocalUnit}
                                        onCancel={() => toggleReturnLocalUnitModalIsOpen(false)}/>}

          {isStatePortal && <>
            <ReturnModal formName={scrubbedFormName}
                         formDescription={form.description}
                         localityDisplayName={form.localUnitDisplayName ?? ''}
                         isOpen={commentModalIsOpen}
                         onReturn={onReturn}
                         onCancel={() => toggleCommentModal(false)}/>

            <AcceptModal isOpen={acceptModalIsOpen}
                         onConfirm={() => toggleAcceptModal(true)}
                         onCancel={toggleAcceptModal}
                         formName={scrubbedFormName}
                         formDescription={form.description}
                         localityDisplayName={form.localUnitDisplayName ?? ''}/>
          </>}
        </>;
      }}
    </Formik>
  </div>;
};

export default Form4022;