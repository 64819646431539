import * as Yup from 'yup';

import * as messages from '../../messages';

const appraisalReportFormSchema = Yup.object().shape({
  localUnitId: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
});

export default appraisalReportFormSchema;