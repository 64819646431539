import * as Yup from 'yup';
import {TestContext} from 'yup';

import * as messages from '../../messages';
import {minValueMessage} from '../../messages';
import {STUDY_TYPES_PERSONAL_4018, STUDY_TYPES_REAL_4018} from '../../utils';
import {transformBlankToNull} from '../helpers';

const numberSchema = (maxValue: number = 999999999999) => Yup.number()
  .nullable()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(maxValue, messages.maxValueMessage(maxValue))
  .transform(transformBlankToNull);

const percentageSchema = Yup.number()
  .nullable()
  .typeError(messages.MUST_BE_NUMBER)
  .min(0, messages.MUST_NOT_BE_NEGATIVE)
  .max(999.99, messages.maxValueMessage('999.99%'))
  .transform(transformBlankToNull);

const numberRequiredByStudyTypeSchema = Yup.number()
  .when('studyType', {
    is: 'AS',
    then: numberSchema(999999999999)
      .min(1, minValueMessage(1))
      .required(messages.REQUIRED),
    otherwise: numberSchema(999999999999)
  });

type Values = {
  classifications: {
    [classification: string]: {
      studyType: string
    }
  }
  estimatedValues: string | null
}

const validateEstimatedValues = function (this: TestContext, values: any) {
  if (!values) {
    return true;
  }
  const classificationData = values as Values;
  const valuesWithEs = Object.values(classificationData.classifications).filter((c) => c.studyType === 'ES');
  const isValid = valuesWithEs.length === 0 ||
    (valuesWithEs.length > 0 && (classificationData.estimatedValues !== '' && classificationData.estimatedValues !== null));

  return isValid || this.createError({
    path: `${this.path}.estimatedValues`,
    message: messages.REQUIRED
  });
};

const realPropertySchema = Yup.object().shape({
  studyType: Yup.string()
    .nullable()
    .oneOf(
      STUDY_TYPES_REAL_4018.concat(''),
      `Study type must be one of the following values: ${STUDY_TYPES_REAL_4018.join(', ')}`
    ),
  assessmentRollClassificationAssessedValue: numberSchema(999999999999),
  sampleAssessedValue4015a: numberRequiredByStudyTypeSchema,
  numberOfParcels4015a: numberSchema(9999999),
  numberOfParcels2793: numberSchema(9999999),
  trueCashValue4015a: numberRequiredByStudyTypeSchema,
  ratioAssessmentToAppraisals4015a: percentageSchema,
  ratioAssessmentToAppraisals2793: percentageSchema,
  ratioAssessmentToAppraisalsOverall: percentageSchema,
  projectedTrueCashValue: numberSchema(999999999999)
});

const personalPropertySchema = Yup.object().shape({
  studyType: Yup.string()
    .nullable()
    .oneOf(
      STUDY_TYPES_PERSONAL_4018.concat(''),
      `Study type must be one of the following values: ${STUDY_TYPES_PERSONAL_4018.join(', ')}`
    ),
  assessedValue: numberSchema(999999999999),
  numberOfParcels: numberSchema(9999999),
  sampleAssessedValue: numberSchema(999999999999),
  sampleTrueCashValue: numberSchema(999999999999),
  studyRatio: percentageSchema,
  projectedTrueCashValue: numberSchema(999999999999)
});

export default Yup.object().shape({
  realProperty: Yup.object().shape({
    classifications: Yup.object().shape({
      100: realPropertySchema,
      200: realPropertySchema,
      300: realPropertySchema,
      400: realPropertySchema,
      500: realPropertySchema,
      600: realPropertySchema
    }),
    remarks: Yup.string()
      .max(240, messages.maxCharactersMessage(240)),
    estimatedValues: Yup.string()
      .max(240, messages.maxCharactersMessage(240))
  }).test('validate estimated values', '', validateEstimatedValues),
  personalProperty: Yup.object().shape({
    classifications: Yup.object().shape({
      150: personalPropertySchema,
      250: personalPropertySchema,
      350: personalPropertySchema,
      450: personalPropertySchema,
      550: personalPropertySchema
    }),
    remarks: Yup.string()
      .max(240, messages.maxCharactersMessage(240))
  })
});
