import {ReactNode, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {useUserContext} from '@reasoncorp/kyber-js';

import {PortalContext} from './PortalContext';

type Props = {
  children: ReactNode
}

const PortalContextProvider = ({
                                 children
                               }: Props) => {
  const {permissions} = useUserContext();
  const location = useLocation();

  const isStatePortal = useMemo(() => {
    return permissions.isStateUser && location.pathname.indexOf('state-portal') > -1;
  }, [
    permissions.isStateUser,
    location.pathname
  ]);

  const isCountyPortal = useMemo(() => {
    return permissions.isCountyUser && location.pathname.indexOf('county-portal') > -1;
  }, [
    permissions.isCountyUser,
    location.pathname
  ]);

  const isLocalUnitPortal = useMemo(() => {
    return permissions.isLocalUnitUser && location.pathname.indexOf('local-unit-portal') > -1;
  }, [
    permissions.isLocalUnitUser,
    location.pathname
  ]);

  const portal = useMemo(() => {
    if (isStatePortal) {
      return 'STATE';
    } else if (isCountyPortal) {
      return 'COUNTY';
    } else {
      return 'LOCAL_UNIT';
    }
  }, [
    isStatePortal,
    isCountyPortal
  ]);

  return <PortalContext.Provider value={{portal, isLocalUnitPortal, isStatePortal, isCountyPortal}}>
    {children}
  </PortalContext.Provider>;
};

export default PortalContextProvider;