import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

import {FormStatus} from '../../enum';

type Props = {
  status: string
  onClick?: () => void
  isClickable?: boolean
}

const StateStatusBadge = ({
                            status,
                            onClick,
                            isClickable
                          }: Props) => {
  const color = useMemo(() => {
    if (status === FormStatus.SUBMITTED || status === FormStatus.RECEIVED) {
      return 'danger';
    } else if (status === FormStatus.ACCEPTED) {
      return 'success';
    } else {
      return 'light';
    }
  }, [status]);

  const statusDisplayValue = useMemo(() => {
    if (status === FormStatus.SUBMITTED ||
      status === FormStatus.RECEIVED) {
      return FormStatus.RECEIVED;
    } else {
      return status.replace(/_/g, ' ');
    }
  }, [status]);

  return (
    <Badge color={color}
           tabIndex={isClickable ? 0 : -1}
           className="font-size-100 text-uppercase border-0"
           onClick={onClick}>
      {statusDisplayValue}
    </Badge>
  );
};

export default memo(StateStatusBadge);