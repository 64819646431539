import * as Yup from 'yup';

import {isNumber} from '../../utils';
import {JsonImportFileContent, LocalUnitFormDto} from '../../types';
import formImportJsonFileSchema from './formImportJsonFileSchema';

const localUnitImportSchema = (form: LocalUnitFormDto, fileContent: JsonImportFileContent) => Yup.object().shape({
  jsonFile: formImportJsonFileSchema(form, fileContent)
    .test('validateLocalUnitId', 'Incorrect Local Unit JSON file', (file) => {
      if (file !== null && fileContent !== null) {
        return Object.keys(fileContent)
          .some(key => isNumber(Number(key)) && Number(key) === form.localUnitId);
      }
      return true;
    })
});

export default localUnitImportSchema;