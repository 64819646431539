export const transformBlankToNull = function transformBlankToNull(value: number | null | string, originalValue: number | null | string) {
  if (typeof originalValue === 'number') {
    return value;
  } else if (originalValue === null || originalValue.trim() === '') {
    return null;
  } else {
    return Number(value);
  }
};

export const transformBlankBooleanToNull = function transformBlankToNull(value: boolean | string | null, originalValue: boolean | string | null) {
  if (typeof originalValue === 'boolean') {
    return value;
  } else if (originalValue === null || originalValue.trim() === '') {
    return null;
  } else {
    return Boolean(value);
  }
};