import {useMemo} from 'react';

import {ConfirmationModal} from '@reasoncorp/kyber-js';

import CommentModal from './CommentModal';
import {AmendmentRequest, Comment, CountyFormDto, LocalUnitFormDto} from '../../types';

type Props = {
  form: CountyFormDto | LocalUnitFormDto
  amendModalIsOpen: boolean
  submitFormModalIsOpen: boolean
  resubmitFormModalIsOpen: boolean
  onAmend: (amendmentRequest: AmendmentRequest) => void
  onSubmit: (confirmSubmit: boolean) => void
  onCancelSubmit: (confirmSubmitCancel: boolean) => void
  onResubmit: (confirmResubmit: boolean) => void
  onResubmitCancel: (confirmResubmitCancel: boolean) => void
  toggleAmend: () => void
  isLocalUnit4015?: boolean
}

const FormModals = ({
                      form,
                      amendModalIsOpen,
                      submitFormModalIsOpen,
                      resubmitFormModalIsOpen,
                      onAmend,
                      onSubmit,
                      onCancelSubmit,
                      onResubmit,
                      onResubmitCancel,
                      toggleAmend,
                      isLocalUnit4015 = false
                    }: Props) => {
  const jurisdictionDisplayName = useMemo(() => {
    return form.localUnitDisplayName || form.countyDisplayName;
  }, [
    form
  ]);

  const isForm4015 = useMemo(() => {
    return form.formType === 'FORM_4015';
  }, [
    form
  ]);

  const SubmitModalBody = useMemo(() => () => {
    if (!isLocalUnit4015 && isForm4015 && !(form as LocalUnitFormDto)?.hasBeenSentToReview) {
      return <p>
        Are you sure you want to submit the {form.name} {form.description} form for <span className="text-danger">{jurisdictionDisplayName}</span> without reconciling in MEG with the local unit?
      </p>;
    } else if (!isLocalUnit4015 && (!isForm4015 || (form as LocalUnitFormDto)?.hasBeenSentToReview)) {
      return <p>
        Are you sure you want to submit the {form.name} {form.description} form for <span className="text-danger">{jurisdictionDisplayName}</span>?
      </p>;
    } else if (isLocalUnit4015) {
      return <p>
        Are you sure you want to send the {form.name} {form.description} form for <span className="text-danger">{jurisdictionDisplayName}</span> back to the county for review?
      </p>;
    } else {
      return <></>;
    }
  }, [
    jurisdictionDisplayName,
    form,
    isForm4015,
    isLocalUnit4015
  ]);

  const submitModalTitle = useMemo(() => {
    return isLocalUnit4015 ? 'Send Form to County' : 'Submit Form';
  }, [
    isLocalUnit4015
  ]);

  if (!form) {
    return null;
  } else {
    return <>
      <ConfirmationModal isOpen={submitFormModalIsOpen}
                         size="lg"
                         title={submitModalTitle}
                         aria-modal={true}
                         confirmButtonText="Yes"
                         cancelButtonText="Cancel"
                         confirmCallback={() => onSubmit(true)}
                         cancelCallback={() => onCancelSubmit(false)}>
        <SubmitModalBody/>
      </ConfirmationModal>

      <ConfirmationModal isOpen={resubmitFormModalIsOpen}
                         size="lg"
                         title="Resubmit Form"
                         aria-modal={true}
                         confirmButtonText="Yes"
                         cancelButtonText="Cancel"
                         confirmCallback={() => onResubmit(true)}
                         cancelCallback={() => onResubmitCancel(false)}>
        <p>
          Are you sure you want to resubmit the {form.name} {form.description} form for <span className="text-danger">{jurisdictionDisplayName}</span>?
        </p>
      </ConfirmationModal>

      <CommentModal isOpen={amendModalIsOpen}
                    modalTitle="Reason for Amend"
                    onToggle={toggleAmend}
                    onSubmit={(amendmentRequest: Comment) => onAmend(amendmentRequest as AmendmentRequest)}
                    confirmButtonText="Submit"
                    cancelButtonText="Cancel">
        <p>
          Provide a reason for amending the Form {form.name} {form.description} for <span
          className="text-danger">{jurisdictionDisplayName}</span>.
          Amendment of a previously submitted Equalization study form might require additional amendments/resubmission of subsequent forms (2793, L-4018R/P, L-4023, L-4024).
        </p>
      </CommentModal>
    </>;
  }
};

export default FormModals;