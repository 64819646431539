import {MiSuiteRole, ssoUtils, User} from '@reasoncorp/kyber-js';

const isFosAnalystForCounty = (currentUser: User | undefined, countyId: number) => {
  return ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, countyId, MiSuiteRole.FOS_ANALYST);
};

const isFosSupervisorForCounty = (currentUser: User | undefined, countyId: number) => {
  return ssoUtils.hasJurisdictionCanonicalIdAndRole(currentUser, countyId, MiSuiteRole.FOS_SUPERVISOR);
};

export {
  isFosAnalystForCounty,
  isFosSupervisorForCounty
};