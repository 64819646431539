import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object().shape({
  numberOfCities: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(99, messages.maxValueMessage(99))
    .nullable(),
  numberOfTownships: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(99, messages.maxValueMessage(99))
    .nullable(),
  numberOfAuditedOrVerifiedUnits: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .min(0, messages.MUST_NOT_BE_NEGATIVE)
    .max(99, messages.maxValueMessage(99))
    .nullable(),
  requirementsRecognizes: Yup.string().nullable(),
  cedReceivedStatements: Yup.string().nullable(),
  cedRequestedStatements: Yup.string().nullable(),
  equalizationStudyRealistic: Yup.string().nullable(),
  establishedAdequateProgram: Yup.string().nullable(),
  remarks: Yup.string()
    .max(500, messages.maxCharactersMessage(500))
    .nullable()
});