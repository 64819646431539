import * as Yup from 'yup';

import {YupDate} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

const form4014aSchema = (classificationFilter: number) => {
  const propertyClassificationRangeError = `Must be between ${classificationFilter} and ${classificationFilter + 99}`;
  return Yup.object().shape({
    id: Yup.number(),
    propertyNumber: Yup.string()
      .required(messages.REQUIRED)
      .max(30, messages.maxCharactersMessage(30)),
    propertyAddress: Yup.string()
      .max(50, messages.maxCharactersMessage(50)),
    appraiser: Yup.string()
      .max(3, messages.maxCharactersMessage(3)),
    propertyClassification: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .required(messages.REQUIRED)
      .transform(transformBlankToNull)
      .min(classificationFilter, propertyClassificationRangeError)
      .max(classificationFilter + 99, propertyClassificationRangeError),
    currentYearAssessedValue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .required(messages.REQUIRED)
      .transform(transformBlankToNull)
      .min(1, messages.minValueMessage(1))
      .max(999999999999, messages.MAX_ASSESSED_VALUE),
    vacantOrImproved: Yup.string()
      .matches(/[VI]/)
      .required(messages.REQUIRED),
    fieldInspectionOn: YupDate(true)
      .typeError(messages.MUST_BE_VALID_DATE)
      .nullable(),
    dateOfEntry: YupDate(true)
      .typeError(messages.MUST_BE_VALID_DATE)
      .nullable(),
    comments: Yup.string()
      .max(50, messages.maxCharactersMessage(50))
  });
};

export default form4014aSchema;