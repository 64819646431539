import {memo} from 'react';
import {Table} from 'reactstrap';

import {FormikInput, FormikNumberInput, FormikPercentageInput} from '@reasoncorp/kyber-js';

import {formatDecimal, formatInteger} from '../../utils';

type Props = {
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  selectedTab?: string
  calculatedValues: {
    trueCashValueDifference: number
    trueCashValueRatio: number
    percentOfClassStudied: number | null
  }
}

const CedRecapTotalsTable = ({
                               setHasUnsavedChanges,
                               selectedTab,
                               calculatedValues
                             }: Props) => {
  return <Table bordered responsive>
    <thead>
      <tr className="bg-light">
        <th className="text-primary text-center align-middle">Percent of Class Studied</th>
        <th className="text-primary text-center align-middle">CED L-4018 TCV Projection</th>
        <th className="text-primary text-center align-middle">Summary of CED Study Types</th>
        <th className="text-primary text-center align-middle">CED OA Ratio</th>
        <th className="text-primary text-center align-middle">The CED-PSD TCV Difference</th>
        <th className="text-primary text-center align-middle">CED TCV/PSD TCV</th>
      </tr>
    </thead>
    <tbody>
      <tr key={`${selectedTab}-additional-values`}>
        <td className="text-center align-middle">
          {formatDecimal(calculatedValues.percentOfClassStudied, 2, true)}
        </td>
        <td className="text-center align-middle">
          <FormikNumberInput name={`trueCashValueProjection['${selectedTab}']`}
                             maxLength="15"
                             formGroupClass="mb-0"
                             disableFloatingLabel={true}
                             className="text-center"
                             ariaLabel="CED L-4018 TCV Projection"
                             onChange={() => {
                               setHasUnsavedChanges(true);
                             }}/>
        </td>
        <td className="text-center align-middle">
          <FormikInput name={`studyTypeSummary['${selectedTab}']`}
                       maxLength="25"
                       formGroupClass="mb-0"
                       className="text-center"
                       disableFloatingLabel={true}
                       ariaLabel="Summary of CED Study Types"
                       onChange={() => {
                         setHasUnsavedChanges(true);
                       }}/>
        </td>
        <td className="text-center align-middle">
          <FormikPercentageInput maxLength="6"
                                 ariaLabel="CED OA Ratio"
                                 className="text-center"
                                 disableFloatingLabel={true}
                                 name={`qaRatio['${selectedTab}']`}
                                 formGroupClass="mb-0"
                                 onChange={() => {
                                   setHasUnsavedChanges(true);
                                 }}/>
        </td>
        <td className="text-center align-middle">
          {formatInteger(calculatedValues.trueCashValueDifference)}
        </td>
        <td className="text-center align-middle">
          {formatDecimal(calculatedValues.trueCashValueRatio, 1, true)}
        </td>
      </tr>
    </tbody>
  </Table>;
};

export default memo(CedRecapTotalsTable);