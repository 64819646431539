import {useCallback, useMemo, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  LocalUnitFormNavigator,
  StateFormButtons,
  StateFormModals
} from '../../../components/shared';
import {
  formatBoolean,
  formatDecimal,
  formatInteger,
  STUDY_TYPES_PERSONAL_4018,
  STUDY_TYPES_REAL_4018
} from '../../../utils';
import {form4018V1Schema} from '../../../schemas';
import {AmendmentRequest, forms, LocalUnitFormDto, ReturnRequest} from '../../../types';
import {
  FormikCheckboxGroup,
  FormikInput,
  FormikNumberInput,
  FormikPercentageInput,
  FormikSelect,
  TabNav,
  useTabNav,
  withTabNav
} from '@reasoncorp/kyber-js';

import {calculateTotals, getInitialValues} from '../../../utils/form4018V1Utils';

const tabs = [
  {value: 'realProperty', displayValue: 'Real Property'},
  {value: 'personalProperty', displayValue: 'Personal Property'}
];

type Props = {
  loading: boolean
  form: LocalUnitFormDto
  isStateUser: boolean
  submitButtonText: string
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  isLocalUnitUser: boolean
  onSave: (localUnitFormData: forms.LocalUnitFormData) => void
  onSubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAmend: (amendmentRequest: AmendmentRequest, localUnitFormData: forms.LocalUnitFormData) => void
  onResubmit: (localUnitFormData: forms.LocalUnitFormData) => void
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
}

const Form4018V1 = ({
                      loading,
                      form,
                      isStateUser,
                      submitButtonText,
                      setHasUnsavedChanges,
                      isLocalUnitUser,
                      onSave,
                      onSubmit,
                      onAmend,
                      onResubmit,
                      onAccept,
                      onReturn
                    }: Props) => {
  const {selectedTab} = useTabNav();
  const [commentModalIsOpen, toggleCommentModal] = useState(false);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [resubmitModalIsOpen, setReSubmitModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  // Use latest form submission data if available for state users
  const form4018V1Dto = useMemo(() => (
    isStateUser && form.latestSubmissionData !== null ? form.latestSubmissionData : form.data
  ) as forms.Form4018V1Dto, [form, isStateUser]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4018_P_R' as const,
      realProperty: formikProps.values.realProperty,
      personalProperty: formikProps.values.personalProperty,
      version: 'V1'
    };
  }, []);

  const handleSave = useCallback((formikProps: FormikProps<any>) => {
    onSave(getSaveableData(formikProps));
  }, [
    onSave,
    getSaveableData
  ]);

  const handleAmend = useCallback((formikProps: FormikProps<any>, amendmentRequest: AmendmentRequest) => {
    onAmend(amendmentRequest, getSaveableData(formikProps));
  }, [
    onAmend,
    getSaveableData
  ]);

  const handleSubmit = useCallback((formikProps: FormikProps<any>) => {
    onSubmit(getSaveableData(formikProps));
  }, [
    onSubmit,
    getSaveableData
  ]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      handleSubmit(formikProps);
    }
    setSubmitModalIsOpen(!submitModalIsOpen);
  }, [
    handleSubmit,
    submitModalIsOpen
  ]);

  const handleResubmit = useCallback((formikProps: FormikProps<any>) => {
    onResubmit(getSaveableData(formikProps));
  }, [
    onResubmit,
    getSaveableData
  ]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      handleResubmit(formikProps);
    }
    setReSubmitModalIsOpen(!resubmitModalIsOpen);
  }, [
    handleResubmit,
    resubmitModalIsOpen
  ]);

  const toggleAcceptModal = useCallback((confirmAccept = false) => {
    if (confirmAccept) {
      onAccept();
    }
    setAcceptModalIsOpen(!acceptModalIsOpen);
  }, [
    acceptModalIsOpen,
    onAccept
  ]);

  const shouldDisplayFormActionButtons = useMemo(() => {
    return !isStateUser && !isLocalUnitUser;
  }, [
    isStateUser,
    isLocalUnitUser
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser && form.status !== 'IN_PROGRESS';
  }, [
    isStateUser,
    form.status
  ]);

  const showReadOnlyView = useMemo(() => {
    return isStateUser || isLocalUnitUser || form.locked;
  }, [
    isStateUser,
    isLocalUnitUser,
    form.locked
  ]);

  return <div className="Form4018">
    <Formik initialValues={getInitialValues(form4018V1Dto)}
            validationSchema={form4018V1Schema}
            enableReinitialize={true}
            onSubmit={async () => null}
            validateOnMount={true}>
      {(formikProps) => {
        const totals = calculateTotals(formikProps.values as forms.Form4018V1Dto);
        return <>
          <Card className="mb-3">
            <FormHeader form={form}/>
            <CardHeader className="nav-tabs-header">
              <TabNav/>
            </CardHeader>
            <CardBody>
              {selectedTab === 'realProperty' ?
                <>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th colSpan={5} className="text-primary text-center align-middle">Assessment Roll Classification</th>
                        <th colSpan={3} className="text-primary text-center align-middle border-left-3">Sample</th>
                        <th colSpan={3} className="border-left-3"/>
                      </tr>
                      <tr>
                        <th className="text-primary text-center align-middle">Class of Real Property</th>
                        <th className="text-primary text-center align-middle">Study Type</th>
                        <th className="text-primary text-center align-middle">Stratified Study</th>
                        <th className="text-primary text-center align-middle">Combined Study</th>
                        <th className="text-primary text-center align-middle">Assessed Value</th>
                        <th className="text-primary text-center align-middle border-left-3">Number of Parcels</th>
                        <th className="text-primary text-center align-middle">Assessed Value</th>
                        <th className="text-primary text-center align-middle">True Cash Value</th>
                        <th className="text-primary text-center align-middle border-left-3">% Ratio Assessments to Appraisals</th>
                        <th className="text-primary text-center align-middle">Projected True Cash Value</th>
                        <th className="text-primary text-left align-middle">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries({
                        '100': 'Agricultural',
                        '200': 'Commercial',
                        '300': 'Industrial',
                        '400': 'Residential',
                        '500': 'Timber-Cutover',
                        '600': 'Developmental'
                      }).map(([classification, classificationName]) => {
                        const classificationData = formikProps.values.realProperty.classifications[classification as unknown as forms.RealPropertyClassificationCode];
                        return <tr key={classification}>
                          <td className="align-middle text-center text-nowrap text-primary font-weight-bold">
                            {classification} - {classificationName}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ? classificationData.studyType :
                              <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                            formGroupClass="mb-0"
                                            ariaLabel="Study Type"
                                            name={`realProperty.classifications[${classification}].studyType`}>
                                <option value="">Select</option>
                                {STUDY_TYPES_REAL_4018.map(studyType =>
                                  <option key={studyType} value={studyType}>{studyType}</option>
                                )}
                              </FormikSelect>
                            }
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatBoolean(classificationData.stratifiedStudy) :
                              <FormikCheckboxGroup formGroupClass="mb-0" checkboxes={[{
                                onChange: () => setHasUnsavedChanges(true),
                                ariaLabel: 'Stratified Study',
                                name: `realProperty.classifications[${classification}].stratifiedStudy`
                              }]}/>
                            }
                          </td>
                          <td className="align-middle text-center">
                            {
                              showReadOnlyView ?
                                formatBoolean(classificationData.combinedStudy) :
                                <FormikCheckboxGroup formGroupClass="mb-0"
                                                     checkboxes={[{
                                                       onChange: () => setHasUnsavedChanges(true),
                                                       ariaLabel: 'Combined Study',
                                                       name: `realProperty.classifications[${classification}].combinedStudy`
                                                     }]}/>
                            }
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.assessmentRollClassificationAssessedValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Assessment Roll Classification Assessed Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`realProperty.classifications[${classification}].assessmentRollClassificationAssessedValue`}/>}
                          </td>
                          <td className="align-middle text-center border-left-3">
                            {showReadOnlyView ?
                              formatInteger(classificationData.numberOfParcels) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="8"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Number of Parcels"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`realProperty.classifications[${classification}].numberOfParcels`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.sampleAssessedValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Sample Assessed Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`realProperty.classifications[${classification}].sampleAssessedValue`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.trueCashValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 className="text-center"
                                                 ariaLabel="True Cash Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`realProperty.classifications[${classification}].trueCashValue`}/>}
                          </td>
                          <td className="align-middle text-center border-left-3">
                            {showReadOnlyView ?
                              formatDecimal(classificationData.ratioAssessmentToAppraisals as number / 100, 2, true) :
                              <FormikPercentageInput formGroupClass="mb-0"
                                                     maxLength="7"
                                                     disableFloatingLabel={true}
                                                     className="text-center"
                                                     ariaLabel="Ratio Assessment to Appraisals"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`realProperty.classifications[${classification}].ratioAssessmentToAppraisals`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.projectedTrueCashValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 className="text-center"
                                                 ariaLabel="Projected True Cash Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`realProperty.classifications[${classification}].projectedTrueCashValue`}/>}
                          </td>
                          <td className="align-middle text-left">
                            {showReadOnlyView ?
                              classificationData.remarks :
                              <FormikInput formGroupClass="mb-0"
                                           maxLength="240"
                                           ariaLabel="Remarks"
                                           disableFloatingLabel={true}
                                           className="text-left"
                                           onChange={() => setHasUnsavedChanges(true)}
                                           name={`realProperty.classifications[${classification}].remarks`}/>}
                          </td>
                        </tr>;
                      })}
                    </tbody>
                    <tfoot>
                      <tr className="bg-light">
                        <td className="text-center align-middle font-weight-bold text-primary">Totals</td>
                        <td/>
                        <td/>
                        <td/>
                        <td className="text-center align-middle font-weight-bold">
                          {formatInteger(totals.realProperty.assessmentRollClassificationAssessedValue)}
                        </td>
                        <td className="border-left-3 text-center align-middle font-weight-bold">
                          {formatInteger(totals.realProperty.numberOfParcels)}
                        </td>
                        <td/>
                        <td/>
                        <td className="border-left-3"/>
                        <td className="text-center align-middle font-weight-bold">
                          {formatInteger(totals.realProperty.projectedTrueCashValue)}
                        </td>
                        <td/>
                      </tr>
                    </tfoot>
                  </Table>
                  <Row>
                    <Col>
                      {showReadOnlyView ? <>
                          Estimated Values (Explain)<br/>
                          {formikProps.values.realProperty.estimatedValues}
                        </> :
                        <FormikInput
                          maxLength="240"
                          labelText="Estimated Values (Explain)"
                          onChange={() => setHasUnsavedChanges(true)}
                          name="realProperty.estimatedValues"/>
                      }
                    </Col>
                  </Row>
                </> :
                <>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-primary text-center align-middle"/>
                        <th colSpan={3} className="text-primary text-center align-middle border-left-3">Sample</th>
                        <th colSpan={2} className="text-primary text-center align-middle border-left-3"/>
                      </tr>
                      <tr>
                        <th className="text-primary text-center align-middle">Class of Personal Property</th>
                        <th className="text-primary text-center align-middle">Study Type</th>
                        <th className="text-primary text-center align-middle">Unit Ending Assessed Value</th>
                        <th className="text-primary text-center align-middle">Number of Parcels</th>
                        <th className="text-primary text-center align-middle border-left-3">Assessed Value</th>
                        <th className="text-primary text-center align-middle">True Cash Value</th>
                        <th className="text-primary text-center align-middle">Study % Ratio</th>
                        <th className="text-primary text-center align-middle border-left-3">Unit Starting True Cash Value</th>
                        <th className="text-primary text-left align-middle">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries({
                        '150': 'Agricultural',
                        '250': 'Commercial',
                        '350': 'Industrial',
                        '450': 'Residential',
                        '550': 'Utility'
                      }).map(([classification, classificationName]) => {
                        const classificationData = formikProps.values.personalProperty.classifications[classification as unknown as forms.PersonalPropertyClassificationCode];
                        return <tr key={classification}>
                          <td className="align-middle text-center text-nowrap text-primary font-weight-bold">
                            {classification} - {classificationName}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              classificationData.studyType :
                              <FormikSelect onChange={() => setHasUnsavedChanges(true)}
                                            formGroupClass="mb-0"
                                            ariaLabel="Study Type"
                                            name={`personalProperty.classifications[${classification}].studyType`}>
                                <option value="">Select</option>
                                {STUDY_TYPES_PERSONAL_4018.map(studyType => {
                                  return <option key={studyType} value={studyType}>{studyType}</option>;
                                })}
                              </FormikSelect>
                            }
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.unitEndingAssessedValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Unit Ending Assessed Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`personalProperty.classifications[${classification}].unitEndingAssessedValue`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.numberOfParcels) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="9"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Number of Parcels"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`personalProperty.classifications[${classification}].numberOfParcels`}/>}
                          </td>
                          <td className="align-middle text-center border-left-3">
                            {showReadOnlyView ? formatInteger(classificationData.assessedValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 className="text-center"
                                                 ariaLabel="Assessed Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`personalProperty.classifications[${classification}].assessedValue`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatInteger(classificationData.trueCashValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 disableFloatingLabel={true}
                                                 className="text-center"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 ariaLabel="True Cash Value"
                                                 name={`personalProperty.classifications[${classification}].trueCashValue`}/>}
                          </td>
                          <td className="align-middle text-center">
                            {showReadOnlyView ?
                              formatDecimal(classificationData.studyRatio as number / 100, 2, true) :
                              <FormikPercentageInput formGroupClass="mb-0"
                                                     maxLength="7"
                                                     className="text-center"
                                                     disableFloatingLabel={true}
                                                     ariaLabel="Study Ratio"
                                                     onChange={() => setHasUnsavedChanges(true)}
                                                     name={`personalProperty.classifications[${classification}].studyRatio`}/>}
                          </td>
                          <td className="align-middle text-center border-left-3">
                            {showReadOnlyView ?
                              formatInteger(classificationData.unitStartingTrueCashValue) :
                              <FormikNumberInput formGroupClass="mb-0"
                                                 maxLength="15"
                                                 className="text-center"
                                                 disableFloatingLabel={true}
                                                 ariaLabel="Unit Starting True Cash Value"
                                                 onChange={() => setHasUnsavedChanges(true)}
                                                 name={`personalProperty.classifications[${classification}].unitStartingTrueCashValue`}/>}
                          </td>
                          <td className="align-middle text-left">
                            {showReadOnlyView ?
                              classificationData.remarks :
                              <FormikInput formGroupClass="mb-0"
                                           maxLength="240"
                                           ariaLabel="Remarks"
                                           disableFloatingLabel={true}
                                           className="text-left"
                                           onChange={() => setHasUnsavedChanges(true)}
                                           name={`personalProperty.classifications[${classification}].remarks`}/>}
                          </td>
                        </tr>;
                      })}
                    </tbody>
                    <tfoot>
                      <tr className="bg-light">
                        <td className="text-center align-middle text-primary font-weight-bold">Totals</td>
                        <td/>
                        <td className="text-center align-middle font-weight-bold">
                          {formatInteger(totals.personalProperty.unitEndingAssessedValue)}
                        </td>
                        <td className="text-center align-middle font-weight-bold">
                          {formatInteger(totals.personalProperty.numberOfParcels)}
                        </td>
                        <td className="border-left-3"/>
                        <td/>
                        <td/>
                        <td className="border-left-3 text-center align-middle font-weight-bold">
                          {formatInteger(totals.personalProperty.unitStartingTrueCashValue)}
                        </td>
                        <td/>
                      </tr>
                    </tfoot>
                  </Table>
                  <Row>
                    <Col>
                      {showReadOnlyView ? <>
                          Estimated Values <br/>
                          {formikProps.values.personalProperty.estimatedValues}
                        </> :
                        <FormikInput maxLength="240"
                                     onChange={() => setHasUnsavedChanges(true)}
                                     labelText="Estimated Values (Explain)"
                                     name="personalProperty.estimatedValues"/>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {showReadOnlyView ? <>
                          Additional Remarks<br/>
                          {formikProps.values.personalProperty.additionalRemarks}
                        </> :
                        <FormikInput maxLength="240"
                                     onChange={() => setHasUnsavedChanges(true)}
                                     labelText="Additional Remarks"
                                     name="personalProperty.additionalRemarks"/>}
                    </Col>
                  </Row>
                </>}
            </CardBody>
          </Card>

          <FormHistoryTable items={form.formHistory}/>

          {shouldDisplayFormActionButtons && <>
            <FormActionButtons submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || form.locked}
                               saveDisabled={loading || form.locked}
                               submitButtonText={submitButtonText}
                               onSave={() => handleSave(formikProps)}
                               onToggleCommentModal={() => toggleCommentModal(true)}
                               onToggleResubmitModal={() => toggleResubmitModal(false, formikProps)}
                               onToggleSubmitModal={() => toggleSubmitModal(false, formikProps)}/>

            <FormModals form={form}
                        resubmitFormModalIsOpen={resubmitModalIsOpen}
                        submitFormModalIsOpen={submitModalIsOpen}
                        amendModalIsOpen={commentModalIsOpen}
                        onSubmit={() => toggleSubmitModal(true, formikProps)}
                        onCancelSubmit={() => toggleSubmitModal(false)}
                        onResubmit={() => toggleResubmitModal(true, formikProps)}
                        onResubmitCancel={() => toggleResubmitModal(false)}
                        onAmend={(values) => handleAmend(formikProps, values)}
                        toggleAmend={() => toggleCommentModal(false)}/>
          </>}

          {shouldDisplayStateFormButtons && <>
            <StateFormModals onReturn={onReturn}
                             returnModalIsOpen={commentModalIsOpen}
                             onReturnCancel={() => toggleCommentModal(false)}
                             onAccept={() => toggleAcceptModal(true)}
                             acceptModalIsOpen={acceptModalIsOpen}
                             onAcceptCancel={() => toggleAcceptModal()}
                             form={form}/>
            <StateFormButtons loading={loading}
                              onAcceptClick={() => toggleAcceptModal()}
                              onReturnClick={() => toggleCommentModal(true)}/>
          </>}

          {!isLocalUnitUser && <LocalUnitFormNavigator localUnitForm={form}
                                                       isStateUser={isStateUser}/>}
        </>;
      }}
    </Formik>
  </div>;
};

export default withTabNav(Form4018V1, {tabs});