import {useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps, FormikValues} from 'formik';

import {FormikDatePicker, FormikInput, FormikNumberInput, FormikSelect} from '@reasoncorp/kyber-js';

import {form4014aSchema} from '../../../schemas';
import {forms} from '../../../types';

type Props = {
  property?: forms.Form4014aPropertyDto
  isOpen: boolean
  operation: 'Add' | 'Edit'
  classificationFilter: number | string
  onToggle: (operation: 'Add' | 'Edit', property?: forms.Form4014aPropertyDto) => void
}


const Form4014aPropertyModal = ({
                                  isOpen,
                                  operation,
                                  property,
                                  onToggle,
                                  classificationFilter
                                }: Props) => {
  const validationSchema = useMemo(() => {
    return form4014aSchema(Number(classificationFilter));
  }, [
    classificationFilter
  ]);

  const initialValues = useMemo(() => ({
    id: property?.id || 0,
    propertyNumber: property?.propertyNumber || '',
    propertyAddress: property?.propertyAddress || '',
    appraiser: property?.appraiser || '',
    propertyClassification: property?.propertyClassification || null,
    currentYearAssessedValue: property?.currentYearAssessedValue || null,
    vacantOrImproved: property?.vacantOrImproved || '',
    fieldInspectionOn: property?.fieldInspectionOn || null,
    dateOfEntry: property?.dateOfEntry || null,
    comments: property?.comments || ''
  }), [
    property
  ]);

  const handleToggle = useCallback((formikProps: FormikProps<any>) => {
    onToggle(operation);
    formikProps.resetForm();
  }, [
    onToggle,
    operation
  ]);

  const handleSubmit = useCallback(async (values: FormikValues, actions: FormikHelpers<any>) => {
    const valuesToSubmit = {
      ...values,
      propertyClassification: Number(values.propertyClassification),
      currentYearAssessedValue: Number(values.currentYearAssessedValue)
    } as forms.Form4014aPropertyDto;
    onToggle(operation, valuesToSubmit);
    actions.setSubmitting(false);
    actions.resetForm();
  }, [
    onToggle,
    operation
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal size="lg"
               isOpen={isOpen}
               aria-modal={true}
               toggle={() => handleToggle(formikProps)}
               className="Parcel Modal"
               returnFocusAfterClose={true}
               autoFocus={false}>
          <ModalHeader toggle={() => handleToggle(formikProps)} className="h5" tag="h2">
            {operation === 'Add' ? 'Add Parcel' : 'Edit Parcel'}
          </ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col className="col-4">
                  <FormikInput name="propertyNumber"
                               labelText="Parcel Number"
                               autoFocus
                               aria-required={true}
                               maxLength="30"/>
                </Col>
                <Col className="col-8">
                  <FormikInput name="propertyAddress"
                               labelText="Property Address"
                               maxLength="50"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <FormikInput name="appraiser"
                               labelText="Appraiser Initials"
                               maxLength="3"/>
                </Col>
                <Col className="col-4">
                  <FormikNumberInput name="propertyClassification"
                                     labelText="Property Classification"
                                     aria-required={true}
                                     maxLength="3"/>
                </Col>
                <Col className="col-4">
                  <FormikNumberInput name="currentYearAssessedValue"
                                     aria-required={true}
                                     labelText="Current Year AV"
                                     maxLength="15"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <FormikSelect name="vacantOrImproved"
                                aria-required={true}
                                labelText="Vacant or Improved">
                    <option value="">Select</option>
                    <option value="V">Vacant</option>
                    <option value="I">Improved</option>
                  </FormikSelect>
                </Col>
                <Col className="col-4">
                  <FormikDatePicker labelText="Field Inspection Date"
                                    name="fieldInspectionOn"/>
                </Col>
                <Col className="col-4">
                  <FormikDatePicker labelText="Data Entry Date"
                                    name="dateOfEntry"/>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FormikInput name="comments"
                               labelText="Comments"
                               maxLength="50"/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}>
                {operation === 'Add' ? 'Add' : 'Edit'}
              </Button>
              <Button color="secondary"
                      onClick={() => handleToggle(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default Form4014aPropertyModal;