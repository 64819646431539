import {ChangeEvent, memo, useMemo} from 'react';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {ProgramYear} from '../../types';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  years: ProgramYear[]
  name?: string
  isMega?: boolean
}

const ProgramYearSelect = ({
                             onChange,
                             years,
                             name = 'equalizationYear',
                             isMega = false
                           }: Props) => {
  const renderYear = useMemo(() => (programYear: ProgramYear) => {
    const [year1, year2] = isMega ?
      [programYear.year, programYear.year + 1] :
      [programYear.year - 1, programYear.year];

    const displayValue = `${year1} for ${year2}`;

    return <option key={programYear.year}
                   value={programYear.year}>
      {displayValue}
    </option>;
  }, [isMega]);

  return <FormikSelect labelText="Equalization Cycle"
                       name={name}
                       onChange={onChange}>
    {years.map(renderYear)}
  </FormikSelect>;
};

export default memo(ProgramYearSelect);
