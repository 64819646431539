import {ChangeEvent, memo} from 'react';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {County} from '../../types';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  counties: County[]
}

const CountySelect = ({
                        onChange,
                        counties
                      }: Props) => {
  return <FormikSelect name="county"
                       labelText="County"
                       onChange={onChange}>
    {counties.map(county =>
      <option key={county.displayName} value={county.id}>{county.displayName}</option>)
    }
  </FormikSelect>;
};

export default memo(CountySelect);
