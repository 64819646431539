import {format} from 'date-fns';

// Application strings
export const APP_TITLE = 'MiSuite - MEG';
export const NO_PARCELS_ADDED = 'No parcels added.';
export const NO_FILES_UPLOADED = 'No files uploaded.';

// Growl notification messages
export const API_FAILURE = 'Unable to communicate with the MEG service. Check your connection and try refreshing the page.';
export const API_UNAUTHORIZED = 'You are not authorized to view this resource.';
export const FILE_DELETE_SUCCESSFUL = 'File deleted.';
export const FILE_DELETE_FAILED = 'Unable to delete file.';
export const FILE_SHARE_SUCCESSFUL = 'File shared.';
export const FILE_SHARE_FAILED = 'Unable to share file.';
export const FILE_UPLOAD_SUCCESSFUL = 'File uploaded.';
export const FILE_UPLOAD_FAILURE = 'Unable to upload file.';
export const FORM_LOCK_SUCCESSFUL = 'Form locked.';
export const FORM_LOCK_FAILURE = 'Unable to lock form.';
export const FORM_UNLOCK_SUCCESSFUL = 'Form unlocked.';
export const FORM_UNLOCK_FAILURE = 'Unable to unlock form.';
export const FORM_RETURN_SUCCESSFUL = 'Form returned.';
export const FORM_RETURN_FAILURE = 'Unable to return form.';
export const FORM_SAVE_SUCCESSFUL = 'Form saved.';
export const FORM_SAVE_FAILURE = 'Unable to save form.';
export const FORM_SENT_TO_LOCAL_UNIT_SUCCESSFUL = 'Sent to local unit.';
export const FORM_SENT_TO_LOCAL_UNIT_FAILURE = 'Unable to send to local unit.';
export const FORMS_SENT_TO_LOCAL_UNITS_SUCCESSFUL = 'Forms sent to local units.';
export const FORMS_SENT_TO_LOCAL_UNITS_FAILURE = 'Unable to send forms to local units.';
export const FORM_SUBMIT_SUCCESSFUL = 'Form submitted.';
export const FORM_SUBMIT_FAILURE = 'Unable to submit form.';
export const FORM_RESUBMIT_SUCCESSFUL = 'Form resubmitted.';
export const FORM_RESUBMIT_FAILURE = 'Unable to resubmit form.';
export const FORM_AMEND_SUCCESSFUL = 'Form amended.';
export const FORM_AMEND_FAILURE = 'Unable to amend form.';
export const FORM_ACCEPT_SUCCESSFUL = 'Form accepted.';
export const FORM_ACCEPT_FAILURE = 'Unable to accept form.';
export const FORM_RESET_SUCCESSFUL = 'Form reset.';
export const FORM_RESET_FAILURE = 'Unable to reset form.';
export const FORMS_SUBMIT_SUCCESSFUL = 'Forms submitted.';
export const FORMS_SUBMIT_FAILURE = 'Unable to submit forms.';
export const IMPORT_SUCCESSFUL = 'Import successful.';
export const IMPORT_FAILURE = 'Unable to import.';
export const REPORT_ACCEPT_SUCCESSFUL = 'Report accepted.';
export const REPORT_ACCEPT_FAILURE = 'Unable to accept report.';
export const REPORT_CERTIFICATION_SUCCESSFUL = 'Report certified.';
export const REPORT_CERTIFICATION_FAILURE = 'Unable to certify report.';
export const REPORT_LOCK_SUCCESSFUL = 'Report locked.';
export const REPORT_RETURN_SUCCESSFUL = 'Report returned.';
export const REPORT_RETURN_FAILURE = 'Unable to return report.';
export const REPORT_SAVE_SUCCESSFUL = 'Report saved.';
export const REPORT_SAVE_FAILURE = 'Unable to save report.';
export const REPORT_UNLOCK_SUCCESSFUL = 'Report unlocked.';
export const SIGNATURE_BYPASS_SUCCESSFUL = 'Signature bypassed.';
export const SIGNATURE_BYPASS_FAILURE = 'Unable to bypass signature.';
export const STUDY_CERTIFICATION_SUBMIT_SUCCESSFUL = 'Certification submitted.';
export const STUDY_CERTIFICATION_SUBMIT_FAILURE = 'Unable to submit certification.';
export const STUDY_CERTIFICATION_PIN_INVALID = 'The PIN number you provided does not match our records.';
export const VIEW_FILE_FAILURE = 'Unable to view file.';
export const VIEW_PDF_FAILURE = 'Unable to View PDF.';

// Validation messages
export const maxCharactersMessage = (characters: number) => `Cannot be more than ${characters} characters.`;
export const maxValueMessage = (value: number | string) => `Cannot be more than ${value}.`;
export const minValueMessage = (value: number | string) => `Cannot be less than ${value}.`;
export const mustBeAfterMessage = (date: Date) => `Must be after ${format(date, 'MM/dd/yyyy')}.`;
export const mustBeBeforeMessage = (date: Date) => `Must be before ${format(date, 'MM/dd/yyyy')}.`;

export const BAD_PHONE_NUMBER_FORMAT = 'Phone number must be of format (555) 555-5555.';
export const BAD_FILE_FORMAT_CSV = 'File format must be CSV.';
export const BAD_FILE_FORMAT_JSON = 'File format must be JSON.';
export const BAD_FILE_FORMAT_UPLOAD = 'Invalid file type. Allowed File Types are .pdf, .png, .jpg, .jpeg, .doc, .docx, .txt, .xls, .xlsm, .xlsx, and .csv.';
export const INCORRECT_FORM_JSON = 'Incorrect form JSON file.';
export const INCORRECT_COUNTY_JSON = 'Incorrect County JSON file.';
export const JSON_IMPORT_INVALID = 'Unable to Import Data - Invalid Field(s).';
export const MAX_ASSESSED_VALUE = 'Assessed Value cannot be more than $999,999,999,999.';
export const MAX_FILES_UPLOAD = 'Limit 5 files per upload.';
export const MAX_NUMBER_OF_PARCELS_IN_CLASS = 'Cannot be more than Number of Parcels in Class.';
export const MAX_NUMBER_OF_PARCELS_IN_STUDY = 'Cannot be more than Number of Parcels in Study.';
export const MAX_COMMENT_LENGTH = maxCharactersMessage(200);
export const MAX_VALUE_PERCENT = maxValueMessage('100.00%');
export const MAX_VALUE_NUMBER = maxValueMessage('9,999,999');
export const MAX_FILE_SIZE = 'File size must not be larger than 50MB.';
export const MUST_BE_ALPHANUMERIC = 'Must be an alphanumeric character.';
export const MUST_BE_NUMBER = 'Must be a number.';
export const MUST_BE_VALID_EMAIL = 'Must be a valid email address.';
export const MUST_BE_VALID_DATE = 'Must be a valid date.';
export const MUST_NOT_BE_NEGATIVE = 'Must not be a negative number.';
export const REQUIRED = 'Required';
export const REQUIRED_CERTIFICATION_LEVEL = 'Must be MMAO, MAAO, or MCAO.';