import {FormikProps} from 'formik';

import {JsonImportFileContent} from '../types';

export const readFileContent = (file: File,
                                setFileContent: (json: JsonImportFileContent) => void,
                                formikProps: FormikProps<any>) => {
  if (file !== null) {
    const fileReader = new FileReader();
    fileReader.onloadend = function () {
      try {
        const json = JSON.parse(fileReader.result as string);
        setFileContent(json);
        setTimeout(formikProps.validateForm, 300);
      } catch (e) {
        console.error(e);
        setFileContent(null);
      }
    };

    try {
      fileReader.readAsText(file);
    } catch (e) {
      console.error(e);
    }
  }
};