import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';
import {forms} from '../../types';

const periodSchema = Yup.object({
  numberOfSales: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .max(999999, messages.maxValueMessage(999999))
    .transform(transformBlankToNull),
  totalAssessedValueForSales: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  adjustedAssessedValue: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  totalAdjustedPrices: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .max(999999999999, messages.maxValueMessage(999999999999))
    .transform(transformBlankToNull),
  applicableAdjustmentModifier: Yup.number()
    .typeError(messages.MUST_BE_NUMBER)
    .nullable()
    .max(9.9999, messages.maxValueMessage(9.9999))
    .transform(transformBlankToNull)
});

const classificationSchema = Yup.object().shape({
  adjustmentModifier1: Yup.object({
    beforeAdjustment: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull),
    afterAdjustment: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull)
  }),
  adjustmentModifier2: Yup.object({
    beforeAdjustment: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull),
    afterAdjustment: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
      .max(999999999999, messages.maxValueMessage(999999999999))
      .transform(transformBlankToNull)
  }),
  '24Month': Yup.object({
    period1: periodSchema,
    period2: periodSchema,
    period3: periodSchema,
    period4: periodSchema
  }),
  '12Month': Yup.object({
    period1: periodSchema,
    period2: periodSchema
  })
});

const form4017Schema = (noPropertiesSelections: forms.ClassificationsBooleanMap) => {
  return Yup.object().shape({
    studyYears: Yup.object({
      100: Yup.number().min(1).max(2).nullable(),
      200: Yup.number().min(1).max(2).nullable(),
      300: Yup.number().min(1).max(2).nullable(),
      400: Yup.number().min(1).max(2).nullable(),
      500: Yup.number().min(1).max(2).nullable(),
      600: Yup.number().min(1).max(2).nullable()
    }),
    classifications: Yup.object({
      100: noPropertiesSelections['100'] ? Yup.object({}) : classificationSchema,
      200: noPropertiesSelections['200'] ? Yup.object({}) : classificationSchema,
      300: noPropertiesSelections['300'] ? Yup.object({}) : classificationSchema,
      400: noPropertiesSelections['400'] ? Yup.object({}) : classificationSchema,
      500: noPropertiesSelections['500'] ? Yup.object({}) : classificationSchema,
      600: noPropertiesSelections['600'] ? Yup.object({}) : classificationSchema
    })
  });
};

export default form4017Schema;