import {memo} from 'react';

import {mega} from '../../types';
import {formatDecimal, formatInteger} from '../../utils';

type Props = {
  headerText: string
  isRatioRow?: boolean
  shouldDisplayRatioColumn?: boolean
  totalsRow: mega.LocalUnitReport4017Period
}

const LocalUnitReport4017TotalRow = ({
                                       headerText,
                                       isRatioRow = false,
                                       shouldDisplayRatioColumn = true,
                                       totalsRow
                                     }: Props) => {
  return <tr className="bg-light font-weight-bold">
    <td className="text-nowrap text-primary text-center align-middle" colSpan={3}>
      {headerText}
    </td>
    {!isRatioRow && <>
      <td className="text-center align-middle">
        {formatInteger(totalsRow.numberOfSales)}
      </td>
      <td className="text-center align-middle">
        {formatInteger(totalsRow.totalAssessedValueForSales)}
      </td>
      <td/>
      <td className="text-center align-middle">
        {formatInteger(totalsRow.adjustedAssessedValue)}
      </td>
      <td className="text-center align-middle">
        {formatInteger(totalsRow.totalAdjustedPrices)}
      </td>
    </>}
    {isRatioRow && <td colSpan={5}/>}
    <td className="text-center align-middle">
      {shouldDisplayRatioColumn && formatDecimal(totalsRow.adjustmentRatio, 2, true)}
    </td>
  </tr>;
};

export default memo(LocalUnitReport4017TotalRow);