import * as Yup from 'yup';

import {YupDate} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';

const masterSalesSchema = (countyId: number) => Yup.object().shape({
  psd4015Type: Yup.number()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED),
  propertyId: Yup.string()
    .required(messages.REQUIRED)
    .max(30, messages.maxCharactersMessage(30)),
  verified: Yup.string()
    .max(30, messages.maxCharactersMessage(30)),
  unitNumber: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(countyId + 1000, messages.minValueMessage(countyId + 1000))
    .max(countyId + 10000, messages.maxValueMessage(countyId + 10000)),
  propertyClassification: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .min(100, messages.minValueMessage(100))
    .max(699, messages.maxValueMessage(699)),
  saleOn: YupDate()
    .typeError(messages.MUST_BE_VALID_DATE)
    .required(messages.REQUIRED),
  liberPage: Yup.string()
    .max(30, messages.maxCharactersMessage(30)),
  grantor: Yup.string()
    .required(messages.REQUIRED)
    .max(50, messages.maxCharactersMessage(50)),
  grantee: Yup.string()
    .required(messages.REQUIRED)
    .max(50, messages.maxCharactersMessage(50)),
  instrument: Yup.string()
    .required(messages.REQUIRED),
  assessmentWhenSold: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER)
    .required(messages.REQUIRED)
    .max(999999999999, messages.maxValueMessage(999999999999)),
  unadjustedSalesPrice: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER),
  adjustedSalesPrice: Yup.number()
    .nullable()
    .typeError(messages.MUST_BE_NUMBER),
  ced4015Type: Yup.string()
    .required(messages.REQUIRED),
  cedComments: Yup.string()
    .max(30, messages.maxCharactersMessage(30)),
  psdComments: Yup.string()
    .max(30, messages.maxCharactersMessage(30))
});

export default masterSalesSchema;