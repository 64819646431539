import {useMemo} from 'react';
import {Badge} from 'reactstrap';

import {Report4030Dto} from '../../types/mega';

type Props = {
  report4030Dto: Report4030Dto
  isAdmin: boolean
  isAnalyst: boolean
  isSupervisor: boolean
}

const Report4030Badge = ({
                           report4030Dto: {
                             status
                           },
                           isAdmin,
                           isAnalyst,
                           isSupervisor
                         }: Props) => {
  const isCertifiedForCurrentUser = useMemo(() => {
    return (isAnalyst && (status === 'CERTIFIED_BY_ANALYST' || status === 'CERTIFIED_BY_BOTH')) ||
      (isSupervisor && (status === 'CERTIFIED_BY_SUPERVISOR' || status === 'CERTIFIED_BY_BOTH'));
  }, [
    isSupervisor,
    isAnalyst,
    status
  ]);

  const displayValue = useMemo(() => {
    if (isAdmin && status === 'CERTIFIED_BY_BOTH') {
      return 'RECEIVED';
    } else if (isAdmin && (status !== 'RETURNED' && status !== 'ACCEPTED')) {
      return 'NOT CERTIFIED';
    } else if (isAdmin) {
      return status;
    } else if (isCertifiedForCurrentUser) {
      return 'CERTIFIED';
    } else if (status === 'RETURNED' || status === 'ACCEPTED') {
      return status;
    } else {
      return 'NOT CERTIFIED';
    }
  }, [
    isAdmin,
    status,
    isCertifiedForCurrentUser
  ]);

  const color = useMemo(() => {
    if (displayValue === 'NOT CERTIFIED' ||
      (isAdmin && displayValue === 'RETURNED')) {
      return 'light';
    } else if (displayValue === 'RECEIVED' || (!isAdmin && displayValue === 'RETURNED')) {
      return 'danger';
    } else {
      return 'success';
    }
  }, [
    displayValue,
    isAdmin
  ]);

  return <Badge color={color}>
    {displayValue}
  </Badge>;
};

export default Report4030Badge;