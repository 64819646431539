import * as Yup from 'yup';

import * as messages from '../../messages';
import {transformBlankToNull} from '../helpers';

const numberSchema = Yup.number()
  .typeError(messages.MUST_BE_NUMBER)
  .nullable()
  .max(999999999999, messages.maxValueMessage(999999999999))
  .transform(transformBlankToNull);

export default Yup.object().shape({
  localUnits: Yup.array().of(Yup.object({
    id: Yup.number(),
    displayName: Yup.string(),
    realProperty: Yup.object({
      agricultural: numberSchema,
      commercial: numberSchema,
      industrial: numberSchema,
      residential: numberSchema,
      timberCutover: numberSchema,
      developmental: numberSchema
    }),
    valuations: Yup.object({
      personalProperty: numberSchema,
      homeownerPrincipalResidence: numberSchema,
      commercialPersonalProperty: numberSchema,
      industrialPersonalProperty: numberSchema,
      nonHomesteadPersonalProperty: numberSchema
    })
  }))
});
