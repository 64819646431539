import {memo} from 'react';
import {Table} from 'reactstrap';

import {format4022AdjustmentValueWithCommas, formatInteger} from '../../../utils';

type Props = {
  formYear: number
  totals: {
    propertyCount: number
    boardOfReview1: number
    boardOfReview2: number
    loss: number
    adjustment: number
    newField: number
  }
}

const Form4022TotalsTable = ({
                               formYear,
                               totals
                             }: Props) => {
  return <Table responsive bordered>
    <thead>
      <tr>
        <th className="align-middle text-primary w-20"/>
        <th className="text-center align-middle text-primary w-15">
          Parcel Count
        </th>
        <th className="text-center align-middle text-primary w-15">
          {formYear - 1} Board of Review
        </th>
        <th className="text-center align-middle text-primary w-10">
          Loss
        </th>
        <th className="text-center align-middle text-primary w-15">
          + or ( - ) Adjustment
        </th>
        <th className="text-center align-middle text-primary w-10">
          New
        </th>
        <th className="text-center align-middle text-primary w-15">
          {formYear} Board of Review
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="text-center align-middle text-primary font-weight-bold text-nowrap w-20">
          Total Real and Personal
        </td>
        <td className="text-center align-middle font-weight-bold">
          {formatInteger(totals.propertyCount)}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {formatInteger(totals.boardOfReview1)}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {formatInteger(totals.loss)}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {format4022AdjustmentValueWithCommas(totals.adjustment)}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {formatInteger(totals.newField)}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {formatInteger(totals.boardOfReview2)}
        </td>
      </tr>
    </tbody>
  </Table>;
};

export default memo(Form4022TotalsTable);