import {FormikProps} from 'formik';
import {Button} from 'reactstrap';

import {MiSuiteRole, ssoUtils, User} from '@reasoncorp/kyber-js';

import {LocalUnitFormDto} from '../../../types';

type Props = {
  formikProps: FormikProps<any>
  currentUser: User | undefined
  isLocalUnitPortal: boolean
  form: LocalUnitFormDto
  loading: boolean
  setSubmitModalIsOpen: (isOpen: boolean) => void
  isAmending: boolean
}

const Form4022LuSubmitButton = ({
                                  formikProps,
                                  isLocalUnitPortal,
                                  currentUser,
                                  form,
                                  loading,
                                  isAmending,
                                  setSubmitModalIsOpen
                                }: Props) => {
  const shouldShowSubmitButton = isLocalUnitPortal &&
    ssoUtils.hasJurisdictionCanonicalIdAndRole(
      currentUser,
      form.localUnitId,
      MiSuiteRole.ASSESSOR_OF_RECORD
    );

  let buttonText = 'Submit';

  if (!shouldShowSubmitButton) {
    return null;
  } else if (isAmending) {
    buttonText = 'Amend';
  }

  return <Button disabled={form.lockedForLocalUnit || loading || !formikProps.isValid || formikProps.isSubmitting}
                 onClick={() => setSubmitModalIsOpen(true)}
                 color={isAmending ? 'danger' : 'primary'}>
    {buttonText}
  </Button>;
};

export default Form4022LuSubmitButton;
