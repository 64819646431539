import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, CustomInput, Form, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {TabNav, useTabNav, withTabNav} from '@reasoncorp/kyber-js';
import {
  FormActionButtons,
  FormHeader,
  FormHistoryTable,
  FormModals,
  NoPropertiesModal,
  StateFormButtons,
  StateFormModals
} from '../../components/shared';
import {form4027iSchema} from '../../schemas';
import {AmendmentRequest, CountyFormDto, forms, ReturnRequest} from '../../types';
import {
  Form4027iAdditionalInformationTab,
  Form4027iCedCertificationTab,
  Form4027iLocalUnitsForm,
  Form4027iPersonalPropertyTab,
  Form4027iRealPropertyTab
} from '../../components/forms/form4027i';
import {Form4027iClassificationKey} from '../../types/forms/form4027i';
import * as form4027iUtils from '../../utils/form4027iUtils';

const PERSONAL_PROPERTY = 'personalProperty';
const ADDITIONAL_INFORMATION = 'additionalInformation';
const CED_CERTIFICATION = 'cedCertification';

const tabs = [
  {value: 'agricultural', displayValue: '100 Agricultural'},
  {value: 'commercial', displayValue: '200 Commercial'},
  {value: 'industrial', displayValue: '300 Industrial'},
  {value: 'residential', displayValue: '400 Residential'},
  {value: 'timberCutover', displayValue: '500 Timber-Cutover'},
  {value: 'developmental', displayValue: '600 Developmental'},
  {value: 'personalProperty', displayValue: 'Personal Property'},
  {value: ADDITIONAL_INFORMATION, displayValue: 'Additional Information'},
  {value: CED_CERTIFICATION, displayValue: 'CED Certification'}
];

type Props = {
  form: CountyFormDto
  loading: boolean
  onSave: (countyFormData: forms.CountyFormData) => void
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  onSubmit: (countyFormData: forms.CountyFormData) => void
  submitButtonText: string
  onResubmit: (countyFormData: forms.CountyFormData) => void
  onAmend: (amendmentRequest: AmendmentRequest, countyFormData: object) => void
  isStateUser: boolean
  onAccept: () => void
  onReturn: (returnRequest: ReturnRequest) => void
}

const Form4027i = ({
                     form,
                     loading,
                     onSave,
                     setHasUnsavedChanges,
                     onSubmit,
                     submitButtonText,
                     onResubmit,
                     onAmend,
                     isStateUser,
                     onAccept,
                     onReturn
                   }: Props) => {
  const [noPropertiesModalIsOpen, setNoPropertiesModalIsOpen] = useState(false);
  const [submitFormModalIsOpen, setSubmitFormModalIsOpen] = useState(false);
  const [resubmitFormModalIsOpen, setResubmitFormModalIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [acceptFormModalIsOpen, setAcceptFormModalIsOpen] = useState(false);
  const {selectedTab} = useTabNav();

  const form4027iDto = useMemo(() => {
    // Use latest form submission data if available for state users
    return isStateUser && form.latestSubmissionData !== null ?
      form.latestSubmissionData as forms.Form4027iDto : form.data as forms.Form4027iDto;
  }, [
    isStateUser,
    form
  ]);

  const [noPropertiesSelections, setNoPropertiesSelections] = useState<forms.ClassificationsBooleanMap>(
    form4027iDto?.noPropertiesInClassification
  );

  const filteredLocalUnits = useMemo(() => {
    return form.localUnits.filter(localUnit => {
      // ID 812019 is Manchester City, this was not available until the 2024 for 2025 equalization Cycle
      return form?.year < 2025 ? localUnit.id !== 812019 : true;
    });
  }, [
    form
  ]);

  const getSaveableData = useCallback((formikProps: FormikProps<any>) => {
    return {
      type: 'FORM_4027I' as const,
      noPropertiesInClassification: noPropertiesSelections,
      classifications: formikProps.values.classifications,
      additionalInformation: formikProps.values.additionalInformation,
      cedCertification: formikProps.values.cedCertification
    };
  }, [
    noPropertiesSelections
  ]);

  const handleNoPropertiesSelection = useCallback((formikProps: FormikProps<any>) => {
    // If it was selected let the user unselect with no prompts, otherwise display the confirmation modal
    if (selectedTab && noPropertiesSelections[selectedTab as Form4027iClassificationKey]) {
      const updatedClassificationsBooleanMap = ({
        ...noPropertiesSelections,
        [selectedTab as string]: false
      }) as forms.ClassificationsBooleanMap;
      setNoPropertiesSelections(updatedClassificationsBooleanMap);
      setHasUnsavedChanges(true);
      setTimeout(() => formikProps.validateForm().then(), 300);
    } else {
      setNoPropertiesModalIsOpen(true);
    }
  }, [
    noPropertiesSelections,
    setHasUnsavedChanges,
    selectedTab
  ]);

  const handleSave = useCallback((formikProps: FormikProps<any>) => {
    onSave(getSaveableData(formikProps));
  }, [
    getSaveableData,
    onSave
  ]);

  const handleSubmit = useCallback((formikProps: FormikProps<any>) => {
    onSubmit(getSaveableData(formikProps));
  }, [
    onSubmit,
    getSaveableData
  ]);

  const handleResubmit = useCallback((formikProps: FormikProps<any>) => {
    onResubmit(getSaveableData(formikProps));
  }, [
    onResubmit,
    getSaveableData
  ]);

  const handleAmend = useCallback((amendmentRequest: AmendmentRequest, formikProps: FormikProps<any>) => {
    onAmend(amendmentRequest, getSaveableData(formikProps));
  }, [
    onAmend,
    getSaveableData
  ]);

  const toggleNoPropertiesModal = useCallback((formikProps: FormikProps<any>) => (confirmNoProperties = false) => {
    if (noPropertiesModalIsOpen && selectedTab) {
      if (confirmNoProperties) {
        const noPropertiesSelectionsCopy = {...noPropertiesSelections, [selectedTab as string]: true};
        setNoPropertiesSelections(noPropertiesSelectionsCopy);
        setNoPropertiesModalIsOpen(false);
        void formikProps.setFieldValue(
          `classifications['${selectedTab}']`,
          form4027iUtils.blankClassification(filteredLocalUnits, form?.year)
        );
        setHasUnsavedChanges(true);
        setTimeout(() => formikProps.validateForm().then(), 300);
      } else {
        setNoPropertiesModalIsOpen(false);
      }
    } else {
      setNoPropertiesModalIsOpen(true);
    }
  }, [
    filteredLocalUnits,
    noPropertiesSelections,
    setHasUnsavedChanges,
    selectedTab,
    noPropertiesModalIsOpen,
    form?.year
  ]);

  const toggleCommentModal = useCallback(() => setCommentModalIsOpen(!commentModalIsOpen), [commentModalIsOpen]);

  const toggleSubmitModal = useCallback((confirmSubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmSubmit && formikProps) {
      handleSubmit(formikProps);
    }
    setSubmitFormModalIsOpen(!submitFormModalIsOpen);
  }, [
    submitFormModalIsOpen,
    handleSubmit
  ]);

  const toggleResubmitModal = useCallback((confirmResubmit = false, formikProps?: FormikProps<any>) => {
    if (confirmResubmit && formikProps) {
      handleResubmit(formikProps);
    }
    setResubmitFormModalIsOpen(!resubmitFormModalIsOpen);
  }, [
    resubmitFormModalIsOpen,
    handleResubmit
  ]);

  const toggleAcceptFormModal = useCallback((confirmSubmit = false) => {
    if (confirmSubmit) {
      onAccept();
    }
    setAcceptFormModalIsOpen(!acceptFormModalIsOpen);
  }, [
    acceptFormModalIsOpen,
    onAccept
  ]);

  const handleStudyTypeChange = useCallback((formikProps: FormikProps<any>, index: number) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (selectedTab) {
        setHasUnsavedChanges(true);
        const prevValue = formikProps.values.classifications[selectedTab as Form4027iClassificationKey].localUnits[index].studyType;

        if (e.target.value === 'NC' || e.target.value === 'NS') {
          void formikProps.setFieldValue(`classifications['${selectedTab as Form4027iClassificationKey}'].localUnits[${index}].numberOfParcelsInStudy`, 0);
          void formikProps.setFieldValue(`classifications['${selectedTab as Form4027iClassificationKey}'].localUnits[${index}].numberOfParcelsInClass`, 0);
        } else if (prevValue === 'NC' || prevValue === 'NS') {
          const originalNumberOfParcelsInStudy = form4027iDto.classifications[selectedTab].localUnits[index].numberOfParcelsInStudy;
          void formikProps.setFieldValue(`classifications['${selectedTab as Form4027iClassificationKey}'].localUnits[${index}].numberOfParcelsInStudy`, originalNumberOfParcelsInStudy);
          const originalNumberOfParcelsInClassValue = form4027iDto.classifications[selectedTab].localUnits[index].numberOfParcelsInClass;
          void formikProps.setFieldValue(`classifications['${selectedTab as Form4027iClassificationKey}'].localUnits[${index}].numberOfParcelsInClass`, originalNumberOfParcelsInClassValue);
        }
      }
    }, [
    form4027iDto.classifications,
    setHasUnsavedChanges,
    selectedTab
  ]);

  const shouldDisplayLocalUnitsForm = useMemo(() => {
    return selectedTab &&
      !noPropertiesSelections[selectedTab as string] &&
      selectedTab !== ADDITIONAL_INFORMATION &&
      selectedTab !== CED_CERTIFICATION;
  }, [
    selectedTab,
    noPropertiesSelections
  ]);

  const shouldDisplayClassificationsForm = useMemo(() => {
    return selectedTab &&
      !noPropertiesSelections[selectedTab] &&
      ![PERSONAL_PROPERTY, ADDITIONAL_INFORMATION, CED_CERTIFICATION].includes(selectedTab);
  }, [
    selectedTab,
    noPropertiesSelections
  ]);

  const shouldDisplayPersonalPropertyForm = useMemo(() => {
    return selectedTab &&
      !noPropertiesSelections[selectedTab] &&
      selectedTab === PERSONAL_PROPERTY;
  }, [
    selectedTab,
    noPropertiesSelections
  ]);

  const shouldDisplayNoPropertiesInClass = useMemo(() => {
    return !isStateUser &&
      selectedTab !== ADDITIONAL_INFORMATION &&
      selectedTab !== CED_CERTIFICATION;
  }, [
    isStateUser,
    selectedTab
  ]);

  const showReadOnlyView = useMemo(() => isStateUser || form.locked, [isStateUser, form.locked]);

  const noPropertiesSelectionDisabled = useMemo(() => {
    return showReadOnlyView || form.formType !== 'FORM_4027I_JUNE';
  }, [
    form.formType,
    showReadOnlyView
  ]);

  const isJuneStatusEditable = useMemo(() => {
    return !showReadOnlyView && form.formType === 'FORM_4027I_JUNE';
  }, [
    form.formType,
    showReadOnlyView
  ]);

  const isAugustStatusEditable = useMemo(() => {
    return !showReadOnlyView && form.formType !== 'FORM_4027I_OCT';
  }, [
    showReadOnlyView,
    form.formType
  ]);

  const shouldDisplayStateFormButtons = useMemo(() => {
    return isStateUser && form.status !== 'IN_PROGRESS';
  }, [
    isStateUser,
    form.status
  ]);

  const shouldDisplayFormActionButtons = useMemo(() => !isStateUser, [isStateUser]);

  return (
    <div className="Form4027i">
      <Formik validationSchema={form4027iSchema(noPropertiesSelections)}
              initialValues={form4027iUtils.getInitialValues(form4027iDto, filteredLocalUnits, form?.year)}
              onSubmit={async () => null}
              enableReinitialize={true}
              validateOnMount={true}>
        {(formikProps) => {
          return selectedTab && <>
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <Card className="mb-3">
                <FormHeader form={form}/>
                <CardHeader className="nav-tabs-header">
                  <TabNav/>
                </CardHeader>
                <CardBody>
                  {shouldDisplayNoPropertiesInClass && <>
                    <Row className="mb-4">
                      <Col xs="12" md="6">
                        <CustomInput type="switch"
                                     id="noParcelsSwitch"
                                     name="noParcels"
                                     label="No Parcels in this Classification"
                                     aria-label="No Parcels in this Classification"
                                     onChange={() => handleNoPropertiesSelection(formikProps)}
                                     checked={noPropertiesSelections[selectedTab as Form4027iClassificationKey] as boolean}
                                     disabled={loading || noPropertiesSelectionDisabled}/>
                      </Col>
                    </Row>
                  </>}
                  {shouldDisplayLocalUnitsForm &&
                    <Form4027iLocalUnitsForm filteredLocalUnits={filteredLocalUnits}
                                             handleStudyTypeChange={handleStudyTypeChange}
                                             formikProps={formikProps}
                                             formType={form.formType}
                                             setHasUnsavedChanges={setHasUnsavedChanges}
                                             showReadOnlyView={showReadOnlyView}
                                             selectedTab={selectedTab}/>}

                  {shouldDisplayClassificationsForm && <Form4027iRealPropertyTab
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    showReadOnlyView={showReadOnlyView}
                    formikProps={formikProps}
                    isAugustStatusEditable={isAugustStatusEditable}
                    isJuneStatusEditable={isJuneStatusEditable}
                    selectedTab={selectedTab}
                    formType={form.formType}/>}
                  {shouldDisplayPersonalPropertyForm && <Form4027iPersonalPropertyTab
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    showReadOnlyView={showReadOnlyView}
                    formikProps={formikProps}
                    isAugustStatusEditable={isAugustStatusEditable}
                    isJuneStatusEditable={isJuneStatusEditable}
                    formType={form.formType}/>}

                  {selectedTab === ADDITIONAL_INFORMATION &&
                    <Form4027iAdditionalInformationTab additionalInformation={formikProps.values.additionalInformation}
                                                       showReadOnlyView={showReadOnlyView}/>}
                  {selectedTab === CED_CERTIFICATION &&
                    <Form4027iCedCertificationTab numberOfLocalUnits={filteredLocalUnits.length}
                                                  isStateUser={isStateUser}
                                                  formikProps={formikProps}
                                                  showReadOnlyView={showReadOnlyView}
                                                  setHasUnsavedChanges={setHasUnsavedChanges}
                                                  cedCertification={formikProps.values.cedCertification}/>}
                </CardBody>
              </Card>

              <FormHistoryTable items={form.formHistory || []}/>

              {shouldDisplayFormActionButtons && <>
                <NoPropertiesModal onToggle={toggleNoPropertiesModal(formikProps)}
                                   isOpen={noPropertiesModalIsOpen}
                                   selectedClassificationFilter={form4027iUtils.getCurrentClassificationNumber(selectedTab)}/>
                <FormActionButtons submitDisabled={loading || !formikProps.isValid || formikProps.isSubmitting || showReadOnlyView}
                                   submitButtonText={submitButtonText}
                                   saveDisabled={loading || formikProps.isSubmitting || showReadOnlyView}
                                   onSave={() => handleSave(formikProps)}
                                   onToggleCommentModal={toggleCommentModal}
                                   onToggleResubmitModal={() => toggleResubmitModal(false)}
                                   onToggleSubmitModal={() => toggleSubmitModal(false)}/>
                <FormModals form={form}
                            resubmitFormModalIsOpen={resubmitFormModalIsOpen}
                            submitFormModalIsOpen={submitFormModalIsOpen}
                            amendModalIsOpen={commentModalIsOpen}
                            onSubmit={() => toggleSubmitModal(true, formikProps)}
                            onCancelSubmit={toggleSubmitModal}
                            onResubmit={() => toggleResubmitModal(true, formikProps)}
                            onResubmitCancel={toggleResubmitModal}
                            onAmend={(values) => handleAmend(values, formikProps)}
                            toggleAmend={() => toggleCommentModal()}/>
              </>}

              {shouldDisplayStateFormButtons && <>
                <StateFormButtons loading={loading}
                                  onAcceptClick={() => toggleAcceptFormModal()}
                                  onReturnClick={toggleCommentModal}/>
                <StateFormModals onReturn={(values) => onReturn(values)}
                                 returnModalIsOpen={commentModalIsOpen}
                                 onReturnCancel={toggleCommentModal}
                                 onAccept={() => toggleAcceptFormModal(true)}
                                 acceptModalIsOpen={acceptFormModalIsOpen}
                                 onAcceptCancel={() => toggleAcceptFormModal()}
                                 form={form}/>
              </>}
            </Form>
          </>;
        }}
      </Formik>
    </div>
  );
};

export default withTabNav(Form4027i, {tabs});