import * as Yup from 'yup';

import * as messages from '../../messages';
import {CountyFormDto, JsonImportFileContent} from '../../types';
import formImportJsonFileSchema from './formImportJsonFileSchema';

const filteredKeys = ['type', 'countyId', 'version'];

const countyImportSchema = (form: CountyFormDto, fileContent: JsonImportFileContent) => Yup.object().shape({
  jsonFile: formImportJsonFileSchema(form, fileContent)
    .test('validateLocalUnitIds', messages.JSON_IMPORT_INVALID, (file) => {
      if (file !== null && fileContent !== null) {
        const fileContentLocalUnitIds = fileContent.localUnits ? fileContent.localUnits.map((localUnit) => Number(localUnit.id))
          : Object.keys(fileContent).filter(key => !filteredKeys.includes(key)).map(id => Number(id));
        const formLocalUnitIds: number[] = form.localUnits.map(localUnit => localUnit.id);

        return fileContentLocalUnitIds.every((id: number) => formLocalUnitIds.includes(id));
      }

      return true;
    })
});

export default countyImportSchema;